import { cloneDeep as _cloneDeep } from 'lodash';
import { routes } from './common-values';

import { Data } from '../interfaces/data.interface';
import { Injectable } from "@angular/core";

declare var dataLayer: any;

@Injectable()
export class GtmDataLayer {

  title: string;
  defaultParams = {
    event: 'submit',
    category: '',
    action: '',
    label: ''
  };

  constructor() {}

  recordEvents(path: string, data: Data): void {
    // record the current URL for all events to be logged;
    this.defaultParams.category = path;
    this.title = document.title;
    this.recordVirtualPageView(path, this.title);

    // determine which events to log based off the URL;
    switch (path) {
      case routes.requestorIdentification:
        this.recordEvent(
          'Has your name changed since attending school?',
          data.form.requestor.personal.nameChanged
        );
        this.recordEvent('feePathDesc', data.form.requestor.personal.path);
        this.recordEvent(
          'Are you currently enrolled?',
          data.form.requestor.personal.enrolledCurrently
        );
        this.recordEvent(
          'electronicDeliveryEligibilityQuestion',
          data.form.requestor.personal.enrolledBefore
        );
        break;

      case routes.requestorAddress:
        this.recordEvent(
          'Send order updates via text messaging to this phone number?',
          data.form.requestor.contact.textUpdates
        );
        this.recordEvent(
          'Allow the school to use this information to update their records?',
          data.form.requestor.contact.updateRecords
        );
        break;

      case routes.recipientSelect:
        this.recordEvent('Select Recipient Type', data.form.recipient.who.recipientType);
        this.recordEvent('Select Organization', data.form.recipient.who.organization);
        this.recordEvent('State', data.form.recipient.who.state);
        this.recordEvent('Country', data.form.recipient.who.country);
        break;

      case routes.recipientDeliveryMethod:
        this.recordEvent(
          'What transcript do you want sent?',
          data.form.recipient.delivery.transcriptWhen
        );
        this.recordEvent(
          'What type of transcript do you want?',
          data.form.recipient.delivery.transcriptType
        );
        this.recordEvent(
          'What is the purpose of your transcript?',
          data.form.recipient.delivery.transcriptPurpose
        );
        this.recordEvent('Transcript Delivery Method', data.form.recipient.delivery.deliveryMethod);
        this.recordEvent('How many copies do you want?', data.form.recipient.delivery.howMany);
        break;

      case routes.orderConsent:
        this.recordEvent('Signature', data.form.consent.signature !== null);
        break;

      case routes.orderPayment:
        this.recordEvent(
          'Do you want to use your contact address as your billing address?',
          data.form.payment.useContactInfo
        );
        break;

      case routes.orderConfirmation:
        if (data.response.payment.ccAmount === 0 ){
          this.recordEvent('Free Order', 'Free Order');
        } else {
          this.recordEvent('Paid Order', 'Paid Order');
        }
        this.recordEvent('OrderConfirmation', 'Order Completed');
        break;

      case routes.schoolWelcome:
        this.recordSchoolSelectionEvent(data.schoolProfile.schlName);
        break;
    }
  }

  recordEvent(question: string, value: string | number | boolean): void {
    // only record an event if a value has been set;
    if (value) {
      const params = _cloneDeep(this.defaultParams);

      params.action = question;
      params.label = value;

      dataLayer.push(params);
    }
  }

  recordAddRecipient(): void {
    dataLayer.push({
      event: 'click',
      category: routes.orderReview,
      action: 'Add Recipient',
      label: ''
    });
  }

  recordConsentDownload(): void {
    dataLayer.push({
      event: 'click',
      category: routes.orderConsent,
      action: 'Download Consent PDF',
      label: ''
    });
  }

  recordVirtualPageView(path: string, title: string ): void {
    dataLayer.push({
      event: 'virtualPageview',
      vpURL: '/' + path,
      vpPageTitle: title
    });
  }

  recordSchoolSelectionEvent(value: string): void {
    dataLayer.push({
      event: 'submit-school',
      category: routes.schoolWelcome,
      action: 'School Selected',
      label: value
    });
  }

}
