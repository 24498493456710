<div class="nsc-form-field-toggle {{classGroup}}" >
  <div class="nsc-form-field-toggle__label" [class.nsc-form-field-toggle__label--disabled]="disabled">
    <mat-label id="{{idx}}-lbl" *ngIf="label">{{ label }}</mat-label>
    <div  id="{{idx}}-lbl" *ngIf="isHtmlContent" innerHtml="{{isHtmlContent}}"></div>
    <div class="mat-caption bold">{{ note }}</div>
  </div>
  <div class="nsc-form-field-toggle__actions" [class.nsc-form-field-toggle__actions--disabled]="disabled">
      <mat-radio-group attr.aria-labelledby="{{idx}}-lbl"  name="{{controlName}}" class="radio-group" id="{{ idx}}" [attr.aria-required]="required" >
        <mat-radio-button class="nsc-radio-button" [disableRipple]="true"
          id="{{idx}}_yes"
          value="YES"
          aria-label="{{controlName}}-yes"
          [disableRipple]="true"
          [checked]="group.controls[controlName].value === values.yes"
          (change)="changeValue(values.yes)"
          (click)="clickBtn(values.yes)">yes</mat-radio-button>
       <nsc-spacer horizontal="true"></nsc-spacer>
       <mat-radio-button class="nsc-radio-button" [disableRipple]="true"
          id="{{idx}}_no"
          value="NO"
          aria-label="{{controlName}}-no"
          [disableRipple]="true"
          [checked]="group.controls[controlName].value === values.no"
          (change)="changeValue(values.no)"
          (click)="clickBtn(values.no)">no</mat-radio-button>
      </mat-radio-group>
  </div>
  <div class="nsc-form-field-toggle__help" *ngIf="help">
    <button  [disabled]=disabled mat-icon-button aria-label="Information" (click)="openDialog()">
      <mat-icon aria-hidden="true">info</mat-icon>
    </button>
  </div>
</div>
