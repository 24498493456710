import { Component, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { DialogHelpComponent } from '../dialog-help/dialog-help.component';
import { DataService } from '../../services/data/data.service';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'nsc-form-field-files',
  templateUrl: './form-field-files.component.html',
  styleUrls: ['./form-field-files.component.scss']
})
export class FormFieldFilesComponent implements OnInit {
  public uploader: FileUploader;
  public dataSource: MatTableDataSource<FileItem>;
  public columnsToDisplay = ['itemName', 'itemStatus', 'itemSize', 'itemButtons'];
  public uploadedFiles: any[] = [];
  private reg = /^((?![.]{2})[a-zA-Z0-9 _.-]){1,}$/;
  private infoText: string;
  public uploadStatus: string;
  public removeStatus: string;
  public filesUploadedCount$ = new Subject();
  @Input() url: string;
  @Input() allowedFileTypes: string[];
  @Input() maxFileCount: number;


  constructor(public dialog: MatDialog,
    private dataService: DataService, ) { }

  ngOnInit() {
    this.uploader = new FileUploader({ url: this.url,
      authTokenHeader: "Authorization",
      authToken: this.dataService.get().jsonWebToken });


    this.uploader.onAfterAddingFile = (fileItem: FileItem): any => {
      const uploadedFile = {
        originalFileItem: fileItem
      };
      // push fileItem to array.
      this.uploadedFiles.push(uploadedFile);
      // update datasource
      this.updateDataSource();
      // upload File
      this.uploadFile(fileItem);

    };


    this.uploader.onErrorItem = (item: any, response: any, status: any, headers: any): any => {
      Array.from(this.uploadedFiles).forEach((uploadedFile, index) => {
        if (uploadedFile.originalFileItem === item) {
          this.uploader.removeFromQueue(item);
          this.uploadedFiles.splice(index, 1);
        }
      });
      this.filesUploadedCount$.next(this.uploadedFiles.length);
      this.showAlertMessage('We are unable to accept any file uploads at this time due to a technical difficulty.');
    };


    // only using onSuccessItem, because API will always return a 200 status. It may fail in the API layer, but it will still come here.
    this.uploader.onSuccessItem = (item: any, response: any, status: any, headers: any) => {

      try {
        response = JSON.parse(response);
        this.uploadStatus = response.originalFileName;
        if (response.level === 'ERROR') {
          this.showAlertMessage(response.message);
          response = { status: 'fail' };
          Array.from(this.uploadedFiles).forEach((uploadedFile, index) => {
            if (uploadedFile.originalFileItem === item) {
              this.uploader.removeFromQueue(item);
              this.uploadedFiles.splice(index, 1);
            }
          });
        }
        this.filesUploadedCount$.next(this.uploadedFiles.length);
      }
      catch (e) {
        // 'fail' status will be set by API in case of Vvirus detected' or 'System is out of service'.
        // here, we protect the UI from any other possible problem, such as error parsing. If API returns 'Internal Server Error', we fake a response with 'fail' status.

        response = { status: 'fail' };
      }

      Array.from(this.uploadedFiles).forEach((uploadedFile, index) => {
        if (uploadedFile.originalFileItem === item) {
          uploadedFile.attachmentResponse = response;
        }
      });
    };

    this.dataSource = new MatTableDataSource<FileItem>();

    this.infoText = '<div><ul>' +
      '<li >File name(s): Supports alpha numeric characters, non-consecutive periods, dashes, underscores and spaces</li>' +
      '<li >Maximum file size: 5 MB</li>'+
      '<li >Maximum file name size: 64 characters</li>' +
      '<li >Number of documents allowed: ' + this.maxFileCount + '</li>' +
      '<li >Supported formats: .' + this.allowedFileTypes.join(', .') + '</li>' +
      '<li >Do not upload secured or password protected documents.</li>' +
      '</ul></div>';

  }

  public onChange(event: any): void {
    event.srcElement.value = "";
    if (!this.uploader.isUploading) {
      this.uploader.isUploading = false;
    }
  }
  updateDataSource() {
    this.filesUploadedCount$.next(this.uploadedFiles.length);
    // we update dataSource for the file table.
    this.dataSource.data = this.uploadedFiles;
  }

  // Pass the 'select files' button click to the file input element
  fileInputClick() {
    const fileInput: HTMLElement = document.getElementById('input-files');

    fileInput.click();
  }

  uploadFile(fileItem: FileItem) {
    const validFile = this.validateUploadedFile(fileItem);

    if (validFile) {
      this.uploader.uploadItem(fileItem);
    } else {
      this.removeInvalidFile(fileItem);
    }
  }

  validateUploadedFile(fileItem: FileItem): boolean {
    // Double-check that all file parameters are valid
    let validUpload = true;

    const item = fileItem;
    const filenameArr = item.file.name.split('.'); // split filename into array
    const ext = filenameArr.pop(); // keep last element as the file extension

    // Check filename against regex
    if (!this.reg.test(item.file.name)) {
      validUpload = false;
      
      const message='The document does not meet the file naming conventions. File names may only contain alpha numeric characters, periods, dashes, underscore and spaces. Please ensure your document meets this requirement and try again.';
      this.showAlertMessage(message);
    }

    // Check filename length
    if (item.file.name.length > 64) {
      validUpload = false;
      const message='The file name cannot exceed 64 characters. Please rename the file and try again.';
      this.showAlertMessage(message);
    }

    // Check the extension
    if (filenameArr.length > 0 && this.allowedFileTypes.indexOf(ext.toLowerCase()) < 0) {
      validUpload = false;
      const message= 'The document you are trying to upload does not meet the requirements, it is the wrong file type. Please ensure your document meets this requirement and try again.';
      this.showAlertMessage(message);
    }

    // Check the file size
    if (item.file.size > 5000000) {
      validUpload = false;
      const message= 'The document you are trying to upload does not meet the requirements, it is larger than 5 MB. Please ensure your document meets this requirement and try again.';
      this.showAlertMessage(message);
    }

    return validUpload;
  }


  showAlertMessage(message): void{
    this.dialog.open(DialogHelpComponent, {
      width: '450px',
      ariaLabelledBy: 'uploadFile' + "Title",
      ariaDescribedBy: 'uploadFile'  + "Content",
      autoFocus: false,
      data: {
        body: message,
        title: 'Information',
        id: 'uploadFile'
      }
    });
  }

  removeInvalidFile(uploadedFile: any) {
    const self = this;
    Array.from(this.uploadedFiles).forEach((tempFile: any, index: number) => {
      if (tempFile.originalFileItem.file.name === uploadedFile.file.name) {
        self.uploader.removeFromQueue(tempFile.originalFileItem);
        self.uploadedFiles.splice(index, 1);
      }
    });
    this.updateDataSource();
  }

  removeFileClick(uploadedFile: any) {
    this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      ariaLabelledBy:  "removeFileTitle",
      ariaDescribedBy: "removeFileContent",
      autoFocus: false,
      data: {
        body: 'Are you sure you want to remove the ' + uploadedFile.originalFileItem.file.name + ' file?',
        title: "Information",
        id:"removeFile"
      }
    }).afterClosed().subscribe(result =>{
      if(result){
        const rmStatus = uploadedFile.originalFileItem.file.name + " has been removed.";
        const self = this;
        const alreadyUploadedFiles = this.uploadedFiles;
          Array.from(alreadyUploadedFiles).forEach((tempFile: any, index: number) => {
            if (tempFile.attachmentResponse.fileIdentifier === uploadedFile.attachmentResponse.fileIdentifier) {
              if (self.uploader.queue.length > 0) {

                self.uploader.removeFromQueue(tempFile.originalFileItem);

              } else {
                this.dataService.deleteAttachmentWithFileId(uploadedFile.attachmentResponse.fileIdentifier);
              }
              this.removeStatus = rmStatus;
              self.uploadedFiles.splice(index, 1);
            }
          });
          this.updateDataSource(); 
        }
    });

    
  }

  retryFileClick(uploadedFile: any) {
    const self = this;
    Array.from(this.uploadedFiles).forEach((tempFile: any, index: number) => {
      if (tempFile.originalFileItem.file.name === uploadedFile.originalFileItem.file.name) {
        self.uploadedFiles.splice(index, 1);
      }
    });
    this.uploader.addToQueue(uploadedFile.originalFileItem._file);
    this.uploader.uploadItem(this.uploader.queue[0]);
  }

  // Simple utility methods to format the file byte size
  roundFileSizeValue(val: number): string {
    let size = val;
    let suffix = ' bytes';
    // This could be something more elegant, but I don't
    // need to account for anything bigger than megabytes
    if (size > 1000) {
      size = this.byteRounding(size);
      suffix = ' kB';
    }
    if (size > 1000) {
      size = this.byteRounding(size);
      suffix = ' MB';
    }
    return size + suffix;
  }

  byteRounding(val: number): number {
    return Math.round((val / 1000.0) * 10) / 10;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogHelpComponent, {
      width: this.infoText.length > 300 ? '480px' : '310px',
      autoFocus:false,
      ariaLabelledBy: "modalFileReqTitle",
      ariaDescribedBy: "modalFileReqContent",
      data: {
        title: 'File Requirements',
        body: this.infoText,
        id: "modalFileReq",
      }
    });
  }

}
