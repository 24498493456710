import { FormControl } from '@angular/forms';
import { isEmpty as _isEmpty } from 'lodash';
import { isNull } from 'util';
import { Injectable } from "@angular/core";

@Injectable()
export class ValidationMessage {
  get(formField: FormControl, label: string): string {
    // if no errors, do nothing;
    // if errors, check properties to determine which message to show;
    // only a single message is displayed at a time, so the order of the IF statements is important;
    if (_isEmpty(formField.errors) === false) {
      const errors = formField.errors;
      let message = `${label} is required`; // default error message;

      if (
        errors.casId ||
        errors.creditCardNumber ||
        errors.dateCompare ||
        errors.dateCreditCard ||
        errors.dateOfBirth ||
        errors.email ||
        errors.invalid ||
        errors.max ||
        errors.min ||
        errors.minlength ||
        errors.maxlength ||
        errors.pattern ||
        errors.studentIdPrimary
      ) {
        message = `Invalid ${label}`;
        if ( errors.minlength && errors.studentIdPrimary ){
          
          message = `Required length is ${errors.minlength.requiredLength }` + (errors.maxlength ? ` to ${errors.maxlength}` : '');
       }
      }

      if (errors.schoolAutocomplete) {
        
        if(label.includes('organization')){
          message = 'There is no match for the organization entered';
        }else{
          message = 'There is no match for the school entered';
        }
        
      }

      // will always win out due to being run after all previous form validation;
      if (errors.match) {
        message = `This does not match ${label.replace('Confirm', '')}`;
      }

      return message;
    }
  }
}
