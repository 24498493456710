import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { empty as observableEmpty, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommonValues } from '../../classes/common-values';
import { DataService } from '../data/data.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';

@Injectable()
export class SecurityService  {
  showDeactivateWarning = false;

  constructor(
    private commonValues: CommonValues,
    private dataService: DataService,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  canDeactivate(): boolean {
    const confirmMessage =
      'We do not recommend using the browser back button if you want to proceed with this order. To exit the Transcript Ordering Center, please select ‘OK’. If you would like to continue with your order but require changes, select ‘Cancel’ and review the available editing options.';

    return this.showDeactivateWarning ? window.confirm(confirmMessage) : true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    switch (state.url.replace('/', '')) {
      // these routes requite a school to be selected;
      case this.commonValues.routes.schoolWelcome:
      case this.commonValues.routes.requestorIdentification:
      case this.commonValues.routes.requestorAddress:
      case this.commonValues.routes.requestorAttend:
      case this.commonValues.routes.recipientSelect:
        if (this.dataService.isSchoolNull()) {
          this.loadSafeURL();
          return false;
        }
        break;

      // these routes require a school to be selected and a recipient started;
      case this.commonValues.routes.recipientDeliveryMethod:
      case this.commonValues.routes.recipientAddress:
      case this.commonValues.routes.recipientEmail:
        if (this.dataService.isSchoolNull() || this.dataService.isRecipientNull()) {
          this.loadSafeURL();
          return false;
        }
        break;

      // these routes require a school to be selected and at least one finished recipient;
      case this.commonValues.routes.orderReview:
      case this.commonValues.routes.orderConsent:
      case this.commonValues.routes.orderPayment:
      case this.commonValues.routes.orderConfirmation:
        if (this.dataService.isSchoolNull() || this.dataService.isRecipientsEmpty()) {
          this.loadSafeURL();
          return false;
        }
        break;
    }

    // if all those checks were passed, load the route;
    return true;
  }

  setDeactivateWarning(value: boolean): void {
    this.showDeactivateWarning = value;
  }

  // if any security check fails, load the school-select route;
  loadSafeURL(): void {
    this.setDeactivateWarning(false);

    this.router.navigate([this.commonValues.routes.schoolSelect], this.commonValues.routes.extras);
  }

  // `cancel order` button click;
  // reset any user entered data, then load the school-select route;
  cancelOrder(): void {
   
      this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        ariaLabelledBy:  "cancelTitle",
        ariaDescribedBy: "cancelContent",
        autoFocus: false,
        data: {
          body: 'This order will be canceled. You will not be charged for this order. To continue canceling the order, select OK',
          title: "Cancel Order",
          id:"cancel",
          btnYesName:'OK',
          btnNoName:'Cancel'
        }
      }).afterClosed().subscribe(result =>{
        if(result){
          const data = this.dataService.get();
          const redirectUrl = data.propertyConfig.oauthRootURL + data.propertyConfig.oauthLogoutSubURL;
          let isSamlPath = false;
          if (this.dataService.postAuthorizationData && this.dataService.postAuthorizationData.appType === this.commonValues.applicationType.saml){
            isSamlPath =  true;
          }
          this.dataService.resetUserData();
          if (data.form.recipients.length > 0) {
            this.dataService.deleteRecipient(data.form.recipients.length);
          }
    
          this.setDeactivateWarning(false);
          if (isSamlPath){
            setTimeout(() => {
              window.location.href = redirectUrl;
            }, 0);
          }else{
            this.router.navigate(
              [this.commonValues.routes.schoolSelect],
              this.commonValues.routes.extras
            );
          }
        }
      });

   
  }

  catchResponse(error): Observable<never> {
    console.error(error);

    // clear all data for security reasons;
    this.dataService.resetDataObject();

    this.setDeactivateWarning(false);

    // send the user to the thank you page;
    if (navigator.onLine) {
      this.router.navigate(
        [this.commonValues.routes.messageError],
        this.commonValues.routes.extras
      );
    } else {
      this.router.navigate(
        [this.commonValues.routes.messageNetwork],
        this.commonValues.routes.extras
      );
    }

    // stop the chain, so the subscribe method doesn't fire;
    return observableEmpty();
  }

  // use this when error while calling a service can be ignored and user can continue forward
  ignoreError(error): Observable<never> {
    // stop the chain, so the subscribe method doesn't fire;
    console.error(error);
    return observableEmpty();
  }
}
