import * as moment from 'moment';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiPostEllucian } from '../../interfaces/api-post-ellucian.interface';
import { ApiResponseStudentProfile } from '../../interfaces/api-response-student-profile.interface';
import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';
import { SecurityService } from '../../services/security/security.service';

@Injectable()
export class StudentService {
  constructor(
    private commonValues: CommonValues,
    private dataService: DataService,
    private http: HttpClient,
    private securityService: SecurityService
  ) {}

  get(): Observable<ApiResponseStudentProfile> {
    const requestor = this.dataService.get().form.requestor;
    const schoolProfile = this.dataService.get().schoolProfile;
    const postData: ApiPostEllucian = {
      dateOfBirth: moment(requestor.personal.dob, this.commonValues.dateFormat.input).format(
        this.commonValues.dateFormat.output
      ),
      ficeCode: schoolProfile.ficeCode,
      firstName: requestor.personal.nameFirst,
      formerFirstName: requestor.personal.nameChangedFirst,
      formerLastName: requestor.personal.nameChangedLast,
      lastName: requestor.personal.nameLast,
      ssn: (requestor.personal.ssnPrimary ? requestor.personal.ssnPrimary.replace(/-/g, '') : null)   || ((this.dataService.postAuthorizationData
        && this.dataService.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService
        && this.dataService.postAuthorizationData.ssn) ? this.dataService.postAuthorizationData.ssn : null),
      studentId: ( requestor.personal.studentIdPrimary ? requestor.personal.studentIdPrimary.replace(/-/g, '') : null)|| 
                ((this.dataService.postAuthorizationData
                  && this.dataService.postAuthorizationData.appType === this.commonValues.applicationType.studentSelfService
                  && this.dataService.postAuthorizationData.collegeStudentId) ? this.dataService.postAuthorizationData.collegeStudentId : null) ,
      toScprofilId: schoolProfile.toScprofilId,
      isVerified: (this.dataService.postAuthorizationData && this.dataService.postAuthorizationData.appType === this.commonValues.applicationType.saml && this.dataService.postAuthorizationData.collegeStudentId) ? this.commonValues.api.yes : this.commonValues.api.no
    };

    return (
      // no error handling here, because if there is an error, it's managed in the route;
      this.http
        .post<ApiResponseStudentProfile>('/api/tsorder/student/check', postData)
        .pipe(tap(json => this.saveResponse(json)))
    );
  }

  saveResponse(json: ApiResponseStudentProfile): void {
    if (json) {
      this.dataService.save({
        studentProfile: json
      });
    } else {
      throw new Error('Error found in valid response JSON');
    }
  }
}
