import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { DataService } from '../../services/data/data.service';
import { CommonValues } from '../../classes/common-values';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'nsc-card-consent',
  templateUrl: './card-consent.component.html',
  styleUrls: ['./card-consent.component.scss']
})
export class CardConsentComponent implements OnInit {

  data = this.dataService.get();
  unsubscribe$ = new Subject();
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;

  show = {
    consentCard: false,
    totalFees: this.data.response.orderHeader.totalFee,
    trackYourOrderURL: null,
    trackYourOrderContextRoot: null
  };


  constructor(
    private dataService: DataService,
    private commonValues: CommonValues,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
     const status = this.data.response.orderHeader.orderStatus;
     this.show.consentCard = this.dataService.isConsentRequired(status);

     // this.show.trackYourOrderURL = this.dataService.get().propertyConfig.rootURL;
     // this.show.trackYourOrderContextRoot = this.commonValues.applicationURL.trackOrderContextRoot;
  }

  openConfirmationDialog() {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ariaLabelledBy: "modalTitle",
      ariaDescribedBy: "modalContent",
      autoFocus:false,
      data:{
        body: 'You are leaving the ordering site. Do you want to continue?',
        title: "Proceed to Track Your Order",
        id: "modal"
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.routeToTrackOrder();
      }
      this.dialogRef = null;
    });
  }

  routeToTrackOrder(): void {
    this.dataService.routeToTrackOrderUrl();
  }
}
