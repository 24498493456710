import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nsc-card-title-error',
  templateUrl: './card-title-error.component.html',
  styleUrls: ['./card-title-error.component.scss']
})
export class CardTitleErrorComponent implements OnInit {
  @Input('title') title: string;
  @Input('icon') icon: string;
  constructor() { }

  ngOnInit() {
  }

}
