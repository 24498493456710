import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nsc-auth-failure',
  templateUrl: './auth-failure.component.html',
  styleUrls: ['./auth-failure.component.scss']
})
export class AuthenticationFailureComponent implements OnInit {
  constructor(
    ) {}

  ngOnInit() {
  }

}
