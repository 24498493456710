import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor( private sanitized: DomSanitizer){

  }

  // sanitaze the string/url that will be display in the html
  // in order to prevent an injected unsafe html
  transform(value: any, type?: any): SafeHtml | SafeStyle {
    if ( type === 'html'){
      return this.sanitized.bypassSecurityTrustHtml(value);
    }
    else if ( type === 'style'){
      return this.sanitized.bypassSecurityTrustStyle(value);
    }
  }

}
