<mat-form-field appearance="outline" [hideRequiredMarker]="true"
  [ngClass]="loading ? 'nsc-address-auto-complete' : ''"
  [formGroup]="group"
  class="nsc-address-auto-complete">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    id="{{ idx }}"
    #itemFilterInput
    matInput
    autocomplete="off"
    maxlength="{{ maxlength }}"
    formControlName="{{ controlName }}"
    type="search"
    (blur)="onBlur()"
    (focus)="onFocus()">
  <mat-card
    *ngIf="show.autocomplete"
    #optionList>
    <mat-option
      *ngFor="let option of values.autocomplete; let i = index;"
      [disabled]="option.disabled"
      [ngClass]="optionClass(i)"
      [value]="option.text"
      (click)="!option.disabled && selectOption(option)"
      (mouseenter)="optionHoverHandler($event, i)">
      {{ option.text }}
    </mat-option>
  </mat-card>
  <mat-hint>{{ content.hint }} <span class="mat_hit_hidden"  *ngIf="required">required</span></mat-hint>
  <mat-error *ngIf="group.controls[controlName].invalid" [attr.aria-label]="'Error' + content.hint + validationMessage.get(group.controls[controlName], placeholder) ">Error: {{ validationMessage.get(group.controls[controlName], placeholder) }}</mat-error>
</mat-form-field>

