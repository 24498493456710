import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiResponseOrder } from '../../interfaces/api-response-order.interface';
import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';
import { GtmDataLayer } from '../../classes/gtm-data-layer';
import { PostService } from '../../services/post/post.service';
import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';
import { SecurityService } from '../../services/security/security.service';
import { DialogHelpComponent } from '../../components/dialog-help/dialog-help.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { DynamicScriptLoaderService } from '../../services/script-loader/dynamic-script-loader.service';

@Component({
  selector: 'nsc-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.scss']
 // providers: [DynamicScriptLoaderService]
})
export class OrderReviewComponent implements OnDestroy, OnInit {
  unsubscribe$ = new Subject();
  content = {
    recipients: this.dataService.get().form.recipients,
    totalFees: this.dataService.getTotalFees()
  };

  values = {
    maxRecipients: 50,
    isRequiredFieldNull: false,
  };
  formGroup: FormGroup;

  isCheckoutFlag: any;
  isConsentWavied = this.displayConsentWaivedQuestion();

  constructor(
    private commonValues: CommonValues,
    private dataService: DataService,
    private gtmDataLayer: GtmDataLayer,
    private postService: PostService,
   // private dynamicScriptLoader: DynamicScriptLoaderService,
    private blockingIndicatorService: BlockingIndicatorService,
    private router: Router,
    private securityService: SecurityService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    // clear out any inprogress recipient data;
    // the recipient has already been saved, or abandoned when this route loads;
    this.initForm();
    this.dataService.resetRecipient();
//      this.loadPaymentScripts();
  }

  // private loadPaymentScripts() {
  //   this.dynamicScriptLoader.load(this.dataService.get().propertyConfig.payeezyScriptToLoad, 'payment-helper').then(data => {
  //     // Script Loaded Successfully
  //   }).catch(error => console.log(error));
  // }
  initForm(){
    this.formGroup = this.formBuilder.group({
      consentConfirmation: new FormControl(null),
     
  })}

  displayConsentWaivedQuestion(){
     const data = this.dataService.get();
    const recipientsList= data.form.recipients;
   const referMethod = this.postService.getReferMethod();
    
    let isConsentWaived = false;
    for (let recipient of recipientsList) {
      const isThirdParty = recipient.who.recipientType === this.commonValues.api.business || recipient.who.recipientType === this.commonValues.api.organization;
      const isSSS=  referMethod === this.commonValues.referMethod.studentSelfService;
      const isSmal =referMethod === this.commonValues.referMethod.saml;
      if ( isSmal || isSSS ) 
      {
        isConsentWaived = isThirdParty ? data.schoolProfile.consentSThird.includes('WVD') : data.schoolProfile.consentSSelf.includes('WVD');
      } else {
        isConsentWaived = isThirdParty ? data.schoolProfile.consentPThird.includes('WVD') : data.schoolProfile.consentPSelf.includes('WVD');
      }

      if (isConsentWaived) {
        break;
      }
    }

   return isConsentWaived;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  addRecipient(): void {
    this.gtmDataLayer.recordAddRecipient();

    this.securityService.setDeactivateWarning(false);

    setTimeout(() => {
      this.router.navigate(
        [this.commonValues.routes.recipientSelect],
        this.commonValues.routes.extras
      );
    }, this.commonValues.loading.delay);
    
  }

  editRecipient(index: number): void {
    this.blockingIndicatorService.open();
    this.dataService.editRecipient(index);

    this.securityService.setDeactivateWarning(false);

    // now that we've set the current recipient, we can go back and edit the content;
    setTimeout(() => {
      this.router.navigate(
        [this.commonValues.routes.recipientSelect],
        this.commonValues.routes.extras
      );
    }, this.commonValues.loading.delay);
  }

  deleteRecipient(index: number): void {
    const recipient = this.content.recipients[index].address.recipient;

    
    // make sure the user wanted to delete the recipient;
    this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      ariaLabelledBy:  "deleteTitle",
      ariaDescribedBy: "deleteContent",
      autoFocus: false,
      data: {
        body: 'You are removing this recipient from the order. Are you sure you want to continue?',
        title: "Delete Recipient",
        id:"delete"
      }
    }).afterClosed().subscribe(result =>{
      if(result){
        this.dataService.deleteRecipient(index);
        
        // now that the data has been updated in the service, update our locate copy;
        this.content.totalFees = this.dataService.getTotalFees();
        this.content.recipients = this.dataService.get().form.recipients;
        this.isConsentWavied = this.displayConsentWaivedQuestion();
        if(!this.isConsentWavied){
          this.formGroup.reset();
        }
      }
    });
  }

  cancel(): void {
    this.securityService.cancelOrder();
  }

  continue(): void {
    this.blockingIndicatorService.open();

    this.dataService.save((this.formGroup.value), 'form.consent');
    // post the data to the back-end;
    // once that's complete, save the response, and move onto the next oute;
    this.postService
      .recipients()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: ApiResponseOrder) => {
        // if json contains response then allow to proceed otherwise means that some value is missing
        if (json) {
          window.setTimeout(() => {
            
            const route =
              (json.orderHeader.requireReleaseFm === this.commonValues.api.no)
                ? this.dataService.get().response.orderHeader.totalFee
                  ? this.commonValues.routes.orderPayment
                  : this.commonValues.routes.orderConfirmation
                : this.commonValues.routes.orderConsent;

            this.securityService.setDeactivateWarning(false);
            this.router.navigate([route], this.commonValues.routes.extras);
          }, this.commonValues.loading.delay);
        }
        else {
          // validate that 'lsac, amcas,liason' values are not empty if they are required
          this.validateOrgRequiredValue();
        }
      });
  }


  validateOrgRequiredValue(): void {
    let recipientIndex;
    const data = this.dataService.get();
    this.postService.organizationNetworkType$.pipe(takeUntil(this.unsubscribe$))
      .subscribe((index) => {
        recipientIndex = index;
      });
    // checking null or undefined index
    if (recipientIndex != null) {
      // this value is used in order to disable 'continue' button once the popup displays
      this.values.isRequiredFieldNull = true;
      // find the recipient that has null or empty ID and show the popup
      const recipientInfo = data.form.recipients[recipientIndex];
      const dialogContent = this.commonValues.getServiceErrorMessage(recipientInfo.who.recipientResponse.subNetwork.subNetworkType);
      const dialogRef = this.dialog.open(DialogHelpComponent, {
        width: '480px',
        autoFocus:false,
        ariaLabelledBy: "modalNetworkTypeTitle",
        ariaDescribedBy: "modalNetworkTypeContent",
        data: {
          body: dialogContent,
          id:"modalNetworkType",
          title:"System Error"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // save the index for the recipient that has null value
        recipientInfo.index = recipientIndex;
        this.dataService.save(recipientInfo, 'form.recipient');
        // resetting the values if there is one, so user is force to enter it again.
        recipientInfo.who.aamcAccountNumber = null;
        recipientInfo.who.lsacAccountNumber = null;
        recipientInfo.who.casId = null;
        this.dataService.saveRecipient();
        // reusing edit functionallity so user can enter again the ID
        this.editRecipient(recipientIndex);
      });
    }
  }
  disableCheckout() {
    let disableCheckout = false;
    this.isCheckoutFlag = this.content.recipients.length === 0 || this.values.isRequiredFieldNull;
    if ((this.isConsentWavied && this.formGroup.controls['consentConfirmation'].value !== 'Y') || this.isCheckoutFlag) {
       disableCheckout = true; 
    }
    return disableCheckout;
  }
}
