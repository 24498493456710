import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import {  takeUntil } from 'rxjs/operators';

import { AutocompleteOption } from '../../interfaces/autocomplete-option.interface';
import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';
import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';
import { filter as _filter } from 'lodash';
import { SelectOption } from '../../interfaces/select-option.interface';
import { OrganizationsService } from '../../services/organizations/organizations.service';



@Component({
  selector: 'nsc-school-orgid',
  templateUrl: './school-orgid.component.html',
  styleUrls: ['./school-orgid.component.scss']
})
export class SchoolOrgIDComponent implements OnDestroy, OnInit {
  unsubscribe$ = new Subject();

  values = {
    schools$: new BehaviorSubject(<AutocompleteOption[]>[]) // complete list for autocomplete to filter down;
  };


  constructor(
    private activatedRoute: ActivatedRoute,
    private commonValues: CommonValues,
    private blockingIndicatorService: BlockingIndicatorService,
    private router: Router,
    private dataService: DataService,
    private organizationsService: OrganizationsService,
    ) { }

  ngOnInit() {
    // enable progress bar, it will be disabled after route change;
    this.blockingIndicatorService.open();
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
        //save default recipient OrgId from path to be used at recipient-select page
        if (params && params.orgId ){
          this.organizationsService.data$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((json: SelectOption[]) => {
            if (json.length ) {
              const defaultOrganization = json.find(x => x.ficeCode && x.ficeCode === params.orgId);
              if (defaultOrganization && params.orgId.toUpperCase().startsWith('LSON')){
                this.dataService.save(
                  {defaultRecipientOrganization: defaultOrganization}
                );
              }
              window.setTimeout(() => {
              this.router.navigate(
                  [this.commonValues.routes.schoolSelect],
                  this.commonValues.routes.extras
                );
              }, this.commonValues.loading.delay);
            }
            

          });
        }
        
          
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.blockingIndicatorService.close();
  }
}
