import { Injectable } from "@angular/core";
import { CommonValues } from '../classes/common-values'

@Injectable()
export class ZipcodeValidation{

    constructor(
        private commonValues: CommonValues,
    ){}

    internationalZipValidation =[
        {
          countryCode:this.commonValues.api.cn,
          validation:this.commonValues.address.validationZipCNVNSG
        },
        {
          countryCode:this.commonValues.api.kr,
          validation:this.commonValues.address.validationZipKRDESA
        },
        {
          countryCode:this.commonValues.api.jp,
          validation:this.commonValues.address.validationZipJP
        },
        {
          countryCode:this.commonValues.api.de,
          validation:this.commonValues.address.validationZipKRDESA
  
        },
        {
          countryCode:this.commonValues.api.gb,
          validation:this.commonValues.address.validationZipGB
  
        },
        {
          countryCode:this.commonValues.api.tw,
          validation:this.commonValues.address.validationZipTW
  
        },
        {
          countryCode:this.commonValues.api.nw,
          validation:this.commonValues.address.validationZipNO
  
        },
        {
          countryCode:this.commonValues.api.nl,
          validation:this.commonValues.address.validationZipNL
  
        },
        {
          countryCode:this.commonValues.api.sa,
          validation:this.commonValues.address.validationZipKRDESA
  
        },
        {
          countryCode:this.commonValues.api.vn,
          validation:this.commonValues.address.validationZipCNVNSG
  
        },
        {
          countryCode:this.commonValues.api.sg,
          validation:this.commonValues.address.validationZipCNVNSG
  
        }
      ]
}