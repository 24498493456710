import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from '../../services/security/security.service';

import { CommonValues } from '../../classes/common-values';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../data/data.service';

@Injectable()
export class TimeoutService {
  showWarning = new EventEmitter(); // used by the dialog-timeout component to know when to show the dialog;
  timeout: number; // container for the setTimeout, so it can be cleared when the user interacts with the page;
  durationWarning = 15 * 60 * 1000; // minutes * seconds * milliseconds;

  constructor(
    private commonValues: CommonValues,
    private router: Router,
    private securityService: SecurityService,
    private dataService: DataService,
    private dialogRef: MatDialog
  ) {}

  // called by the timeout dialog;
  // the timeout dialog controls when so the UI matches when the session ends;
  // don't want the timer in the dialog to hit zero and then not end the session;
  endSession(): void {
    this.securityService.setDeactivateWarning(false);
    this.dialogRef.closeAll();
    if (this.dataService.postAuthorizationData && this.dataService.postAuthorizationData.appType === this.commonValues.applicationType.saml){
      const data = this.dataService.get();
      const redirectUrl = data.propertyConfig.oauthRootURL + data.propertyConfig.oauthLogoutSubURL;
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 0);
    }else{
      this.router.navigate(
        [this.commonValues.routes.messageTimeout],
        this.commonValues.routes.extras
      );
    }
  }

  startWarningCountdown(): void {
    // clear out any existing timeout;
    window.clearTimeout(this.timeout);

    // start the countdown till the warning dialog is shown;
    this.timeout = window.setTimeout(() => {
      this.showWarning.emit(true);
    }, this.durationWarning);
  }
}
