import { BehaviorSubject, interval as observableInterval, Observable, Subscription } from 'rxjs';
import { Component, Inject, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TimeoutService } from '../../services/timeout/timeout.service';

@Component({
  selector: 'nsc-dialog-timeout',
  templateUrl: './dialog-timeout.component.html',
  styleUrls: ['./dialog-timeout.component.scss']
})
export class DialogTimeoutComponent implements OnInit {
  //@ViewChild('alertMessage') el: ElementRef;

  countdown$: Observable<number>;
  countdownSubscription: Subscription;

  values = {
    countdownFrom: 118, // number of seconds until the user session is ended;
    count: 118
  };

  timeMinSec = this.convertTimeMinSec(this.returnTime(this.values.countdownFrom));
  content = {
    message: this.returnMessage(this.returnTime(this.values.countdownFrom), this.timeMinSec),
    convertedTimeMessage: null
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title?: string; body: string , id?:string},
    public dialogRef: MatDialogRef<DialogTimeoutComponent>,
    private timeoutService: TimeoutService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    // start the countdown with an interval;
    // on each interval set the new number of seconds that will used to generate the displayed time;
    this.countdown$ = observableInterval(1000).pipe(
      map(n => {
        return (this.values.count = this.values.countdownFrom - n);
      })
    );


    // create a subscription that listens to the observable;
    this.countdownSubscription = this.countdown$.subscribe(n => {
      // parse the time based on the number of seconds;
      const time = this.returnTime(n);
      // determine if the timer has reached zero;
      const atZero = n === 0;

      // time needs to be split in order the screen reader read time correctly
      this.timeMinSec = this.convertTimeMinSec(time);

      // set the message based on the amount of time left;
      this.content.message = this.returnMessage(time, this.timeMinSec);

      const minSec = (this.timeMinSec.intMin * 60) + this.timeMinSec.intSec;
      if((minSec % 10 )== 0 ){
        this.content.convertedTimeMessage = this.returnConvertedMessage(time, this.timeMinSec);
      }

      // once the countdown hits zero;
      // kill the subscription (for to save memory);
      // and end the user session;
      if (atZero) {
        this.countdownSubscription.unsubscribe();
        this.timeoutService.endSession();
        this.dialogRef.close();
      }
    });
  }

  // abstracted to its own method so it can be used in in the subscription and during initalization;
  returnMessage(time: string, timeMinSec): string {
    return `Your session is about to expire in ${time} ${timeMinSec.intMin > 0 ? 'minutes' : 'seconds'}. Please select OK to renew the session, or your work might be lost.`;
  }

  // custom message for screen reader
  returnConvertedMessage(time: string, timeMinSec): string {
    // converting min to seconds
    const minSec = (timeMinSec.intMin * 60) + timeMinSec.intSec;
   // we are checking for less than 2 sec because we dont need that the session will expire in 0 sec ( otherwise blind people can get confuse with it)
    // message sending as string instead of template
    if (minSec < 2) {
      return 'Session expired';
    } else {
      return 'Your session is about to expire in' + timeMinSec.txtMinutes + timeMinSec.intSec + 'seconds. Please select OK to renew the session, or your work might be lost.';
    }
  }

  // abstract min and sec
  convertTimeMinSec(time: string) {
    const obj: any = {};
    const _time = (time.split(':'));
    obj.intSec = +(_time[1]);
    obj.intMin = +(_time[0]);
    // if minutes are 0 then should not be read and show in the popup
    obj.txtMinutes = obj.intMin > 0 ? (obj.intMin + 'minutes') : '';
    return obj;
  }

  

  // abstracted to its own method so it can be used in in the subscription and during initalization;
  returnTime(n: number): string {
    return new Date(n * 1000).toISOString().substr(15, 4);
  }

  // start the countdown again when the user clicks OK;
  resetCountdown(): void {
    this.countdownSubscription.unsubscribe();
    this.dialogRef.close();
    // if there was time left, that means the user session is still valid;
    // so start the timer again;
    if (this.values.count) {
      this.timeoutService.startWarningCountdown();
    }
  }
}
