import { catchError, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, Observable, empty as observableEmpty } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { ApiResponseSystemMessage } from '../../interfaces/api-response-system-message.interface';
import { SecurityService } from '../../services/security/security.service';

@Injectable()
export class SystemMessagesService implements OnDestroy {
  data$ = new BehaviorSubject(<ApiResponseSystemMessage[]>[]);
  unsubscribe$ = new Subject();

  constructor(private http: HttpClient, private securityService: SecurityService) {}

  ngOnDestroy() {
    this.unsubscribe();
  }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this.http
      .get('/api/tsorder/system-message')
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(error => this.securityService.ignoreError(error))
      )
      .subscribe((data: ApiResponseSystemMessage[]) => {
        this.data$.next(data);
      });
  }

  setData(data: ApiResponseSystemMessage[]) {
    this.data$.next(data);
  }

}
