<mat-card-header class="system-message error">
  <div mat-card-avatar >
    <mat-icon [svgIcon]="icon" class="nsc-icon__svg"></mat-icon>  
  </div>
    <mat-card-title>
        <h5>{{title}}</h5>
    </mat-card-title>
    <mat-card-subtitle>
      <ng-content></ng-content>
    </mat-card-subtitle>
  </mat-card-header>