import * as moment from 'moment';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { sortedUniq as _sortedUniq } from 'lodash';
import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';
import { PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogHelpComponent } from '../../components/dialog-help/dialog-help.component';


@Component({
  selector: 'nsc-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements OnInit, AfterViewInit {


  data = this.dataService.get();

  timeoutInMinutes = this.data.propertyConfig.orderResetTimeOutInMinutes;

  sessionResetMessage = 'This page will close in ' + this.timeoutInMinutes + ' minutes for security purposes. The information contained on this page will be in your order confirmation email.';

  content = {
    attachments: [],
    orderId: this.data.response.orderHeader.toOrderId,
    payment: this.data.response.payment,
    pendingConsent: this.data.response.orderHeader.orderStatus === 'S',
    recipients: [],
    requestor: this.data.response.student,
    schoolProfile: this.data.schoolProfile,
    orderDate: null,
    totalFees: this.data.response.orderHeader.totalFee,
    state: this.data.form.requestor.personal.state,
    referMethodSaml: this.data.response.orderHeader.referMethod && this.data.response.orderHeader.referMethod.toUpperCase() === 'O',
    allowStudentName: this.data.response.student.allowStudentName 
                      && this.data.response.student.allowStudentName !== this.commonValues.studentName.none.code,
    studentNameLabel: (this.data.response.student.allowStudentName === this.commonValues.studentName.livedName.code)
                        ? this.commonValues.studentName.livedName.name 
                        : this.commonValues.studentName.preferredName.name                  
    };
   
  values = {
    yes: this.commonValues.api.yes,
  };

  constructor(private commonValues: CommonValues, private dataService: DataService, private location: PlatformLocation, private router: Router, public dialog: MatDialog) {
    location.onPopState((e) => {
      if (this.router.url.indexOf(this.commonValues.routes.orderConfirmation) !== -1) {
        this.routeBrowserBackButton();
      }
    });
  }

  ngOnInit() {
    this.setSignatureDate();
    this.setRecipients();
    // user will be sent back to Bridge page within a minute
    let redirectUrl = this.data.propertyConfig.rootURL;
    if (this.content.referMethodSaml){
       redirectUrl = this.data.propertyConfig.oauthRootURL + this.data.propertyConfig.oauthLogoutSubURL;
    }
    setTimeout(() => {
      window.location.href = redirectUrl;
    }, this.timeoutInMinutes * 60 * 1000);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.openDialog();
    }, 0);
  }

  setSignatureDate(): void {
    const consentFormDate = this.data.response.recipients[0].timestamp;

    // convert the string into a moment date, then format the date;
    // oddly the date has a period in it, so remove whatever comes after the period;
    this.content.orderDate = moment(consentFormDate.split('.')[0]).format(
      this.commonValues.dateFormat.display
    );
  }

  // Back Button Logic to route it to Angular Bridge page
  routeBrowserBackButton(): void {
    setTimeout(() => {
      this.router.navigate([this.commonValues.routes.schoolSelect], { replaceUrl: true });
    }, 0);
  }


  setRecipients(): void {
    const unsortedAttachments = [];
    this.data.response.recipients.forEach((recipient, index) => {
      const isSPDEZeroPrgmEligible = this.dataService.getSPDEZeroFeeEligibilityByNetworkCode(recipient.exchangeNetworkTypeCode);
      const isFASTZeroPrgmEligible = this.dataService.getFASTZeroFeeEligibilityByNetworkCode(recipient.exchangeNetworkTypeCode);

      // convert into a structure that the `recipient-details` is expecting;
      const recipientAttachmentsTemp = [];
      if (recipient.attachments) {
        recipient.attachments.forEach(attachment => {
          recipientAttachmentsTemp.push(attachment);
        });
      }

       this.content.recipients.push({
        address: {
          addressLine1: recipient.street1,
          addressLine2: recipient.street2,
          attention: recipient.deptName || recipient.attention,
          city: recipient.city,
          country: recipient.country,
          emailPrimary: recipient.email,
          phone: recipient.phone,
          recipient: recipient.organization,
          state: recipient.state,
          zip: recipient.zip
        },
        delivery: {
          deliveryMethod: recipient.deliveryMethodId,
          howMany: recipient.quantityId,
          specialInstructions: recipient.specialInstr,
          transcriptPurpose: recipient.xcriptPurposeId,
          transcriptType: recipient.xcriptTypeId,
          transcriptWhen: recipient.processingOption,
          zeroFeeProgramEligible: isSPDEZeroPrgmEligible || isFASTZeroPrgmEligible? this.commonValues.api.yes : recipient.isZeroFeePrgrmEligible
        },
        fees: this.data.form.recipients[index].fees,
        who: {
          recipientType: recipient.sendToType,
          sendElectronically: recipient.sendToSchoolElectronic,
          recipientFiceCode: recipient.ficeCode,
          recipientResponse: this.data.form.recipients[index].who.recipientResponse,
        },
        attachments: recipientAttachmentsTemp
      });

      // if there are attachments for the recipient, save them so we can display all the attachments in a single list;
      if (recipient.attachments) {
        recipient.attachments.forEach(attachment => {
          unsortedAttachments.push(attachment.originalFileName);
        });
      }
    });

    // if there are attachments, sort them and remove duplicates;
    if (unsortedAttachments.length) {
      this.content.attachments = _sortedUniq(unsortedAttachments);
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogHelpComponent, {
      width: '480px',
      ariaLabelledBy: 'modalConfirmationTitle',
      ariaDescribedBy: "modalConfirmationContent",
      autoFocus:true,
      data: {
        body: this.sessionResetMessage,
        title: "Order Confirmation",
        id: "modalConfirmation"
      }
    });
  }

  logout(): void {
    const logOutUrl = this.data.propertyConfig.oauthRootURL + this.data.propertyConfig.oauthLogoutSubURL;
    setTimeout(() => {
      window.location.href = logOutUrl;
    }, 0);
  }

}
