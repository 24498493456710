import { cloneDeep as _cloneDeep, filter as _filter, get as _get, trim as _trim } from 'lodash';
import { EventEmitter, Injectable } from '@angular/core';

import { CommonValues } from '../../classes/common-values';
import { Data } from '../../interfaces/data.interface';
import {
  SchoolProfilePathOptions,
  SchoolProfileProcessingOption,
  SchoolProfileDeliveryMethod,
  SchoolProfileTranscriptType,
  SchoolProfileQuantity
} from '../../interfaces/api-response-school-profile.interface';
import { StudentProfileDetail } from '../../interfaces/api-response-student-profile.interface';

@Injectable()
export class DataService {
  // emitter to let other components know when a recipient has been added to the data.form.recipients object;
  // lets the header know so it can update the shopping cart;
  recipientsUpdated = new EventEmitter();
  postAuthorizationData: any;
  dataEmpty: Data;
  showSystemMessage: any;
  data: Data = {
    // Added the sssRequestCheck to set this value when the request is coming in from Student Self Service
    sssRequestCheck: true,

    // school profile data obj;
    schoolProfile: null,

    // ellucian student profile data obj;
    studentProfile: null,

    // payment config
    paymentConfig: null,

    // property config
    propertyConfig: null,
      // POST response data that drive application configuration/data;
    // only track the latest response;
    // not all POST responses matter, but if they do, they override the previous response;
    response: null,

    // JWT token to pass in protected API calls;
    jsonWebToken: null,

    // the default recipient receiver to optionally be passed in on the initial route to the site
    defaultRecipientOrganization: null,

    // user entered form data;
    form: {
      // information about the person making the transcript request;
      requestor: {
        // Personal Information;
        personal: {
          dob: null,
          enrolledBefore: null,
          enrolledCurrently: null,
          zeroFeePrgmCurrEnroll: null,
          state: null,
          campus: null,
          enrolledYearFrom: null,
          enrolledYearTo: null,
          nameChanged: null,
          nameChangedFirst: null,
          nameChangedLast: null,
          nameChangedMiddle: null,
          allowStudentName: null,
          livedNameFirst: null,
          livedNameLast: null,
          livedNameMiddle: null,
          nameFirst: null,
          nameLast: null,
          nameMiddle: null,
          path: null,
          ssnConfirm: null,
          ssnPrimary: null,
          studentIdConfirm: null,
          studentIdPrimary: null
        },

        // Contact Information;
        contact: {
          addressLine1: null,
          addressLine2: null,
          city: null,
          country: this.commonValues.api.us,
          emailConfirm: null,
          emailPrimary: null,
          phone: null,
          state: null,
          textUpdates: null,
          updateRecords: this.commonValues.api.no,
          zip: null
        },

        // School Information;
        attend: {
          degree1: null,
          degree2: null,
          degree3: null,
          degree4: null,
          programs: null,
          year1: null,
          year2: null,
          year3: null,
          year4: null
        }
      },

      // information about who will recieve the transcript;
      recipient: {
        index: null,
        fees: null,

        // recipient route;
        who: {
          aamcAccountNumber: null,
          amcasTranscriptIdNumber: null,
          business: null,
          casId: null,
          country: this.commonValues.api.us,
          department: null,
          departmentNotInList: null,
          emailConfirm: null,
          emailPrimary: null,
          lsacAccountNumber: null,
          matchedUboxEntry: null,
          oborDeliveryEligibility: null,
          organization: null,
          organizationNotInList: null,
          recipientFiceCode: null,
          recipientResponse: null,
          recipientType: null,
          school: null,
          schoolNotInList: null,
          sendElectronically: null,
          state: null
        },

        // delivery-method route;
        delivery: {
          acceptTerms: null,
          course1: null,
          course2: null,
          zeroFeeProgramEligible: null,
          degreeTitle: null,
          deliveryMethod: null,
          howMany: null,
          processingMethod: null,
          program: null,
          specialInstructions: null,
          term: null,
          transcriptWhen: null,
          transcriptPurpose: null,
          transcriptType: null,
          ungradedTerm: null,
          year: null,
          allowDocWaived:null
        },

        // recipient-address & recipient-address-electronic routes;
        address: {
          addressLine1: null,
          addressLine2: null,
          attention: null,
          city: null,
          country: this.commonValues.api.us,
          emailConfirm: null,
          emailPrimary: null,
          phone: null,
          recipient: null,
          state: null,
          zip: null,
          isVerifiedAddress: null,
          isAckgInvalidAddress: null,
          enteredAddressLine1: null,
          enteredAddressLine2: null
        },

        attachments: []

      },

      // all recipients for the order;
      recipients: [],

      // consent form route;
      consent: {
        date: null,
        signature: null,
        legalSignatureName: null,
        consentConfirmation: null
      },

      // payment route;
      payment: {
        addressLine1: null,
        addressLine2: null,
        ccAmount: null,
        ccType: null,
        city: null,
        country: null,
        creditCardNumber: null,
        expirationMonth: null,
        expirationYear: null,
        name: null,
        payeezyToken: null,
        securityCode: null,
        state: null,
        useContactInfo: this.commonValues.api.no,
        zip: null
      }
    }
  };


  constructor(private commonValues: CommonValues) {
    this.dataEmpty = _cloneDeep(this.data);
  }

  transformContent(content) {
    // these keys need special characters stripped from the value;
    const addressKeys = ['recipient', 'attention', 'addressLine1', 'addressLine2', 'city', 'administrative_area'];

    // these keys should not be transformed into uppercase;
    const dontTransform = ['jsonWebToken', 'degreeTitle', 'term', 'course1', 'course2'];

    // trim all string values;
    // and convert to uppercase;
    Object.keys(content).forEach((key, index) => {
      const shouldTransform = dontTransform.indexOf(key) === -1;

      if (typeof content[key] === 'string') {
        // if this is the JWT value, dont transform it;
        if (shouldTransform) {
          content[key] = _trim(content[key].toUpperCase());
        }

        // for address fields we need to remove the pipe character;
        if (addressKeys.indexOf(key) > -1) {
          content[key] = content[key].replace(/\|/gi, '');
        }
      }
    });

    return content;
  }

  save(newContentObject, dataProperty?): void {
    const assignTarget = dataProperty ? _get(this.data, dataProperty) : this.data;
    Object.assign(assignTarget, this.transformContent(newContentObject));

    console.log(_cloneDeep(this.data));
  }

  saveRecipient(): void {
    const recipient = _cloneDeep(this.data.form.recipient);
    const index = this.data.form.recipient.index;
    const recipients = this.data.form.recipients;

    // if in edit mode, replace the old recipient with the new recipient;
    // and reset the edit index because we're no longer editing a recipient;
    if (index !== null) {
      recipients[index] = recipient;

      // otherwise save the new recipient;
    } else {
      recipients.push(recipient);
    }

    // update the shopping cart count;
    this.emitRecipientCount();

    // reset the recipient since we're done with it;
    this.resetRecipient();

    // console.log(_cloneDeep(this.data));
  }

  resetDataObject(): void {
    this.data = _cloneDeep(this.dataEmpty);
  }

  resetUserData(): void {
    this.data.form = _cloneDeep(this.dataEmpty.form);
    this.data.response = _cloneDeep(this.dataEmpty.response);
    this.data.studentProfile = _cloneDeep(this.dataEmpty.studentProfile);
  }

  resetRequestorAddress(): void {
    this.data.form.requestor.contact = _cloneDeep(this.dataEmpty.form.requestor.contact);
  }

  resetRequestorAttend(): void {
    this.data.form.requestor.attend = _cloneDeep(this.dataEmpty.form.requestor.attend);
  }

  resetRequestorIdentification(): void {
    this.data.form.requestor.personal = _cloneDeep(this.dataEmpty.form.requestor.personal);
  }

  resetRecipientAddress(): void {
    this.data.form.recipient.address = _cloneDeep(this.dataEmpty.form.recipient.address);
  }

  resetRecipientDeliveryMethod(): void {
    this.data.form.recipient.delivery = _cloneDeep(this.dataEmpty.form.recipient.delivery);
  }

  resetRecipientEmail(): void {
    this.data.form.recipient.address = _cloneDeep(this.dataEmpty.form.recipient.address);
  }

  resetRecipientSelect(): void {
    this.data.form.recipient.who = _cloneDeep(this.dataEmpty.form.recipient.who);
  }

  // preserveIndex is for when a recipient is in edit mode, but changed;
  // without preserveIndex, then the app would act like a new recipient is being generated, and not replace the one being edited;
  resetRecipient(preserveIndex = false): void {
    const index = preserveIndex ? this.data.form.recipient.index : null;
    this.data.form.recipient = _cloneDeep(this.dataEmpty.form.recipient);

    if (index !== null) {
      this.data.form.recipient.index = index;
    }
  }

  resetRecipientSelectFromRecipients(index: number):void {
    this.data.form.recipients[index] = _cloneDeep(this.dataEmpty.form.recipient);
  }

  resetOrderConsent(): void {
    this.data.form.consent = _cloneDeep(this.dataEmpty.form.consent);
  }

  resetOrderPayment(): void {
    this.data.form.payment = _cloneDeep(this.dataEmpty.form.payment);
  }

  editRecipient(index: number): void {
    this.data.form.recipient = _cloneDeep(this.data.form.recipients[index]);

    // record the index so when done editing, we can replace the value;
    this.data.form.recipient.index = index;

    // console.log(_cloneDeep(this.data));
  }

  deleteRecipient(index: number): void {
    this.data.form.recipients.splice(index, 1);
    this.emitRecipientCount();

    // console.log(_cloneDeep(this.data));
  }

  deleteAttachmentWithFileId(id: String): void {
    Array.from(this.data.form.recipient.attachments).forEach((uploadedFile: any, index: number) => {
      if (uploadedFile.attachmentResponse && uploadedFile.attachmentResponse.status === 'success') {
        const tempAttachment = {
          fileIdentifier: uploadedFile.attachmentResponse.fileIdentifier,
          originalFileName: uploadedFile.attachmentResponse.originalFileName,
          contentType: uploadedFile.attachmentResponse.contentType
        };
        if (tempAttachment.fileIdentifier === id){
          this.data.form.recipient.attachments.splice(index, 1);
        }
      }
    });
    //console.log(_cloneDeep(this.data));
  }


  emitRecipientCount(): void {
    this.recipientsUpdated.emit(this.data.form.recipients.length);
  }

  get() {
    // return a read only clone so a controller can't manipulate the data directly;
    return _cloneDeep(this.data);
  }

  getFullName(): string {
    const response = this.data.response.student;

    return `${response.firstName} ${response.middleName || ''} ${response.lastName}`.replace(
      '  ',
      ' '
    );
  }

  getRecipient(index: number = null): string {
    // index is defined when we want to get the recipient name for a recipient in the recipients[] array;
    // if index isn't defined, then we're dealing with the active recipient;
    const who =
      index !== null ? this.data.form.recipients[index].who : this.data.form.recipient.who;
    const isCollege = who.recipientType === this.commonValues.api.college;
    const isCollegeNotInList =
      who.school === this.commonValues.autocomplete.notInList.schlName.toUpperCase();
    const collegeValue = isCollegeNotInList ? who.schoolNotInList : who.school;

    const isOrganization = who.recipientType === this.commonValues.api.organization;
    const isOrganizationNotInList =
      who.organization === this.commonValues.autocomplete.notInList.schlName.toUpperCase();
    const organizationValue = isOrganizationNotInList
      ? who.organizationNotInList
      : who.organization;

    const isBusiness = who.recipientType === this.commonValues.api.business;
    const businessValue = who.business;

    // return either the college name, or the users name;
    return isCollege
      ? collegeValue
      : isOrganization
        ? organizationValue
        : isBusiness
          ? businessValue
          : this.getFullName();
  }

  getTotalFees(): number {
    let totalFee = 0;

    this.data.form.recipients.forEach(recipient => {
      totalFee += recipient.fees.totalFee;
    });

    return totalFee;
  }

  getOnlineProcessingFeeVisibility(): boolean {
    return !(
      this.data.schoolProfile.hideOpFee === this.commonValues.api.no && this.isFeePathTypeP()
    );
  }

  getFeePathType(): SchoolProfilePathOptions {

    if (this.isZeroFeePrgmEligible() ||  (this.isSPDEZeroFeePrgmEligible()|| this.isFASTZeroFeePrgmEligible() )){
      return this.isFeePathTypeP()
      ? this.data.schoolProfile.zeroPayOptions
      : this.data.schoolProfile.zeroFreeOptions;
    }else{
      return this.isFeePathTypeP()
      ? this.data.schoolProfile.payOptions
      : this.data.schoolProfile.freeOptions;
    }

  }

  isNullorBlank(text: string): boolean{
    if (typeof text !== 'undefined' && text !== ' ' && text){
      return false;
   }
   return true;
  }

  isZeroFeePrgmEligible(): boolean {
    const who = this.data.form.recipient.who;
    // we know if this is an ETX recipient if a recipient had a response object, and the value isn't "not in list";
    return (
      who.recipientResponse !== null && ((who.department !== null && who.department.toString() !== this.commonValues.select.notInList.value) || (who.matchedUboxEntry != null && who.matchedUboxEntry.etxRgtryUboxId > 0)) &&
      (who.recipientResponse.zeroFeePrgmId != null && who.recipientResponse.zeroFeePrgmId > 0)
    );
  }

  isSPDEZeroFeePrgmEligible(recipient?): boolean{
    const who = recipient ? recipient : this.data.form.recipient.who;
    if(who.recipientResponse && who.recipientResponse.exchangeNetworkType){
        return  this.getSPDEZeroFeeEligibilityByNetworkCode(who.recipientResponse.exchangeNetworkType);
    }
     return false;
    
  }
  isFASTZeroFeePrgmEligible(recipient?): boolean{
    const who = recipient ? recipient : this.data.form.recipient.who;
    if(who.recipientResponse && who.recipientResponse.exchangeNetworkType){
        return  this.getFASTZeroFeeEligibilityByNetworkCode(who.recipientResponse.exchangeNetworkType);
    }
     return false;
    
  }


  getSPDEZeroFeeEligibilityByNetworkCode(exchangeNetworkType): boolean{

    if (exchangeNetworkType && exchangeNetworkType === this.commonValues.api.speede) {
      const networkInfos = _get(this.data.schoolProfile, 'networkInfos', []);
      const networkInfo  = _filter(networkInfos, {
        exchangeNetworkTypeCode: this.commonValues.api.speede
      });
      
      const isFeeReduceEligible = networkInfo.length > 0 ? _get(networkInfo[0], 'isFeeReduceEligible', null) : null;
      if(isFeeReduceEligible && isFeeReduceEligible ===this.commonValues.api.yes){
        return true;
      }
     
    }
    return false;
  }
  getFASTZeroFeeEligibilityByNetworkCode(exchangeNetworkType): boolean{

    if (exchangeNetworkType && (exchangeNetworkType === this.commonValues.api.fast)) {
      const networkInfos = _get(this.data.schoolProfile, 'networkInfos', []);
      const networkInfo  = _filter(networkInfos, {
        exchangeNetworkTypeCode: this.commonValues.api.fast
      });
      
      const isFeeReduceEligible = networkInfo.length > 0 ? _get(networkInfo[0], 'isFeeReduceEligible', null) : null;
      if(isFeeReduceEligible && isFeeReduceEligible ===this.commonValues.api.yes){
        return true;
      }
     
    }
    return false;
  }

  getZeroOptions(): SchoolProfilePathOptions {
    return this.isFeePathTypeP()
    ? this.data.schoolProfile.zeroPayOptions
    : this.data.schoolProfile.zeroFreeOptions;
  }

  getProcessingOptionObject(code): SchoolProfileProcessingOption {
    return _filter(this.data.schoolProfile.processingOptions, {
      code: code
    })[0];
  }

  getDeliveryMethodObject(id): SchoolProfileDeliveryMethod {
    return _filter(this.getFeePathType().deliveryMethods, {
      deliveryMethodId: id
    })[0];
  }

  getTranscriptTypeObject(id): SchoolProfileTranscriptType {
    return _filter(this.getFeePathType().transcriptTypes, {
      xcriptTypeId: id
    })[0];
  }

  getQuantityObject(id): SchoolProfileQuantity {
    return _filter(this.getFeePathType().quantities, { quantityId: id })[0];
  }


  getZeroFeeDeliveryMethodObject(id): SchoolProfileDeliveryMethod {
    return _filter(this.getZeroOptions().deliveryMethods, {
      deliveryMethodId: id
    })[0];
  }

  getZeroFeeTranscriptTypeObject(id): SchoolProfileTranscriptType {
    return _filter(this.getZeroOptions().transcriptTypes, {
      xcriptTypeId: id
    })[0];
  }

  getZeroFeeQuantityObject(id): SchoolProfileQuantity {
    return _filter(this.getZeroOptions().quantities, { quantityId: id })[0];
  }

  getNote(notesType: string) {
    const notesArray = _filter(this.data.schoolProfile.toSchoolNotes, {
      notesType: notesType
    });

    return notesArray.length ? notesArray[0] : null;
  }

  getStudentHolds(): StudentProfileDetail[] {
    return _get(this.data.studentProfile, 'restrictions', []);
  }

  getStudentTerms(): StudentProfileDetail[] {
    return _get(this.data.studentProfile, 'ungradedTerms', []);
  }

  getStudentPrograms(): StudentProfileDetail[] {
    return _get(this.data.studentProfile, 'programs', []);
  }

  showDegreeQuestions(): boolean {
    return this.data.schoolProfile.askDegrees === this.commonValues.api.optional;
  }

  showProgramQuestions(): boolean {
    const showQuestionsValues = [this.commonValues.api.optional, this.commonValues.api.required];
    const showQuestions = showQuestionsValues.indexOf(this.data.schoolProfile.askSchools) > -1;
    const haveQuestions = this.data.schoolProfile.attendSchoolLabels.length > 0;

    // only show the questions, if there we are suppose to, and there are questions to ask;
    return showQuestions && haveQuestions;
  }

  isFeePathTypeF(): boolean {
    return this.data.response.orderHeader.feePathType === this.commonValues.api.free;
  }

  isFeePathTypeP(): boolean {
    return this.data.response.orderHeader.feePathType === this.commonValues.api.pay;
  }

  isElectronicPDFAvailable(): boolean {

    let electronicPDFAvailability = false;
    this.getFeePathType().deliveryMethods.forEach((option: SchoolProfileDeliveryMethod) => {
      if (option.deliveryMethodType === this.commonValues.api.electronic) {
        electronicPDFAvailability = true;
      }
    });
    return electronicPDFAvailability;
  }

  isElectronicETXAvailable(): boolean {

    let electronicETXAvailable = false;
    this.getFeePathType().deliveryMethods.forEach((option: SchoolProfileDeliveryMethod) => {
      if (option.deliveryMethodType === this.commonValues.api.etx) {
        electronicETXAvailable = true;
      }
    });
    return electronicETXAvailable;
  }

  isOnlyElectronicAvailable(): boolean {

    let onlyElectronicAvailability = true;
    this.getFeePathType().deliveryMethods.forEach((option: SchoolProfileDeliveryMethod) => {
      if (
        option.deliveryMethodType === this.commonValues.api.electronic ||
        option.deliveryMethodType === this.commonValues.api.fax ||
        option.deliveryMethodType === this.commonValues.api.faxMail ||
        option.deliveryMethodType === this.commonValues.api.faxExpress ||
        option.deliveryMethodType === this.commonValues.api.hold ||
        option.deliveryMethodType === this.commonValues.api.mail ||
        option.deliveryMethodType === this.commonValues.api.overnight
        )  {
        onlyElectronicAvailability = false;
      } 
    });
    return onlyElectronicAvailability;


  }


  isNotOnlyElectronicDeliveryMethod(): boolean {

    let isNotOnlyElectronicMethods = false;
    this.getFeePathType().deliveryMethods.forEach((option: SchoolProfileDeliveryMethod) => {
      if (option.deliveryMethodType !== this.commonValues.api.electronic && option.deliveryMethodType !== this.commonValues.api.etx) {
        isNotOnlyElectronicMethods = true;
      }
    });
    return isNotOnlyElectronicMethods;
  }


  isEnrolledBefore(): boolean {
    // if enrolledBefore is null, return false as we have no requirements from school profile.
    // else if enrolledBefore is not null, we check if it's value is 'Y' and if it is, return true because student was enrolledBefore therefore student should not see electronic delivery methods.
    // else if enrolledBefore is not null, we check if it's value is 'N' and if it is, return false because student was not enrolledBefore therefore you should see eletronic delivery methods.
    return (this.data.form.requestor.personal.enrolledBefore) ?
      (this.data.form.requestor.personal.enrolledBefore === this.commonValues.api.yes) : false;
  }

  isSchoolEllucian(): boolean {
    return _get(this.data.schoolProfile, 'integrationProvider', false) !== false;
  }

  getIntegrationProvider(): string {
    return _get(this.data.schoolProfile, 'integrationProvider', null);
  }

  // used in the security route checks;
  // if these return true (different routes have different checks), then the route won't be loaded;
  isSchoolNull(): boolean {
    return this.data.schoolProfile === null;
  }

  isRecipientNull(): boolean {
    return this.data.form.recipient.who.recipientType === null;
  }

  isRecipientsEmpty(): boolean {
    return this.data.form.recipients.length === 0;
  }

  isSamlStudentVerified(): boolean {
    return (this.postAuthorizationData &&
           (this.postAuthorizationData.firstName && this.postAuthorizationData.lastName && this.postAuthorizationData.collegeStudentId )) ? true : false;
  }

  isStudentVerified(): boolean {
    return _get(this.data.studentProfile, 'verifiedStudentId', false) !== false;
  }

  isStudentNotFound(): boolean {
    const obj = this.data.studentProfile;

    // if the value is an empty object, then the student wasn't found;
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  // determine if the order requires consent;
  isConsentRequired(status: string): boolean {
    return status === this.commonValues.api.pending;
  }

  routeToTrackOrderUrl(): void {
    this.resetUserData();
    const trackyourOrderURL = this.get().propertyConfig.rootURL + this.commonValues.applicationURL.trackOrderContextRoot;
    setTimeout(() => {
      window.location.href = trackyourOrderURL;
    }, 0);
  }

  getOrderProcessFeePaidByValue(id, exchangeNetworkType): string {
    const orderProcessFeeOverride =  this.data.schoolProfile.orderProcessFeeOverride;
    let orderProcessFeePaidBy = null;
    if(orderProcessFeeOverride && orderProcessFeeOverride==='Y'){
        const deliveryMethodObj = this.getDeliveryMethodObject(id);
        const deliveryMethodType = deliveryMethodObj? deliveryMethodObj.deliveryMethodType:null;
        if(deliveryMethodType && this.commonValues.api.etx === deliveryMethodType && exchangeNetworkType &&  exchangeNetworkType !== this.commonValues.api.etxNetwork){
          const networkInfos = _get(this.data.schoolProfile, 'networkInfos', []);
          const networkInfo  = _filter(networkInfos, {
            exchangeNetworkTypeCode: this.commonValues.api.fast
          });
          const networkOrderProcessFeePaidBy = networkInfo.length > 0 ? _get(networkInfo[0], 'orderProcessFeePaidBy', null) : null;
          if(networkOrderProcessFeePaidBy && (networkOrderProcessFeePaidBy ==='PS' || networkOrderProcessFeePaidBy ==='FS')){
            orderProcessFeePaidBy = 'S'
          }else{
            orderProcessFeePaidBy = 'R'
          }
        }else{
          orderProcessFeePaidBy = deliveryMethodObj? deliveryMethodObj.orderProcessFeePaidBy:null;
        }

    }
    
    return orderProcessFeePaidBy;

  }

}
