<div class="row">
  
    <div class="col-12 col-md-8" *ngIf="formGroup.controls['oborDeliveryEligibility'].enabled" >
        <nsc-form-field-toggle  
        idx="obor_eligibility" 
        controlName='oborDeliveryEligibility' 
        label="{{data.schoolProfile.oborDeliveryEligibilityQuestion}}"
        [group]="formGroup">
        </nsc-form-field-toggle>
      </div>
      
  <div class="col-12 col-md-8" *ngIf="formGroup.controls['department'].enabled">
    <nsc-form-field-select
      idx="recepientselect_department"
      controlName="department"
      placeholder="Department"
      [group]="formGroup"
      [selectObjects]="values.departments$.value"></nsc-form-field-select>
  </div>

  <div class="col-12">
  <nsc-form-field-sub-network-type [formGroup]="formGroup"></nsc-form-field-sub-network-type>
  </div>
  
  <div class="col-12"></div>

  <div class="col-12 col-md-8" *ngIf="formGroup.controls['sendElectronically'].enabled">
      <nsc-form-field-toggle
        idx="send_electronically"
        controlName="sendElectronically"
        label="{{ content.sendElectronicallyQuestion }}"
        valueNo="{{values.no}}"
        valueYes="{{values.yes}}"
        [disabled]="formGroup.controls['sendElectronically'].disabled"
        [group]="formGroup"></nsc-form-field-toggle>
  </div>

  <div class="col-12"></div>

  <div class="col-12 col-md-8" *ngIf="formGroup.controls['sendElectronically'].enabled && formGroup.controls['sendElectronically'].value == values.yes">
    <nsc-form-field-confirm
    idx="recipientselect_email"
    autocomplete="email"
    controlNameConfirm="emailConfirm"
    controlNamePrimary="emailPrimary"
    placeholder="Email Address"
    [group]="formGroup"></nsc-form-field-confirm>
  </div>

  <div class="col-12"></div>

  <div class="col-12 col-md-8 col-lg-4" *ngIf="formGroup.controls['departmentNotInList'].enabled">
    <nsc-form-field-input
      idx="notinlist"
      controlName="departmentNotInList"
      maxlength="40"
      placeholder="{{ content.labelDepartment }}"
      [group]="formGroup"
      [required]="content.requireDepartment"></nsc-form-field-input>
  </div>

</div>
