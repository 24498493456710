import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'nsc-form-field-confirm',
  templateUrl: './form-field-confirm.component.html',
  styleUrls: ['./form-field-confirm.component.scss']
})
export class FormFieldConfirmComponent implements OnChanges, OnInit {
  @Input() autocomplete = 'off';
  @Input() controlNamePrimary: string;
  @Input() idx: string;
  @Input() controlNameConfirm: string;
  @Input() group: FormGroup;
  @Input() hint: string;
  @Input() mask: (string | RegExp)[] |string | boolean = false;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() placeholder: string;
  @Input() requiredPrimary = true;
  @Input() requiredConfirm = true;
  @Input() type = 'text';
  @Input() allowSpace= false;

  content = {
    hintPrimary: null,
    hintConfirm: null
  };

  values = {
    // used to prevent the confirm field from being autopopulated;
    autocompleteConfirm: null
  };

  constructor() { }

  ngOnInit() {
    // cf. https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
    // if the type is a password, browsers can override the random autocomplete value, so use 'new-password';
    this.values.autocompleteConfirm =
      this.type === 'password'
        ? 'new-password'
        : Math.random()
          .toString(36)
          .substring(7);

    // update validity of confirm field as the primary is changed;
    // so if the user enters in a valid value in confirm, and fixes the value in the primary;
    // the user will see the validator automatgically fix itself;
    this.group.controls[this.controlNamePrimary].valueChanges.subscribe((value: string) => {
      if (value && value.length) {
        this.group.controls[this.controlNameConfirm].updateValueAndValidity();
      }
    });

    this.setHint();
  }

  ngOnChanges() {
    this.setHint();
  }

  handleSpace(e) {
      if (!this.allowSpace && e && e.keyCode === 32) {
        return false;
      }
  }


  removeExtraSpaces(event) {
      // variable to be assigned the pasted value
      let clipboard;
      let dataTextType = '';
      // Accessing the pasted data in Chrome, Firefox, Edge browsers
      if (event && event.clipboardData) {
        clipboard = event.clipboardData;
        dataTextType = 'text/plain';
      }
      else if ((window as any).clipboardData || window['clipboardData']) { // Accessing the pasted data in IE browser
        clipboard = window['clipboardData'];
        dataTextType = 'text';
      }

      // removing spaces from data && extra data base on the maximun length allowed
      let pastedText = clipboard.getData(dataTextType).replace(/\s/g, '');
      if (this.maxlength) {
        const maxlength_text = Number(this.maxlength);
        if (maxlength_text > 0 && pastedText && pastedText.length > 0) {
          if (pastedText.length > maxlength_text) {
            pastedText = pastedText.slice(0, -(pastedText.length - maxlength_text));
          }
        }
      }
      // setting up in the angular form
      this.group.controls[this.controlNamePrimary].setValue(pastedText);
      // Cancelling the event in order for the system to be updated the clipboarData with the new value
      // if we dont cancel the field will display the original value with the spaces.
      event.preventDefault();
  }
  setHint(): void {
    // set the hints even if they're optional;
    this.content.hintPrimary = this.hint || '';
    this.content.hintConfirm = this.hint || '';

    // if the field is optional, show that (with the optional hint);
    if (this.requiredPrimary === false) {
      this.content.hintPrimary = `${this.content.hintPrimary} (Optional)`;
    }

    if (this.requiredConfirm === false) {
      this.content.hintConfirm = `${this.content.hintConfirm} (Optional)`;
    }
  }
}
