import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';
import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'nsc-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();

  content = {
    title: '',
    message: '',

    error: {
      title: 'Temporary Service Interruption',
      message:
        'A temporary service error was identified. Unfortunately, you will need to initiate a new transcript order request. We are sorry for this inconvenience. Please contact our customer service department at <a href="mailto:transcripts@studentclearinghouse.org">transcripts@studentclearinghouse.org</a> for further assistance if this error reoccurs. Thank you'
    },

    network: {
      title: 'Network Error',
      message:
        'An error occurred during your order. Please check your Internet connection and try again. Thank you.'
    },

    payment: {
      title: 'Payment Error',
      message:
        'We were unable to process your credit card and this order has been canceled. Please submit a new request.'
    },

    timeout: {
      title: 'Inactivity Timeout',
      message:
        'The session has timed out due to inactivity and your order information has been cleared. Please restart the order.'
    },
  };

  show = {
    restartButton : true
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private blockingIndicatorService: BlockingIndicatorService,
    private commonValues: CommonValues,
    private dataService: DataService,
    private router: Router
  ) {}

  ngOnInit() {
    this.dataService.resetDataObject();

    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      if (this.content[params.reason]) {
        this.content.title = this.content[params.reason].title;
        this.content.message = this.content[params.reason].message;
        this.show.restartButton = false;
      } else {
        this.restart();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  restart(): void {
    this.router.navigate([this.commonValues.routes.schoolSelect], this.commonValues.routes.extras);
  }
}
