<div mat-dialog-title  class="mat-dialog-title"  id="{{data.id}}Title">
 <h3 class="nsc-title"> {{ data.title }}</h3>
<span mat-dialog-close class="close">
  <mat-icon class="nsc-icon__svg" svgIcon="close" aria-hidden="false" aria-label="close confirmation dialog"></mat-icon>
</span>
</div>
<mat-dialog-content class="mat-typography">
  <p id="{{data.id}}Content">{{data.body}}</p>
</mat-dialog-content>
<mat-dialog-actions align="start">
  <button mat-raised-button color="primary" id="btn-yes" class="nsc-button" type="button" attr.aria-label="{{data.btnYesName}} confirmation" *ngIf="!data.hideYesBtn" (click)="dialogRef.close(true)">{{ data.btnYesName }}</button>
  <button mat-stroked-button color="primary" id="btn-no" class="nsc-button" type="button" attr.aria-label="{{data.btnNoName}} confirmation" *ngIf="!data.hideNoBtn" (click)="dialogRef.close(false)">{{ data.btnNoName }}</button>
</mat-dialog-actions>

