import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter as _filter, cloneDeep as _cloneDeep } from 'lodash';
import { SelectOptgroup } from '../../interfaces/select-optgroup.interface';
import { ValidationMessage } from '../../classes/validation-message';
import { unwatchFile } from 'fs';

@Component({
  selector: 'nsc-form-field-select-optgroup',
  templateUrl: './form-field-select-optgroup.component.html',
  styleUrls: ['./form-field-select-optgroup.component.scss']
})
export class FormFieldSelectOptgroupComponent implements OnInit {
  @Input() controlName: string;
  @Input() idx: string;
  @Input() group: FormGroup;
  @Input() hint: string;
  @Input() placeholder: string;
  @Input() selectObjects: SelectOptgroup[];
  @Input() loading = false;
  @Input() required = true;


  selectedValue: any = {
    value: null,
    name: null,
  };
  constructor(private validationMessage: ValidationMessage) { }

  ngOnInit() { }

  changeValue(value: any) {
    this.selectedValue.name = value.source.selected.viewValue;
    this.selectedValue.id = value.source.selected.value;
  }

  openedChange(opened: boolean) {
    this.selectedValue.opened = opened ? true : false;
  }

}
