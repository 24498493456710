import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';
import { SecurityService } from '../../services/security/security.service';
import { TimeoutService } from '../../services/timeout/timeout.service';
import { ValidateMatch } from '../../validators/match.validator';
import { ValidateCasId } from '../../validators/cas-id.validator';
import { UboxExchangeDetailService } from '../../services/ubox-exchange-detail/ubox-exchange-detail.service';
import { ApiResponseRecipientProfile } from '../../interfaces/api-response-recipient-profile.interface';
import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';
import { ApiRequestUboxExchangeDetail } from '../../interfaces/api-request-uboxexchange-detail';
import { FormFieldSubNetworkTypeComponent } from '../../components/form-field-sub-network-type/form-field-sub-network-type.component';
import { MatDialog } from '@angular/material/dialog';
import { ManageFields } from '../../classes/manage-fields';
import { ApiResponseFees } from '../../interfaces/api-response-fees.interface';
import { FeesService } from '../../services/fees/fees.service';
import { ConvertDeliveryMethod } from '../../classes/convert-delivery-method';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { SelectOption } from '../../interfaces/select-option.interface';
import { OrganizationsService } from '../../services/organizations/organizations.service';

@Component({
  selector: 'nsc-recipient-email',
  templateUrl: './recipient-email.component.html',
  styleUrls: ['./recipient-email.component.scss']
})
export class RecipientEmailComponent implements OnDestroy, OnInit {
  @ViewChild(FormFieldSubNetworkTypeComponent)
  private uboxFormFields: FormFieldSubNetworkTypeComponent;

  formGroup: FormGroup;
  unsubscribe$ = new Subject();
  data = this.dataService.get();
  who = this.data.form.recipient.who;
  
  values = {
    callMatching:
      this.data.form.recipient.index == null && this.data.form.recipient.index == undefined
        ? true
        : false,
    fees: null,
    //recipientResponse: null,
    deliveryType: null,
    organizationsList:[],
    matchingRecipientResponse:null
  };

  constructor(
    private commonValues: CommonValues,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private router: Router,
    private securityService: SecurityService,
    private timeoutService: TimeoutService,
    private uboxExchangeDetailService: UboxExchangeDetailService,
    private blockingIndicatorService: BlockingIndicatorService,
    private dialog: MatDialog,
    private manageFields: ManageFields,
    private feesService: FeesService,
    private organizationsService: OrganizationsService
  ) {}

  ngOnInit() {
    this.initForm();
    this.initEmailListener();
    this.initOrganizationServiceValues();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm(): void {
    const address = this.data.form.recipient.address;

    this.formGroup = this.formBuilder.group(
      {
        recipient: new FormControl(this.dataService.getRecipient(), [Validators.required]),
        emailPrimary: new FormControl(address.emailPrimary, [
          Validators.required,
          Validators.pattern(this.commonValues.emailPattern)
        ]),
        emailConfirm: new FormControl(address.emailConfirm, [
          Validators.required,
          Validators.pattern(this.commonValues.emailPattern)
        ]),
        aamcAccountNumber: new FormControl(
          this.who.aamcAccountNumber || this.commonValues.nullDisabled,
          [Validators.required, Validators.minLength(8)]
        ),
        amcasTranscriptIdNumber: new FormControl(
          this.who.amcasTranscriptIdNumber || this.commonValues.nullDisabled,
          [Validators.required, Validators.minLength(7)]
        ),
        casId: new FormControl(this.who.casId || this.commonValues.nullDisabled, [
          Validators.required,
          Validators.minLength(10),
          ValidateCasId
        ]),
        lsacAccountNumber: new FormControl(
          this.who.lsacAccountNumber || this.commonValues.nullDisabled,
          [Validators.required, Validators.minLength(9)]
        )
      },
      {
        // run custom validators to make sure the both fields are equal;
        validator: [ValidateMatch('emailPrimary', 'emailConfirm')]
      }
    );

    // any change to the form means the user is actively using the app, so extend the system timeout;
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.timeoutService.startWarningCountdown();
    });
  }

  initEmailListener(): void {
    merge(
      this.formGroup.get('emailPrimary').valueChanges,
      this.formGroup.get('emailConfirm').valueChanges
    ).subscribe(data => {
      if (!this.values.callMatching) {
        this.values.callMatching = true;
        this.resetFields();
        this.dataService.save(this.formGroup.value, 'form.recipient.who');
        this.disableFields();
      }
    });
  }

  initOrganizationServiceValues(): void {
    this.organizationsService.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: SelectOption[]) => {
        if (json.length) {
          this.values.organizationsList=json;
        }
      });
  }

  previous(): void {

    this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      ariaLabelledBy:  "prevTitle",
      ariaDescribedBy: "prevContent",
      autoFocus: false,
      data: {
        body: this.commonValues.warningLoseInformation,
        title: "Information",
        id:"prev"
      }
    }).afterClosed().subscribe(result =>{
      if(result){
        this.dataService.resetRecipientAddress();

      this.securityService.setDeactivateWarning(false);

      this.router.navigate(
        [this.commonValues.routes.recipientDeliveryMethod],
        this.commonValues.routes.extras
      );
      }
    });
  }

  cancel(): void {
    this.securityService.cancelOrder();
  }

  resetFields(): void {
    const controls = this.formGroup.controls;
    this.manageFields.reset([
      controls.amcasTranscriptIdNumber,
      controls.aamcAccountNumber,
      controls.casId,
      controls.lsacAccountNumber
    ]);
  }

  disableFields(): void {
    const controls = this.formGroup.controls;
    this.manageFields.disable([
      controls.amcasTranscriptIdNumber,
      controls.aamcAccountNumber,
      controls.casId,
      controls.lsacAccountNumber
    ]);
  }

  getETXFees() {

    const getFirstETXDeliveryMethodAvailable = () =>{
      return recipientDelivery = feePathType.deliveryMethods.find(
        val => (val.deliveryMethodType === this.commonValues.api.etx)
      );
    }
    const recipientDeliveryValues= this.data.form.recipient.delivery;
    
    const feePathType = this.dataService.getFeePathType();
    let recipientDelivery = null;
    // if the school offers only one processing option type then we cant get the ETX delivery method base on the processing option selected because that option is hidden in the prev page
    // instead get the first ETX delivery method available  base on the school configuration
    if(recipientDeliveryValues.processingMethod){
      recipientDelivery = feePathType.deliveryMethods.find(
        val => (val.deliveryMethodType === this.commonValues.api.etx && val.deliveryMethodCategory === recipientDeliveryValues.processingMethod));

      if(!recipientDelivery){
        recipientDelivery = getFirstETXDeliveryMethodAvailable();
      }
    }else{
      recipientDelivery = getFirstETXDeliveryMethodAvailable();
    }

    
    
    const payload = {
      toScprofilId: this.data.schoolProfile.toScprofilId,
      toOrderId: this.data.response.orderHeader.toOrderId,
      deliveryMethodId: recipientDelivery == null ? '' : recipientDelivery.deliveryMethodId.toString(),
      quantityId: feePathType.quantities[0].quantityId,
      xcriptTypeId: recipientDeliveryValues.transcriptType == null ? '' : recipientDeliveryValues.transcriptType.toString(),
      zeroFeePrgrmId: recipientDeliveryValues.zeroFeeProgramEligible,
      feeReduceEligible: null,
      orderProcessFeePaidBy: recipientDeliveryValues.orderProcessFeePaidBy
    };

    this.feesService
      .get(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: ApiResponseFees) => {
        const fees = json.summaryFeeInfo;
        fees.deliveryFeeDescription = 'Delivery Fee';
        
        this.dataService.save({ deliveryMethod: recipientDelivery.deliveryMethodId, howMany: feePathType.quantities[0].quantityId, processingMethod:recipientDelivery.deliveryMethodCategory }, 'form.recipient.delivery');
        this.dataService.save({ fees: fees }, 'form.recipient');
        this.dataService.saveRecipient();
        this.continueGo();
      });
  }

  continueSave(): void {
    this.dataService.resetRecipientEmail();
    this.dataService.save(this.formGroup.value, 'form.recipient.address');
    this.dataService.save(this.formGroup.value, 'form.recipient.who');
   
    if(this.values.matchingRecipientResponse){
      const recipientResponse = this.values.matchingRecipientResponse;
      // since there is a matching the recipient needs to be replaced by the org name matched
     const formValues =  this.formGroup.value;
     
     if (this.data.form.recipient.who.recipientType == this.commonValues.api.business) {
       formValues.business = this.formGroup.controls.recipient.value;
     }
      formValues.recipientResponse = recipientResponse;
      formValues.recipientFiceCode = recipientResponse.receivingSchoolFiceCode;
      formValues.department = (recipientResponse.recipientDepartments && recipientResponse.recipientDepartments.length) > 0 ? recipientResponse.recipientDepartments[0].deptId: null;
      this.dataService.save(formValues, 'form.recipient.who');
      this.dataService.save(formValues, 'form.recipient.address');
      this.getETXFees();
    }
    else{
      
      this.dataService.saveRecipient();
      this.continueGo();
    }  
  }
  

  continueGo(): void {
    this.securityService.setDeactivateWarning(false);
    this.router.navigate([this.commonValues.routes.orderReview], this.commonValues.routes.extras);
  }

  continue(): void {
    this.blockingIndicatorService.open();
    const controls = this.formGroup.controls;
    if (this.values.callMatching && (this.data.form.recipient.who.recipientType != this.commonValues.api.college)) {
      this.values.matchingRecipientResponse= null;
      const queryRequest: ApiRequestUboxExchangeDetail = {
        uboxEmail: controls.emailPrimary.value,
        orgType: 'eo', // hard coded to EO for now as per API implementation
        orderId: this.data.response.orderHeader.toOrderId
      };
      this.uboxExchangeDetailService
        .findEtxMatch(queryRequest)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((json: ApiResponseRecipientProfile) => {
          
          if (json && json.exchangeNetworkType === this.commonValues.api.etxNetwork) {
            this.values.callMatching = false;
            this.blockingIndicatorService.close();
            this.openDialog(json);
          } else {
            this.continueSave();
          }
        });
    } else {
      this.continueSave();
    }
  }

  openDialog(json): void {
    const dialogId = 'recipientUbox';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      ariaLabelledBy: dialogId + 'Title',
      ariaDescribedBy: dialogId + 'Content',
      autoFocus: false,
      data: {
        body: "Based on the email address that you entered, your transcript will be delivered to the recipient's preferred secure electronic exchange inbox.",
        title: 'Recipient Information',
        id: dialogId,
        btnYesName:'OK',
        btnNoName:'Cancel'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const orgName= this.getOrganizationName(json);
        this.values.matchingRecipientResponse = json;
        this.formGroup.controls.recipient.setValue(orgName);
        // check if email is a matching email for LSAC-LIASION-AMCAS then  display input fields
        const isSubnetworkMatch =
          json.subNetwork &&
          (json.subNetwork.subNetworkType == this.commonValues.api.lsac ||
            json.subNetwork.subNetworkType == this.commonValues.api.amcas ||
            json.subNetwork.subNetworkType == this.commonValues.api.liason);
        if (isSubnetworkMatch) {
          this.uboxFormFields.processRecipientJson(json);
        } else {
          this.blockingIndicatorService.open();
          this.continueSave();
        }
      }else{
        // when user clicks on cancel popup we need to call matching api again
        this.values.callMatching= true;
      }
    });
  }

  getOrganizationName(recipientResponse):string{
   const receivingSchoolFiceCode = recipientResponse.receivingSchoolFiceCode ? recipientResponse.receivingSchoolFiceCode.toUpperCase(): recipientResponse.receivingSchoolFiceCode;
    const orgName= this.values.organizationsList.find((val:SelectOption)=>val.ficeCode.toUpperCase() ==  receivingSchoolFiceCode);
    return (orgName ? orgName.name : this.formGroup.controls.recipient.value);
  }
}
 

