import { EventEmitter, Component, Input, OnChanges, OnInit, SimpleChanges, Output, ElementRef, ViewChild} from '@angular/core';
import { Router } from '@angular/router';

import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';
import { SecurityService } from '../../services/security/security.service';
import { WebAimContrastChecker } from '../../classes/web-aim-contrast-checker';
import { DialogHelpComponent } from '../dialog-help/dialog-help.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'nsc-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnChanges, OnInit {
  @ViewChild('skipLink') skipLink: ElementRef;
  @Output() skipToMainContent = new EventEmitter();

  @Input('route') route: string;

  content = {
    headerBackgroundColorDefault: '#ffffff',
    headerBackgroundColor: null,
    pageTitle: null,
    schoolLogoSrc: null,
    schoolLogoAlt: null,
    schoolName: null,
    shoppingCartCount: 0
  };

  show = {
    cart: false,
    darkHeader: false,
    link: false
  };

  constructor(
    private commonValues: CommonValues,
    private dataService: DataService,
    private router: Router,
    private securityService: SecurityService,
    private webAimContrastChecker: WebAimContrastChecker,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.content.headerBackgroundColor = this.content.headerBackgroundColorDefault;

    this.initShoppingCartCount();
  }

  // emitting parent event when press 'skip to main content' link focus should go to main content
  //  in order to be reader by the screen reader
  clickSkipToMainContent() {
    this.skipToMainContent.emit();
  }

  // on route init, or change, configure what to display based on the route;
  ngOnChanges(changes: SimpleChanges) {
    if (this.route) {
      this.setHeaderConfig();

      // dont enable the link on the order review route;
      // since the link points to this route;
      this.show.link = this.route !== this.commonValues.routes.orderReview;
    }
  }

  initShoppingCartCount(): void {
    // set initial value;
    this.content.shoppingCartCount = this.dataService.get().form.recipients.length;

    // listen for changes to the recipients and update the shopping cart;
    this.dataService.recipientsUpdated.subscribe((count: number) => {
      this.content.shoppingCartCount = count;
    });
  }

  setHeaderConfig(): void {
    const setSchoolValues = () => {
      const schoolProfile = this.dataService.get().schoolProfile;
      const logo = schoolProfile.toSchoolHeader;

      // if the logo is defined, then show it, otherwise do not;
      if (logo && logo.contentType && logo.logoBase64) {
        this.content.schoolLogoSrc = `data:${logo.contentType};base64,${logo.logoBase64}`;
        this.content.schoolLogoAlt = logo.alternateText;
        this.content.headerBackgroundColor = logo.bgColor;
        // show the dark header if the light color combo don't have enough contrast;
        this.show.darkHeader = !this.webAimContrastChecker.hasEnoughContrast(
          '#000000',
          logo.bgColor
        );
      } else {
        this.content.schoolName = schoolProfile.schlName;
      }
    };

    // set configuration based on the route;
    switch (this.route) {
      case this.commonValues.routes.schoolSelect:
      case this.commonValues.routes.messageError:
      case this.commonValues.routes.messageTimeout:
        this.content.schoolLogoSrc = null;
        this.content.headerBackgroundColor = this.content.headerBackgroundColorDefault;
        this.content.schoolName = null;
        this.show.darkHeader = false;
        this.show.cart = false;
        break;

      case this.commonValues.routes.schoolWelcome:
      case this.commonValues.routes.orderConsent:
      case this.commonValues.routes.orderPayment:
      case this.commonValues.routes.orderConfirmation:
        setSchoolValues();
        this.show.cart = false;
        break;

      case this.commonValues.routes.requestorIdentification:
      case this.commonValues.routes.requestorAddress:
      case this.commonValues.routes.requestorAttend:
      case this.commonValues.routes.recipientSelect:
      case this.commonValues.routes.recipientDeliveryMethod:
      case this.commonValues.routes.recipientAddress:
      case this.commonValues.routes.recipientEmail:
      case this.commonValues.routes.orderReview:
        setSchoolValues();
        this.show.cart = true;
        break;
    }
 
  if(this.skipLink && this.skipLink.nativeElement ){
   (this.skipLink.nativeElement).focus();
  }
  
  }


  goToOrderReview(): void {
   // if(this.route !== this.commonValues.routes.orderReview){
    const go = () => {
      this.securityService.setDeactivateWarning(false);

      this.router.navigate([this.commonValues.routes.orderReview], this.commonValues.routes.extras);
    };
    const routesToConfirm = [
      this.commonValues.routes.recipientSelect,
      this.commonValues.routes.recipientDeliveryMethod,
      this.commonValues.routes.recipientAddress,
      this.commonValues.routes.recipientEmail
    ];
    const confirmMessage =
      'If you leave this page now, you will lose any incomplete order information for the current recipient. Do you wish to continue?';

    // if we should confirm navigation before happening;
    if (routesToConfirm.indexOf(this.route) > -1) {
      const dialogRef = this.dialog.open(DialogHelpComponent, {
        width: '480px',
        autoFocus:false,
        ariaLabelledBy: "modalLeavePageTitle",
        ariaDescribedBy: "modalLeavePageContent",
        data: {
          body: confirmMessage,
          id:"modalLeavePage",
          title:"Information"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
       if(result){
          go();
        }
      });
    }
    // otherwise just go;
    else {
      go();
    }
  // }
  }
}
