import { BehaviorSubject, Subject } from 'rxjs';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { cloneDeep as _cloneDeep, filter as _filter } from 'lodash';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { CommonValues } from '../../../classes/common-values';
import { DataService } from '../../../services/data/data.service';
import { ManageFields } from '../../../classes/manage-fields';
import { OrganizationsService } from '../../../services/organizations/organizations.service';
import { RecipientSelectDepartmentComponent } from '../recipient-select-department/recipient-select-department.component';
import { SelectOption } from '../../../interfaces/select-option.interface';
import { ValidateSchoolAutocomplete } from '../../../validators/school-autocomplete.validator';
import { AutocompleteOption } from '../../../interfaces/autocomplete-option.interface';

@Component({
  selector: 'nsc-recipient-select-organization',
  templateUrl: './recipient-select-organization.component.html',
  styleUrls: ['./recipient-select-organization.component.scss']
})
export class RecipientSelectOrganizationComponent implements OnDestroy, OnInit, AfterViewInit {
  @Input() formGroup: FormGroup;
  @ViewChild(RecipientSelectDepartmentComponent)
  recipientSelectDepartmentComponent: RecipientSelectDepartmentComponent;

  data = this.dataService.get(); // set to we dont have to call get() over and over again during init;
  who = this.data.form.recipient.who;
  unsubscribe$ = new Subject();
  isOnlyelectronicAvailable = false;
  values = {
    // SELECT values populated by service call;
    organizations$: new BehaviorSubject(<any[]>[]),

    isEnrolledBefore: this.dataService.isEnrolledBefore(),
    minCharactersToTrigger: null,
    searchLinkLabel:this.commonValues.autocomplete.custom.requestor.defaultSearchLinkLabel,
    autocompleteOptions$: new BehaviorSubject(<any[]>[]),
    searchType: null
  };

  show = {
    advancedSearch: false
  };

  constructor(
    private commonValues: CommonValues,
    private dataService: DataService,
    private manageFields: ManageFields,
    private organizationsService: OrganizationsService,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.initServiceValues();
    this.initOrganizationListener();
  }

  ngAfterViewInit(): void {
    if (this.data.defaultRecipientOrganization  && this.dataService.isRecipientsEmpty()){
      this.formGroup.controls.organization.setValue(this.data.defaultRecipientOrganization.value);
      this.formGroup.controls.recipientFiceCode.setValue(this.data.defaultRecipientOrganization.ficeCode);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initServiceValues(): void {
    const controls = this.formGroup.controls;
    let autocompleteOption$ = new BehaviorSubject(<AutocompleteOption[]>[]);
    this.organizationsService.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: SelectOption[]) => {
        if (json.length) {

          const updatedJson = json.map((option: SelectOption) => ({
            ...option,
            schlName: option.name
            }));
          // clone the response so we dont add the Not In List value to the service value;
          // if we do, multiple trips through this route will result in multiple Not In List options;
          const jsonClone = _cloneDeep(updatedJson);

          autocompleteOption$.next(jsonClone);
          controls.organization.setValidators([
            Validators.required,
            ValidateSchoolAutocomplete(autocompleteOption$.value)
          ]);

          jsonClone.push({
            ...this.commonValues.select.notInList,
            schlName:''
          });
          this.values.organizations$.next(jsonClone);
        }
      });
  }

  initOrganizationListener(): void {
    const controls = this.formGroup.controls;

    // wach the `organization` field, and when it changes get the next questions ready;
    controls.organization.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        if (value) {
          this.manageFields.disable([
            controls.amcasTranscriptIdNumber,
            controls.aamcAccountNumber,
            controls.casId,
            controls.department,
            controls.departmentNotInList,
            controls.lsacAccountNumber,
            controls.organizationNotInList,
            controls.noDeliveryMethodAvailble
          ]);

          // null out any selected recipient;
          // this isn't a field visible to the user;
          // don't disable, just nullify;
          // if disabled, the value wouldn't be saved;
          controls.recipientResponse.setValue(null);

          if (value.toLowerCase() === this.commonValues.select.notInList.value.toLowerCase()) {
            this.isOnlyelectronicAvailable = this.dataService.isOnlyElectronicAvailable();
            if ((this.isOnlyelectronicAvailable)){
             
                    
              controls.noDeliveryMethodAvailble.setValue(true);
                  
              } else{
            this.manageFields.enable([controls.organizationNotInList]);
              }
          } else {
            //only call when student is not enrolledBefore.
            if(!this.values.isEnrolledBefore){
              // determine if the department fields should be shown;
              this.recipientSelectDepartmentComponent.changeRecipient(
                controls.organization,
                this.values.organizations$.value
              );
            }
          }
        }
      });
  }

  searchLinkAction(e): void{
    if (!this.show.advancedSearch){
      this.openAdvancedSearch();
    }else{
      this.closeAdvancedSearch();
    }
    this.resetDepartmentControl(this.formGroup.controls);
    this.manageFields.reset([this.formGroup.controls.organization]);
    this.formGroup.controls.organization.enable();
    // timeout makes sure that it is invoked after any other event has been triggered.
    // e.g. click events that need to run before the focus
    window.setTimeout(() => {
      try {
        this.renderer.selectRootElement('#recepientselectorgautocomplete').focus();
      } catch (ignored){}
    });
    e.preventDefault();
  }

  openAdvancedSearch(): void{
    this.show.advancedSearch = true;
    this.values.searchLinkLabel = this.commonValues.autocomplete.custom.backSearchLinkLabel;
    this.values.searchType = this.commonValues.searchType.wildCardSearch;
    this.values.minCharactersToTrigger = 4;
  }

  closeAdvancedSearch(): void{
    const controls = this.formGroup.controls;
    this.show.advancedSearch = false;
    this.values.searchLinkLabel = this.commonValues.autocomplete.custom.requestor.defaultSearchLinkLabel;
    this.values.minCharactersToTrigger = null;
    this.values.searchType = null;
  }

  resetDepartmentControl(controls: any): void{
    this.manageFields.disable([
      controls.amcasTranscriptIdNumber,
            controls.aamcAccountNumber,
            controls.casId,
            controls.department,
            controls.departmentNotInList,
            controls.lsacAccountNumber,
            controls.organizationNotInList,
            controls.noDeliveryMethodAvailble
    ]);

    controls.recipientResponse.setValue(null);
  }

  
}
