<div  *ngIf="systemMessages.data$.value.length" class="nsc-system__message">
  <nsc-card [error]="true">
    <nsc-card-title-error title="Alert" icon="warning">
      <div *ngFor="let message of systemMessages.data$.value">
        <span [innerHtml]="message.message | safeHtml:'html'"></span>
     </div>
    </nsc-card-title-error>
    <!-- <nsc-card-content>
      
    </nsc-card-content> -->
  </nsc-card>

  <nsc-spacer size="md"></nsc-spacer>
</div>
