<div class="row">
  <div class="col-12 col-md-8" *ngIf="!show.advancedSearch">
    <nsc-form-field-select
      idx="recepientselect_org"
      controlName="organization"
      placeholder="Select Organization"
      [group]="formGroup"
      [selectObjects]="values.organizations$.value"></nsc-form-field-select>
  </div>
  <div class="col-12 col-md-8" *ngIf="show.advancedSearch">
    <nsc-form-field-autocomplete idx="recepientselectorgautocomplete" controlName="organization"
      placeholder="Enter a keyword to find and select the organization you are sending your transcript to" 
      [group]="formGroup" [notInList]="true" [searchType]="values.searchType"
      [options]="values.organizations$.value"
      [minCharactersToTrigger]="values.minCharactersToTrigger">
    </nsc-form-field-autocomplete>
  </div>
  <div class="col-12 ">
    <a href="#" id="search_link" (click)="searchLinkAction($event)">{{values.searchLinkLabel}}</a>
  </div>
  <div class="col-12" *ngIf="formGroup.controls['organizationNotInList'].enabled">
    <nsc-spacer size="sm"></nsc-spacer>
   </div>
  <div class="col-12 col-md-8" *ngIf="formGroup.controls['organizationNotInList'].enabled">
    <nsc-form-field-input
      idx="recepientselect_orgnotinlist"
      controlName="organizationNotInList"
      maxlength="60"
      placeholder="Enter an Organization"
      [group]="formGroup"></nsc-form-field-input>
  </div>
  <div class="col-12" *ngIf="!values.isEnrolledBefore">
    <nsc-spacer size="sm"></nsc-spacer>
   </div>
  <nsc-recipient-select-department
    class="col-12"
    [formGroup]="formGroup" *ngIf="!values.isEnrolledBefore"></nsc-recipient-select-department>
</div>
