import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'nsc-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnChanges, OnInit {
  @Input('route') route: string;

  content = {
    appTitle: 'National Student Clearinghouse Transcript Services',
    pageTitle: null,
    recipient: null
  };

  constructor(
    private commonValues: CommonValues,
    private dataService: DataService,
    private title: Title
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.setPageTitleConfig(this.route);
  }

  // set the content to display based on the current route;
  setPageTitleConfig(route: string): void {

    switch (route) {
      case this.commonValues.routes.requestorIdentification:
        this.content.pageTitle = 'Personal Information';
        this.content.recipient = null;
        break;
      
      case this.commonValues.routes.requestorAddress:
        this.content.pageTitle = 'Personal Information Continued';
        this.content.recipient = null;
        break;
      
      case this.commonValues.routes.requestorAttend:
        this.content.pageTitle = 'Enrollment and/or Degree Information';
        this.content.recipient = null;
        break;

      case this.commonValues.routes.recipientSelect:
        this.content.pageTitle = 'Select Transcript and Delivery Details';
        this.content.recipient = null;
        break;

      case this.commonValues.routes.recipientDeliveryMethod:
        this.content.pageTitle = 'Select Transcript and Delivery Details';
        this.content.recipient = this.dataService.getRecipient();
        break;

      case this.commonValues.routes.recipientAddress:
      case this.commonValues.routes.recipientEmail:
        this.content.pageTitle = 'Provide Delivery Information';
        this.content.recipient = this.dataService.getRecipient();
        break;

      case this.commonValues.routes.orderReview:
        this.content.pageTitle = 'Checkout';
        this.content.recipient = null;
        break;

      case this.commonValues.routes.orderConsent:
        this.content.pageTitle = 'Consent';
        this.content.recipient = null;
        break;

      case this.commonValues.routes.orderPayment:
        this.content.pageTitle = 'Payment';
        this.content.recipient = null;
        break;

      case this.commonValues.routes.orderConfirmation:
        this.content.pageTitle = 'Order Confirmation';
        this.content.recipient = null;
        break;

      case this.commonValues.routes.schoolSelect:
        this.content.pageTitle = 'Order a Transcript';
        break;

      default:
        this.content.pageTitle = null;
        this.content.recipient = null;
        break;
    }

    // change the TITLE tag based on the route;
    const newTitle = this.content.pageTitle && route!== this.commonValues.routes.schoolSelect
      ? `${this.content.pageTitle} · ${this.content.appTitle}`
      : this.content.appTitle;
    this.title.setTitle(newTitle);
  }

  redirectToProcessFlowLink(){
    const processFlowURL = this.dataService.data.propertyConfig.processFlowURL;
    window.open(processFlowURL, '_blank');
  }
}
