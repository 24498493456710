import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ValidationMessage } from '../../classes/validation-message';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'nsc-form-field-input',
  templateUrl: './form-field-input.component.html',
  styleUrls: ['./form-field-input.component.scss']
})
export class FormFieldInputComponent implements OnChanges, OnInit {
  @Input() autocomplete = 'off';
  @Input() controlName: string;
  @Input() group: FormGroup;
  @Input() hint: string;
  @Input() mask: (string | RegExp)[] |string | boolean = false;
  @Input() prefixT:string;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() placeholder: string;
  @Input() required = true;
  @Input() suffix: string;
  @Input() type = 'text';
  @Input() idx: string;
  @Input() triggerOnFocus = false;
  @Output() emitOnFocus= new EventEmitter();
  @Output() blurEvent = new EventEmitter<boolean>();
  content = {
    hint: ''
  };

  constructor(private validationMessage: ValidationMessage) { }

  ngOnInit() {
    this.setHint(this.hint, this.required);
  }

  ngOnChanges(changes: SimpleChanges) {
    const newHint = changes.hint ? changes.hint.currentValue : this.hint;
    const newRequired = changes.required ? changes.required.currentValue : this.required;

    this.setHint(newHint, newRequired);
  }


  setHint(hint: string, required: boolean): void {
    // if a field isn't required and it's optional, we need to combine the `hint` values;
    this.content.hint = `${hint || ''} ${required ? '' : '(Optional)'}`;
  }

  onFocus(){
    if(this.triggerOnFocus){
       this.emitOnFocus.emit();
    }
  }

  onBlur() {
    const control = this.group.get(this.controlName);
    const hasError = control.invalid && (control.dirty || control.touched);
    this.blurEvent.emit(hasError);
  }
}
