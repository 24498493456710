import { BehaviorSubject, Subject, Observable, merge } from 'rxjs';
import { cloneDeep as _cloneDeep, filter as _filter, get as _get, isEmpty as _isEmpty } from 'lodash';
import { Component, OnDestroy, OnInit, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { CommonValues } from '../../classes/common-values';
import { CountriesService } from '../../services/countries/countries.service';
import { DataService } from '../../services/data/data.service';
import { FormFieldInputComponent } from '../../components/form-field-input/form-field-input.component';
import { ManageFields } from '../../classes/manage-fields';
import { SecurityService } from '../../services/security/security.service';
import { SelectOptgroup } from '../../interfaces/select-optgroup.interface';
import { SelectOption } from '../../interfaces/select-option.interface';
import { States } from '../../interfaces/states.interface';
import { StatesService } from '../../services/states/states.service';
import { TimeoutService } from '../../services/timeout/timeout.service';
import { IfStmt } from '@angular/compiler';
import { AutoCompleteUsService } from '../../services/address/auto-complete-us.service';
import { AddressAutoCompleteComponent } from '../../components/address-auto-complete/address-auto-complete.component';
import { AddressValidation } from '../../interfaces/address-validation';
import { VerifyUsService } from '../../services/address/verify-us.service';
import { ZipcodeValidation } from '../../classes/zipcode-validation';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'nsc-recipient-address',
  templateUrl: './recipient-address.component.html',
  styleUrls: ['./recipient-address.component.scss']
})
export class RecipientAddressComponent implements OnDestroy, AfterViewInit, OnInit {
  @ViewChild('phone',{ static: true }) private phoneInput: FormFieldInputComponent;
  @ViewChild('addressAutoComplete', { static: true }) private addressVerify: AddressAutoCompleteComponent;

  formGroup: FormGroup;
  data = this.dataService.get();
  unsubscribe$ = new Subject();
  addressSuggestions: AddressValidation[];

  content = {
    hintPhone: this.commonValues.address.hintPhoneDomestic,
    hintPhoneDomestic: this.commonValues.address.hintPhoneDomestic,
    hintPhoneInternational: this.commonValues.address.hintPhoneInternational,
    hintDescValue : this.commonValues.address.hintDescValue.international,

    hintZip: this.commonValues.address.hintZipDomestic,
    hintZipDomestic: this.commonValues.address.hintZipDomestic,
    hintZipInternational: this.commonValues.address.hintZipInternational,

    labelPhone: this.commonValues.address.labelPhoneUS,
    labelState: this.commonValues.address.labelStateUS,
    labelStateUS: this.commonValues.address.labelStateUS,
    labelStateCA: this.commonValues.address.labelStateCA,
    labelStateInternational: this.commonValues.address.labelStateInternational,
    certifiedMailUsWarningMessage : this.commonValues.invalidDeliveryMethodWarningMessageCertifiedMailUS
  };

  masks = {
    phonemask: '(000) 000-0000'
  };

  show = {
    acknowledgeInvalidAddress: false
  };

  values = {
    countries$: new BehaviorSubject(<SelectOption[]>[]),
    recipientReadonly:
      this.data.form.recipient.who.recipientType !== this.commonValues.api.organization,

    states$: new BehaviorSubject(<SelectOptgroup[]>[]),
    statesUS: <SelectOptgroup[]>[], // populated by the states API;
    statesCA: <SelectOptgroup[]>[], // populated by the states API;
    stateInternational: null, // to be defined with a value from the state API;

    validatorsAttention: [],
    validationPhoneDomestic: _cloneDeep(this.commonValues.address.validationPhoneDomestic),
    validationPhoneInternational: _cloneDeep(
      this.commonValues.address.validationPhoneInternational
    ),
    validationZipCA: this.commonValues.address.validationZipCA,
    validationZipUS: this.commonValues.address.validationZipUS,
    validateInternationAddressKey : null,
    addressVerified: false,
    userChoseHisAddress: false,
    userReachedContinue: false,
    securePrint: false,
    securePrintInvalidAddress: false,
    reqEnteredAddressLine1: null,
    reqEnteredAddressLine2: null,
    acknowledgeMessage: null,
    securePrintInvalidAddressMessage: null,
    isInternationalPremisePartialAddress: false,
    deliveryMethodObject: this.dataService.getDeliveryMethodObject(
      this.data.form.recipient.delivery.deliveryMethod
    ),
    invalidDeliveryMethod: false,
    isRecipientTypeMyself : this.isRecipientTypeMyself(),
    certifiedMailSecurePrint: false,
    certifiedMailSecurePrintInvalidAddress : false,
    certifiedMailSecurePrintInvalidAddressMessage : null
    };

  // needs to be after values becuase the functions setting the require values manipulate `value.validators...`;
  require = {
    attention: this.isAttentionRequired(),
    phone: this.isPhoneRequired()
  };

  constructor(
    private commonValues: CommonValues,
    private countriesService: CountriesService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private manageFields: ManageFields,
    private router: Router,
    private securityService: SecurityService,
    private statesService: StatesService,
    private timeoutService: TimeoutService,
    private usAddressAutoCompleteService: AutoCompleteUsService,
    private renderer: Renderer2,
    private verifyUsService: VerifyUsService,
    private zipcodeValidation: ZipcodeValidation,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initCountryValues();
    this.initStateValues();
    this.initForm();
    this.initIsDomestic();
    this.initAddressValidation();
    this.initIsStateNotAllowedForCertifiedMail();
    this.isInvalidDeliveryMethodForCertifiedMail();
  }

  ngAfterViewInit(): void {
  }

  setOnfocus(): void{
    const controlElement = this.renderer.selectRootElement('#recipientPhone');
    const existingAriaId  = controlElement.getAttribute('aria-describedby');
    controlElement.setAttribute('aria-describedby',  `${existingAriaId}  customDescribedPhoneId`);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isRecipientTypeMyself(){
    return this.data.form.recipient && this.data.form.recipient.who && this.data.form.recipient.who.recipientType && this.data.form.recipient.who.recipientType.toUpperCase() === 'M' ? true : false;
  }

  isInvalidDeliveryMethodForCertifiedMail(){

    if (this.isDeliveryMethodCertifiedMailUS()){
      const requesterState = this.data.form.recipient ? (this.data.form.recipient.address ? this.data.form.recipient.address.state : null) : null;
      if (this.isStateNotAllowedForCertifiedMailUS(requesterState)){
        this.values.invalidDeliveryMethod = true;
      } else {
        this.values.invalidDeliveryMethod = false;
      }
    }else{
      this.values.invalidDeliveryMethod = false;
    }
  }

  private isDeliveryMethodCertifiedMailUS(): boolean {

     const deliveryMethodObject = this.values.deliveryMethodObject;
     return (deliveryMethodObject && (deliveryMethodObject.xmitCode === this.commonValues.api.certifiedMailUsa ||
      deliveryMethodObject.xmitCode === this.commonValues.api.rushCertifiedMailUsa) ? true : false);
  }


  initIsStateNotAllowedForCertifiedMail(){

    this.formGroup.controls.state.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {

        if (this.isDeliveryMethodCertifiedMailUS() && this.isStateNotAllowedForCertifiedMailUS(value)){
            this.values.invalidDeliveryMethod = true;
        }else{
          this.values.invalidDeliveryMethod = false;
        }
      });
  }


  isStateNotAllowedForCertifiedMailUS(state: string): boolean{

    const selectedState: SelectOption = this.getStateObject(state);
    return (selectedState && selectedState.category && (selectedState.category.toUpperCase() === 'T' || selectedState.category.toUpperCase() === 'A')) ? true : false;
  }

  private getStateObject(state: string): SelectOption{

    let selectedState: SelectOption = null;

    for (const usStates of this.values.statesUS){
      const option: SelectOption = usStates.options.find(usState => usState.value === state);
      if (option){
        selectedState = option;
        break;
      }
    }
    return selectedState;
  }


  getDeliveryMethodType(xmitCode?: boolean): string {
    const deliveryMethod = this.values.deliveryMethodObject;
    const deliveryMethodType = xmitCode ? deliveryMethod.xmitCode : deliveryMethod.deliveryMethodType;
    return deliveryMethodType ? deliveryMethodType : null;
  }

  getXmitCodeDeliveryMethod(){
    const requiredValuesXmitCode = [
      this.commonValues.api.certifiedMailUsa,
      this.commonValues.api.rushCertifiedMailUsa];
   return requiredValuesXmitCode.indexOf(this.getDeliveryMethodType(true)) > -1;
  }

  getDeliveryMethod(){
    const requiredValues = [
      this.commonValues.api.faxExpress,
      this.commonValues.api.overnight];
    return requiredValues.indexOf(this.getDeliveryMethodType()) > -1;
  }

  // return the required value for the attention field;
  // and if the attention field is required, update the validator for the field;
  isAttentionRequired(): boolean {
    const isXmitcodeDeliveryType = this.getXmitCodeDeliveryMethod();
    const isDeliveryMethodType = this.getDeliveryMethod();
    return (isDeliveryMethodType || isXmitcodeDeliveryType);

  }

  // return the required value for the phone field;
  // and if the phone field is required, update the validators for the field;
  isPhoneRequired(): boolean {
    const requiredValues = [
      this.commonValues.api.faxExpress,
      this.commonValues.api.overnight,
      this.commonValues.api.fax
    ];
    return requiredValues.indexOf(this.getDeliveryMethodType()) > -1;
  }

  initCountryValues(): void {

    // get Ajax values for the SELECT fields;
    this.countriesService.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: SelectOption[]) => {

        // filter out the available countries based on the selected delivery method;
        // the following is commented out until the `xmitCodeId` is available in the School Profile;

       const _xmitCode = this.values.deliveryMethodObject.xmitCode;
        // set the default return values to be the unaltered response from the service;
        // the deliveryMethodType may change the values;
        let filteredValues = json;

        switch (_xmitCode) {
          // return only the US;
          // case this.commonValues.api.both:
          case this.commonValues.api.FedExUsa:
          case this.commonValues.api.RushFedExUsa:
          case this.commonValues.api.mailUsa:
          case this.commonValues.api.expressUsa:
          case this.commonValues.api.rushMailUsa:
          case this.commonValues.api.rushExpressUsa:
          case this.commonValues.api.certifiedMailUsa:
          case this.commonValues.api.rushCertifiedMailUsa: 
            filteredValues = _filter(json, o => {
              return o.value === this.commonValues.api.us;
            });
            break;

          // return only CA and MX;
          // case this.commonValues.api.mail:
          case this.commonValues.api.FedExCanadaMexico:
          case this.commonValues.api.RushFedExCanadaMexico:
          case this.commonValues.api.mailCaMex:
          case this.commonValues.api.expressCaMex:
          case this.commonValues.api.rushMailCaMex:
          case this.commonValues.api.rushExpressCaMex:
            filteredValues = _filter(json, o => {
              return o.value === this.commonValues.api.ca || o.value === this.commonValues.api.mx;
            });
            break;

          // return everything except US;
          // case this.commonValues.api.fax:
          case this.commonValues.api.FedExInternational:
          case this.commonValues.api.RushFedExInternational:
          case this.commonValues.api.mailInternational:
          case this.commonValues.api.expressInternational:
          case this.commonValues.api.rushMailInternational:
          case this.commonValues.api.rushExpressInternational:
            filteredValues = _filter(json, o => {
              return o.value !== this.commonValues.api.us;
            });
            break;
        }

        this.values.countries$.next(filteredValues);
      });
  }

  initStateValues(): void {
    // break the states up by their country;
    this.statesService.data$.pipe(takeUntil(this.unsubscribe$)).subscribe((json: States) => {
      this.values.statesUS = json.us;
      this.values.statesCA = json.canada;
      this.values.stateInternational = json.international;

      // set default value;
      this.values.states$.next(this.values.statesUS);
    });
  }

  initForm(): void {
    const recipient = this.data.form.recipient;
    const address = recipient.address;
    const deliveryMethod = this.values.deliveryMethodObject;
    const isDeliveryExpress = deliveryMethod.deliveryMethodType === this.commonValues.api.overnight;
    const isRecipientMe = recipient.who.recipientType === this.commonValues.api.me;
    const isExpressMe = isDeliveryExpress && isRecipientMe;
    const isXmitcodeCM = deliveryMethod.xmitCode === this.commonValues.api.certifiedMailUsa;
    const isXmitcodeRCM = deliveryMethod.xmitCode === this.commonValues.api.rushCertifiedMailUsa;
    const isCertifiedMe = isXmitcodeCM && isRecipientMe;
    const isRushCertifiedMe = isXmitcodeRCM && isRecipientMe;



    if (this.require.phone) {
      this.values.validationPhoneDomestic.push(Validators.required);
      this.values.validationPhoneInternational.push(Validators.required);
    }

    if (this.require.attention) {
      this.values.validatorsAttention.push(Validators.required);
    }

    // if the delivery method is express mail, and the recipient is me, then the attention line is me;
    // or if a department was entered, that's the default attention value;
    /*  const attention =
       address.attention || isExpressMe
         ? this.dataService.getFullName()
         : recipient.who.departmentNotInList; */
    const countriesList = this.values.countries$.getValue();
    const isRequestorCountry = _filter(countriesList, o => {
      return o.value === address.country;
    });
    address.country = isRequestorCountry.length === 0 ? null : address.country;

    let attention = null;
    if (isExpressMe || isCertifiedMe || isRushCertifiedMe )   {
      if (address.attention !== this.dataService.getFullName() && address.attention !== null) {
        attention = address.attention;
      }
      else {
        attention = this.dataService.getFullName();
      }
    } else {
      if (address.attention == null) {
        attention = recipient.who.departmentNotInList;
      }
      else {
        attention = address.attention;
      }
    }
   
    const isSecurePrint = isDeliveryExpress && this.isPrintDelivery(deliveryMethod);
    this.values.securePrint = isSecurePrint;
    this.values.certifiedMailSecurePrint = this.isDeliveryMethodCertifiedMailUS() && this.isPrintDelivery(deliveryMethod);

    this.formGroup = this.formBuilder.group({
      recipient: new FormControl(this.dataService.getRecipient(), [Validators.required]),
      attention: new FormControl(attention, this.values.validatorsAttention),
      addressLine1: new FormControl(address.addressLine1, [Validators.required]),
      addressLine2: new FormControl(address.addressLine2),
      city: new FormControl(address.city, [Validators.required, Validators.minLength(2)]),
      state: new FormControl(address.state, [Validators.required]),
      zip: new FormControl(address.zip, this.values.validationZipUS),
      country: new FormControl(address.country, [Validators.required]),
      phone: new FormControl(address.phone, this.values.validationPhoneDomestic),
      isVerifiedAddress: new FormControl(address.isVerifiedAddress),
      isAckgInvalidAddress: new FormControl(address.isAckgInvalidAddress, [Validators.required]),
      securePrintInvalidAddress: new FormControl(this.values.securePrintInvalidAddress, [Validators.required]),
      certifiedMailInvalidAddress: new FormControl(this.values.certifiedMailSecurePrintInvalidAddress, [Validators.required]),
      enteredAddressLine1: new FormControl(address.enteredAddressLine1),
      enteredAddressLine2: new FormControl(address.enteredAddressLine2)
    });


    
    // disable this initially
    this.manageFields.disable([this.formGroup.controls.isAckgInvalidAddress]);
    this.manageFields.disable([this.formGroup.controls.securePrintInvalidAddress]);
    this.manageFields.disable([this.formGroup.controls.certifiedMailInvalidAddress]);

    // disable countries dropdown if  is USA and  default it
    if (countriesList.length === 1) {
      if (countriesList[0].value === this.commonValues.api.us) {
        this.manageFields.disable([this.formGroup.controls.country]);
        this.formGroup.controls.country.setValue(countriesList[0].value);
        address.country = countriesList[0].value;
      }
    }
    // now update the form settings based on the country, if a country is supplied (on edit, or `me` recipient);
    if (address.country) {
      this.setControlAttributes(address.country);
      if(address.country === this.commonValues.api.us){
          // format phoneNumber to match the mask
          if(address.phone){
            address.phone  = address.phone.replace(/-/g, '').replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
            this.formGroup.controls.phone.setValue(address.phone);
          }
      }
    }
    if (address.zip === this.commonValues.defaultZip) {
      this.manageFields.reset([this.formGroup.controls.zip]);
    }

    // any change to the form means the user is actively using the app, so extend the system timeout;
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.timeoutService.startWarningCountdown();
    });
  }

  isPrintDelivery(deliveryMethod){
    return (deliveryMethod && deliveryMethod.isPrint === this.commonValues.api.yes);
  }

  initIsDomestic(): void {
    const controls = this.formGroup.controls;
    // watch the `country` field because when toggled we want to change content on other fields;
    this.formGroup.controls.country.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        this.setControlAttributes(value);

        // reset the fields that are dependant upon country;
        // the reset is not done within `setControlAttributes` because we don't want it done on page init;
        // otherwise preselected values would be cleared out;
        this.manageFields.reset([controls.phone, controls.state, controls.zip]);
      });
  }

  initAddressValidation() {

    /* Disable auto complete
    // Enable auto complete
    this.formGroup.controls.addressLine1.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(200), distinctUntilChanged())
      .subscribe(
        (value: string) => this.usAddressAutoCompleteService.searchAddress(value)
          .subscribe(response => (this.addressSuggestions = response))
      );
	*/

   /*
   // Phone input is being changed programmtically on country change so need to differentiate user input
      vs code change
    this.formGroup.controls.phone.valueChanges.subscribe((value => {
      // address is still invalid and user did not prefer to use his address
      // so trigger validation again
      if (!this.addressVerify.values.addressIsValid &&
        !this.addressVerify.values.userUsedHisAddress) {
        this.addressVerify.verifyEnteredAddress();
      }
    }
    ));
    */

    merge(
      this.formGroup.controls.addressLine1.valueChanges,
      this.formGroup.controls.addressLine2.valueChanges,
      this.formGroup.controls.city.valueChanges,
      this.formGroup.controls.state.valueChanges,
      this.formGroup.controls.country.valueChanges,
      this.formGroup.controls.zip.valueChanges
    ).subscribe(data => {

      if (this.formGroup.controls.isAckgInvalidAddress) {
        this.manageFields.disable([this.formGroup.controls.isAckgInvalidAddress]);
        this.show.acknowledgeInvalidAddress = false;
      }
      if (this.addressVerify.values.addressIsValid) {
        this.addressVerify.values.addressIsValid = false;
      }
      if (this.addressVerify.values.userUsedHisAddress) {
        this.addressVerify.values.userUsedHisAddress = false;
        this.show.acknowledgeInvalidAddress = false;
      }
      if (this.values.securePrint === true) {
        this.values.securePrintInvalidAddress = false;
        this.manageFields.disable([this.formGroup.controls.securePrintInvalidAddress]);
      }
      if (this.values.certifiedMailSecurePrint) {
        this.values.certifiedMailSecurePrintInvalidAddress = false;
        this.manageFields.disable([this.formGroup.controls.certifiedMailInvalidAddress]);
      }
    });
  }

  setInternationalPremisePartialAddress(premiseValue: boolean){
    this.values.isInternationalPremisePartialAddress = premiseValue;
  }

  changeFocusOnValidAddress(focus) {

    // US-CA address and non secure print
    // acknowledgement should be displayed for all countries
    if (this.addressVerify.values.userUsedHisAddress &&
      ((!this.values.securePrint && !this.values.certifiedMailSecurePrint) || this.values.isInternationalPremisePartialAddress)) {
      this.show.acknowledgeInvalidAddress = true;
      this.values.acknowledgeMessage = this.formGroup.controls.country.value === this.commonValues.api.us ? this.commonValues.addressValidationMessages.acknowlelgeAddressUS : this.commonValues.addressValidationMessages.acknowlelgeAddressInternational;
      this.manageFields.enable([this.formGroup.controls.isAckgInvalidAddress]);
    }

    // Secure print
    if (!this.values.isInternationalPremisePartialAddress){
      this.isSecurePrintAndInvalidAddress();
      if (this.values.certifiedMailSecurePrint){
        this.isSecurePrintCertifiedMailAndInvalidAddress();
      }
    }

    if (focus) {
      if (this.addressVerify.values.addressIsValid && this.values.userReachedContinue) {
        this.continue();
      } else if (this.addressVerify.values.addressIsValid) {
        const recipientPhoneElement = this.renderer.selectRootElement('#recipientPhone');
        window.setTimeout(() => recipientPhoneElement.focus(), 0);
      } else if (!this.addressVerify.values.addressIsValid){
        const recipientZipCodeElement = this.renderer.selectRootElement('#recipientZipCode');
        window.setTimeout(() => recipientZipCodeElement.focus(), 0);
      }
    }
  }

  isSecurePrintAndInvalidAddress(): boolean {

    // for all international countries, the acknowledgement should be displayed to user
    /*if (this.formGroup.controls.country.value !== this.commonValues.api.ca && this.formGroup.controls.country.value !== this.commonValues.api.us ) {
      return false;
    }
    else */if (this.addressVerify.values.addressVerificationTriggered &&
      this.values.securePrint &&
      !this.addressVerify.values.addressIsValid) {
        // add a conidtion here use @out put to pass the variable from the child in order to see if its express or non secure print addres premise
      this.values.securePrintInvalidAddress = true;
      this.values.securePrintInvalidAddressMessage = this.formGroup.controls.country.value === this.commonValues.api.us ? this.commonValues.addressValidationMessages.securePrintInvalidAddressUS :  this.commonValues.addressValidationMessages.securePrintInvalidAddressInternational;
      this.show.acknowledgeInvalidAddress = false;
      this.manageFields.disable([this.formGroup.controls.isAckgInvalidAddress]);
      this.manageFields.enable([this.formGroup.controls.securePrintInvalidAddress]);
      return true;
    }
    return false;
  }

  isSecurePrintCertifiedMailAndInvalidAddress(): boolean {

    if (this.formGroup.controls.country.value !== this.commonValues.api.us) {
      return false;
    }
    else if (this.addressVerify.values.addressVerificationTriggered &&
      this.values.certifiedMailSecurePrint &&
      !this.addressVerify.values.addressIsValid) {
        // add a conidtion here use @out put to pass the variable from the child in order to see if its express or non secure print addres premise
      this.values.certifiedMailSecurePrintInvalidAddress = true;
      this.values.certifiedMailSecurePrintInvalidAddressMessage = this.commonValues.addressValidationMessages.securePrintInvalidAddressUS;
      this.show.acknowledgeInvalidAddress = false;
      this.manageFields.disable([this.formGroup.controls.isAckgInvalidAddress]);
      this.manageFields.enable([this.formGroup.controls.certifiedMailInvalidAddress]);
      return true;
    }
    return false;
  }

  setControlAttributes(countryValue: string): void {
    const controls = this.formGroup.controls;
    const isUS = countryValue === this.commonValues.api.us;
    const isCA = countryValue === this.commonValues.api.ca;
    const isDomestic = isUS || isCA;

    this.content.hintPhone = isUS
      ? this.content.hintPhoneDomestic
      : this.content.hintPhoneInternational;
    
    // configure phone;
    // domestic vs international formats;
    if (isUS) {
      this.phoneInput.mask = this.masks.phonemask;
      this.content.labelPhone =this.commonValues.address.labelPhoneUS;
      controls.phone.setValidators(this.values.validationPhoneDomestic);
    } else {
      this.phoneInput.mask = false;
      this.masks.phonemask= null;
      const _xmitCode = this.values.deliveryMethodObject.xmitCode;

      if(_xmitCode === this.commonValues.api.expressCaMex || _xmitCode === this.commonValues.api.expressInternational){
        this.content.labelPhone = this.commonValues.address.labelPhone.internationalExpress;
        this.content.hintDescValue = this.commonValues.address.hintDescValue.internationalExpress;
      }else{
        this.content.labelPhone = this.commonValues.address.labelPhone.international;
      }
      controls.phone.setValidators(this.values.validationPhoneInternational);
      
    }

    // configure zip;
    // us & canda have different validations;
    if (isDomestic) {
      controls.zip.setValidators(isUS ? this.values.validationZipUS : this.values.validationZipCA);
      this.content.hintZip = this.content.hintZipDomestic;
    }
    // international has no zip validation;
    else {
      let validationValue = this.zipcodeValidation.internationalZipValidation.find(e => e.countryCode === countryValue);
      if(!_isEmpty(validationValue)){
        controls.zip.setValidators(validationValue.validation);
        this.content.hintZip = this.content.hintZipDomestic;
      }else{
        controls.zip.clearValidators();
        this.content.hintZip = this.content.hintZipInternational;
      }
    }

    // configure states (labels and values) based on country;
    if (isDomestic) {
      if (isUS) {
        this.content.labelState = this.content.labelStateUS;
        this.values.states$.next(this.values.statesUS);
      } else {
        this.content.labelState = this.content.labelStateCA;
        this.values.states$.next(this.values.statesCA);
      }

      this.manageFields.enable([controls.state]);
    }
    // or disable the state field if international;
    else {
      this.content.labelState = this.content.labelStateInternational;

      this.manageFields.disable([controls.state]);
    }
  }

  wasAddressValidated(): boolean {
    // not US/Canada address, skip validation
    if(!this.verifyUsService.verifyInternationalAddressVerificationToggle() && 
    !(this.formGroup.controls.country.value === this.commonValues.api.us || 
      this.formGroup.controls.country.value === this.commonValues.api.ca)){
      return true;
    }
    // US Address or Canada address
    if (this.formGroup.controls.country.value === this.commonValues.api.us || (//this.formGroup.controls.country.value === this.commonValues.api.ca && 
      (this.values.validateInternationAddressKey || this.values.validateInternationAddressKey === null))) {
      return (this.addressVerify.values.addressIsValid || this.addressVerify.values.userUsedHisAddress ||
              this.addressVerify.values.verificationServiceUnavailable);
    } else {
      return true;
   }
  }
  canContinue(): boolean {
    return (
      (
        /*this.formGroup.controls.country.value !== this.commonValues.api.us && 
        this.formGroup.controls.country.value !== this.commonValues.api.ca ||*/
      this.formGroup.controls.isVerifiedAddress.value === this.commonValues.api.yes ||
      this.addressVerify.values.verificationServiceUnavailable ||
      ((this.formGroup.controls.isAckgInvalidAddress.disabled ||
        this.formGroup.controls.isAckgInvalidAddress.value === this.commonValues.api.yes) &&
        this.formGroup.controls.securePrintInvalidAddress.disabled &&
        this.formGroup.controls.certifiedMailInvalidAddress.disabled)) &&
      this.formGroup.valid && !this.values.invalidDeliveryMethod);
  }

  previous(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      ariaLabelledBy:  "prevTitle",
      ariaDescribedBy: "prevContent",
      autoFocus: false,
      data: {
        body: this.commonValues.warningLoseInformation,
        title: "Information",
        id:"prev"
      }
    }).afterClosed().subscribe(result =>{
      if(result){
        this.dataService.resetRecipientAddress();

      this.securityService.setDeactivateWarning(false);

      this.router.navigate(
        [this.commonValues.routes.recipientDeliveryMethod],
        this.commonValues.routes.extras
      );
      }
    });
 
  }

  cancel(): void {
    this.securityService.cancelOrder();
  }


  continue(): void { 
    this.values.userReachedContinue = true;
    this.setIsAddressValidation();
    // if address is still not validated and user did not consent to invalid address then verify
    if (!this.wasAddressValidated()) {
      const addressLine1 = this.formGroup.controls['addressLine1'].value? this.formGroup.controls['addressLine1'].value : '';
      const addressLine2 = this.formGroup.controls['addressLine2'].value? this.formGroup.controls['addressLine2'].value : '';
      const city = this.formGroup.controls['city'].value? this.formGroup.controls['city'].value : '';
      const state = this.formGroup.controls['state'].value? this.formGroup.controls['state'].value +' ' : '';
      const zip = this.formGroup.controls['zip'].value? this.formGroup.controls['zip'].value : '';

      this.values.reqEnteredAddressLine1 = addressLine1 + ' ' + addressLine2;
      this.values.reqEnteredAddressLine2  = city + ' '+ state + zip;

       this.addressVerify.verifyEnteredAddress();
    }

    // capturing the requestor entered address when suggested address is choosen by the requestor. This is for entered address event log purpose
    if (this.addressVerify.values.addressVerificationTriggered && this.addressVerify.values.addressIsValid && !this.addressVerify.values.userUsedHisAddress){
      // address.enteredAddressLine1 = this.values.reqEnteredAddressLine1;
      // address.enteredAddressLine2 = this.values.reqEnteredAddressLine2;
      this.formGroup.controls.enteredAddressLine1.setValue(this.values.reqEnteredAddressLine1? this.values.reqEnteredAddressLine1.trim(): null);
      this.formGroup.controls.enteredAddressLine2.setValue(this.values.reqEnteredAddressLine2? this.values.reqEnteredAddressLine2.trim(): null);
    }

    if (this.wasAddressValidated() && this.formGroup.valid) {

      // we need to clear out any previous content before saving to prevent orphaned content (like state/phone);
      this.dataService.resetRecipientAddress();
      // if phone number is not present reset the value
      if (!this.formGroup.value.phone) {
        if (this.formGroup.value.phone === '') {
          this.manageFields.reset([this.formGroup.controls.phone]);
        }
      }
      let formControls = this.formGroup.value;

      // when country is USA , dropdown it's been disabled so value is been resetting as well so reading all the values again
      if (!formControls.country) {
        formControls = this.formGroup.getRawValue();
      }
      // if the country is international and state and zip values has not been filled out then needs to be sent like below.
      if (formControls.country !== this.commonValues.api.us && formControls.country !== this.commonValues.api.ca) {
        if (!(formControls.zip)) {
          formControls.zip = this.commonValues.defaultZip;
        }
        if (!(formControls.state)) {
          formControls.state = this.values.stateInternational;
        }
      }
      // this will apply just for US-CA country since we dont have any mask for international phone number
      // checking if '(' is included on the phone  because if we edit the order , phone number is been already formatted so no need to format twice
      if (formControls.country === this.commonValues.api.us || formControls.country === this.commonValues.api.ca) {
        if (formControls.phone) {
          formControls.phone = formControls.phone.includes('(') ? ((formControls.phone).replace(/[\)\s']+/g, '-')).substring(1) : formControls.phone;
        }
      }
      // set address verified values
      formControls.isVerifiedAddress = this.addressVerify.values.addressIsValid ? this.commonValues.api.yes : this.commonValues.api.no;
      formControls.isAckgInvalidAddress = this.addressVerify.values.userUsedHisAddress ? this.commonValues.api.yes : this.commonValues.api.no;

      this.dataService.save(formControls, 'form.recipient.address');
      this.dataService.saveRecipient();

      this.securityService.setDeactivateWarning(false);

      this.router.navigate([this.commonValues.routes.orderReview], this.commonValues.routes.extras);
    }
  }

  setIsAddressValidation(){
    const countriesList = this.values.countries$.getValue()
    const selectedCountry = countriesList.find(eachCountry => eachCountry.value === this.formGroup.value.country);
    if(selectedCountry){
      this.verifyUsService.isAddressValidation = selectedCountry.isAddressValidation
    }
  }

  onPhoneBlur(hasError: boolean){
    const _xmitCode = this.values.deliveryMethodObject?.xmitCode;
    if(!hasError && (_xmitCode === this.commonValues.api.expressCaMex || _xmitCode === this.commonValues.api.expressInternational) 
      && this.formGroup.controls.country.value !== this.commonValues.api.us){
      this.dialog.open(ConfirmationDialogComponent, {
        width: '460px',
        ariaLabelledBy:  "onPhoneBlurTitle",
        ariaDescribedBy: "onPhoneBlurContent",
        autoFocus: false,
        data: {
          body: this.commonValues.onPhoneBlurPopupInfo,
          title: "International Recipient Phone Number",
          id:"onPhoneBlur",
          btnYesName: "CLOSE",
          hideNoBtn: true
        }
      })
    }
  }
}
