<mat-form-field appearance="outline" [hideRequiredMarker]="true"
  [formGroup]="group"
  [ngClass]="loading ? 'nsc-form-field-loading' : ''">
  <mat-label>{{placeholder}}</mat-label>
  <mat-select
    [id]="idx"
    [formControlName]="controlName"
    (openedChange)="openedChange($event)"
    (selectionChange)="changeValue($event)"
    [(value)]="selectedValue.id">

<!-- obj.xpathCode is configured only in one scenario where custom xpath is required in all other scenarios i will be used for generating xpath-->

    <mat-option *ngFor="let obj of selectObjects; let i = index"
                id = "{{idx}}_{{obj.xpathCode || i}}"
                [value]="obj.value" >
      {{ obj.name }}
    </mat-option>
  </mat-select>
   <mat-hint>{{ content.hint }} </mat-hint>
  <mat-error *ngIf="group.controls[controlName].invalid">Error: {{ content.hint }} {{ validationMessage.get(group.controls[controlName], placeholder) }}</mat-error>
</mat-form-field>