<div class="row">
  <div class="col-12 col-md-8 col-lg-4">
    <nsc-form-field-select
      idx="recepientselect_country"
      controlName="country"
      placeholder="Country"
      [group]="formGroup"
      [selectObjects]="values.countries$.value"></nsc-form-field-select>
  </div>
  <div class="col-12 col-md-8 col-lg-4" *ngIf="formGroup.controls['state'].enabled">
    <nsc-form-field-select-optgroup
      idx="recepientselect_state"
      controlName="state"
      placeholder="{{ content.labelState }}"
      [group]="formGroup"
      [selectObjects]="values.states$.value"></nsc-form-field-select-optgroup>
  </div>

  <div class="col-12"></div>

  <div class="col-12 col-md-8" *ngIf="show.school">
    <nsc-form-field-autocomplete
    [minCharactersToTrigger]="values.minCharactersToTrigger"
	[searchType]="values.searchType"
      idx="recepientselect_school"
      controlName="school"
      placeholder="{{ content.labelSchool }}"
      [group]="formGroup"
      [notInList]="true"
      [options]="values.schools$.value"
      [loading]="loadingSchools"
      (valueSelected)="isReceipientSameAsSending($event)"
      ></nsc-form-field-autocomplete>
  </div>
  <div class="col-12 " *ngIf="show.school"> 
    <a id="recipientselect_searchLink" tabindex="0" href="#" (click)="searchLinkAction($event)">{{content.searchLinkLabel}}</a>
  </div>
 <div class="col-12" *ngIf="show.school">
  <nsc-spacer size="sm"></nsc-spacer>
 </div>
  <div class="col-12 col-md-8" *ngIf="formGroup.controls['schoolNotInList'].enabled">
    <nsc-form-field-input
      idx="recepientselect_notinlist"
      controlName="schoolNotInList"
      maxlength="60"
      placeholder="Enter School Name"
      [group]="formGroup"></nsc-form-field-input>
  </div>
  
  <nsc-recipient-select-department
    class="col-12"
    [formGroup]="formGroup" *ngIf="show.department"></nsc-recipient-select-department>
</div>
