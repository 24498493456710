
<div mat-dialog-title class="mat-dialog-title" id="modalAddressTitle">
  <h3 class="nsc-title" >Address Notification</h3>
  <span mat-dialog-close class="close">
    <mat-icon class="nsc-icon__svg" svgIcon="close" aria-hidden="false"
      aria-label="close confirmation dialog"></mat-icon>
  </span>
</div>
<mat-dialog-content id="modalAddressContent" class="mat-typography">
<p class= "">{{addressValidationMessage}}</p>
<p class="">{{instructionalText}}</p>
<div *ngIf='addressCorrected' class="row justify-content-center">
  <div class="col-auto nsc-wrapper-option" *ngIf ="show.suggestedAddress">
    <p class="subTitle">
      <strong>Suggested Address</strong>
    </p>
    <button id="address_validation_SuggestedAddress"  color="primary"  mat-stroked-button   type="button" (click)="dialogRef.close(correctedAddressValues)">
      {{ correctedAddressValues.delivery_line_1 || correctedAddressValues.address1}}
      <br *ngIf="(correctedAddressValues.delivery_line_2 || correctedAddressValues.address2)">
      {{correctedAddressValues.delivery_line_2 || correctedAddressValues.address2 }}
      <br>
      {{correctedAddressValues.components.city_name || correctedAddressValues.components.locality }}<span *ngIf="(correctedAddressValues.components.city_name || correctedAddressValues.components.locality)">,</span> {{correctedAddressValues.components.state_abbreviation || correctedAddressValues.components.administrative_area}}
      <br>
      {{postalOrZipCode}}

    </button>
  </div>

  <div class="col-auto" *ngIf="show.suggestedAddress && continueOnlyWithValidAddress">
      
        <strong>Entered Address</strong><br>
        <p >
        {{enteredAddress.request.street}} <br *ngIf="!isNullorBlank(enteredAddress.request.street2)">
        {{enteredAddress.request.street2}} <br>
        {{enteredAddress.request.city}} <span *ngIf="enteredAddress.request.state !='ZZ'">{{enteredAddress.request.state}}</span> {{enteredAddress.request.zipcode}}</p>
  </div>

  <nsc-spacer size="sm"></nsc-spacer>
  <div class="col-auto nsc-wrapper-option" *ngIf="show.suggestedAddress && show.enteredAddress">
      <p class="subTitle">
          <strong>Entered Address</strong>
        </p>
      <button  id="address_validation_EnteredAddress" color="primary" mat-stroked-button type="button"   (click)="dialogRef.close(enteredAddress)">
          {{enteredAddress.request.street}} <br *ngIf="!isNullorBlank(enteredAddress.request.street2)">
          {{enteredAddress.request.street2}} <br>
          {{enteredAddress.request.city}} <span *ngIf="enteredAddress.request.state !='ZZ'">{{enteredAddress.request.state}}</span> {{enteredAddress.request.zipcode}}
      </button> 
  </div>
</div>
<div *ngIf="!addressCorrected && show.enteredAddress && !missingSecondary">
    <nsc-spacer size="sm"></nsc-spacer>
    
      <strong>Entered Address</strong><br>
      <p class="m-sm">
      {{enteredAddress.request.street}} <br *ngIf="!isNullorBlank(enteredAddress.request.street2)">
      {{enteredAddress.request.street2}} <br>
      {{enteredAddress.request.city}} <span *ngIf="enteredAddress.request.state !='ZZ'">{{enteredAddress.request.state}}</span> {{enteredAddress.request.zipcode}} </p>
</div>
<div *ngIf='missingSecondary'>
  <mat-form-field appearance="outline">
    <input id="address_validation_change_apt" matInput [(ngModel)]='secondaryNbr' placeholder="Correct your Apt/Suite Number">
  </mat-form-field>
  <button id="address_validation_submit" [disabled]="!secondaryNbr" mat-raised-button color="primary" class="nsc-button " type="button" [mat-dialog-close]='secondaryNbr'
    cdkFocusInitial>Submit</button>
</div>
<nsc-spacer size="sm"></nsc-spacer>
<button id="address_validation_change_suggested" *ngIf="show.suggestedAddress && continueOnlyWithValidAddress" color="primary" mat-stroked-button class="nsc-button" type="button" 
        (click)="dialogRef.close(false)">Edit Address</button>
<div *ngIf='!addressCorrected' class="d-flex justify-content-center">
  <button mat-raised-button id="address_validation_EditAddress" class="nsc-button " type="button" color="primary" (click)="dialogRef.close(false)">Edit Address</button>
  <nsc-spacer size="md"></nsc-spacer>
  <button mat-raised-button id="address_validation_use_entered" class="nsc-button " type="button" color="primary" (click)="dialogRef.close(true)">Use Entered Address</button>
</div>


</mat-dialog-content>