import { BehaviorSubject, Subject } from 'rxjs';
import { catchError ,  takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { ApiResponsePaymentConfig } from '../../interfaces/api-response-payment-config.interface';
import { SecurityService } from '../../services/security/security.service';

@Injectable()
export class PaymentConfigService implements OnDestroy {
  data$ = new BehaviorSubject(null);
  unsubscribe$ = new Subject();

  constructor(private http: HttpClient, private securityService: SecurityService) {
    this.getData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  getData(): void {
    this.http
      .get('/api/payment/config')
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(error => this.securityService.catchResponse(error))
      )
      .subscribe((data: ApiResponsePaymentConfig) => {
        this.data$.next(data);
      });
  }  
}
