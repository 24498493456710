<div *ngIf="!dataService.data.sssRequestCheck && !hideSchoolSelect">
  
  <form [formGroup]="formGroup" novalidate aria-atomic="true" aria-relevant="additions"
    aria-labelledby="invalidSchoolIdentifier">
    
    <nsc-system-message></nsc-system-message>
    <div id="invalidSchoolIdentifier">
      <div *ngIf="dataService.showSystemMessage">
        <nsc-spacer size="md"></nsc-spacer>
        <nsc-card [error]="true">
          <nsc-card-title-error title="Invalid School Identifier" icon="warning">
            <div 
            aria-label=" We cannot confirm your school based on the URL you are coming from. Please ask your Registrar's Office to contact the National Student Clearinghouse.">
            We cannot confirm your school based on the URL you are coming from. Please ask your Registrar's Office to
            contact the National Student Clearinghouse.
          </div>
          </nsc-card-title-error>
        </nsc-card>
        <nsc-spacer size="md"></nsc-spacer>
      </div>
    </div>

    <nsc-card>
      <nsc-card-title>{{content.title}}</nsc-card-title>
      <nsc-card-content>
        <div class="row">
          <div class="col-12 col-md-8">
            <nsc-form-field-autocomplete idx="nsc-form-field-autocomplete" controlName="school"
              placeholder="{{ content.labelSchool }}" [group]="formGroup" [notInList]="false"
              [options]="values.schools$.value" [loading]="loadingSchools"
              [minCharactersToTrigger]="values.minCharactersToTrigger" [searchType]="values.searchType">
            </nsc-form-field-autocomplete>
          </div>
          <div class="col-12 ">
            <a href="#" id="search_link" (click)="searchLinkAction($event)">{{content.searchLinkLabel}}</a>
          </div>
        </div>
      </nsc-card-content>
    </nsc-card>
    <nsc-spacer size="md"></nsc-spacer>

    <div *ngIf="(show.systemAcknowledge)">     
        <nsc-card [error]="true" >
          <nsc-card-title-error [error]="true" icon="warning" title ="Test System Acknowledgement"></nsc-card-title-error>
          <nsc-card-content>
          <div id="testSystemAck">        
            <nsc-form-field-toggle idx="testSystemAck" 
                                  label={{values.testSystemAcknowledgeMessage}}
                                  controlName = "testSystemAck"
                                  [group]="formGroup"
                                  >
            </nsc-form-field-toggle>
          </div>
          <div>If you are a student or alumni please go to <a href="https://tsorder.studentclearinghouse.org">https://tsorder.studentclearinghouse.org</a> to place your order.</div>
          </nsc-card-content>
        </nsc-card>
    </div>
  
    <nsc-spacer size="md"></nsc-spacer>

    <div class="nsc-form-actions">
      <button mat-raised-button id="schoolselect_continue" class="nsc-form-actions__button nsc-btn__right button-big" color="primary"
        type="button" [disabled]="isContinueDisabled()" (click)="continue()">Continue <mat-icon aria-hidden="true"
          *ngIf="formGroup.valid">chevron_right</mat-icon></button>
    </div>
  </form>


 
</div>