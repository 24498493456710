import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { empty as observableEmpty, Observable } from 'rxjs';
import { Router } from '@angular/router';

import { ApiResponseSchoolProfile } from '../../interfaces/api-response-school-profile.interface';
import { CheckApiResponse } from '../../classes/check-api-response';
import { DataService } from '../../services/data/data.service';
import { SecurityService } from '../../services/security/security.service';
import { CommonValues } from '../../classes/common-values';

@Injectable()
export class SchoolService {
  constructor(
    private checkApiResponse: CheckApiResponse,
    private dataService: DataService,
    private http: HttpClient,
    private router: Router,
    private commonValues: CommonValues,
    private securityService: SecurityService
  ) {}

  get(id): Observable<ApiResponseSchoolProfile> {
    return this.http.get<ApiResponseSchoolProfile>(`/api/tsorder/toscprofiledetail/ficecode/${id}`).pipe(
      tap(json => this.saveResponse(json)),
      catchError(error => this.catchResponse(error))
    );
  }

  getBySchoolProfileId(id): Observable<ApiResponseSchoolProfile> {
    return this.http.get<ApiResponseSchoolProfile>(`/api/tsorder/toscprofiledetail/toscprofilid/${id}`).pipe(
      tap(json => this.saveResponse(json)),
      catchError(error => this.catchResponse(error))
    );
  }

  saveResponse(json: ApiResponseSchoolProfile): void {
    if ( !(json && json.errorCode) &&  this.checkApiResponse.isValid(json)) {
      this.dataService.save({
        schoolProfile: json
      });
    } else {
      throw new Error('Error found in valid response JSON');
    }
  }

  catchResponse(error): Observable<never> {

    // clear all data for security reasons;
    //this.dataService.resetDataObject();

    this.dataService.showSystemMessage = true;

    this.securityService.setDeactivateWarning(false);

    window.setTimeout(() => {
      this.router.navigate(
        [this.commonValues.routes.schoolSelect],
        this.commonValues.routes.extras
      );
    }, this.commonValues.loading.delay);

    // stop the chain, so the subscribe method doesn't fire;
    return observableEmpty();
  }


}
