import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'nsc-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit{

  btnYesName = 'Yes';
  btnNoName = 'No';
  public confirmMessage: string;
  
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title?: string; body: string, id?:string,  btnYesName?: string;
      btnNoName?: string , hideYesBtn?:boolean, hideNoBtn?:boolean;}
      ) { }

 
  ngOnInit() {
    this.data.btnYesName = this.data.btnYesName ? this.data.btnYesName : this.btnYesName;
    this.data.btnNoName = this.data.btnNoName ? this.data.btnNoName : this.btnNoName;
  }
}
