import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { FormGroup } from '@angular/forms';
import { ApiResponseRecipientProfile } from '../../interfaces/api-response-recipient-profile.interface';
import { filter as _filter } from 'lodash';
import { ManageFields } from '../../classes/manage-fields';
import { DialogHelpComponent } from '../dialog-help/dialog-help.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonValues } from '../../classes/common-values';

@Component({
  selector: 'nsc-form-field-sub-network-type',
  templateUrl: './form-field-sub-network-type.component.html',
  styleUrls: ['./form-field-sub-network-type.component.scss']
})
export class FormFieldSubNetworkTypeComponent implements OnInit {
  @Input() formGroup: FormGroup;

  data = this.dataService.get();
  who = this.data.form.recipient.who;

  content = {
    labelAamcAccountNumber: null,
    labelAmcasTranscriptIdNumber: null,
    labelCasId: null,
    labelLsacAccountNumber: null,
  };

  masks = {
    aamcAccountNumber:"00000000",
    amcasTranscriptIdNumber:"0000000",
    lsacAccountNumber:"00000000",
  };

  constructor(private dataService: DataService, private manageFields: ManageFields,public dialog: MatDialog , private commonValues:CommonValues) { }

  ngOnInit(): void {
   
  }

  processRecipientJson(json: ApiResponseRecipientProfile): void {
    const controls = this.formGroup.controls;

      // show AMACs form fields;
        if (json.subNetwork && json.subNetwork.subNetworkType === this.commonValues.api.amcas) {
          const profiles = json.subNetwork.eteIdProfiles || [];
          const aamcArray = _filter(profiles, { eteIdType: this.commonValues.api.amcasEteIdType });
          const amcasArray = _filter(profiles, {
            eteIdType: 'AMCAS_TRANSCRIPT_ID'
          });

          if (aamcArray.length && amcasArray.length) {
            this.content.labelAamcAccountNumber = aamcArray[0].eteIdLabel.replace(':', '');
            this.content.labelAmcasTranscriptIdNumber = amcasArray[0].eteIdLabel.replace(':', '');

            this.manageFields.enable([
              controls.amcasTranscriptIdNumber,
              controls.aamcAccountNumber
            ]);
          } else {
            // do nothing if the labels weren't found;
          }
        }
        // show LSAC form field;
        else if (json.subNetwork && json.subNetwork.subNetworkType === this.commonValues.api.lsac) {
          const profiles = json.subNetwork.eteIdProfiles || [];
          const lsacArray = _filter(profiles, { eteIdType: this.commonValues.api.lsacEteIdType });

          if (lsacArray.length) {
            this.content.labelLsacAccountNumber = lsacArray[0].eteIdLabel.replace(':', '');

            this.manageFields.enable([controls.lsacAccountNumber]);
          } else {
            // do nothing if the labels weren't found;
          }
        }
        // show CAS form field;
        else if (json.subNetwork && json.subNetwork.subNetworkType === this.commonValues.api.liason) {
          const profiles = json.subNetwork.eteIdProfiles || [];
          const lsonArray = _filter(profiles, {
            eteIdType: this.commonValues.api.liasonEteIdType 
          });

          if (lsonArray.length) {
            this.content.labelCasId = lsonArray[0].eteIdLabel.replace(':', '');

            this.manageFields.enable([controls.casId]);
          } 
        }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogHelpComponent, {
      width: '480px',
      ariaLabelledBy: "modalCasTitle",
      ariaDescribedBy: "modalCasContent",
      autoFocus:false,
      data: {
        id: "modalCas",
        title: 'CAS Transcript ID Help',
        body:
          '<img src="/assets/liaison-barcode.png" alt="" /><br />Enter a value for the CAS Transcript ID field. The CAS Transcript ID can be found on the Transcript Request form generated by the admissions service. The number is under the bar code on the form. Please copy and paste the ID number into the ‘CAS Transcript ID’ field.'
      }
    });
  }
}
