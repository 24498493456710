<nsc-card>
  <nsc-card-title>Fee Summary</nsc-card-title>
  <nsc-card-content>
    <div class="row">
      <div class="col-12 col-lg-6">
       
        <div class="nsc-table nsc-table--no-title">
          <div class="nsc-table__row" *ngIf="fees.transcriptFee !== 0" >
            <div class="nsc-table__col nsc-table__col--label">
              <strong class="nsc-txt">Transcript Quantity Fee</strong>
            </div>
            <div class="nsc-table__col nsc-table__col--value">
              <strong class="nsc-txt">{{ fees.transcriptFee | currency }}</strong>
            </div>
          </div>

          <div class="nsc-table__row" *ngIf="fees.deliveryFee !== 0 " >
            <div class="nsc-table__col nsc-table__col--label">
              <strong class="nsc-txt">{{ fees.deliveryFeeDescription}}</strong>
            </div>
            <div class="nsc-table__col nsc-table__col--value">
              <strong class="nsc-txt">{{ fees.deliveryFee | currency }}</strong>
            </div>
          </div>

          <div class="nsc-table__row" *ngIf="fees.transcriptTypeFee !== 0" >
            <div class="nsc-table__col nsc-table__col--label">
              <strong class="nsc-txt">Transcript Type Fee</strong>
            </div>
            <div class="nsc-table__col nsc-table__col--value">
              <strong class="nsc-txt">{{ fees.transcriptTypeFee | currency }}</strong>
            </div>
          </div>

          <div class="nsc-table__row" *ngIf="fees.shippingAndHandlingFee !== 0" >
            <div class="nsc-table__col nsc-table__col--label">
              <strong class="nsc-txt">Shipping and Handling Fee</strong>
            </div>
            <div class="nsc-table__col nsc-table__col--value">
              <strong class="nsc-txt">{{ fees.shippingAndHandlingFee | currency }}</strong>
            </div>
          </div>

          <div class="nsc-table__row" *ngIf="fees.onlineProcessingFee !== 0" >
            <div class="nsc-table__col nsc-table__col--label">
              <strong class="nsc-txt">Online Processing Fee</strong>
            </div>
            <div class="nsc-table__col nsc-table__col--value">
              <strong class="nsc-txt">{{ fees.onlineProcessingFee | currency }}</strong>
            </div>
          </div>

          <div class="nsc-table__row nsc-table__row--total" >
            <div class="nsc-table__col nsc-table__col--label nsc-table__col--label--total" style="text-align: right">
              <strong>Total Fee for this Recipient</strong>
            </div>
            <div class="nsc-table__col nsc-table__col--value nsc-table__col--total">
              <strong>{{ fees.totalFee | currency }} </strong>
              <button *ngIf="(isSPDESchool || isFASTSchool || (isTNSchool && fees.totalFee === 0))" (click)="viewTotalFeeTNDetails()" mat-icon-button aria-label="info">
                <mat-icon>info</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nsc-card-content>
</nsc-card>