<div mat-dialog-title class="mat-dialog-title" id="{{data.id}}Title">
  <h3  class="nsc-title">{{ data.title }}</h3>
  <span mat-dialog-close class="close">
    <mat-icon class="nsc-icon__svg" svgIcon="close" aria-hidden="false"
      aria-label="close confirmation dialog"></mat-icon>
  </span>
</div>
<mat-dialog-content class="mat-typography">
  <div id="{{data.id}}Content" [innerHTML]="data.body | safeHtml:'html' "></div>
</mat-dialog-content>
<mat-dialog-actions align="start">
  <button mat-raised-button id="orderconfirmation_ok" attr.aria-label="{{confirmMessage}} confirmation" class="nsc-button" type="button" color="primary"
    (click)="close()">
    {{confirmMessage}}
  </button>
</mat-dialog-actions>