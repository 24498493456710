import { NgModule } from "@angular/core";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@NgModule({
  exports: [MatIconModule]
})
export class CustomSVGIconModule {
  customIcons: Array<[string, string]> = [["close", "assets/close.svg"],["warning", "assets/warning.svg"]];
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    this.customIcons.forEach(([iconName, iconPath]) => {
      iconRegistry.addSvgIcon(
        iconName,
        sanitizer.bypassSecurityTrustResourceUrl(iconPath)
      );
    });
  }
}