<mat-form-field id="{{idx}}-mat-form-field" appearance="outline" [hideRequiredMarker]="true" [formGroup]="group">
  <mat-label>{{ placeholder }}</mat-label>
  <input matInput
    (focus)="onFocus()"
    (blur)="onBlur()"
    id="{{ idx }}"
    formControlName="{{ controlName }}"
    maxlength="{{ maxlength }}"
    minlength="{{ minlength }}"
    [attr.aria-pressed]=""
    type="{{ type }}"
    [dropSpecialCharacters]="false"
    prefix="{{prefixT}}"
    [attr.autocomplete]="autocomplete"
    [mask]="mask">
  <mat-icon *ngIf="suffix" matSuffix>{{ suffix }}</mat-icon>
  <mat-hint>{{ content.hint }} <span class="mat_hit_hidden"  *ngIf="required">required</span></mat-hint>
  <mat-error *ngIf="group.controls[controlName].invalid" [attr.aria-label]="'Error '+content.hint + validationMessage.get(group.controls[controlName], placeholder) ">Error: {{ content.hint }} {{ validationMessage.get(group.controls[controlName], placeholder) }}</mat-error>
</mat-form-field>