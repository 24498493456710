import { BehaviorSubject, Subject } from 'rxjs';
import { sortBy as _sortBy } from 'lodash';
import { Component, Input, OnDestroy, OnInit, ViewChild, Output, Renderer2 } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { AutocompleteOption } from '../../../interfaces/autocomplete-option.interface';
import { ApiResponseRecipient } from '../../../interfaces/api-response-recipient.interface';
import { BlockingIndicatorService } from '../../../services/blocking-indicator/blocking-indicator.service';
import { CommonValues } from '../../../classes/common-values';
import { CountriesService } from '../../../services/countries/countries.service';
import { DataService } from '../../../services/data/data.service';
import { ManageFields } from '../../../classes/manage-fields';
import { RecipientSelectDepartmentComponent } from '../recipient-select-department/recipient-select-department.component';
import { SchoolsByStateService } from '../../../services/schools-by-state/schools-by-state.service';
import { SelectOption } from '../../../interfaces/select-option.interface';
import { SelectOptgroup } from '../../../interfaces/select-optgroup.interface';
import { States } from '../../../interfaces/states.interface';
import { StatesService } from '../../../services/states/states.service';
import { ValidateSchoolAutocomplete } from '../../../validators/school-autocomplete.validator';
import { EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogHelpComponent } from '../../../components/dialog-help/dialog-help.component';

@Component({
  selector: 'nsc-recipient-select-college',
  templateUrl: './recipient-select-college.component.html',
  styleUrls: ['./recipient-select-college.component.scss']
})
export class RecipientSelectCollegeComponent implements OnDestroy, OnInit {
  @Input() formGroup: FormGroup;
  @Input() snSchool: string;
  @ViewChild(RecipientSelectDepartmentComponent)
  recipientSelectDepartmentComponent: RecipientSelectDepartmentComponent;

  data = this.dataService.get();
  who = this.data.form.recipient.who;
  loadingInterval: number;
  unsubscribe$ = new Subject();
  unsubscribeAjax$ = new Subject();
  dialogRef: MatDialogRef<DialogHelpComponent>;

  isOnlyelectronicAvailable = false;

  @Output() loadingOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  loadingSchools = false;

  content = {
    
    labelInternational: 'International',
    // label values for State SELECT form field;
    // value of the label changes based on the selected Country;
    labelState: this.commonValues.address.labelStateUS,
    labelStateUS: this.commonValues.address.labelStateUS,
    labelStateCA: this.commonValues.address.labelStateCA,

    // label values for School AUTOCOMPLETE form field;
    // value of the label changes during loading of values through a service call;
    labelSchool: null,
    labelSchoolLoaded: this.commonValues.autocomplete.custom.recipient.labelSchoolLoaded,
    labelSchoolLoading: this.commonValues.autocomplete.custom.labelSchoolLoading,
    searchLinkLabel: this.commonValues.autocomplete.custom.recipient.searchLinkLabel,
  };

  show = {
    // used to determine if the school fields should be shown;
    // can be shown while still disabled (during loading of School values);
    school: this.who.school !== null,
    advancedSearch: false,
    department: !this.dataService.isEnrolledBefore()
  };

  values = {
    // SELECT values populated by service call;
    countries$: new BehaviorSubject(<SelectOption[]>[]),
    states$: new BehaviorSubject(<SelectOptgroup[]>[]),

    // AUTOCOMPLETE values populated by service call;
    schools$: new BehaviorSubject(<AutocompleteOption[]>[]),

    // states$ value transformed into US and Canda SELECT values;
    statesUS: <SelectOptgroup[]>[],
    statesCA: <SelectOptgroup[]>[],

    // search autocomplete default values
    searchType: null,
    minCharactersToTrigger: null
  };

  constructor(
    public blockingIndicatorService: BlockingIndicatorService,
    private commonValues: CommonValues,
    private countriesService: CountriesService,
    private dataService: DataService,
    private manageFields: ManageFields,
    private schoolsByStateService: SchoolsByStateService,
    private statesService: StatesService,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initServiceValues();
    this.initCountryListener();
    this.initStateListener();
    this.initSchoolListener();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribeAjax$.next();
    this.unsubscribeAjax$.complete();
  }

  initServiceValues(): void {
    this.countriesService.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: SelectOption[]) => {
        // get CA && US values only from json array
        const groupCountries = json.filter((item) => {
          return item.value === this.commonValues.api.ca || item.value === this.commonValues.api.us;
        });
        // since there is no international country we added manually
        groupCountries.push({ 'name': this.content.labelInternational, 'value': this.commonValues.api.international });

        // find out US country index;
        let indexUS;
        groupCountries.map((option: SelectOption, index: number) => {
          if (option.value === this.commonValues.api.us) {
            indexUS = index;
          }
        });
        // move "US" country value to the top;
        if (indexUS) {
          groupCountries.splice(0, 0, groupCountries.splice(indexUS, 1)[0]);
        }

        this.values.countries$.next(groupCountries);
      });

    // convert the states object into individual lists per country;
    this.statesService.data$.pipe(takeUntil(this.unsubscribe$)).subscribe((json: States) => {
      this.values.statesUS = json.us;
      this.values.statesCA = json.canada;

      // determine if selected value is CA or US so we can populate the state list;
      const isCA = this.formGroup.controls.country.value === this.commonValues.api.ca;
      const isUS = this.formGroup.controls.country.value === this.commonValues.api.us;

      // set default list value;
      if (isCA) {
        this.values.states$.next(this.values.statesCA);
      } else if (isUS) {
        this.values.states$.next(this.values.statesUS);
      }
    });

    // if a school is already, then we're editing the page;
    // and we need to grab the schools for this state or country (in case the user changes the autocomplete field);
    if (this.who.school) {
      this.getSchools(this.who.state || this.who.country);
    }
  }

  initCountryListener(): void {
    const controls = this.formGroup.controls;

    // watch the `country` field because when toggled we want to change content on other fields;
    controls.country.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value: string) => {
      const isUS = value === this.commonValues.api.us;
      const isCA = value === this.commonValues.api.ca;
      const isDomestic = isUS || isCA;

      if (value) {
        this.formGroup.clearValidators();
        // always clear out the fields dependent on this fields value;
        this.manageFields.disable([
          controls.amcasTranscriptIdNumber,
          controls.aamcAccountNumber,
          controls.casId,
          controls.department,
          controls.departmentNotInList,
          controls.lsacAccountNumber,
          controls.school,
          controls.schoolNotInList,
          controls.emailPrimary,
          controls.emailConfirm,
          controls.matchedUboxEntry,
          controls.sendElectronically,
          controls.oborDeliveryEligibility,
          controls.electronicDeliveryUnavailableFlag
        ]);

        this.formGroup.patchValue({
          recipientResponse: null,
          state: null
        });

        this.show.school = false;

        // configure states (labels and values) based on country;
        if (isDomestic) {
          if (isUS) {
            this.content.labelState = this.content.labelStateUS;
            this.values.states$.next(this.values.statesUS);
          } else {
            this.content.labelState = this.content.labelStateCA;
            this.values.states$.next(this.values.statesCA);
          }

          this.manageFields.enable([controls.state]);
          // or disable the state field if international;
          // and get the schools for the selected country;
        } else {
          this.manageFields.disable([controls.state]);

          this.getSchools(value);
        }
      }
    });
  }

  initStateListener(): void {
    const controls = this.formGroup.controls;

    // wach the `state` field, and when it changes get the next questions ready;
    controls.state.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value: string) => {
      // show the school if we have a state selected;
      if (value) {
        this.formGroup.clearValidators();
        // always clear out the fields we will hide/show;
        this.manageFields.disable([
          controls.amcasTranscriptIdNumber,
          controls.aamcAccountNumber,
          controls.casId,
          controls.department,
          controls.departmentNotInList,
          controls.lsacAccountNumber,
          controls.school,
          controls.schoolNotInList,
          controls.emailPrimary,
          controls.emailConfirm,
          controls.matchedUboxEntry,
          controls.sendElectronically,
          controls.oborDeliveryEligibility,
          controls.electronicDeliveryUnavailableFlag,
          controls.noDeliveryMethodAvailble
        ]);

        controls.recipientResponse.setValue(null);

        this.show.school = false;

        this.getSchools(value);
      }
    });
  }

  initSchoolListener(): void {
    const controls = this.formGroup.controls;

    // watch the `school` field, and when it changes get the next questions ready;
    controls.school.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value: string) => {
      if (value) {
        this.formGroup.clearValidators();
        // When school changes value, disable/reset select-department form controls.
        this.resetDepartmentControl(controls);
        // this.isReceipientSameAsSending(value);
        // only call when student is not enrolledBefore.
        if (!this.dataService.isEnrolledBefore()) {
          // determine if the departments field should be shown;
          this.recipientSelectDepartmentComponent.changeRecipient(
            controls.school,
            this.values.schools$.value
          );
        }

        // determine if the 'not in list' field should be shown;
        this.setSchoolNotInList();
      }
    });
  }

  isReceipientSameAsSending(receivingSchool: string){
    if(this.data.schoolProfile.schlName.toLowerCase() === receivingSchool.toLowerCase()){
      const text = 'You have selected the sending school as your recipient. Please confirm that you intend to send your transcript from '+ this.data.schoolProfile.schlName +' to ' + receivingSchool +'.';
      this.dialogRef = this.dialog.open(DialogHelpComponent, {
        width:  '480px',
        ariaLabelledBy: "modalRecipientTitle",
        ariaDescribedBy: "modalRecipientContent",
        autoFocus:false,
        data: {
          body: text,
          title: "Recipient Information",
          id: "modalRecipient"
        }
      });
        this.dialogRef.componentInstance.confirmMessage = 'CLOSE';
    }
  }

  resetDepartmentControl(controls: any): void{
    this.manageFields.disable([
      controls.department,
      controls.departmentNotInList,
      controls.emailConfirm,
      controls.emailPrimary,
      controls.matchedUboxEntry,
      controls.sendElectronically,
      controls.oborDeliveryEligibility,
      controls.electronicDeliveryUnavailableFlag
    ]);

    controls.recipientResponse.setValue(null);
  }


  getSchools(state: string): void {
    const controls = this.formGroup.controls;
    this.show.school = true;
    this.setLabel(true);

    // kill the previous takeUntil() observable;
    // so any previously made calls won't be processed;
    // just the latest one;
    this.unsubscribeAjax$.next();
    this.unsubscribeAjax$.complete();
    this.unsubscribeAjax$ = new Subject();

    // get the new school list for the selected state and reenable the autocomplete;
    // every change of state is a new list, no caching;
    this.schoolsByStateService
      .get(state)
      .pipe(takeUntil(this.unsubscribeAjax$))
      .subscribe((json: ApiResponseRecipient[]) => {
        const newValue = json.map((option: ApiResponseRecipient) => {
          return {
            schlName: option.schlName,
            value: option.schlName.toUpperCase(),
            ficeCode: option.ficeCode // used when determining department visibility;
          };
        });

        // assign the data so it binds to the autocomplete;
        // then enables the autocomplete form field;
        this.values.schools$.next(newValue);

        // set the validation for the autocomplete NOW that we know the valid options for this field;
        // validation needs to be reapplied with each Ajax call;
        controls.school.setValidators([
          Validators.required,
          ValidateSchoolAutocomplete(this.values.schools$.value)
        ]);

        // ensure that the loading state will display for at least X seconds;
        // then disable the loading indicators;
        window.setTimeout(() => {
          this.setLabel(false);
          if (!json) {
            this.show.school = false;
          }
          this.setSchoolNotInList();
          // the field is not disabled on edit;
          // and we dont want to trigger a change event, so check before enabling the field;
          if (controls.school.disabled) {
            this.manageFields.enable([controls.school]);
          }
        }, this.commonValues.loading.delay);
      });
  }

  // set the label for the autocomplete;
  // since the autocomplete values come from an Ajax call, let the user know that loading is happening;
  setLabel(loading: boolean): void {
    // control the number of periods after the label;
    // this will loop from 0 to 3 periods, to appear animated;
    let count = 0;
    const countMax = 3;

    this.loadingSchools = loading;
    this.loadingOutput.emit(this.loadingSchools);

    // build the new label based on the count value;
    const setLabel = () => {
      let newLabel = this.content.labelSchoolLoading;

      if (count !== 0) {
        for (let index = 1; index <= count; index++) {
          newLabel += '.';
        }
      }

      count = count === countMax ? 0 : count + 1;

      this.content.labelSchool = newLabel;
    };

    // if loading, define the interval that will set the 'animated' label;
    // otherwise, clear the interval and set the loaded label;
    if (loading) {
      this.content.labelSchool = this.content.labelSchoolLoading;
      if (this.loadingInterval) {
        window.clearInterval(this.loadingInterval);
      }
      this.loadingInterval = window.setInterval(setLabel, this.commonValues.loading.interval);
    } else {
      window.clearInterval(this.loadingInterval);
      if (this.show.advancedSearch){
        this.content.labelSchool = this.commonValues.autocomplete.custom.recipient.advLabelSchoolLoaded;
      }else{
        this.content.labelSchool = this.content.labelSchoolLoaded;
      }
    }
  }

  setSchoolNotInList(): void {
    const controls = this.formGroup.controls;
    const schoolValue = controls.school.value;
    const isNotInList =
      schoolValue &&
      schoolValue.toUpperCase() === this.commonValues.autocomplete.notInList.schlName.toUpperCase();
    const isValid = controls.school.valid;
    this.isOnlyelectronicAvailable = this.dataService.isOnlyElectronicAvailable();
    
    if(schoolValue && isNotInList && isValid) {
      
      if ((this.isOnlyelectronicAvailable)){
        
        controls.noDeliveryMethodAvailble.setValue(true);
            
        } else {
          
          this.manageFields.enable([controls.schoolNotInList]);
        }
    } else {
      controls.noDeliveryMethodAvailble.setValue(false);
      this.manageFields.disable([controls.schoolNotInList]);      
    }
  }



  searchLinkAction(e): void{
    if (!this.show.advancedSearch){
      this.openAdvancedSearch();
    }else{
      this.closeAdvancedSearch();
    }
    this.resetDepartmentControl(this.formGroup.controls);
    this.manageFields.reset([this.formGroup.controls.school]);
    // this.formGroup.controls.school.enable();
    // timeout makes sure that it is invoked after any other event has been triggered.
    // e.g. click events that need to run before the focus
    window.setTimeout(() => {
      try {
        this.renderer.selectRootElement('#recepientselect_school').focus();
      } catch (ignored){}
    });
    e.preventDefault();
  }

  openAdvancedSearch(): void{
    this.show.advancedSearch = true;
    this.content.searchLinkLabel = this.commonValues.autocomplete.custom.backSearchLinkLabel;
    this.values.searchType = this.commonValues.searchType.wildCardSearch;
    this.values.minCharactersToTrigger = 4;
    this.content.labelSchool = this.commonValues.autocomplete.custom.recipient.advLabelSchoolLoaded;
  }

  closeAdvancedSearch(): void{
    this.show.advancedSearch = false;
    this.content.searchLinkLabel = this.commonValues.autocomplete.custom.requestor.defaultSearchLinkLabel;
    this.values.searchType = null;
    this.values.minCharactersToTrigger = null;
    this.content.labelSchool = this.content.labelSchoolLoaded;
  }
}
