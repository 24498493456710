<div class="row">
  <div class="col-12 col-md-6 col-lg-4">
    <nsc-form-field-input
      controlName="{{ controlNamePrimary }}"
      idx="{{idx}}_primary"
      hint="{{ content.hintPrimary }}"
      maxlength="{{ maxlength }}"
      minlength="{{ minlength }}"
      placeholder="{{ placeholder }}"
      type="{{ type }}"
      [autocomplete]="autocomplete"
      [group]="group"
      [mask]="mask"
      (paste)="removeExtraSpaces($event)"
      (keydown)="handleSpace($event)"></nsc-form-field-input>
  </div>
  <div class="col-12 col-md-6 col-lg-4">
    <nsc-form-field-input
      controlName="{{ controlNameConfirm }}"
      idx="{{idx}}_confirm"
      hint="{{ content.hintConfirm }}"
      maxlength="{{ maxlength }}"
      minlength="{{ minlength }}"
      onPaste="return false"
      onCopy="return false"
      placeholder="Confirm {{ placeholder }}"
      type="{{ type }}"
      [autocomplete]="values.autocompleteConfirm"
      [group]="group"
      [mask]="mask"
      (keydown)="handleSpace($event)"></nsc-form-field-input>
  </div>
</div>
