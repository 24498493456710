import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TimeoutService } from '../../services/timeout/timeout.service';
import { CommonValues } from '../../classes/common-values';

@Component({
  selector: 'nsc-dialog-help',
  templateUrl: './dialog-help.component.html',
  styleUrls: ['./dialog-help.component.scss']
})
export class DialogHelpComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title?: string; body: string , id?:string},
    public dialogRef: MatDialogRef<DialogHelpComponent>,
    private timeoutService: TimeoutService,
    private commonValues: CommonValues
  ) {}

  
  public confirmMessage = 'OK';

  ngOnInit() {}

  // initiate a close action by clicking on the close button within the dialog;
  close(): void {
    this.timeoutService.startWarningCountdown();
    this.dialogRef.close(this.commonValues.popupDialog.okBtn);
  }
}
