import { Component, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep as _cloneDeep, find as _find } from 'lodash';
import { SelectOption } from '../../interfaces/select-option.interface';
import { ValidationMessage } from '../../classes/validation-message';
import { CommonValues } from '../../classes/common-values';

@Component({
    selector: 'nsc-form-field-select',
    templateUrl: './form-field-select.component.html',
    styleUrls: ['./form-field-select.component.scss']
})
export class FormFieldSelectComponent implements OnInit,OnChanges {
    @Input() controlName: string;
    @Input() idx: string;
    @Input() group: FormGroup;
    @Input() hint: string;
    @Input() placeholder: string;
    @Input() selectObjects: SelectOption[];
    @Input() loading = false;
    @Input() required = true;

    selectedValue: any = {
        value: null,
        name: null,
    };
    content = {
        hint: ''
    };
    constructor( private validationMessage: ValidationMessage,
        private commonValues: CommonValues) { }

    ngOnInit() {
        this.setHint(this.hint, this.required);
        if (this.group.controls[this.controlName].value) {
            const controls = this.group.controls[this.controlName].value;
            const selectObjectsList = _cloneDeep(this.selectObjects);
            const selectedValueTemp = _find(selectObjectsList, function(obj) {
                if (obj.value === controls) {
                    return obj;
                }
            });
            if (typeof selectedValueTemp != this.commonValues.api.undefined && selectedValueTemp != null ){
                this.selectedValue = selectedValueTemp;
            }

        }
    }

    changeValue(value: any) {
        this.selectedValue.name = value.source.selected.viewValue;
        this.selectedValue.id = value.source.selected.value;
    }

    ngOnChanges(changes: SimpleChanges) {
        const newHint = changes.hint ? changes.hint.currentValue : this.hint;
        const newRequired = changes.required ? changes.required.currentValue : this.required;
    
        this.setHint(newHint, newRequired);
      }

    openedChange(opened: boolean) {
        this.selectedValue.opened = opened ? true : false;
    }

    setHint(hint: string, required: boolean): void {
        // if a field isn't required and it's optional, we need to combine the `hint` values;
        this.content.hint = `${hint || ''} ${required ? '' : '(Optional)'}`;
      }
}
