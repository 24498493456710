import { Injectable } from "@angular/core";
@Injectable()
export class ConvertDeliveryMethod {
  deliveryMethod = {
    electronicDisplaySource: ['Electronic PDF', 'Electronic Exchange'], // value to convert from;
    electronicDisplayTarget: 'Electronic' // value to convert to;
  };
  deliveryFee = {
    electronicDisplaySource: ['Delivery Fee'], // value to convert from;
    electronicDisplayTarget: 'Secure Electronic PDF Fee' // value to convert to;
  };

  get(value: string): string {
    let returnValue = value;

    this.deliveryMethod.electronicDisplaySource.forEach(source => {
      returnValue = returnValue.replace(source, this.deliveryMethod.electronicDisplayTarget);
    });

    this.deliveryFee.electronicDisplaySource.forEach(source => {
      returnValue = returnValue.replace(source, this.deliveryFee.electronicDisplayTarget);
    });

    return returnValue;
  }
}
