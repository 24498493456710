<form [formGroup]="formGroup" novalidate>
  <nsc-card>
    <nsc-card-title note="All fields required, unless otherwise indicated">Contact Information</nsc-card-title>
    <nsc-card-content>
      <div class="row">
        <div class="col-12 col-lg-8">
          <nsc-form-field-input
            autocomplete="address-line1"
            idx="addressLine1"
            controlName="addressLine1"
            hint="Street number and name or PO Box"
            maxlength="30"
            placeholder="Address 1"
            [group]="formGroup"></nsc-form-field-input>
        </div>

        <div class="col-12 col-lg-8">
          <nsc-form-field-input
            autocomplete="address-line2"
            idx="addressLine2"
            controlName="addressLine2"
            hint="Building, campus box, floor, apt, suite"
            maxlength="30"
            placeholder="Address 2"
            [group]="formGroup"
            [required]="false"></nsc-form-field-input>
        </div>
        <div class="col-12">
        </div>

       
        <div class="col-12 col-md-6 col-lg-4">
          <nsc-form-field-input
            idx="city_field"
            controlName="city"
            maxlength="20"
            placeholder="City"
            [group]="formGroup"></nsc-form-field-input>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <nsc-form-field-select-optgroup
            idx="state_field"
            controlName="state"
            placeholder="{{ content.labelState }}"
            [group]="formGroup"
            [selectObjects]="values.states$.value"></nsc-form-field-select-optgroup>
        </div>
        <div class="col-12">
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <nsc-form-field-input
            idx="requestorZipCode"
            autocomplete="postal-code"
            controlName="zip"
            hint="{{ content.hintZip }}"
            maxlength="10"
            placeholder="Zip/Postal Code"
            [group]="formGroup"></nsc-form-field-input>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <nsc-form-field-select
            controlName="country"
            idx="country_field"
            placeholder="Country"
            [group]="formGroup"
            [selectObjects]="values.countries$.value"></nsc-form-field-select>
        </div>

        <div class="col-12">
        
            <nsc-form-field-confirm
            #emailPrimaryFocus
            autocomplete="email" 
            idx="email"        
            controlNameConfirm="emailConfirm"
            controlNamePrimary="emailPrimary"
            maxlength="60"
            placeholder="Email"
            [group]="formGroup"></nsc-form-field-confirm>

        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <nsc-form-field-input
            #phone
            autocomplete="tel"
            idx="phone_field"
            controlName="phone"
            hint="{{ content.hintPhone }}"
            maxlength="20"
            placeholder="Phone Number"
            [mask]="masks.phonemask"
            [group]="formGroup"></nsc-form-field-input>
        </div>
        <div class="col-12">
        </div>

        <div class="col-12" *ngIf="formGroup.controls['textUpdates'].enabled">
          <nsc-form-field-toggle idx="receiveupdates" classGroup="__toggleNotification"
            controlName="textUpdates" isHtmlContent="To receive NSC Msg updates to this phone number, you must Opt-in by selecting 'YES' below. You may receive up to 5 transcript text status updates for each recipient order. Message and data rates may apply. If text messaging is requested, you will receive an Opt-In confirmation message. For help text HELP. To cease messages, text STOP.  Texting STOP will stop all text messages to the Opted-In mobile phone number for all existing transcript orders. Do you agree to Opt-in? <a href='https://www.studentclearinghouse.org/terms-of-use/'  target='_blank'> Terms of Use</a>|<a href='https://www.studentclearinghouse.org/privacy-policy/' target='_blank'>Privacy Policy</a> "
            valueNo="N" valueYes="T"
            [group]="formGroup"></nsc-form-field-toggle>
        </div>

        <div class="col-12 col-lg-8" *ngIf="formGroup.controls['updateRecords'].enabled">
          <nsc-form-field-toggle
            idx="update_records"
            controlName="updateRecords"
            label="Allow the school to use this information to update their records?"
            [group]="formGroup"></nsc-form-field-toggle>
        </div>
      </div>
    </nsc-card-content>
  </nsc-card>

  <nsc-spacer size="md"></nsc-spacer>

  <div class="nsc-form-actions">
    <button
      mat-stroked-button
      id="requestoraddress_cancel"
      class="nsc-form-actions__button button-big"
      color="primary"
      type="button"
      (click)="cancel()">Cancel Order</button>
    <nsc-spacer horizontal="true" size="md"></nsc-spacer>
    <button
      mat-raised-button
      id="requestoraddress_continue"
      class="nsc-form-actions__button  nsc-btn__right button-big"
      color="primary"
      type="button"
      (click)="continue()"
      [disabled]="!formGroup.valid">Continue <mat-icon aria-hidden="true" *ngIf="formGroup.valid">chevron_right</mat-icon></button>
  </div>
</form>
