import * as moment from 'moment';
import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable ,  Subject, merge } from 'rxjs';
import { saveAs } from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { CommonValues } from '../../classes/common-values';
import { ApiResponseOrder } from '../../interfaces/api-response-order.interface';
import { DataService } from '../../services/data/data.service';
import { GtmDataLayer } from '../../classes/gtm-data-layer';
import { PostService } from '../../services/post/post.service';
import { SecurityService } from '../../services/security/security.service';
import { TimeoutService } from '../../services/timeout/timeout.service';
import { ConsentFormService } from '../../services/consent-form/consent-form.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogHelpComponent } from '../../components/dialog-help/dialog-help.component';
import { ValidateMatch } from '../../validators/match.validator';
import { ManageFields } from '../../classes/manage-fields';
import { ValidateLegalName } from '../../validators/signature-legal-name.validator';
// let TypeScript ignore the global jQuery object;
declare var $: any;

@Component({
  selector: 'nsc-order-consent',
  templateUrl: './order-consent.component.html',
  styleUrls: ['./order-consent.component.scss']
})
export class OrderConsentComponent implements AfterViewInit, OnDestroy, OnInit {
  data = this.dataService.get();
  formGroup: FormGroup;
  unsubscribe$ = new Subject();
  private infoText: string;
  private isWaterMark: boolean = true;

  content = {
    orderId: this.dataService.get().response.orderHeader.toOrderId,
    recipients: this.dataService.get().response.recipients,
    requestor: this.dataService.getFullName()
  };

  require = {
    legalSignatureName: false,
    legalSignatureNameConfirm: false
  };

  show = {
    electronicConsentForm: this.data.response.orderHeader.consentTypeAllowed === 'PPPL',
    printableDefaultConsentForm: this.data.response.orderHeader.consentTypeAllowed !== 'PP',
    signatureError: false,
    paperConsentError: false,
    printablePaperOnlyConsentForm: this.data.response.orderHeader.consentTypeAllowed === 'PP',
    electronicSignature: true
  };

  values = {
    disableSignElectronically: false,
    disableAcceptButton: true,
    disableClearButton: true,
    signatureDate: null,
    signatureSelector: '#signature',
    signatureSelectorValue: null,
    paperConsentDownloadFailed: true,
    paperConsentFormDownloaded:false,
    enableContinueButton: false,
    totalFees: this.dataService.get().response.orderHeader.totalFee,
    signatureValidators: this.show.electronicConsentForm ? [Validators.required] : [],
    legalSignatureNameValidators: []
  };

  constructor(
    private commonValues: CommonValues,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private gtmDataLayer: GtmDataLayer,
    private postService: PostService,
    private router: Router,
    private securityService: SecurityService,
    private timeoutService: TimeoutService,
    private consentFormService: ConsentFormService,
    private dialog: MatDialog,
    private manageFields: ManageFields
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group(
      {
        signature: new FormControl(null, this.values.signatureValidators),
        signElectronically: new FormControl(true),
        legalSignatureName: new FormControl(null, this.values.legalSignatureNameValidators),
        legalSignatureNameConfirm: new FormControl(null, this.values.legalSignatureNameValidators)
      },
      {
        validator: [
          ValidateMatch('legalSignatureName', 'legalSignatureNameConfirm'),
          ValidateLegalName('legalSignatureName'),
          ValidateLegalName('legalSignatureNameConfirm')
        ]
      }
    );

    // any change to the form means the user is actively using the app, so extend the system timeout;
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if (this.values.paperConsentFormDownloaded) {
        this.values.enableContinueButton = false;
        this.values.paperConsentFormDownloaded =false;
      }
      this.timeoutService.startWarningCountdown();
    });

    this.initLegalSignatureListener();

    this.infoText =
      '<div class="row nsc-row--12">' +
      '<div class="col-auto">' +
      '<i aria-hidden = "true" class="material-icons nsc-icon--large">desktop_mac </i>' +
      '</div>' +
      '<div class="col">' +
      '<p class="nsc-icon__align">' +
      '<strong>Desktop</strong>' +
      '</p>' +
      '<p>' +
      ' Hold down the left button on your mouse and, using the mouse, sign your name in the signature box as you would with a pen.' +
      '</p>' +
      '<p >' +
      '<strong >Mac with Trackpad only: </strong>' +
      ' Hold down the options button on the keyboard, continue to hold down the options button while pressing your finger onto the trackpad, sign your name in the signature box while signing your name on your trackpad.' +
      '</p>' +
      '</div>' +
      ' </div>' +
      '<nsc-spacer size="sm"></nsc-spacer>' +
      '<p></p>' +
      '<div class="row nsc-row--12">' +
      ' <div class="col-auto">' +
      '<i aria-hidden = "true" class="material-icons">smartphone</i>' +
      '</div>' +
      '<div class="col ">' +
      '<p class="nsc-icon__align">' +
      '<strong>Mobile</strong>' +
      ' </p>' +
      '<p>' +
      'Sign your name in the signature box using your finger as a stylus.' +
      ' </p>' +
      '</div>' +
      '</div>';
  }

  initLegalSignatureListener():void{
  const controls= this.formGroup.controls;
    merge(controls.legalSignatureName.valueChanges,
      controls.legalSignatureNameConfirm.valueChanges)
       .pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
        if (!this.values.enableContinueButton) {
          if (controls.legalSignatureName.value || controls.legalSignatureNameConfirm.value) {
            this.values.disableClearButton = false;
          } else {
            this.values.disableClearButton = true;
          }
        }
    });
  }

  viewSignatureOption(signType: string) {
    if (!this.values.enableContinueButton || this.values.paperConsentFormDownloaded) {
      const controls = this.formGroup.controls;
      if (this.show.electronicSignature) {
        this.clearSignature();
      }
      const isElectronicView = signType === 'legal' ? false : true;

      this.show.electronicSignature = isElectronicView;
      this.require.legalSignatureName = !isElectronicView;
      this.require.legalSignatureNameConfirm = !isElectronicView;
      // reset the values everytime we switch between otpions
      if (this.isInvalidField(controls)) {
        this.manageFields.reset([controls.legalSignatureName, controls.legalSignatureNameConfirm]);
      }
      if (this.show.electronicSignature) {
        this.values.disableAcceptButton = true;
        controls.legalSignatureName.setErrors(null);
        controls.legalSignatureNameConfirm.setErrors(null);
        controls.signature.setValidators([Validators.required]);
      } else {
        controls.signature.clearValidators();
        this.setLegalSignatureValues();
      }
      this.values.disableClearButton = true;
      this.values.enableContinueButton = false;
    }
  }

  isInvalidField(controls) {
    return (
      controls.legalSignatureName.invalid ||
      controls.legalSignatureNameConfirm.invalid ||
      controls.legalSignatureName.value ||
      controls.legalSignatureNameConfirm.value
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    // init the jQuery plugin after the view has initalized (DOM is available);
    $(this.values.signatureSelector).signature({
      background: '#f4f4f4',
      guideline: true,
      guidelineColor: '#d8d8d8',
      guidelineOffset: 20,
      guidelineIndent: 0,
      notAvailable:'Please update your browser to enable signing.',
      change: () => {
        // extend the timeout based on user activity;
        this.timeoutService.startWarningCountdown();

        const signatureLength = $(this.values.signatureSelector).signature('toJSON').length;
        if(this.show.electronicSignature) {
          let isValidSignature= true;
          if (signatureLength < 500) {
            isValidSignature=false;
             this.show.signatureError = true;
            this.values.disableAcceptButton = true;
          } else {
            this.show.signatureError = false;
            this.values.disableAcceptButton = false;
          }
          this.values.disableClearButton = false;
          this.setElectronicSignature(isValidSignature);
        }
      }
    });
  }

  captureDownload(): void {
    if (this.values.totalFees > 0) {
       this.openDialogue(this.values.totalFees);
    }
    else {
      this.captureDownloadDocument();
    }
  }

  base64toBlob(base64String) {
    // convert base64 to raw binary data held in a string
    var byteString = atob(base64String);
    var mimeString = "application/pdf";
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var bb = new Blob([ab], {
      type: mimeString
    });
    return bb;
  }

  // convert the base64 pdf string to pdf downloadable file
  captureDownloadDocument() {
    this.clearSignature();
    this.values.paperConsentFormDownloaded = true;
    this.values.paperConsentDownloadFailed = false;
    this.values.enableContinueButton = true;
    this.show.paperConsentError = false;
    this.consentFormService
      .get()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        pdfString => {
          var blob = this.base64toBlob(pdfString);
          saveAs(blob, this.content.orderId + 'consent.pdf');
        },
        // there was an error, so treat it like user downloaded consent form;
        // then act like there wasn't an error in the API call;
        error => {
          console.error(error);
          return;
        }
      );
  }

  clearSignature(): void {
    if (this.show.electronicSignature) {
      // clear out the current signature so the user can try again;
      $(this.values.signatureSelector).signature('clear');
      this.isSignature();
    } else {
      this.manageFields.reset([
        this.formGroup.controls.legalSignatureName,
        this.formGroup.controls.legalSignatureNameConfirm
      ]);
      this.setLegalSignatureValues();
    }
    this.values.disableClearButton = true;
    this.show.signatureError = false;
  }

  setLegalSignatureValues(): void {
    const controls = this.formGroup.controls;
    this.values.legalSignatureNameValidators = [Validators.maxLength(60), Validators.required];
    controls.legalSignatureName.setValidators(this.values.legalSignatureNameValidators);
    controls.legalSignatureNameConfirm.setValidators(this.values.legalSignatureNameValidators);
    this.values.disableAcceptButton = false;
    this.values.disableClearButton = false;
  }

  setElectronicSignature(isValid): void {
    let signatureJson = null;
    if (isValid) {
      signatureJson = JSON.parse($(this.values.signatureSelector).signature('toJSON'));
    }
    this.formGroup.patchValue({
      signature: signatureJson
    });
  }

  captureSignature(): void {
    // create a date/time to display;
    this.values.signatureDate = moment().format(this.commonValues.dateFormat.display);

    // stop the user from using the signature field anymore;
    $(this.values.signatureSelector).signature('disable');

    // disable (the now non-functional) clear button;
    this.values.disableAcceptButton = true;
    this.values.disableClearButton = true;
    this.values.enableContinueButton = true;
    this.disableFields();

    // hide the download form section;
    this.show.printableDefaultConsentForm = false;
  }

disableFields():void {
  const controls =  this.formGroup.controls;
    const isElectronicSignature = controls.signature.value;
    if(!isElectronicSignature){
      this.values.disableSignElectronically = true;
      controls.legalSignatureName.disable();
      controls.legalSignatureNameConfirm.disable();
      controls.signElectronically.disable();
    }
 }

  cancel(): void {
    this.securityService.cancelOrder();
  }

  continue(): void {
    if (this.show.printablePaperOnlyConsentForm && this.values.paperConsentDownloadFailed) {
      this.show.paperConsentError = true;
      this.values.paperConsentDownloadFailed = false;
    } else {
      this.dataService.resetOrderConsent();
      this.dataService.save(
        {
          
          signature: this.show.electronicSignature? this.formGroup.controls.signature.value:'legalName',
          date: this.values.signatureDate,
          legalSignatureName: this.formGroup.controls.legalSignatureName.value
        },
        'form.consent'
      );

      this.securityService.setDeactivateWarning(false);

      // go to the payment page if there is a fee for this order;
      // otherwise POST the consent data and send the user to the order confifmation screen, the order is done;
      if (this.values.totalFees) {
        this.router.navigate(
          [this.commonValues.routes.orderPayment],
          this.commonValues.routes.extras
        );
      } else {

        // if there is a signature to post, do it, otherwise move on;
        if (this.dataService.get().form.consent.signature) {
          this.postService
            .consent()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              () => {
                this.navigateOrderConfirmation();
              },
              error => {
                // ignore consent error and send to the next screen
                console.log(error);
                this.navigateOrderConfirmation();
              });
        } else {

          this.navigateOrderConfirmation();

        }
      }
    }
  }

  navigateOrderConfirmation(): void {
    window.setTimeout(() => {

      this.securityService.setDeactivateWarning(false);

      this.router.navigate(
        [this.commonValues.routes.orderConfirmation],
        this.commonValues.routes.extras
      );
    }, this.commonValues.loading.delay);
  }


  openDialogue(isFeeItem?: any) {
    const dialogContent = {
      message: this.infoText,
      title: "Signing Instructions",
      id: "modalHeadingSignin"
    }
    // Since we are going to use the same in two methods different places
    // added a condition for fee option
    if (isFeeItem) {
      this.values.enableContinueButton = false;
      dialogContent.message = '<p> After downloading the paper consent form, please continue to the Payment page to complete your request or your order will NOT be processed.</p>';
      dialogContent.title =  "Continue to Payment",
      dialogContent.id = "modalHeadingPayment"
    }
    const dialogRef = this.dialog.open(DialogHelpComponent, {
      ariaLabelledBy: dialogContent.id+"Title",
      ariaDescribedBy: dialogContent.id+"Content",
      width: this.infoText.length > 300 ? '480px' : '310px',
      autoFocus:false,
      data: {
        body: dialogContent.message,
        title: dialogContent.title,
        id: dialogContent.id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (isFeeItem) {
          this.captureDownloadDocument();
          this.values.enableContinueButton = true;
        }
      }
    });
  }

  hideWaterMark() {
    this.isWaterMark = false;
  }
  isSignature() {
    if(!this.show.printablePaperOnlyConsentForm){
      const signatureJson = JSON.parse($(this.values.signatureSelector).signature('toJSON')).lines.length;
      if (signatureJson <= 0 || signatureJson == null) {
        this.isWaterMark = true;
      }
    }
  }
}