import * as moment from 'moment';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';
import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';
import { ManageFields } from '../../classes/manage-fields';
import { PostService } from '../../services/post/post.service';
import { SecurityService } from '../../services/security/security.service';
import { TimeoutService } from '../../services/timeout/timeout.service';
import { ValidateDateCompare } from '../../validators/date-compare.validator';

@Component({
  selector: 'nsc-requestor-attend',
  templateUrl: './requestor-attend.component.html',
  styleUrls: ['./requestor-attend.component.scss']
})
export class RequestorAttendComponent implements OnDestroy, OnInit {
  data = this.dataService.get();
  formGroup: FormGroup;
  unsubscribe$ = new Subject();

  content = {
    programLabels: this.data.schoolProfile.attendSchoolLabels
  };

  masks = {
    year: "0000"
  };

  require = {
    programsYear: this.data.schoolProfile.askEnrollYears === this.commonValues.api.required
  };

  show = {
    degreeQuestions: this.dataService.showDegreeQuestions(),
    programQuestions: this.dataService.showProgramQuestions(),
    programYearQuestions: this.data.schoolProfile.askEnrollYears !== this.commonValues.api.no
  };

  values = {
    programsYearValidators: [
      Validators.minLength(4),
      Validators.min(1940),
      Validators.max(moment().year() + 1)
    ],
    programsYearValidatorsRequired: [
      Validators.minLength(4),
      Validators.min(1940),
      Validators.max(moment().year() + 1),
      Validators.required
    ]
  };

  constructor(
    private blockingIndicatorService: BlockingIndicatorService,
    private commonValues: CommonValues,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private manageFields: ManageFields,
    private postService: PostService,
    private router: Router,
    private securityService: SecurityService,
    private timeoutService: TimeoutService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm(): void {
    const attend = this.data.form.requestor.attend;
    const programsGroup = this.initPrograms();
    const programControls = programsGroup.map(group => group.controls.program);
    const yearValidators = [
      Validators.minLength(4),
      Validators.min(1940),
      Validators.max(moment().year())
    ];

    this.formGroup = this.formBuilder.group({
      programs: this.formBuilder.array(programsGroup),
      degree1: new FormControl(attend.degree1),
      degree2: new FormControl(attend.degree2),
      degree3: new FormControl(attend.degree3),
      degree4: new FormControl(attend.degree4),
      year1: new FormControl(attend.year1, yearValidators),
      year2: new FormControl(attend.year2, yearValidators),
      year3: new FormControl(attend.year3, yearValidators),
      year4: new FormControl(attend.year4, yearValidators)
    });

    const controls = this.formGroup.controls;

    if (this.show.degreeQuestions === false) {
      this.manageFields.disable([
        controls.degree1,
        controls.degree2,
        controls.degree3,
        controls.degree4,
        controls.year1,
        controls.year2,
        controls.year3,
        controls.year4
      ]);
    }

    // disable any created program controls;
    // because they may be defined even if they aren't suppose to be shown to the user;
    if (this.show.programQuestions === false) {
      controls.programs.disable();
    }

    // any change to the form means the user is actively using the app, so extend the system timeout;
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.timeoutService.startWarningCountdown();
    });
  }

  initPrograms(): FormGroup[] {
    const array = [];

    // create a set of form controls for each label defined in the school profile;
    this.content.programLabels.map(program => {
      const yearFromValidators = this.require.programsYear
        ? this.values.programsYearValidatorsRequired
        : this.values.programsYearValidators;

      array.push(
        this.formBuilder.group(
          {
            program: new FormControl(false),
            yearFrom: new FormControl(this.commonValues.nullDisabled, yearFromValidators),
            yearTo: new FormControl(this.commonValues.nullDisabled, [
              Validators.minLength(4),
              Validators.min(1940)
            ])
          },
          {
            validator: [ValidateDateCompare('yearFrom', 'yearTo')]
          }
        )
      );
    });

    return array;
  }

  changeProgram(
    event: MatCheckboxChange,
    yearFrom: AbstractControl,
    yearTo: AbstractControl
  ): void {
    if (this.show.programYearQuestions) {
      if (event.checked) {
        this.manageFields.enable([yearFrom, yearTo]);
      } else {
        this.manageFields.disable([yearFrom, yearTo]);
      }
    }
  }

  cancel(): void {
    this.securityService.cancelOrder();
  }

  continue(): void {
    this.dataService.resetRequestorAttend();
    this.dataService.save(this.formGroup.value, 'form.requestor.attend');

    this.blockingIndicatorService.open();

    this.postService
      .student()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        window.setTimeout(() => {
          this.securityService.setDeactivateWarning(false);

          this.router.navigate(
            [this.commonValues.routes.recipientSelect],
            this.commonValues.routes.extras
          );
        }, this.commonValues.loading.delay);
      });
  }
}
