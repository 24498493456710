<div *ngIf="content.pendingConsent">
  <nsc-card [error]="true">
    <nsc-card-title-error icon="warning" title="Order Pending">
      <div class="mat-title">
        Order Is Pending A Signed Consent
      </div>
      <div >
        Please go to www.xxxxxx.org and complete the consent process so that your order can be forwarded to your school for fulfillment.
      </div>
    </nsc-card-title-error>
    <!-- <nsc-card-content>
    
    </nsc-card-content> -->
  </nsc-card>

  <nsc-spacer size="md"></nsc-spacer>
</div>

<nsc-card-consent ></nsc-card-consent>

<nsc-card>
  <nsc-card-title>Requestor Information</nsc-card-title>
  <nsc-card-content>
    <div class="row nsc-order-confirmation__requestor">
      <div class="col-12 col-lg-6">
        <div >
          <div class="row">
            <div class="col-4">
              <em>Order Number:</em>
            </div>
            <div class="col-6">
              {{ content.orderId }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <em>School:</em>
            </div>
            <div class="col-6">
              {{ content.schoolProfile.schlName }}
            </div>
          </div>
          <div class="row" *ngIf="content.requestor.toScCampusName">
            <div class="col-4">
              <em>Campus:</em>
            </div>
            <div class="col-8">
              {{ content.requestor.toScCampusName }}
            </div>
          </div>
         <div class="row" *ngIf="content.state">
            <div class="col-4">
              <em>State:</em>
            </div>
            <div class="col-6">
              {{ content.state }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <em>Name:</em>
            </div>
            <div class="col-6">
              {{ content.requestor.firstName }} {{ content.requestor.middleName }} {{ content.requestor.lastName }}
            </div>
          </div>
          <div class="row" *ngIf="content.requestor.schlFirstName">
            <div class="col-4">
              <em>Previous Name:</em>
            </div>
            <div class="col-6">
              {{ content.requestor.schlFirstName }} {{ content.requestor.schlMiddleName }} {{ content.requestor.schlLastName }}
            </div>
          </div>
          <div class="row" *ngIf="content.allowStudentName">
            <div class="col-4">
              <em>{{content.studentNameLabel}}:</em>
            </div>
            <div class="col-6">
              {{ content.requestor.livedFirstName }} {{ content.requestor.livedMiddleName }} {{ content.requestor.livedLastName }}
            </div>
          </div>
          <div class="row" *ngIf="content.orderDate">
            <div class="col-4">
              <em>Order Date:</em>
            </div>
            <div class="col-6">
              {{ content.orderDate }} ET
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div  *ngIf="content.payment && content.payment.payeezyToken">
          <div class="row">
            <div class="col-4">
              <em>Credit Card:</em>
            </div>
            <div class="col-6">
              {{ content.payment.payeezyToken.slice(-4) }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <em>Billing Address:</em>
            </div>
            <div class="col-7">
              <div *ngIf="content.payment && !content.payment.emailPrimary">
                {{ content.payment.billStreet1 }}<br>
                {{ content.payment.billStreet2 }}<br *ngIf="content.payment.billStreet2">
                {{ content.payment.billCity }}, {{ content.payment.billState }} {{ content.payment.billZip }}
                <div *ngIf="content.payment && content.payment.billCountry !== 'UNITED STATES'">{{ content.payment.billCountry }}</div>
              </div>
              <div *ngIf="content.payment && content.payment.emailPrimary">
                {{ content.payment.emailPrimary }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nsc-card-content>
</nsc-card>

<nsc-spacer size="lg"></nsc-spacer>

<nsc-card>
  <nsc-card-title>Recipient Order Details</nsc-card-title>
  <nsc-card-content>
    <div *ngFor="let recipient of content.recipients; let i = index">
      <nsc-recipient-details
        [recipient]="recipient">
      </nsc-recipient-details>

      <hr class="nsc-hr" *ngIf="i < content.recipients.length - 1">
    </div>

    <nsc-spacer size="md"></nsc-spacer>

    <div class="row justify-content-end">
      <div class="col-auto">
        <div class=" mat-elevation-z0 nsc-order-total" *ngIf="content.recipients.length > 0">
          Total Fee for Order: <strong>{{ content.totalFees | currency }}</strong>
        </div>
      </div>
    </div>

    <nsc-spacer size="lg"></nsc-spacer>

    <div  style="text-align: center; " >
      <span *ngIf="content.totalFees">Your credit card will not be charged until transcript(s) are sent. The charge for this transaction will appear on your credit card statement as 'Transcript Order from College or University'.</span> An order confirmation will be sent to <strong>{{ content.requestor.email }}</strong>. Thank you for your order!
    </div>
  </nsc-card-content>
</nsc-card>

<nsc-spacer size="md"></nsc-spacer>

<div class="nsc-form-actions" *ngIf="content.referMethodSaml">
  <button
    mat-raised-button
    id="logout"
    class="nsc-form-actions__button "
    color="primary"
    type="button"
    (click)="logout()">logout</button>
</div>
<nsc-spacer size="md"></nsc-spacer>
<div  style="text-align: center;">
  <p class="subtitle-1">
    For security purposes, close your browser.
  </p>
</div>
