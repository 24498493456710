import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { filter, first } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule  } from '@angular/common/http';
import { Location } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {ProgressBarMode, MatProgressBarModule} from '@angular/material/progress-bar';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import {  IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

import { MatAutocompleteModule } from '@angular/material/autocomplete';

// classes;
import { CheckApiResponse } from './classes/check-api-response';
import { CommonValues } from './classes/common-values';
import { ConvertDeliveryMethod } from './classes/convert-delivery-method';
import { GtmDataLayer } from './classes/gtm-data-layer';
import { ManageFields } from './classes/manage-fields';
import { Payeezy } from './classes/payeezy';
import { ValidationMessage } from './classes/validation-message';
import { WebAimContrastChecker } from './classes/web-aim-contrast-checker';
import { ZipcodeValidation } from './classes/zipcode-validation';

// directives:
import { FileUploadModule } from 'ng2-file-upload';

// components;
import { AppRootComponent } from './components/app-root/app-root.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { BlockingIndicatorComponent } from './components/blocking-indicator/blocking-indicator.component';
import { CardComponent } from './components/card/card.component';
import { CardTitleComponent } from './components/card-title/card-title.component';
import { CardContentComponent } from './components/card-content/card-content.component';
import { DialogHelpComponent } from './components/dialog-help/dialog-help.component';
import { DialogTimeoutComponent } from './components/dialog-timeout/dialog-timeout.component';
import { FormFieldFilesComponent } from './components/form-field-files/form-field-files.component';
import { FormFieldSelectComponent } from './components/form-field-select/form-field-select.component';
import { FormFieldToggleComponent } from './components/form-field-toggle/form-field-toggle.component';
import { FormFieldInputComponent } from './components/form-field-input/form-field-input.component';
import { FormFieldConfirmComponent } from './components/form-field-confirm/form-field-confirm.component';
import { FormFieldSelectOptgroupComponent } from './components/form-field-select-optgroup/form-field-select-optgroup.component';
import { FormFieldAutocompleteComponent } from './components/form-field-autocomplete/form-field-autocomplete.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { RecipientDetailsComponent } from './components/recipient-details/recipient-details.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { SpacerComponent } from './components/spacer/spacer.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { SystemMessageComponent } from './components/system-message/system-message.component';
import { CardConsentComponent } from './components/card-consent/card-consent.component';
import { CardTitleErrorComponent } from './components/card-title-error/card-title-error.component';
// interceptors;
import { JwtInterceptor } from './interceptors/jwt.interceptor';

// modules;
import { AppRoutingModule } from './app-routing.module';

// routes & route specific components;
import { MessageComponent } from './routes/message/message.component';
import { OrderConfirmationComponent } from './routes/order-confirmation/order-confirmation.component';
import { OrderConsentComponent } from './routes/order-consent/order-consent.component';
import { OrderPaymentComponent } from './routes/order-payment/order-payment.component';
import { OrderReviewComponent } from './routes/order-review/order-review.component';
import { RecipientAddressComponent } from './routes/recipient-address/recipient-address.component';
import { RecipientDeliveryMethodComponent } from './routes/recipient-delivery-method/recipient-delivery-method.component';
import { RecipientDeliveryMethodFeesComponent } from './routes/recipient-delivery-method/recipient-delivery-method-fees/recipient-delivery-method-fees.component';
import { RecipientEmailComponent } from './routes/recipient-email/recipient-email.component';
import { RecipientSelectCollegeComponent } from './routes/recipient-select/recipient-select-college/recipient-select-college.component';
import { RecipientSelectComponent } from './routes/recipient-select/recipient-select.component';
import { RecipientSelectDepartmentComponent } from './routes/recipient-select/recipient-select-department/recipient-select-department.component';
import { RecipientSelectOrganizationComponent } from './routes/recipient-select/recipient-select-organization/recipient-select-organization.component';
import { RequestorAddressComponent } from './routes/requestor-address/requestor-address.component';
import { RequestorAttendComponent } from './routes/requestor-attend/requestor-attend.component';
import { RequestorIdentificationComponent } from './routes/requestor-identification/requestor-identification.component';
import { SchoolFiceCodeComponent } from './routes/school-ficecode/school-ficecode.component';
import { SchoolSelectComponent } from './routes/school-select/school-select.component';
import { SchoolWelcomeComponent } from './routes/school-welcome/school-welcome.component';
import { AuthenticationFailureComponent } from './routes/authentication-failure/auth-failure.component';

// services;
import { CountriesService } from './services/countries/countries.service';
import { DataService } from './services/data/data.service';
import { OrganizationsService } from './services/organizations/organizations.service';
import { PostService } from './services/post/post.service';
import { BlockingIndicatorService } from './services/blocking-indicator/blocking-indicator.service';
import { FeesService } from './services/fees/fees.service';
import { RecipientService } from './services/recipient/recipient.service';
import { RecipientTypesService } from './services/recipient-types/recipient-types.service';
import { SchoolsByStateService } from './services/schools-by-state/schools-by-state.service';
import { SchoolService } from './services/school/school.service';
import { PaymentConfigService } from './services/payment/payment-config.service';
import { SchoolsService } from './services/schools/schools.service';
import { SecurityService } from './services/security/security.service';
import { StatesService } from './services/states/states.service';
import { StudentService } from './services/student/student.service';
import { SystemMessagesService } from './services/system-messages/system-messages.service';
import { TimeoutService } from './services/timeout/timeout.service';
import { StudentAuthorizationService } from './services/studentauthservice/studentauth.service';
import { ConsentFormService } from './services/consent-form/consent-form.service';
import { PropertyConfigService } from './services/property/property-config.service';
import { SchoolProfileIDComponent } from './routes/school-profileid/school-profileid.component';
import { SafeHtmlPipe } from './custom-pipes/safe-html.pipe';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AutoCompleteUsService } from './services/address/auto-complete-us.service';
import { AddressAutoCompleteComponent } from './components/address-auto-complete/address-auto-complete.component';
import { AddressValidationDialogComponent } from './components/address-validation-dialog/address-validation-dialog.component';
import { OutagePageComponent } from './routes/outage-page/outage-page.component';
import { FormFieldSubNetworkTypeComponent } from './components/form-field-sub-network-type/form-field-sub-network-type.component';
import { CustomSVGIconModule } from './customSVGIcon.module';

@NgModule({
    declarations: [
        AppFooterComponent,
        AppHeaderComponent,
        AppRootComponent,
        BlockingIndicatorComponent,
        CardComponent,
        CardContentComponent,
        CardTitleComponent,
        DialogHelpComponent,
        DialogTimeoutComponent,
        FormFieldAutocompleteComponent,
        FormFieldConfirmComponent,
        FormFieldFilesComponent,
        FormFieldInputComponent,
        FormFieldSelectComponent,
        FormFieldSelectOptgroupComponent,
        FormFieldToggleComponent,
        FormFieldSubNetworkTypeComponent,
        MessageComponent,
        OrderConfirmationComponent,
        OrderConsentComponent,
        OrderPaymentComponent,
        OrderReviewComponent,
        OutagePageComponent,
        PageTitleComponent,
        RecipientAddressComponent,
        RecipientDeliveryMethodComponent,
        RecipientDeliveryMethodFeesComponent,
        RecipientDetailsComponent,
        RecipientEmailComponent,
        RecipientSelectCollegeComponent,
        RecipientSelectComponent,
        RecipientSelectDepartmentComponent,
        RecipientSelectOrganizationComponent,
        RequestorAddressComponent,
        RequestorAttendComponent,
        RequestorIdentificationComponent,
        SchoolFiceCodeComponent,
        SchoolSelectComponent,
        SchoolWelcomeComponent,
        ScrollToTopComponent,
        SpacerComponent,
        StepperComponent,
        SystemMessageComponent,
        AuthenticationFailureComponent,
        CardConsentComponent,
        CardTitleErrorComponent,
        SchoolProfileIDComponent,
        SafeHtmlPipe,
        ConfirmationDialogComponent,
        AddressAutoCompleteComponent,
        AddressValidationDialogComponent,
        FormFieldSubNetworkTypeComponent
    ],
    imports: [
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FileUploadModule,
        FormsModule,
        CustomSVGIconModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTableModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatToolbarModule,
        NgxMaskDirective, NgxMaskPipe
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        provideNgxMask(),
        StudentAuthorizationService,
        CheckApiResponse,
        CommonValues,
        ConvertDeliveryMethod,
        CountriesService,
        DataService,
        FeesService,
        GtmDataLayer,
        ManageFields,
        OrganizationsService,
        Payeezy,
        PostService,
        RecipientService,
        RecipientTypesService,
        SchoolsByStateService,
        SchoolService,
        PaymentConfigService,
        SchoolsService,
        SecurityService,
        StatesService,
        StudentService,
        SystemMessagesService,
        TimeoutService,
        ValidationMessage,
        WebAimContrastChecker,
        ConsentFormService,
        PropertyConfigService,
        ZipcodeValidation,
        AutoCompleteUsService
    ],
    bootstrap: [AppRootComponent]
})
export class AppModule {
 
  constructor(
    private blockingIndicatorService: BlockingIndicatorService,
    private dataService: DataService,
    private gtmDataLayer: GtmDataLayer,
    private location: Location,
    private router: Router,
    private securityService: SecurityService,
  ) {
    this.initRouteListener();
    this.initLocationListener();
  }

  initRouteListener(): void {
    // set up a listener for route changes so we can consolidate code that needs to execute on every route change;
    this.router.events
      .pipe(filter(event => event instanceof NavigationCancel || event instanceof NavigationEnd))
      .subscribe(val => {
        // hide the progress bar now that the new route has been loaded;
        // this way each route component doesn't have to set the prop to `false` after route change;
        this.blockingIndicatorService.close();

        // enable the `showDeactivateWarning` prop;
        // because before each route change (except the back button) the prop is set to `false` so the user isn't warned;
        // this way each route component doesn't have to set the prop to `true` on init;
        this.securityService.setDeactivateWarning(true);
      });
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(val => {
      const path = this.location.path().replace('/', '');
      // tell GTM any relevent event data related to this page;
      this.gtmDataLayer.recordEvents(path, this.dataService.get());
    });
  }

  initLocationListener(): void {
    // cf. https://github.com/angular/angular/pull/13922
    // fix for broken history management in canDeactivate;
    // if a user cancels a route change, there is a bug that still changes the browser history;
    // this code listens for a canceled route change, and moves the history forward if that happens (back to where the user is currently);
    // without this fix, the user can hit back, cancel, hit back, accept, and go back two pages instead of one;
    this.location.subscribe(locationEvent => {
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationCancel || event instanceof NavigationEnd),
          first(),
          filter(event => event instanceof NavigationCancel),
          filter((event: NavigationCancel) => event.url === locationEvent.url)
        )
        .subscribe(cancelEvent => {
          this.location.replaceState(cancelEvent.url);
          this.location.forward();
        });
    });
  }
}
