<div *ngIf="show.consentCard"  role="alert">
    <nsc-card [error]="true">
        <nsc-card-title-error title ="Your Consent Is Required" icon="warning">
            <div *ngIf="show.totalFees !== 0"class="row nsc-row--12 align-items-center">
                <div class="col">
                    If we do not receive your signed and dated consent form within 30 calendar days from the date you submitted your request, your order will be canceled and you will not be charged. Please go to <a aria-label="View Transcript Order Status" [routerLink]="[]" (click)="openConfirmationDialog()">Transcript Order Status</a> and complete the consent process so that your order can be forwarded to your school for processing. Have your order number available.
                </div>
            </div>
            <div *ngIf="show.totalFees === 0"class="row nsc-row--12 align-items-center">
                <div class="col">
                    If we do not receive your signed and dated consent form within 30 calendar days from the date you submitted your request, your order will be canceled. Please go to <a aria-label="View Transcript Order Status" [routerLink]="[]" (click)="openConfirmationDialog()">Transcript Order Status</a> and complete the consent process so that your order can be forwarded to your school for processing. Have your order number available.
                </div>
            </div>
        </nsc-card-title-error>
  
    </nsc-card>
    <nsc-spacer size="md"></nsc-spacer>
</div>
