import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { filter as _filter, get as _get, forEach as _forEach } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


import { CommonValues } from '../../classes/common-values';
import { ConvertDeliveryMethod } from '../../classes/convert-delivery-method';
import { DataRecipient } from '../../interfaces/data.interface';
import { DataService } from '../../services/data/data.service';
import { DialogHelpComponent } from '../dialog-help/dialog-help.component';
import { CountriesService } from '../../services/countries/countries.service';
import { SelectOption } from '../../interfaces/select-option.interface';


@Component({
  selector: 'nsc-recipient-details',
  templateUrl: './recipient-details.component.html',
  styleUrls: ['./recipient-details.component.scss']
})
export class RecipientDetailsComponent implements OnInit {
  @Input() recipient: DataRecipient;

  @Output() deleteRecipient = new EventEmitter();
  @Output() editRecipient = new EventEmitter();

  unsubscribe$ = new Subject();

  content = {
    deliveryMethod: null,
    deliveryMethodDisplay: null,
    fullName: this.dataService.getFullName(),
    etxDepartmentName: null,
    processingOption: null,
    quantity: null,
    transcriptType: null,
    attachments: [],
    recipient: null
  };
  isTNSchool= false;
  isSPDESchool = false;
  isFASTSchool = false;

  values = {
    countries$: new BehaviorSubject(<SelectOption[]>[])
  };

  constructor(
    private convertDeliveryMethod: ConvertDeliveryMethod,
    private commonValues: CommonValues,
    private dataService: DataService,
    public dialog: MatDialog,
    private countriesService: CountriesService,
  ) {}

  ngOnInit() {
    this.content.recipient = this.recipient.address.recipient || this.recipient.who.business ||
    this.recipient.who.organization || this.recipient.who.school;
    if ((!this.dataService.isNullorBlank(this.recipient.delivery.zeroFeeProgramEligible) && this.recipient.delivery.zeroFeeProgramEligible === 'Y')){
      this.isSPDESchool = this.dataService.getSPDEZeroFeeEligibilityByNetworkCode(this.recipient.who.recipientResponse.exchangeNetworkType);
      this.isFASTSchool = this.dataService.getFASTZeroFeeEligibilityByNetworkCode(this.recipient.who.recipientResponse.exchangeNetworkType);

            // if zero fee program is eligible then get deliverymethod, transcript and quantity from zero pay or free options
      if (!this.isSPDESchool || !this.isFASTSchool){
        this.isTNSchool = true;
      }
      this.content.deliveryMethod = this.dataService.getZeroFeeDeliveryMethodObject(
        this.recipient.delivery.deliveryMethod
      );

      this.content.transcriptType = this.dataService.getZeroFeeTranscriptTypeObject(
        this.recipient.delivery.transcriptType
      );
      this.content.quantity = this.dataService.getZeroFeeQuantityObject(this.recipient.delivery.howMany);

    } else{
      // get from Active pay or free options
      this.content.deliveryMethod = this.dataService.getDeliveryMethodObject(
        this.recipient.delivery.deliveryMethod
      );

      this.content.transcriptType = this.dataService.getTranscriptTypeObject(
        this.recipient.delivery.transcriptType
      );

      this.content.quantity = this.dataService.getQuantityObject(this.recipient.delivery.howMany);
    }

    this.content.processingOption = this.dataService.getProcessingOptionObject(
      this.recipient.delivery.transcriptWhen
    );



    this.content.deliveryMethodDisplay = this.convertDeliveryMethod.get(
      this.content.deliveryMethod.display
    );

    if (this.recipient.attachments) {
      this.content.attachments = this.recipient.attachments;
    }

    if (this.isETX() && !this.isCentralInbox()){
      // if it's ETX and not CentralInbox we need to retrieve the department name to display.
      this.content.etxDepartmentName = this.getETXDepartmentName(this.recipient.who.department);
    }

    this.countriesService.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: SelectOption[]) => this.values.countries$.next(json));

  }

  getETXDepartmentName(id): string {
    // retrieving department name from recipientResponse.
    const departments = _get(this.recipient.who, 'recipientResponse.recipientDepartments', []);
    const recipientResponseDepartment = _filter(departments, {
      deptId: id
    });
    return recipientResponseDepartment.length > 0 ? _get(recipientResponseDepartment[0], 'deptName', null) : null;
  }

  isOther(): boolean {
    return !this.isHold() && !this.isETX() && !this.isElectronic();
  }

  isHold(): boolean {
    return this.content.deliveryMethod.deliveryMethodType === this.commonValues.api.hold;
  }

  isETX(): boolean {
    return this.content.deliveryMethod.deliveryMethodType === this.commonValues.api.etx;
  }

  isAttentionNotVisible(): boolean {
    if (this.recipient.who.recipientResponse && (this.recipient.who.recipientResponse.exchangeNetworkType === this.commonValues.api.obor || this.recipient.who.recipientResponse.exchangeNetworkType === this.commonValues.api.speede || this.recipient.who.recipientResponse.exchangeNetworkType === this.commonValues.api.fast)) {
      return true;
    }
    else{
    const isETX = this.content.deliveryMethod.deliveryMethodType === this.commonValues.api.etx;
    if (this.recipient.who.recipientFiceCode) {
      const isAmcas = this.recipient.who.recipientFiceCode.startsWith('AMCAS');
      const isLsac = this.recipient.who.recipientFiceCode.startsWith('LSAC');
      const isLiaison = (this.recipient.who.recipientFiceCode.startsWith('LSON') || this.recipient.who.recipientFiceCode.startsWith(this.commonValues.api.liason));

      if (isETX && (isAmcas || isLsac || isLiaison)) {
          return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

  isElectronicPDF(): boolean {
    return this.content.deliveryMethod.deliveryMethodType === this.commonValues.api.electronic;
  }

  isCentralInbox(): boolean {
    return this.content.deliveryMethod.deliveryMethodType === this.commonValues.api.etx &&
              this.recipient.who.sendElectronically === this.commonValues.api.yes;
  }

  isElectronic(): boolean {
    return (
      this.commonValues.electronicDeliveryTypes.indexOf(
        this.content.deliveryMethod.deliveryMethodType
      ) > -1
    );
  }

  viewDetails(): void {
    const helpText = this.content.deliveryMethod.helpText;
    const dialogRef = this.dialog.open(DialogHelpComponent, {
      width: helpText.length > 300 ? '480px' : '370px',
      ariaLabelledBy: "modalDeliveryInstrTitle",
      ariaDescribedBy: "modalDeliveryInstrContent",
      autoFocus:false,
      data: {
        body: helpText,
        title: 'Delivery Instructions',
        id: "modalDeliveryInstr",
      }
    });
  }

  viewTotalFeeDetails(): void {
    let dialogContent= this.commonValues.zeroFeeTNDialogContent;
    //Helptext might need to be modified for fastzerofee
    if (this.isSPDESchool) {
      dialogContent = this.commonValues.getPartnerNetworkInfoDialogContent(this.commonValues.SPDEPartner);  
    }else if (this.isFASTSchool) {
      dialogContent = this.commonValues.getPartnerNetworkInfoDialogContent(this.commonValues.FASTPartner);  
    }  
    const dialogRef = this.dialog.open(DialogHelpComponent, {
      width: '480px',
      ariaLabelledBy: dialogContent.id+"Title",
      ariaDescribedBy: dialogContent.id+"Content",
      autoFocus:false,
      data: {
        body: dialogContent.message,
        title: dialogContent.title,
        id: dialogContent.id
      }
    });
  }

  isAttachmentAvailable(): boolean {
    if (this.content.attachments.length > 0) {
      const attachment = this.content.attachments[0];

      if (attachment.originalFileName) {
        return true;
      } else if (attachment.attachmentResponse) {
         return true;
      }
    }

    return false;
  }

  getFullCountryName(code: string): string {
    let countryName: string = code;
    _forEach(this.values.countries$.value, country => {
      if (country.value === code) {
        countryName = country.name;
        return;
      }
    });
    return countryName;
  }

  edit(): void {
    this.editRecipient.emit();
  }

  delete(): void {
    this.deleteRecipient.emit();
  }
}
