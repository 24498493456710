<div class="row">
  <div class="col-12 col-md-4" *ngIf="formGroup.controls['lsacAccountNumber'].enabled">
    <nsc-form-field-input
      idx="lsac_id"
      controlName="lsacAccountNumber"
      hint="Enter LSAC ID 'Lxxxxxxxx'"
      maxlength="9"
      placeholder="Enter {{ content.labelLsacAccountNumber }}"
      [group]="formGroup"
      prefixT="L"
      [mask]="masks.lsacAccountNumber"></nsc-form-field-input>
  </div>

  <div class="col-12"></div>

  <div class="col-12 col-md-4" *ngIf="formGroup.controls['aamcAccountNumber'].enabled">
    <nsc-form-field-input
      idx="amcas_id"
      controlName="aamcAccountNumber"
      hint="(8 digits)"
      maxlength="8"
      placeholder="Enter {{ content.labelAamcAccountNumber }}"
      [group]="formGroup"
      [mask]="masks.aamcAccountNumber"></nsc-form-field-input>
  </div>
  <div class="col-12 col-md-4" *ngIf="formGroup.controls['amcasTranscriptIdNumber'].enabled">
    <nsc-form-field-input
      idx="amcas_number"
      controlName="amcasTranscriptIdNumber"
      hint="(7 digits)"
      maxlength="7"
      placeholder="Enter {{ content.labelAmcasTranscriptIdNumber }}"
      [group]="formGroup"
      [mask]="masks.amcasTranscriptIdNumber"></nsc-form-field-input>
  </div>

  <div class="col-12"></div>

  <div class="col-12 col-md-6 col-lg-4" *ngIf="formGroup.controls['casId'].enabled">
    <div class="row nsc-row--12">
      <div class="col">
        <nsc-form-field-input
          idx="cas_id"
          controlName="casId"
          maxlength="60"
          placeholder="Enter {{ content.labelCasId }}"
          [group]="formGroup"></nsc-form-field-input>
      </div>
      <div class="col-auto nsc-ts-help__icon">
        <mat-icon 
          id="helpUboxIcon" 
          role="button"
          tabindex="0"
          aria-hidden="false"
          aria-label="Help"
          (click)="openDialog()"
          (keyup.enter)="openDialog()"
          (keyup.space)="openDialog()">help</mat-icon>        
      </div>
    </div>
  </div>
</div>
