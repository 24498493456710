import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

import { CommonValues } from '../../classes/common-values';

@Component({
  selector: 'nsc-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnChanges, OnInit {
  @Input('route') route: string;

  @ViewChild('stepper')  myStepper: MatStepper;

  content = {
    step1StatusComplete: false,
    step2StatusComplete: false,
    step3StatusComplete: false,

    state: null
  };

  show = {
    stepper: null
  };

  values = {
    step1Routes: [
      this.commonValues.routes.schoolWelcome
    ],
    step2Routes: [
      this.commonValues.routes.recipientSelect
    ],
    step3Routes: [
      this.commonValues.routes.orderReview
    ]
  };

  constructor(private commonValues: CommonValues) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.setStepperConfig(this.route);
    this.setStepperVisibility(this.route);
  }

  // set the classes that will determine how the stepper is displayed (step 1, 2, or 3);
  setStepperConfig(route: string): void {
   
    if (this.values.step2Routes.indexOf(route) > -1) {
      if(this.content.step2StatusComplete === true){
        this.myStepper.previous();
      }else if(this.content.step1StatusComplete === false){
        this.content.step1StatusComplete = true;
        this.myStepper.next();
      }
    }

    if (this.values.step3Routes.indexOf(route) > -1) {
      this.content.step2StatusComplete = true;
      this.myStepper.next();
    }

    if(this.commonValues.routes.orderConfirmation === route){
      this.content.step3StatusComplete = true;
      this.content.state = 'done';
    }

    //re-setting the step values to false when back button is clicked
    if(this.values.step1Routes.indexOf(route)>-1){
      this.content.step1StatusComplete = false;
      this.content.step2StatusComplete = false;
      this.content.step3StatusComplete = false;
    }

  }

  setStepperVisibility(route: string): void {
    // use split on the routes because we dont care about the route params being passed in;
    const hideStepperRoutes = [
      this.commonValues.routes.schoolSelect,
      this.commonValues.routes.schoolWelcome,
      this.commonValues.routes.schoolWithFiceCode.split('/')[0],
      this.commonValues.routes.messageWithReason.split('/')[0],
      this.commonValues.routes.outageMessage.split('/')[0]
    ];

    if (this.route) {
      this.show.stepper = hideStepperRoutes.indexOf(this.route.split('/')[0]) === -1;
    }
  }
}
