import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { ApiResponseState } from '../../interfaces/api-response-state.interface';
import { SecurityService } from '../../services/security/security.service';
import { SelectOptgroup } from '../../interfaces/select-optgroup.interface';
import { SelectOption } from '../../interfaces/select-option.interface';
import { States } from '../../interfaces/states.interface';

@Injectable()
export class StatesService implements OnDestroy {
  data$ = new BehaviorSubject(<States>{});
  unsubscribe$ = new Subject();

  constructor(private http: HttpClient, private securityService: SecurityService) {
    this.getData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this.http
      .get('/api/tsorder/system-config/state')
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(error => this.securityService.catchResponse(error))
      )
      .subscribe((data: ApiResponseState[]) => {
        const us = <SelectOptgroup[]>[
          {
            label: 'States',
            options: []
          },
          {
            label: 'Territories',
            options: []
          },
          {
            label: 'Army Post Offices',
            options: []
          }
        ];
        const canada = <SelectOptgroup[]>[
          {
            label: 'Provinces',
            options: []
          },
          {
            label: 'Territories',
            options: []
          }
        ];
        let international = '';

        // transform the data into a structure our component is expecting (a SelectOption);
        const newValues = data.map((option: ApiResponseState) => {
          return {
            name: option.description,
            value: option.code.toUpperCase(),
            category: option.category
          };
        });

        // now organize the values by country;
        newValues.forEach((state: SelectOption) => {
          switch (state.category) {
            case 'U': // states;
              us[0].options.push(state);
              break;

            case 'T': // territories;
              us[1].options.push(state);
              break;

            case 'A': // army post offices;
              us[2].options.push(state);
              break;

            case 'C': // canada;
              // canadian regions are not broken up, in the back-end;
              // but we want to break the up in the front-end;
              if (['NT', 'NU', 'YT'].indexOf(state.value.toString()) === -1) {
                // add to canda provinces;
                canada[0].options.push(state);
              } else {
                // add to canada territories;
                canada[1].options.push(state);
              }
              break;

            case 'I':
              international = state.value.toString();
              break;
          }
        });

        // return all three arrays for each component to hide/show as needed;
        this.data$.next({ us, canada, international });
      });
  }
}
