import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../data/data.service';
import { SecurityService } from '../security/security.service';
import { ApiResponseRecipientProfile } from '../../interfaces/api-response-recipient-profile.interface';
import { ApiRequestUboxExchangeDetail } from '../../interfaces/api-request-uboxexchange-detail';

@Injectable({
  providedIn: 'root'
})
export class UboxExchangeDetailService {

  constructor(
    private http: HttpClient
  ) {}

  findEtxMatch(query: ApiRequestUboxExchangeDetail): Observable<ApiResponseRecipientProfile> {
    const url = `/api/tsorder/ubox-exchange-detail/${query.orderId}`;

    return this.http
      .post<ApiResponseRecipientProfile>(url, query);
  }
}
