<div mat-dialog-title class="mat-dialog-title" id="{{data.id}}Title">
  <h3  class="nsc-title">{{ data.title }}</h3>
  <span mat-dialog-close class="close">
    <mat-icon class="nsc-icon__svg" svgIcon="close" aria-hidden="false"
      aria-label="close confirmation dialog"></mat-icon>
  </span>
</div>
<mat-dialog-content class="mat-typography">
  <div id="{{data.id}}Content">
    <p aria-hidden="true"> {{ content.message }}</p>
    <span [attr.aria-label]="content.convertedTimeMessage"></span>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="start">
  <button mat-raised-button class="nsc-button" color="primary" type="button" (click)="resetCountdown()" aria-label="ok confirmation">OK</button>
</mat-dialog-actions>