import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../data/data.service';

import { AddressValidation } from '../../interfaces/address-validation';


// https://smartystreets.com/docs/cloud/us-autocomplete-api
@Injectable()
export class AutoCompleteUsService {

  autoCompleteURL = 'https://us-autocomplete.api.smartystreets.com/suggest?';
  addressValidation: AddressValidation;

  constructor(private http: HttpClient, private dataService: DataService) { }

  lookup = {
    suggestions: 10,
    geolocate: true,
    geolocate_precision: 'city',
    prefer_ratio: 0.333333333
  };


  searchAddress(addressEntered) {
    const websiteKey = this.dataService.get().propertyConfig.addressValidationAuthKey;
    const url = `https://us-autocomplete.api.smartystreets.com/suggest?auth-id=${websiteKey}&${this.serialize(this.lookup)}`;
    const addressQry = url + '&prefix=' + addressEntered;

    return this.http
      .get<AddressValidation[]>(addressQry);

  }

  serialize(obj, prefix?): string {
    const str = [];
    let p;

    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + '[' + p + ']' : p;
        const v = obj[p];

        str.push(v !== null && typeof v === 'object' ? this.serialize(v, k) : k + '=' + v);
      }
    }

    return str.join('&');
  }

}
