<form [formGroup]="formGroup" novalidate>
  <div *ngIf="values.invalidDeliveryMethod && values.isRecipientTypeMyself"  role="alert" [attr.aria-label]="invalidDeliveryMethod">
    <nsc-card [error]="true">
      <nsc-card-title-error title="Invalid Delivery Method For Selected State" icon="warning">
        <p>
          {{content.certifiedMailUsWarningMessage}}
        </p>
      </nsc-card-title-error>
    </nsc-card>
  </div>
  <nsc-spacer size="md"></nsc-spacer>
  <nsc-card>
    <nsc-card-title note="All fields required, unless otherwise indicated">Recipient Delivery Information</nsc-card-title>
    <nsc-card-content>
      <div class="row">
        <div class="col-12 col-lg-8">
          <mat-form-field appearance="outline" [hideRequiredMarker]="true">
            <mat-label>Recipient</mat-label>
            <input
              matInput
              id="recipient_name"
              formControlName="recipient"
              [readonly]="values.recipientReadonly"
              [value]="formGroup.controls['recipient'].value">
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-8">
          <nsc-form-field-input
            idx="recipient_attention"
            controlName="attention"
            maxlength="40"
            placeholder="Attention"
            [group]="formGroup"
            [required]="require.attention"></nsc-form-field-input>
        </div>
      </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <nsc-form-field-select
              idx="recipient_country"
              controlName="country"
              placeholder="Recipient Country"
              [group]="formGroup"
              [selectObjects]="values.countries$.value"></nsc-form-field-select>
          </div>
        </div>
        <div class="row">
        <div class="col-12 col-lg-8">
            <nsc-address-auto-complete
            #addressAutoComplete
            (emitFocusOnValidAddress) = "changeFocusOnValidAddress($event)"
            (emitInternationalPremisePartialAddress)="setInternationalPremisePartialAddress($event)"
            idx="nsc-address-auto-complete2"
            controlName="addressLine1"
            [continueOnlyWithValidAddress]="values.securePrint"
            placeholder="Address 1"
            maxlength="40"
            hint="Street number and name or PO Box"
            [group]="formGroup"
            [notInList]="false"
            [isExpressSecurePrint]="values.securePrint"
            [options]="addressSuggestions"
            [validateInternationAddressKey]="values.validateInternationAddressKey"></nsc-address-auto-complete>

        </div>

        <div class="col-12 col-lg-8">
          <nsc-form-field-input
            idx="recipient_address_line2"
            controlName="addressLine2"
            hint="Building, campus box, floor, apt, suite"
            maxlength="40"
            placeholder="Address 2"
            [group]="formGroup"
            [required]="false"></nsc-form-field-input>
        </div>
        <div class="col-12 col-lg-4">
        </div>

        
        <div class="col-12 col-lg-4">
          <nsc-form-field-input
            idx="recipient_city"
            controlName="city"
            maxlength="20"
            placeholder="City"
            [group]="formGroup"></nsc-form-field-input>
        </div>
        <div class="col-12 col-lg-4">
          <nsc-form-field-select-optgroup
            idx="recipient_state"
            controlName="state"
            placeholder="{{ content.labelState }}"
            [group]="formGroup"
            [selectObjects]="values.states$.value"></nsc-form-field-select-optgroup>
        </div>
        <div class="col-12 col-lg-4">
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <nsc-form-field-input
            idx="recipientZipCode"
            controlName="zip"
            hint="{{ content.hintZip }}"
            maxlength="10"
            placeholder="Zip/Postal Code"
            [group]="formGroup"></nsc-form-field-input>
        </div>    

        <div class="col-12 col-lg-8">
        </div>

        <div class="col-12 col-lg-4">
          <nsc-form-field-input
           (emitOnFocus)="setOnfocus()"
           (blurEvent)="onPhoneBlur($event)"
            [triggerOnFocus]="true"
            idx="recipientPhone"
            #phone
            autocomplete="tel"
            controlName="phone"
            hint="{{ content.hintPhone }}"
            maxlength="20"
            placeholder="{{ content.labelPhone }}"
            [group]="formGroup"
            [mask]="masks.phonemask"
            [required]="require.phone"></nsc-form-field-input>
            <div id="customDescribedPhoneId" class="nsc-phone-hint mat-hint mat-caption">
              <span *ngIf="content.hintPhone === content.hintPhoneInternational"> For express shipments outside the U.S., {{content.hintDescValue }} phone number is required by the shipping company. Without one your package may be returned.</span> 
            </div>
        </div>
      </div>
    </nsc-card-content>
  </nsc-card>
  <div *ngIf="show.acknowledgeInvalidAddress"  role="alert" [attr.aria-label]="addressNotVerified">
      <nsc-spacer size="md"></nsc-spacer>
      <nsc-card [error]="true">
        <nsc-card-title-error title="Address Verification - Acknowledge Invalid Address" icon="warning"></nsc-card-title-error>
        <nsc-card-content>
          <div  id="addressNotVerified">
            <nsc-form-field-toggle idx="acknowledge_address" controlName="isAckgInvalidAddress" [label]="values.acknowledgeMessage"
              [group]="formGroup"></nsc-form-field-toggle>
          </div>
        </nsc-card-content>
      </nsc-card>
  </div>
  <div *ngIf="formGroup.controls['securePrintInvalidAddress'].enabled" role="alert" [attr.aria-label]="addressNotVerified">
    <nsc-spacer size="md"></nsc-spacer>
    <nsc-card [error]="true">
      <nsc-card-title-error title="Address Verification - Invalid Address" icon="warning">
        <p>
          {{values.securePrintInvalidAddressMessage}}
        </p>
      </nsc-card-title-error>
      
    </nsc-card>
  </div>

  <div *ngIf="formGroup.controls['certifiedMailInvalidAddress'].enabled"  role="alert" [attr.aria-label]="addressNotVerified">
    <nsc-spacer size="md"></nsc-spacer>
    <nsc-card [error]="true">
      <nsc-card-title-error title="Address Verification - Invalid Address" icon="warning">
        <p>
          {{values.certifiedMailSecurePrintInvalidAddressMessage}}
        </p>
      </nsc-card-title-error>
    
    </nsc-card>
  </div>

  <div *ngIf="values.invalidDeliveryMethod && !values.isRecipientTypeMyself" role="alert" [attr.aria-label]="invalidDeliveryMethod">
    <nsc-spacer size="md"></nsc-spacer>
    <nsc-card [error]="true">
      <nsc-card-title-error title="Invalid Delivery Method For Selected State" icon="warning">
        <p>
          {{content.certifiedMailUsWarningMessage}} 
        </p>
      </nsc-card-title-error>
     
    </nsc-card>
  </div>

  <nsc-spacer size="md"></nsc-spacer>

  <div class="nsc-form-actions">
    <button
      id="recipient_address_previous"
      mat-raised-button
      id="recepient_previous"
      class="nsc-form-actions__button  nsc-form-actions__button button-big"
      color="primary"
      type="button"
      (click)="previous()"><mat-icon aria-hidden="true">chevron_left</mat-icon> Previous</button>
    <nsc-spacer horizontal="true" size="md"></nsc-spacer>
    <button
      id="recipient_address_cancel"
      mat-stroked-button
      id="recepient_cancel"
      class="nsc-form-actions__button button-big "
      color="primary"
      type="button"
      (click)="cancel()">Cancel</button>
    <nsc-spacer horizontal="true" size="md"></nsc-spacer>
    <button
      id="recipient_address_next"
      mat-raised-button
      id="recepient_addtocart"
      class="nsc-form-actions__button nsc-btn__right button-big "
      color="primary"
      type="button"
      [disabled]="!canContinue()"
      (click)="continue()">Add to Cart <mat-icon aria-hidden="true" *ngIf="formGroup.valid">chevron_right</mat-icon></button>
  </div>
</form>

<!-- <nsc-spacer size="md"></nsc-spacer>
<nsc-card>
  <nsc-card-title note="development mode only">Form Data Model</nsc-card-title>
  <nsc-card-content>
    <textarea class="json">{{ formGroup.value | json }}</textarea>
  </nsc-card-content>
</nsc-card> -->