<div class="nsc-app-footer mat-caption" role="contentinfo">
  <img src="/assets/Nsc-Tree-Logo.png" alt="National Student Clearinghouse" class="nsc-app-footer__logo--nsc" />
  <nsc-spacer size="sm"></nsc-spacer>
  <div aria-label="2300 Dulles Station Blvd. Suite 220, Herndon,VA 20171">2300 Dulles Station Blvd. Suite 220 <br>  Herndon,VA 20171</div>
  <nsc-spacer size="sm"></nsc-spacer>
  <ul class="nsc-listitem">
    <li>
      <a href="https://nschelpcenter.force.com/student/s/contactsupport" rel="noopener" target="_blank" aria-label="Open contact us in a new window">
        Contact Us <mat-icon>open_in_new</mat-icon>
      </a>
    </li>

    <li>
      <a href="https://www.studentclearinghouse.org/privacy-policy/" rel="noopener" target="_blank" aria-label="Open privacy policy in a new window">
        Privacy Policy <mat-icon>open_in_new</mat-icon>
      </a>
    </li>

    <li>
      <a href="https://www.studentclearinghouse.org/terms-of-use/" rel="noopener" target="_blank" aria-label="Open Terms and Conditions of use in a new window">
        Terms & Conditions of Use <mat-icon>open_in_new</mat-icon>
      </a>
    </li>
    <li>
      &copy;{{ copyrightYear }} <a href="http://www.studentclearinghouse.org/" rel="noopener" target="_blank" aria-label="Open National Student Clearinghouse in a new window">
        National Student Clearinghouse <mat-icon>open_in_new</mat-icon>
      </a>. All Rights Reserved.
    </li>
  </ul>
</div>
