import * as moment from 'moment';
import { BehaviorSubject, Subject ,  empty as observableEmpty } from 'rxjs';
import { cloneDeep as _cloneDeep, forEach as _forEach } from 'lodash';
import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil, catchError } from 'rxjs/operators';

import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';
import { CommonValues } from '../../classes/common-values';
import { CountriesService } from '../../services/countries/countries.service';
import { PaymentConfigService } from '../../services/payment/payment-config.service';
import { DataService } from '../../services/data/data.service';
import { ManageFields } from '../../classes/manage-fields';
import { Payeezy, PayeezyFormData } from '../../classes/payeezy';
import { PostService } from '../../services/post/post.service';
import { SecurityService } from '../../services/security/security.service';
import { SelectOptgroup } from '../../interfaces/select-optgroup.interface';
import { SelectOption } from '../../interfaces/select-option.interface';
import { States } from '../../interfaces/states.interface';
import { StatesService } from '../../services/states/states.service';
import { TimeoutService } from '../../services/timeout/timeout.service';

declare function paymentFirstData(orderId, token, prodOrder): any;

@Component({
  selector: 'nsc-order-payment',
  templateUrl: './order-payment.component.html',
  styleUrls: ['./order-payment.component.scss']
})
export class OrderPaymentComponent implements OnDestroy, OnInit {
  formGroup: FormGroup;
  firstdata: any;

  unsubscribe$ = new Subject();

  content = {
    totalFees: this.dataService.get().response.orderHeader.totalFee,

    hintZip: this.commonValues.address.hintZipDomestic,
    hintZipDomestic: this.commonValues.address.hintZipDomestic,
    hintZipInternational: this.commonValues.address.hintZipInternational,

    labelState: this.commonValues.address.labelStateUS,
    labelStateUS: this.commonValues.address.labelStateUS,
    labelStateCA: this.commonValues.address.labelStateCA,
    labelStateInternational: this.commonValues.address.labelStateInternational,

    nonceDetails: null,
    errors: [],
    errorTitle: null,
    errorBody: null
  };

  masks = {
    securityCode: [/\d/, /\d/, /\d/, /\d/]
  };

  values = {
    countries$: new BehaviorSubject(<SelectOption[]>[]),
    // creditCardMonths: this.getCreditCardMonths(),
    // creditCardYears: this.getCreditCardYears(),

    states$: new BehaviorSubject(<SelectOptgroup[]>[]),
    statesUS: <SelectOptgroup[]>[], // populated by the states API;
    statesCA: <SelectOptgroup[]>[], // populated by the states API;
    stateInternational: null, // to be defined with a value from the state API;

    validationZipCA: this.commonValues.address.validationZipCA,
    validationZipUS: this.commonValues.address.validationZipUS
  };
  paymentErrorValues = {
    creditCard: {
      title: 'Payment Error',
      content:
        'Our credit card processor is unable to process your credit card as entered. You may have entered incorrect information or there is an issue with your credit card. ' +
        'Please verify the information below has been entered correctly and resubmit. ' +
        'After one more attempt your order will be canceled and you will need to start a new order.'
    },
    missingDataFields: {
      title: 'Missing Information',
      content:
        'All required fields have not been populated correctly. Please check all fields are entered correctly and resubmit.'
    }
  };

  constructor(
    private blockingIndicatorService: BlockingIndicatorService,
    private commonValues: CommonValues,
    private countriesService: CountriesService,
    // private paymentConfigService: PaymentConfigService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private manageFields: ManageFields,
    private payeezy: Payeezy,
    private postService: PostService,
    private router: Router,
    private securityService: SecurityService,
    private statesService: StatesService,
    private timeoutService: TimeoutService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    const data = this.dataService.get();
    paymentFirstData(
      data.response.orderHeader.toOrderId,
      data.jsonWebToken,
      data.propertyConfig.payeezyScriptToLoad === 'payeezy-test' ? false: true
    );

    this.initServiceValues();
    this.initForm();
    this.initIsDomestic();
    this.initUseContactInfo();
    window['angularComponentRef'] = {
      zone: this.ngZone,
      componentFn: value => this.angularFunctionCalled(value)
   };
  }

  ngOnDestroy() {
    window['angularComponentRef'] = null;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initServiceValues(): void {
    // get Ajax values for the SELECT fields;
    this.countriesService.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: SelectOption[]) => this.values.countries$.next(json));
    /*
        // get PaymentConfig
        this.paymentConfigService.data$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((json: ApiResponsePaymentAuthorizeSession) => {
            if (json) {
              this.dataService.save({
                paymentConfig: json
              });
            }
          });
    */
    /*  // get PaymentConfig
     this.paymentConfigService.data$
     .pipe(takeUntil(this.unsubscribe$))
     .subscribe((json: ApiResponsePaymentAuthorizeSession) => {
       if (json) {
         this.dataService.save({
           paymentConfig: json
         });
       }
     }); */

    // break the states up by their country;
    this.statesService.data$.pipe(takeUntil(this.unsubscribe$)).subscribe((json: States) => {
      this.values.statesUS = json.us;
      this.values.statesCA = json.canada;
      this.values.stateInternational = json.international;
      // set default value;
      this.values.states$.next(this.values.statesUS);
    });
  }

  initForm(): void {
    // initialize the form with the default required fields;
    // required fields will change based on selected options and parameters;
    this.formGroup = this.formBuilder.group(
      {
        // nonce: new
        // name: new FormControl(null, [Validators.required]),
        // creditCardNumber: new FormControl(null, [Validators.required, ValidateCreditCardNumber]),
        // expirationMonth: new FormControl(null, [Validators.required]),
        // expirationYear: new FormControl(null, [Validators.required]),
        // securityCode: new FormControl(null, [Validators.required, Validators.minLength(3)]),
        useContactInfo: new FormControl(),
        addressLine1: new FormControl(null, [Validators.required]),
        addressLine2: new FormControl(),
        city: new FormControl(null, [Validators.required, Validators.minLength(2)]),
        state: new FormControl(null, [Validators.required]),
        zip: new FormControl(null, this.values.validationZipUS),
        country: new FormControl(this.commonValues.api.us, [Validators.required])
      }
      // {
      //   validator: [ValidateDateCreditCard('expirationMonth', 'expirationYear')]
      // }
    );

    // any change to the form means the user is actively using the app, so extend the system timeout;
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.timeoutService.startWarningCountdown();
    });
  }

  initIsDomestic(): void {
    const controls = this.formGroup.controls;

    // watch the `country` field because when toggled we want to change content on other fields;
    this.formGroup.controls.country.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        const isUS = value === this.commonValues.api.us;
        const isCA = value === this.commonValues.api.ca;
        const isDomestic = isUS || isCA;

        // configure zip;
        // us & canda have different validations;
        if (isDomestic) {
          controls.zip.setValidators(
            isUS ? this.values.validationZipUS : this.values.validationZipCA
          );
          this.content.hintZip = this.content.hintZipDomestic;
        }
        // international has no zip validation;
        else {
          controls.zip.clearValidators();
          this.content.hintZip = this.content.hintZipInternational;
        }

        // validation rules may have changed, but we dont want to show a validation error;
        // remove any previously entered value, and reset the field;
        this.manageFields.enable([controls.zip]);

        // configure states (labels and values) based on country;
        if (isDomestic) {
          if (isUS) {
            this.content.labelState = this.content.labelStateUS;
            this.values.states$.next(this.values.statesUS);
          } else {
            this.content.labelState = this.content.labelStateCA;
            this.values.states$.next(this.values.statesCA);
          }

          this.manageFields.enable([controls.state]);
        }
        // or disable the state field if international;
        else {
          this.content.labelState = this.content.labelStateInternational;

          this.manageFields.disable([controls.state]);
        }
      });
  }

  initUseContactInfo(): void {
    const controls = this.formGroup.controls;

    // watch the `useContactInfo` field because when toggled we want to fill/empty form fields;
    controls.useContactInfo.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        const requestor = this.dataService.get().response.student;
        let newValues = {
          addressLine1: null,
          addressLine2: null,
          zip: null,
          city: null,
          state: null,
          country: this.commonValues.api.us
        };

        // if the user says to use their contact Info, pull it from the Data service;
        if (value === this.commonValues.api.yes) {
          newValues = {
            addressLine1: requestor.street1,
            addressLine2: requestor.street2,
            zip: requestor.zip === this.commonValues.defaultZip ? null : requestor.zip,
            city: requestor.city,
            state: requestor.state,
            country: requestor.country
          };
        }

        this.manageFields.reset([
          controls.addressLine1,
          controls.zip,
          controls.city,
          controls.state
        ]);

        this.formGroup.patchValue(newValues);
      });
  }

  // getCreditCardMonths(): SelectOption[] {
  //   return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => {
  //     return {
  //       name: moment()
  //         .month(i - 1)
  //         .format('MMMM'),
  //       value: i < 10 ? `0${i}` : i.toString()
  //     };
  //   });
  // }

  // // dynamically build the CC years into the future (so the values dont have to be updated each Januaray);
  // getCreditCardYears(): SelectOption[] {
  //   const years = [];
  //   const year = new Date().getFullYear();
  //   let step;

  //   // get 10 years into the future;
  //   for (step = 0; step < 11; step++) {
  //     const yearAsString = (year + step).toString();

  //     years.push({
  //       name: yearAsString,
  //       value: yearAsString.substr(-2)
  //     });
  //   }

  //   return years;
  // }

  // getCreditCardType(ccNumber: string): string {
  //   const creditCardRegEx = this.commonValues.creditCardRegEx;

  //   const testAmericanExpress = creditCardRegEx.americanExpress.test(ccNumber);
  //   const testDinersClub = creditCardRegEx.dinersClub.test(ccNumber);
  //   const testDiscover = creditCardRegEx.discover.test(ccNumber);
  //   const testJCB = creditCardRegEx.jcb.test(ccNumber);
  //   const testMastercard = creditCardRegEx.mastercard.test(ccNumber);
  //   const testVisa = creditCardRegEx.visa.test(ccNumber);

  //   let cardType;

  //   if (testAmericanExpress) {
  //     cardType = 'American Express';
  //   } else if (testDinersClub) {
  //     cardType = 'Diners Club';
  //   } else if (testDiscover) {
  //     cardType = 'Discover';
  //   } else if (testJCB) {
  //     cardType = 'JCB';
  //   } else if (testMastercard) {
  //     cardType = 'Mastercard';
  //   } else if (testVisa) {
  //     cardType = 'Visa';
  //   }

  //   return cardType;
  // }

  cancel(): void {
    this.securityService.cancelOrder();
  }
  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  angularFunctionCalled(detail) {
    if (detail.isValidForm) {
      this.setPaymentCardErrorMessage(detail.isValidForm);
    } else if (detail.nonceDetails) {
      this.content.nonceDetails = detail.nonceDetails;
      this.continue();
    } else if (detail.continueProcess) {
      this.continue();
    } else if (detail.showBlockingIndicator) {
      this.blockingIndicatorService.open();
    }
  }

  setPaymentCardErrorMessage(isInvalidForm) {
    this.blockingIndicatorService.close();
    // checks if all the fields has been fillout however there was some error while processing the payment
    if (this.formGroup.valid && !isInvalidForm) {
      this.content.errorTitle = this.paymentErrorValues.creditCard.title;
      this.content.errorBody = this.paymentErrorValues.creditCard.content;
    } // check if the form is missing any field to be fillout
    else if (isInvalidForm) {
      this.content.errorTitle = this.paymentErrorValues.missingDataFields.title;
      this.content.errorBody = this.paymentErrorValues.missingDataFields.content;
    }

    this.content.errors = [{}];
  }

  continue(): void {
    const controls = this.formGroup.controls;
    const payeezyData: PayeezyFormData = {
      //  ccType: this.getCreditCardType(controls.creditCardNumber.value),
      // ccName: controls.name.value,
      // ccNumber: controls.creditCardNumber.value,
      // ccDate: `${controls.expirationMonth.value}${controls.expirationYear.value}`,
      // ccCvv: controls.securityCode.value,
      city: controls.city.value,
      country: controls.country.value,
      street: controls.addressLine1.value,
      state: controls.state.value,
      zip: controls.zip.value
    };

    /// this.blockingIndicatorService.open();

    const production = this.dataService.get().response.orderHeader.chFlag === 'P';

    // this.payeezyService.tokenizeCard(production, this.dataService.get().paymentConfig, payeezyData)
    //  .subscribe((response) =>
    // {
    this.commonValues.payeezyCount = this.commonValues.payeezyCount + 1;

    // if the creditcard token generation failed;
    /*  if (response.status !== 200 ) {
        // if we haven't hit the threshold, display any errors that have been returned;
        if (this.commonValues.payeezyCount < this.commonValues.payeezyCountLimit) {
          // show the errors and prevent the user from moving forward;
          this.content.errors = [response.status];
          // hide the loading indicator so the user can edit the form;
          this.blockingIndicatorService.close();
          this.scrollToTop();
        }
        // the error threshold has been hit, so redirect the user to the message page saying the order is done;
        else {
          this.securityService.setDeactivateWarning(false);

          this.router.navigate(
            [this.commonValues.routes.messagePayment],
            this.commonValues.routes.extras
          );
        }
      }
      */
    // if the creditcard token generation succeded;

    // create a clone of the form data so we can append payeezy data before submitting to the data service;
    const data = _cloneDeep(this.formGroup.value);

    // add values to our data object to store;
    // these are not present in the Angular form, but are needed for the PostService;
    data.payeezyToken = this.content.nonceDetails;
    // data.ccType = payeezyData.ccType;
    //  data.nonce = this.content.nonceDetails;

    // this form requires the values in a different format;
    // need to convert the value property into the value of the name property;
    _forEach(this.values.countries$.value, country => {
      if (country.value === controls.country.value) {
        data.country = country.name;
      }
    });
    _forEach(this.values.states$.value, region => {
      _forEach(region, group => {
        _forEach(group, state => {
          if (state.value === controls.state.value) {
            data.state = state.name;
          }
        });
      });
    });

    this.dataService.resetOrderPayment();

    this.dataService.save(data, 'form.payment');

    // post the data to the back-end;
    // once that's complete move onto the order-confirmation route;
    this.postService
      .payment()
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(error => {
          if (this.commonValues.payeezyCount < this.commonValues.payeezyCountLimit) {
            // show the errors and prevent the user from moving forward;
            this.setPaymentCardErrorMessage(false);
            this.content.errors = [error.message];
            // hide the loading indicator so the user can edit the form;
            this.blockingIndicatorService.close();
            this.scrollToTop();
          }
          // the error threshold has been hit, so redirect the user to the message page saying the order is done;
          else {
            this.securityService.setDeactivateWarning(false);

            this.router.navigate(
              [this.commonValues.routes.messagePayment],
              this.commonValues.routes.extras
            );
          }
          return observableEmpty();
        })
      )
      .subscribe(() => {
        const continueToOrderConfirmation = () => {
          window.setTimeout(() => {
            this.securityService.setDeactivateWarning(false);

            this.router.navigate(
              [this.commonValues.routes.orderConfirmation],
              this.commonValues.routes.extras
            );
          }, this.commonValues.loading.delay);
        };

        // if there is a signature to post, do it, otherwise move on;
        if (this.dataService.get().form.consent.signature) {
          this.postService
            .consent()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              () => {
                continueToOrderConfirmation();
              },
              error => {
                // ignore consent error and send to the next screen
                console.log(error);
                continueToOrderConfirmation();
              }
            );
        } else {
          continueToOrderConfirmation();
        }
      });
    //  }
    //  });
  }
}
