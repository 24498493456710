import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { ApiResponseRecipient } from '../../interfaces/api-response-recipient.interface';
import { SecurityService } from '../../services/security/security.service';

@Injectable()
export class SchoolsByStateService {
  constructor(private http: HttpClient, private securityService: SecurityService) {}

  get(state): Observable<ApiResponseRecipient[]> {
    return this.http
      .get<ApiResponseRecipient[]>(`/api/tsorder/recipient-organization/institution/state/${state}`)
      .pipe(catchError(error => this.securityService.catchResponse(error)));
  }
}
