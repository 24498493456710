import { AbstractControl } from '@angular/forms';

export function ValidateLegalName(controlName) {
  return (abstractControl: AbstractControl) => {
    const primaryControl = abstractControl.get(controlName);
    // we need to restrict to add space at the beginning of the field
    if (primaryControl.value ) {
          let firstCharacter =primaryControl.value.charAt(0);
          console.log("value"+ primaryControl.value);
          if(firstCharacter === ' '){
           const newValue= primaryControl.value.replace(firstCharacter , '');
            primaryControl.setValue(newValue,{ emitEvent: false });
          }

    }
  };
}
