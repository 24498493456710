import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AddressValidation } from '../../interfaces/address-validation';
import { AddressResponse } from '../../interfaces/address-response';
import { AddressInternationalResponse } from '../../interfaces/address-international-response';
import { isEmpty as _isEmpty } from 'lodash';


@Component({
  selector: 'nsc-address-validation-dialog',
  templateUrl: './address-validation-dialog.component.html',
  styleUrls: ['./address-validation-dialog.component.scss']
})
export class AddressValidationDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title?: string; body: string , id?:string},
    public dialogRef: MatDialogRef<AddressValidationDialogComponent>) { }

  public addressValidationMessage: string;

  public missingSecondary: boolean;

  public secondaryNbr: string;

  public enteredAddress: AddressValidation;

  public correctedAddress: AddressResponse;

  public correctedInternationalAddress: AddressInternationalResponse;

  public addressCorrected: boolean;

  public continueOnlyWithValidAddress: boolean;

  public isAddressValid: boolean;

  public instructionalText: string;

  public postalOrZipCode: string;

  public isSuggestedAddressConstructed: boolean;
  
  show = {
    suggestedAddress: true,
    enteredAddress: true
  };

  correctedAddressValues: any = {};

  ngOnInit() {
    this.correctedAddressValues = this.correctedAddress ? this.correctedAddress : this.correctedInternationalAddress;
    if (this.correctedAddress) {
      this.correctedAddressValues.delivery_line_1 = this.correctedAddress.delivery_line_1 ? this.correctedAddress.delivery_line_1 : '';
      this.correctedAddressValues.delivery_line_2 = this.correctedAddress.delivery_line_2;
    } else if (this.correctedInternationalAddress) {
      this.correctedAddressValues.address1 = this.correctedInternationalAddress.address1 ? this.correctedInternationalAddress.address1 : '';
      this.correctedAddressValues.address2 = this.correctedInternationalAddress.address2 ? this.correctedInternationalAddress.address2 : '';
    }
    if(!_isEmpty(this.correctedAddressValues.components.postal_code)){
      this.postalOrZipCode = this.correctedAddressValues.components.postal_code;
    }else if(!_isEmpty(this.correctedAddressValues.components.zipcode)){
      this.postalOrZipCode = this.correctedAddressValues.components.zipcode + (this.correctedAddressValues.components.plus4_code ? ('-' + this.correctedAddressValues.components.plus4_code) : '');
    }
    //this.displayZipCodeAndCityForSuggestion();
  }

  selectAddress(chosenAddress: any) {
    this.dialogRef.close(chosenAddress);
  }

  isNullorBlank(text: string): boolean {
    if (typeof text !== 'undefined' && text !== ' ' && text) {
      return false;
    }
    return true;
  }

  displayZipCodeAndCityForSuggestion(){
    let address2 = '';
    let postalCode = '';
    let city = '';
    let state = '';
    if(!_isEmpty(this.correctedAddressValues.delivery_line_2)){
      address2 = this.correctedAddressValues.delivery_line_2;
    }else if(!_isEmpty(this.correctedAddressValues.address2)){
      address2 = this.correctedAddressValues.address2;
    }
    if(_isEmpty(address2)){
        return;
    }else {
      if(!_isEmpty(this.correctedAddressValues.components.city_name )){
        city = this.correctedAddressValues.components.city_name ;
      }else if(!_isEmpty(this.correctedAddressValues.components.locality)){
        city = this.correctedAddressValues.components.locality;
      }
      if(!_isEmpty(city) && address2.toLowerCase().includes(city.toLowerCase())){
        address2 = address2.toLowerCase().replace(city.toLowerCase(),'');
      }
      if(!_isEmpty(this.correctedAddressValues.components.administrative_area )){
        state = this.correctedAddressValues.components.administrative_area ;
      }else if(!_isEmpty(this.correctedAddressValues.components.state_abbreviation)){
        state = this.correctedAddressValues.components.state_abbreviation;
      }
      if(!_isEmpty(state) && address2.toLowerCase().includes(state.toLowerCase())){
        address2 = address2.toLowerCase().replace(state.toLowerCase(),'');
      }
      if(!_isEmpty(this.correctedAddressValues.components.postal_code)){
        postalCode = this.correctedAddressValues.components.postal_code;
        this.postalOrZipCode = postalCode;
      }else if(!_isEmpty(this.correctedAddressValues.components.zipcode)){
        postalCode = this.correctedAddressValues.components.zipcode;
        this.postalOrZipCode = postalCode + (this.correctedAddressValues.components.plus4_code ? ('-' + this.correctedAddressValues.components.plus4_code) : '');
      }
      if(!_isEmpty(postalCode) && address2.toLowerCase().includes(postalCode.toLowerCase())){
        address2 = address2.toLowerCase().replace(postalCode.toLowerCase(),'');
      }
    }    
    this.correctedAddressValues.address2 = address2;
    if(!_isEmpty(this.correctedAddressValues.address2)){
      this.correctedAddressValues.delivery_line_2 = '';
    }
  }
}