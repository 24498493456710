import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { SecurityService } from '../../services/security/security.service';
import { Version } from '../../interfaces/version.interface';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  constructor(private http: HttpClient, private securityService: SecurityService) {}

  get(): Observable<Version> {
    return this.http
      .get<Version>('./assets/version.json')
      .pipe(catchError(error => this.securityService.catchResponse(error)));
  }
}
