import { BehaviorSubject, Subject } from 'rxjs';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { filter as _filter } from 'lodash';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ManageFields } from '../../classes/manage-fields';

import { AutocompleteOption } from '../../interfaces/autocomplete-option.interface';
import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';
import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';
import { SchoolService } from '../../services/school/school.service';
import { SchoolsService } from '../../services/schools/schools.service';
import { PropertyConfigService } from '../../services/property/property-config.service';
import { ApiResponsePropertyConfig } from '../../interfaces/api-response-property-config.interface';
import { ValidateSchoolAutocomplete } from '../../validators/school-autocomplete.validator';
import { StatesService } from '../../services/states/states.service';
import { SystemMessagesService } from '../../services/system-messages/system-messages.service';

@Component({
  selector: 'nsc-school-select',
  templateUrl: './school-select.component.html',
  styleUrls: ['./school-select.component.scss']
})
export class SchoolSelectComponent implements OnDestroy, OnInit {
  formGroup: FormGroup;
  loadingInterval: number;
  unsubscribe$ = new Subject();
  loadingSchools = false;
  hideSchoolSelect: boolean;
   content = {
    labelSchool: null,
    labelSchoolLoaded: this.commonValues.autocomplete.custom.requestor.defaultLabelSchoolLoaded,
    labelSchoolAdvancedSearch: this.commonValues.autocomplete.custom.requestor.advLabelSchoolLoaded,
    labelSchoolLoading: this.commonValues.autocomplete.custom.labelSchoolLoading,
    title: this.commonValues.autocomplete.custom.requestor.defaultTitle,
    searchLinkLabel: this.commonValues.autocomplete.custom.requestor.defaultSearchLinkLabel,
  };

  values = {
    schools$: new BehaviorSubject(<AutocompleteOption[]>[]), // complete list for autocomplete to filter down;
    searchType: null,
    minCharactersToTrigger: null,
    testSystemAcknowledgeMessage:null
  };

  show = {
    advancedSearch: false,
    systemAcknowledge: false
  };

  
  constructor(
    private commonValues: CommonValues,
    public dataService: DataService,
    private formBuilder: FormBuilder,
    private blockingIndicatorService: BlockingIndicatorService,
    private renderer: Renderer2,
    private router: Router,
    private schoolService: SchoolService,
    private schoolsService: SchoolsService,
    private systemMessagesService: SystemMessagesService,
    private statesService: StatesService,
    private propertyConfigService: PropertyConfigService,
    private manageFields: ManageFields
  ) {}
   ngOnInit() {
    this.setLabel(true);
    this.initForm();
    this.initServiceValues();
    this.getSchools();
    this.hideSchoolSelect = false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm(): void {
    // set control to be disabled on load, will be enabled after Ajax content is loaded;
    this.formGroup = this.formBuilder.group({
      school: new FormControl(this.commonValues.nullDisabled),
      testSystemAck: new FormControl(this.commonValues.nullDisabled,[Validators.required] )
    });
  }

  initServiceValues(): void {
    this.propertyConfigService.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: ApiResponsePropertyConfig) => {
        if (json)
        {          
        this.show.systemAcknowledge = json.testSystemAcknowledgeToggle === "true";
        this.values.testSystemAcknowledgeMessage = json.testSystemAcknowledgeMessage;
        this.dataService.save({
          propertyConfig: json
        });
      }
      });
  }

  // set the label for the autocomplete;
  // since the autocomplete values come from an Ajax call, let the user know that loading is happening;
  setLabel(loading: boolean): void {
    // control the number of periods after the label;
    // this will loop from 0 to 3 periods, to appear animated;
    let count = 0;
    const countMax = 3;

    // build the new label based on the count value;
    const setLabel = () => {
      let newLabel = this.content.labelSchoolLoading;

      if (count !== 0) {
        for (let index = 1; index <= count; index++) {
          newLabel += '.';
        }
      }

      count = count === countMax ? 0 : count + 1;

      this.content.labelSchool = newLabel;
    };

    // if loading, define the interval that will set the 'animated' label;
    // otherwise, clear the interval and set the loaded label;
    if (loading) {
      this.content.labelSchool = this.content.labelSchoolLoading;
      this.loadingInterval = window.setInterval(setLabel, this.commonValues.loading.interval);
    } else {
      window.clearInterval(this.loadingInterval);
      if (this.show.advancedSearch){
        this.content.labelSchool = this.content.labelSchoolAdvancedSearch;
      }else{
        this.content.labelSchool = this.content.labelSchoolLoaded;
      }


    }
  }

  getSchools(): void {
    const controls = this.formGroup.controls;
    this.loadingSchools = true;

    // get all schools and then set the value to schools and filteredSchools so the form can now work;
    this.schoolsService.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: AutocompleteOption[]) => {
        if (json.length) {
          // set the autocomplete content and enable the form field;
          // set the validator now that we know the valid options;
          this.values.schools$.next(json);

          controls.school.setValidators([
            Validators.required,
            ValidateSchoolAutocomplete(this.values.schools$.value)
          ]);

          // ensure that the loading state will display for at least X seconds;
          // then disable the loading indicators;
          // and set focus on the autocomplete field;
          window.setTimeout(() => {
            this.setLabel(false);
            controls.school.enable();
            this.loadingSchools = false;
          }, this.commonValues.loading.delay);
        }
      });
  }

  searchLinkAction(e): void{
    if (!this.show.advancedSearch){
      this.openAdvancedSearch();
    }else{
      this.closeAdvancedSearch();
    }
    this.manageFields.reset([this.formGroup.controls.school]);
    this.formGroup.controls.school.enable();
    // timeout makes sure that it is invoked after any other event has been triggered.
    // e.g. click events that need to run before the focus
    window.setTimeout(() => {
      try {
        this.renderer.selectRootElement('#nsc-form-field-autocomplete').focus();
      } catch (ignored){}
    });
    e.preventDefault();
  }

  openAdvancedSearch(): void{
    this.show.advancedSearch = true;
    this.content.title = this.commonValues.autocomplete.custom.requestor.advTitle;
    this.content.searchLinkLabel = this.commonValues.autocomplete.custom.backSearchLinkLabel;
    this.values.searchType = this.commonValues.searchType.wildCardSearch;
    this.values.minCharactersToTrigger = 4;
    this.content.labelSchool = this.content.labelSchoolAdvancedSearch;
  }

  closeAdvancedSearch(): void{
    this.show.advancedSearch = false;
    this.content.title = this.commonValues.autocomplete.custom.requestor.defaultTitle;
    this.content.searchLinkLabel = this.commonValues.autocomplete.custom.requestor.defaultSearchLinkLabel;
    this.values.searchType = null;
    this.values.minCharactersToTrigger = null;
    this.content.labelSchool = this.content.labelSchoolLoaded;
  }

  continue(): void {
    // find the school object based on the entered name;
    const nameToMatch = this.formGroup.controls.school.value.toLowerCase();
    const schoolArray = _filter(
      this.values.schools$.value,
      o => o.schlName.toLowerCase() === nameToMatch
    );
    const school = schoolArray.length ? schoolArray[0] : null;

    // if a school was found, move on;
    if (school) {
      // if the school isn't configured to use this application, send them to the app they should use;
      if (school.uiVersion !== '2.0') {
        const legacySchoolWelcomeURL =  this.dataService.get().propertyConfig.legacySchoolWelcomeURL;
        const url = legacySchoolWelcomeURL + `?toscprofilid=${school.scProfileId}`;
        window.location.href = url;
      }

      // enable progress bar, it will be disabled after route change;
      this.blockingIndicatorService.open();

      // get the full school profile based on the selected school;
      // then move to the next page;
        this.schoolService
        .get(school.ficeCode)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          // force a small delay;
          window.setTimeout(() => {
            this.router.navigate([this.commonValues.routes.schoolWelcome]);
            if (this.dataService.data.sssRequestCheck === false) {
              this.hideSchoolSelect = true;
            }
          }, this.commonValues.loading.delay);
        });
    }
  }

  //check if testSystemAcknowledge enabled and only if selected 'yes' and form validations are true to continue
  isContinueDisabled(): boolean{
    const formValid = this.formGroup.valid;
    const testAckValue = this.formGroup.controls.testSystemAck.value;
    const testSysAckSectionEnabled = this.show.systemAcknowledge;
    if(testSysAckSectionEnabled){
      return !(formValid && (testAckValue!= null && testAckValue ==='Y'));
    }else{
      return !(formValid)
    }
  }
 
}
