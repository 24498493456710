import { Component, Input, OnInit , ViewChild , ElementRef} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { CommonValues } from '../../classes/common-values';
import { DialogHelpComponent } from '../dialog-help/dialog-help.component';

@Component({
  selector: 'nsc-form-field-toggle',
  templateUrl: './form-field-toggle.component.html',
  styleUrls: ['./form-field-toggle.component.scss']
})
export class FormFieldToggleComponent implements OnInit {
  @Input() controlName: string;
  @Input() idx: string;
  @Input() disabled = false;
  @Input() group: FormGroup;
  @Input() help: string;
  @Input() helpTitlePopUp: string;
  @Input() label: string;
  @Input() note: string;
  @Input() isHtmlContent: any;
  @Input() valueNo = this.commonValues.api.no;
  @Input() valueYes = this.commonValues.api.yes;
  @Input() classGroup: string;
  @Input() required = true;
  @Input() selectedValue = null;
  @Input() idGroup: string = null;


  values = {
    yes: this.valueYes,
    no: this.valueNo
  };
  hint = '';
  constructor(private commonValues: CommonValues, public dialog: MatDialog) { }

  ngOnInit() {
    // reset if the properties were defined (odd that it wasn't picked up during initialization);
    this.values = {
      yes: this.valueYes,
      no: this.valueNo
    };
    this.selectedValue = this.group.controls[this.controlName].value;
  }

  // button toggle to change the selected value;
  // this will trigger a listener to update the form requirements;
  changeValue(value: string): void {
    const newValue = {};
    newValue[this.controlName] = value;
    this.selectedValue = value;
    this.group.patchValue(newValue);
  }

  clickBtn(value: string): void {
    if (!this.disabled) {
      this.changeValue(value);
    }
  }

  openDialog(): void {
    this.dialog.open(DialogHelpComponent, {
      width: "490px",
      autoFocus: false,
      ariaLabelledBy: "modal"+this.idx+"Title",
      ariaDescribedBy:  "modal"+this.idx+"Content",
      id:"modal"+this.idx,
      data: {
        body: this.help,
        title: this.helpTitlePopUp,
        id: "modal"+this.idx
      }
    });
  }
}
