<div> 
  <section class="nsc-section">
    <div class="nsc-card">
        <div class="nsc-card__logo">
            <img src="assets/images/nslc-logo.png" alt="">
        </div>
        <div class="nsc-card-content">
            <h1 class="nsc-title">Our system is currently unavailable</h1>
            <p>We apologize for this inconvenience and are working to restore service as soon as possible.
                Please allow us some time to correct the issue and try again.</p>
        </div>
    </div>
  </section>
</div>

