<mat-form-field appearance="outline" [hideRequiredMarker]="true"
  [formGroup]="group"
  [ngClass]="loading ? 'nsc-form-field-loading' : ''">
  <mat-label>{{placeholder}}</mat-label>
  <mat-select 
   id="{{idx}}"
   formControlName="{{ controlName }}"
   autocomplete="off"
   (openedChange)="openedChange($event)"
   (selectionChange)="changeValue($event)" >
    <mat-optgroup *ngFor="let obj of selectObjects" [label]="obj.label" id="{{idx}}_group">
      <mat-option  id="{{idx}}_{{i}}" *ngFor="let obj2 of obj.options; let i = index;" [value]="obj2.value">
        {{ obj2.name }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
  <mat-hint>{{ hint }} </mat-hint>
  <mat-error *ngIf="group.controls[controlName].invalid">Error: {{ hint }} {{ validationMessage.get(group.controls[controlName], placeholder) }}</mat-error>
</mat-form-field>
