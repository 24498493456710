<form [formGroup]="formGroup" novalidate>
  <nsc-card>
    <nsc-card-title note="All fields required, unless otherwise indicated">Send To Information</nsc-card-title>
    <nsc-card-content>
      <div class="row">
        <div class="col-12 col-lg-8">
          <mat-form-field appearance="outline">
            <mat-label><strong>Send To Name</strong></mat-label>
            <input
              matInput
              readonly
              placeholder="Recipient"
              [value]="formGroup.controls['recipient'].value">
          </mat-form-field>
        </div>
        
        
        <div class="col-12">
        <mat-label><strong>Enter the Email Address where you want the transcript delivered.</strong></mat-label><br><br>
        
          <nsc-form-field-confirm
            autocomplete="email"
            controlNameConfirm="emailConfirm"
            controlNamePrimary="emailPrimary"
			      maxlength="60"
            placeholder="Send To Email Address"
            [group]="formGroup"></nsc-form-field-confirm>
        </div>
        
        <div class="col-12">
          <nsc-form-field-sub-network-type [formGroup]="formGroup"></nsc-form-field-sub-network-type>
        </div>
      </div>
    </nsc-card-content>
  </nsc-card>

  <nsc-spacer size="md"></nsc-spacer>

  <div class="nsc-form-actions">
    <button
      mat-raised-button
      id="recepientemail_previous"
      class="nsc-form-actions__button  nsc-form-actions__button button-big"
      color="primary"
      type="button"
      (click)="previous()"><mat-icon aria-hidden="true">chevron_left</mat-icon> Previous</button>
    <nsc-spacer horizontal="true" size="md"></nsc-spacer>
    <button
      mat-stroked-button
      id="recepientemail_cancel"
      class="nsc-form-actions__button button-big"
      color="primary"
      type="button"
      (click)="cancel()">Cancel</button>
    <nsc-spacer horizontal="true" size="md"></nsc-spacer>
    <button
      mat-raised-button
      id="recepientemail_addtocart"
      class="nsc-form-actions__button   nsc-btn__right button-big"
      color="primary"
      type="button"
      tabindex="0"
      [disabled]="!formGroup.valid"
      (click)="continue()">Add to Cart <mat-icon aria-hidden="true">chevron_right</mat-icon></button>
  </div>
</form>

<!-- <nsc-spacer size="md"></nsc-spacer>
<nsc-card>
  <nsc-card-title note="development mode only">Form Data Model</nsc-card-title>
  <nsc-card-content>
    <textarea class="json">{{ formGroup.value | json }}</textarea>
  </nsc-card-content>
</nsc-card> -->
