import { BehaviorSubject, Subject } from 'rxjs';
import { cloneDeep as _cloneDeep } from 'lodash';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { sortBy as _sortBy } from 'lodash';
import { takeUntil } from 'rxjs/operators';

import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';
import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';
import { ManageFields } from '../../classes/manage-fields';
import { RecipientTypesService } from '../../services/recipient-types/recipient-types.service';
import { SecurityService } from '../../services/security/security.service';
import { SelectOption } from '../../interfaces/select-option.interface';
import { TimeoutService } from '../../services/timeout/timeout.service';
import { ValidateCasId } from '../../validators/cas-id.validator';
import { MatDialog } from '@angular/material/dialog';
import { DialogHelpComponent } from '../../components/dialog-help/dialog-help.component';
@Component({
  selector: 'nsc-recipient-select',
  templateUrl: './recipient-select.component.html',
  styleUrls: ['./recipient-select.component.scss']
})
export class RecipientSelectComponent implements OnDestroy, OnInit {
  data = this.dataService.get();
  who = this.data.form.recipient.who;

  formGroup: FormGroup;
  unsubscribe$ = new Subject();

  loading = false;
  isOnlyElectronicDeliveryMethods = false;
  isOnlyelectronicAvailable = false;

  content = {
    errorTitle: '',
    errorDescription: '',
    electronicPDFNotAvailableTitle: 'Invalid Delivery Methods',
    electronicPDFNotAvailable: 'Electronic delivery is not available for this email address.',
    onlyElectronicDeliveryMethodTitle: 'Paper Delivery Unavailable',
    onlyElectronicDeliveryMethod: 'Your school only offers electronic delivery. Please select "Yes" to "Do you want to send Electronically" or cancel this order.',
    noDeliveryAvailableTitle: 'Invalid Delivery Methods',
    noDeliveryAvailbleMethod: 'There are no delivery methods available for your chosen recipient.'
  
  };

  values = {
    // value to compare against (used to show additional fields when selected);
    business: this.commonValues.api.business,
    college: this.commonValues.api.college,
    organization: this.commonValues.api.organization,
    me: this.commonValues.api.me,

    // SELECT values populated by service call;
    recipientTypes$: new BehaviorSubject(<SelectOption[]>[])
  };

  constructor(
    public blockingIndicatorService: BlockingIndicatorService,
    private commonValues: CommonValues,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private manageFields: ManageFields,
    private recipientTypesService: RecipientTypesService,
    private router: Router,
    private securityService: SecurityService,
    private timeoutService: TimeoutService,
    private dialog: MatDialog
  ) { }
  

  ngOnInit() {
    this.initServiceValues();
    this.initForm();
    this.initElectronicDeliveryUnavailableFlag();
    this.initRecipientListenerAndSetDefaultRecipient();
    this.isOnlyelectronicAvailable = this.dataService.isOnlyElectronicAvailable();
  }



  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm(): void {
    // initialize the form with the default required fields;
    // required fields will change based on selected options and parameters;
    this.formGroup = this.formBuilder.group({
      recipientType: new FormControl(this.who.recipientType, [Validators.required]),
      recipientFiceCode: new FormControl(this.who.recipientFiceCode), // not visible to the user, used on edit to load form values;
      recipientResponse: new FormControl(this.who.recipientResponse), // not visible to the user, used on edit to load form values;
      // school recipient;
      country: new FormControl(this.who.country || this.commonValues.nullDisabled, [
        Validators.required
      ]),
      state: new FormControl(this.who.state || this.commonValues.nullDisabled, [
        Validators.required
      ]),
      school: new FormControl(this.who.school || this.commonValues.nullDisabled),
      schoolNotInList: new FormControl(this.who.schoolNotInList || this.commonValues.nullDisabled, [
        Validators.required,
        Validators.minLength(3)
      ]),
      // organization recipient;
      organization: new FormControl(this.who.organization || this.commonValues.nullDisabled, [
        Validators.required
      ]),
      organizationNotInList: new FormControl(
        this.who.organizationNotInList || this.commonValues.nullDisabled,
        [Validators.required, Validators.minLength(2)]
      ),
      aamcAccountNumber: new FormControl(
        this.who.aamcAccountNumber || this.commonValues.nullDisabled,
        [Validators.required, Validators.minLength(8)]
      ),
      amcasTranscriptIdNumber: new FormControl(
        this.who.amcasTranscriptIdNumber || this.commonValues.nullDisabled,
        [Validators.required, Validators.minLength(7)]
      ),
      casId: new FormControl(this.who.casId || this.commonValues.nullDisabled, [
        Validators.required,
        Validators.minLength(10),
        ValidateCasId
      ]),
      lsacAccountNumber: new FormControl(
        this.who.lsacAccountNumber || this.commonValues.nullDisabled,
        [Validators.required, Validators.minLength(9)]
      ),

      // business recipient;
      business: new FormControl(this.who.business || this.commonValues.nullDisabled, [
        Validators.required,
        Validators.minLength(2)
      ]),

      // school and organization recipient;
      oborDeliveryEligibility: new FormControl(this.who.oborDeliveryEligibility || this.commonValues.nullDisabled, [
        Validators.required
      ]),
      department: new FormControl(this.who.department || this.commonValues.nullDisabled, [
        Validators.required
      ]),
      departmentNotInList: new FormControl(
        this.who.departmentNotInList || this.commonValues.nullDisabled
      ),

      // ETX - CentralInbox Form controls
      matchedUboxEntry: new FormControl(this.who.matchedUboxEntry || this.commonValues.nullDisabled), // not visible to the user, used on edit to load form values;
      sendElectronically: new FormControl(this.who.sendElectronically || this.commonValues.nullDisabled),
      emailPrimary: new FormControl(this.who.emailPrimary || this.commonValues.nullDisabled, [
        Validators.pattern(this.commonValues.emailPattern)
      ]),
      emailConfirm: new FormControl(this.who.emailPrimary || this.commonValues.nullDisabled, [
        Validators.pattern(this.commonValues.emailPattern)
      ]),
      electronicDeliveryUnavailableFlag: new FormControl({ value: false, disabled: true }),
      isYesSendElectronically: new FormControl({ value: false, disabled: true }),
      noDeliveryMethodAvailble: new FormControl({value: false, disabled: true})
    }
    );
    

    // any change to the form means the user is actively using the app, so extend the system timeout;
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.timeoutService.startWarningCountdown();
    });
  }

  initServiceValues(): void {
    this.recipientTypesService.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: SelectOption[]) => this.values.recipientTypes$.next(json));
  }

   initRecipientListenerAndSetDefaultRecipient(): void {

    if (this.data.defaultRecipientOrganization && this.dataService.isRecipientsEmpty()){
      this.formGroup.controls.recipientType.setValue(this.commonValues.api.organization);
      this.onRecipientValueChange(this.commonValues.api.organization);
    }
    const controls = this.formGroup.controls;

    // watch the `recipient` field because when a college is selected we want to hide/show form fields, and adjust required fields;
    controls.recipientType.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        this.onRecipientValueChange(value);
      });
      
      
  }

  onRecipientValueChange(value): void {
    const controls = this.formGroup.controls;
    
    const isCollege = value === this.values.college;
    const isOrganization = value === this.values.organization;
    const isBusiness = value === this.values.business;
    const isMe = value === this.values.me;

    if (value) {
      // clear all previously entered recipient data;
      // any previous data no longer applies since the user has started down a different recipient path;
      // pass true so the index is preserved;
      // true allows this recipient to be edited, without true, then the index is blown away, and a new recipient will be generated;
      this.dataService.resetRecipient(true);

      this.formGroup.clearValidators();
      // disable these fields no matter what the selected recipient type is;
      // they will be enabled based on other selected values;
      this.manageFields.disable([
        controls.oborDeliveryEligibility,
        controls.amcasTranscriptIdNumber,
        controls.aamcAccountNumber,
        controls.business,
        controls.casId,
        controls.country,
        controls.department,
        controls.departmentNotInList,
        controls.lsacAccountNumber,
        controls.organization,
        controls.organizationNotInList,
        controls.school,
        controls.schoolNotInList,
        controls.state,
        controls.emailPrimary,
        controls.emailConfirm,
        controls.matchedUboxEntry,
        controls.sendElectronically,
        controls.electronicDeliveryUnavailableFlag,
        controls.noDeliveryMethodAvailble
      ]);

      // null out any selected recipient;
      // this isn't a field visible to the user;
      // don't disable, just nullify;
      // if disabled, the value wouldn't be saved;
      controls.recipientResponse.setValue(null);

      // show the appropriate fields based on the newly selected recipient type;

      
        
      
      if (isCollege) {
        controls.country.setValue(this.commonValues.api.us);

        this.manageFields.enable([controls.country, controls.state]);
      }

      else if (isBusiness) {
        if ((this.isOnlyelectronicAvailable)){
          let errorTitle = this.content.noDeliveryAvailableTitle;  
          let errorDescription = this.content.noDeliveryAvailbleMethod
  
          this.content.errorTitle = errorTitle;
          this.content.errorDescription = errorDescription;
                
          controls.noDeliveryMethodAvailble.setValue(true);
              
          } else {
        this.manageFields.enable([controls.business]);
          }
      }

      else if (isOrganization) {
        if ((this.isOnlyelectronicAvailable)){
          let errorTitle = this.content.noDeliveryAvailableTitle;  
          let errorDescription = this.content.noDeliveryAvailbleMethod
  
          this.content.errorTitle = errorTitle;
          this.content.errorDescription = errorDescription;
                                  
          }
        this.manageFields.enable([controls.organization]);
      }

      else if(isMe) {

        if ((this.isOnlyelectronicAvailable)){
          let errorTitle = this.content.noDeliveryAvailableTitle;  
          let errorDescription = this.content.noDeliveryAvailbleMethod
  
          this.content.errorTitle = errorTitle;
          this.content.errorDescription = errorDescription;
                
          controls.noDeliveryMethodAvailble.setValue(true);
              
          }

      }
    }
  }
  initElectronicDeliveryUnavailableFlag(): void {
    const controls = this.formGroup.controls;
    controls.electronicDeliveryUnavailableFlag.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: Boolean) => {
          let errorTitle = this.content.electronicPDFNotAvailableTitle;
          let errorDescription = this.content.electronicPDFNotAvailable;
          let isOnlyElectronic = controls.isYesSendElectronically.value ? true : false;
          if (controls.electronicDeliveryUnavailableFlag.value && controls.sendElectronically.value === this.commonValues.api.no && !controls.isYesSendElectronically.value){
            errorTitle = this.content.onlyElectronicDeliveryMethodTitle;
            errorDescription = this.content.onlyElectronicDeliveryMethod;
            isOnlyElectronic = true;
          }
          this.content.errorTitle = errorTitle;
          this.content.errorDescription = errorDescription;
          this.isOnlyElectronicDeliveryMethods = isOnlyElectronic;
          controls.isYesSendElectronically.setValue(false);
      });
  }

  cancel(): void {
    if (this.data.form.recipients.length) {
      this.securityService.setDeactivateWarning(false);

      this.router.navigate([this.commonValues.routes.orderReview], this.commonValues.routes.extras);
    } else {
      this.securityService.cancelOrder();
    }
  }

  continue(): void {
    // When edit we need to clear out any previous content before saving to prevent orphaned content (like not in list values);
    this.dataService.resetRecipientSelect();
    let resetRecipientValues = false;
    const recipient = this.data.form.recipient;
    if (recipient.who.recipientType !== this.formGroup.value.recipientType) {
      resetRecipientValues = true;
    }
    else {
      // if we find any new value then we dont need to compare the rest
      // will be better if you use 'every' object method then we dont need to use break however there was some chalenges with nested object
      for (const key in this.formGroup.value) {
        if (typeof this.formGroup.value[key] !== 'object' && recipient.who.hasOwnProperty(key) && this.formGroup.value[key] !== recipient.who[key]) {
          resetRecipientValues = true;
          break;
        }
      }
    }
    // values in the recipient object to be resetted
    if (resetRecipientValues) {
      if (this.data.form.recipient.index != null) {
        this.dataService.resetRecipientSelectFromRecipients(this.data.form.recipient.index);
      }
      const deliveryControls = recipient.delivery;
      deliveryControls.howMany = null;
      deliveryControls.transcriptType = null;
      deliveryControls.transcriptPurpose = null;
      deliveryControls.transcriptWhen = null;
      this.dataService.save(deliveryControls, 'form.recipient.delivery');
    }

    this.dataService.save(this.formGroup.value, 'form.recipient.who');

    this.securityService.setDeactivateWarning(false);
    // double checking if 'AMCAS- LSAC - LIASON' are not passing null or empty values when field is visible and required
    const isNullOrganizationId = this.verifyNotNullOrganizationId();

    if (!isNullOrganizationId){
    this.router.navigate(
      [this.commonValues.routes.recipientDeliveryMethod],
      this.commonValues.routes.extras
    );
    }
  }

verifyNotNullOrganizationId(){
  // preventing organization recipient Id been saving null values for AMCAS,LSAC,LIASON
  let isNullOrganizationId = false;
  const controls = this.formGroup.controls;
  const recipientResponse = controls.recipientResponse;
  if (controls.organization.value && recipientResponse && recipientResponse.value && recipientResponse.value.subNetwork) {
    const subNetworkType =  recipientResponse.value.subNetwork.subNetworkType;
     if (subNetworkType && (subNetworkType === this.commonValues.api.amcas || subNetworkType === this.commonValues.api.lsac || subNetworkType === this.commonValues.api.liason)) {
      // find the input field value in order to reset after pop up close
      let subNetworkFormField;
       switch (subNetworkType) {
        case this.commonValues.api.amcas:
             subNetworkFormField = controls.aamcAccountNumber;
           break;
           case this.commonValues.api.lsac:
               subNetworkFormField = controls.lsacAccountNumber;
           break;
           case this.commonValues.api.liason :
               subNetworkFormField = controls.casId;
           break;
       }
        // change to true in order to test the popup system error for LSAC, AMCAS, LIASON
       if (!subNetworkFormField.value){
         this.loading = true;
         isNullOrganizationId = true;
         const dialogContent = this.commonValues.getServiceErrorMessage(subNetworkType);
         const dialogRef = this.dialog.open(DialogHelpComponent, {
           width: '480px',
           autoFocus:false,
           ariaLabelledBy: "modalNetworkTypeTitle",
           ariaDescribedBy: "modalNetworkTypeContent",
           data: {
             body: dialogContent,
             id:"modalNetworkType",
             title:"System Error"
           }
         });
         dialogRef.afterClosed().subscribe(result => {
           this.loading = false;
           subNetworkFormField.setValue(null);
         });
        }
     }
   }
   return isNullOrganizationId;
}

  onLoadingChange(value: boolean) {
    this.loading = value;
  }
}
