import { BehaviorSubject, Subject, merge } from 'rxjs';
import { Component, OnDestroy, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { cloneDeep as _cloneDeep, get as _get } from 'lodash';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ApiResponseOrder } from '../../interfaces/api-response-order.interface';
import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';
import { CommonValues } from '../../classes/common-values';
import { CountriesService } from '../../services/countries/countries.service';
import { DataService } from '../../services/data/data.service';
import { FormFieldInputComponent } from '../../components/form-field-input/form-field-input.component';
import { ManageFields } from '../../classes/manage-fields';
import { PostService } from '../../services/post/post.service';
import { SecurityService } from '../../services/security/security.service';
import { SelectOption } from '../../interfaces/select-option.interface';
import { SelectOptgroup } from '../../interfaces/select-optgroup.interface';
import { States } from '../../interfaces/states.interface';
import { StatesService } from '../../services/states/states.service';
import { TimeoutService } from '../../services/timeout/timeout.service';
import { ValidateMatch } from '../../validators/match.validator';
import { DynamicScriptLoaderService } from '../../services/script-loader/dynamic-script-loader.service';

@Component({
  selector: 'nsc-requestor-address',
  templateUrl: './requestor-address.component.html',
  styleUrls: ['./requestor-address.component.scss'],
  providers: [DynamicScriptLoaderService]
})
export class RequestorAddressComponent implements OnDestroy, OnInit {
  @ViewChild('phone') private phoneInput: FormFieldInputComponent;

  data = this.dataService.get();
  formGroup: FormGroup;
  unsubscribe$ = new Subject();
  postAuthorizationData: any;

  content = {
    hintPhone: this.commonValues.address.hintPhoneDomestic,
    hintPhoneDomestic: this.commonValues.address.hintPhoneDomestic,
    hintPhoneInternational: this.commonValues.address.hintPhoneInternational,

    hintZip: this.commonValues.address.hintZipDomestic,
    hintZipDomestic: this.commonValues.address.hintZipDomestic,
    hintZipInternational: this.commonValues.address.hintZipInternational,

    labelState: this.commonValues.address.labelStateUS,
    labelStateUS: this.commonValues.address.labelStateUS,
    labelStateCA: this.commonValues.address.labelStateCA,
    labelStateInternational: this.commonValues.address.labelStateInternational
  };

  masks = {
    phonemask: '(000) 000-0000',
  };

  show = {
    // updateRecords: _get(this.data.schoolProfile, 'askUpdateScRec')
    updateRecords: this.data.schoolProfile.askUpdateScRec !== null && this.data.schoolProfile.askUpdateScRec !== 'N'
  };

  values = {
    countries$: new BehaviorSubject(<SelectOption[]>[]),

    states$: new BehaviorSubject(<SelectOptgroup[]>[]),
    statesUS: <SelectOptgroup[]>[], // populated by the states API;
    statesCA: <SelectOptgroup[]>[], // populated by the states API;
    stateInternational: null, // to be defined with a value from the state API;

    validationPhoneDomestic: _cloneDeep(this.commonValues.address.validationPhoneDomestic),
    validationPhoneInternational: _cloneDeep(
      this.commonValues.address.validationPhoneInternational
    ),
    validationZipCA: this.commonValues.address.validationZipCA,
    validationZipUS: this.commonValues.address.validationZipUS,
    verifiedSamlStudent: false
  };

  constructor(
    private blockingIndicatorService: BlockingIndicatorService,
    private commonValues: CommonValues,
    private countriesService: CountriesService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private manageFields: ManageFields,
    private postService: PostService,
    private router: Router,
    private securityService: SecurityService,
    private statesService: StatesService,
    private timeoutService: TimeoutService,
    private dynamicScriptLoader: DynamicScriptLoaderService
  ) { }

  ngOnInit() {
    // default phone validation isn't required - but phone is required on this view;
    this.values.validationPhoneDomestic.push(Validators.required);
    this.values.validationPhoneInternational.push(Validators.required);

    this.initServiceValues();
    this.initForm();
    this.initIsDomestic();
    this.initMyHubUserAuthValues();
    
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  private loadPaymentScripts() {
    this.dynamicScriptLoader.load(this.dataService.get().propertyConfig.payeezyScriptToLoad, 'payment-helper').then(data => {
      // Script Loaded Successfully
    }).catch(error => console.log(error));
  }

  initServiceValues(): void {
    // get Ajax values for the SELECT fields;
    this.countriesService.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: SelectOption[]) => this.values.countries$.next(json));

    // break the states up by their country;
    this.statesService.data$.pipe(takeUntil(this.unsubscribe$)).subscribe((json: States) => {
      this.values.statesUS = json.us;
      this.values.statesCA = json.canada;
      this.values.stateInternational = json.international;

      // set default value;
      this.values.states$.next(this.values.statesUS);
    });
  }

  initForm(): void {
    const requestor = this.data.form.requestor.contact;

    const updateRecordsValue = this.show.updateRecords
      ? this.commonValues.api.yes
      : this.commonValues.nullDisabled;

    const textUpdatesValue = this.isDomestic(requestor.country)
      ? requestor.textUpdates
      : this.commonValues.nullDisabled;

    this.formGroup = this.formBuilder.group(
      {
        addressLine1: new FormControl(requestor.addressLine1, [Validators.required]),
        addressLine2: new FormControl(requestor.addressLine2),
        city: new FormControl(requestor.city, [Validators.required, Validators.minLength(2)]),
        state: new FormControl(requestor.state, [Validators.required]),
        zip: new FormControl(requestor.zip, this.values.validationZipUS),
        country: new FormControl(requestor.country, [Validators.required]),
        emailPrimary: new FormControl(requestor.emailPrimary, [
          Validators.required,
          Validators.pattern(this.commonValues.emailPattern)
        ]),
        emailConfirm: new FormControl(requestor.emailPrimary, [
          Validators.required,
          Validators.pattern(this.commonValues.emailPattern)
        ]),
        phone: new FormControl(requestor.phone, this.values.validationPhoneDomestic),
        textUpdates: new FormControl(textUpdatesValue, [Validators.required]),
        updateRecords: new FormControl(updateRecordsValue)
      },
      {
        // run custom validators;
        // make to make sure the confirm fields are equal;
        // and that we have a valid zip for the selected country;
        validator: [ValidateMatch('emailPrimary', 'emailConfirm')]
      }
    );

    // any change to the form means the user is actively using the app, so extend the system timeout;
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.timeoutService.startWarningCountdown();
    });
  }

  isDomestic(value: string): boolean {
    const isUS = value === this.commonValues.api.us;
    const isCA = value === this.commonValues.api.ca;

    return isUS || isCA;
  }

  initIsDomestic(): void {
    const controls = this.formGroup.controls;

    // watch the `country` field because when toggled we want to change content on other fields;
    this.formGroup.controls.country.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        const isUS = value === this.commonValues.api.us;
        const isDomestic = this.isDomestic(value);

        this.content.hintPhone = isDomestic
          ? this.content.hintPhoneDomestic
          : this.content.hintPhoneInternational;

        // configure phone;
        // domestic vs international formats;
        if (isDomestic) {
          controls.phone.setValidators(this.values.validationPhoneDomestic);
          this.phoneInput.mask = this.masks.phonemask;
        } else {
          controls.phone.setValidators(this.values.validationPhoneInternational);
          this.phoneInput.mask = false;
        }

        // validation rules may have changed, but we dont want to show a validation error;
        // remove any previously entered value, and reset the field;
        this.manageFields.reset([controls.phone]);

        // configure zip;
        // us & canda have different validations;
        if (isDomestic) {
          controls.zip.setValidators(
            isUS ? this.values.validationZipUS : this.values.validationZipCA
          );
          this.content.hintZip = this.content.hintZipDomestic;
        }
        // international has no zip validation;
        else {
          controls.zip.clearValidators();
          this.content.hintZip = this.content.hintZipInternational;
        }

        // validation rules may have changed, but we dont want to show a validation error;
        // remove any previously entered value, and reset the field;
        this.manageFields.reset([controls.zip, controls.state]);

        // configure states (labels and values) based on country;
        if (isDomestic) {
          if (isUS) {
            this.content.labelState = this.content.labelStateUS;
            this.values.states$.next(this.values.statesUS);
          } else {
            this.content.labelState = this.content.labelStateCA;
            this.values.states$.next(this.values.statesCA);
          }

          this.manageFields.enable([controls.state]);
        }
        // or disable the state field if international;
        else {
          this.content.labelState = this.content.labelStateInternational;

          this.manageFields.disable([controls.state]);
        }

        // configure texting question;
        if (isDomestic) {
          this.manageFields.enable([controls.textUpdates]);
        } else {
          this.manageFields.disable([controls.textUpdates]);
        }

      });
  }

  // If user is coming through MyHUb if available prepopulate the values
  initMyHubUserAuthValues(): void {
    // Populates the postAuthorization Data from the Authorization Service.
    this.postAuthorizationData = this.dataService.postAuthorizationData;
    if (this.postAuthorizationData && this.postAuthorizationData.appType !== this.commonValues.applicationType.studentSelfService) {
      const prepopulateValues  = this.postAuthorizationData.appType !== this.commonValues.applicationType.saml;
      this.values.verifiedSamlStudent = this.postAuthorizationData.appType === this.commonValues.applicationType.saml && this.dataService.isSamlStudentVerified() ? true : false;
      if (this.postAuthorizationData.street && prepopulateValues ) {
        const streetAddressString = this.postAuthorizationData.street.toString();
        if (streetAddressString.length > 30) {
          // if address length is >30 split into 2 strings of length 30
          // this is to accomodate field lengths in TS
          const address1 = streetAddressString.substring(0, 30);
          const address2 = streetAddressString.substring(30, 60);
          this.formGroup.controls['addressLine1'].setValue(address1);
          this.formGroup.controls['addressLine2'].setValue(address2);
        } else {
          this.formGroup.controls['addressLine1'].setValue(streetAddressString);
        }
      }
      if (this.postAuthorizationData.city && prepopulateValues) {
        this.formGroup.controls['city'].setValue(this.postAuthorizationData.city.toString().slice(0, 20));
      }
      if (this.postAuthorizationData.zipCode && prepopulateValues) {
        this.formGroup.controls['zip'].setValue(this.postAuthorizationData.zipCode);
      }
      if (this.postAuthorizationData.state && prepopulateValues) {
        this.formGroup.controls.state.setValue(this.postAuthorizationData.state.toString().toUpperCase());
      }
      if (this.postAuthorizationData.email && (prepopulateValues || this.values.verifiedSamlStudent)) {
        this.formGroup.controls['emailPrimary'].setValue(this.postAuthorizationData.email.toString().slice(0, 60) );
      }
      if (this.postAuthorizationData.email && prepopulateValues) {
        this.formGroup.controls['emailConfirm'].setValue(this.postAuthorizationData.email.toString().slice(0, 60));
      }
      if (this.postAuthorizationData.telephone && prepopulateValues) {
        this.formGroup.controls['phone'].setValue(this.postAuthorizationData.telephone.toString().slice(0, 20));
      }
    }
  }

  cancel(): void {
    this.securityService.cancelOrder();
  }

  continue(): void {
    this.dataService.resetRequestorAddress();
    // if the country is international and state and zip values has not been filled out then needs to be sent like below.
    if (this.formGroup.value.country !== this.commonValues.api.us && this.formGroup.value.country !== this.commonValues.api.ca) {
      if (!(this.formGroup.value.zip)) {
        this.formGroup.value.zip = this.commonValues.defaultZip;
      }
      if (!(this.formGroup.value.state)) {
        this.formGroup.value.state = this.values.stateInternational;
      }
    }
    // this will apply just for US-CA country since we dont have any validation for international phone number
    // checking if '(' is included on the phone  because if we edit the order , phone number is been already formatted so no need to format twice
    if (this.formGroup.value.country === this.commonValues.api.us || this.formGroup.value.country === this.commonValues.api.ca) {
      this.formGroup.value.phone = this.formGroup.value.phone.includes('(') ? ((this.formGroup.value.phone).replace(/[\)\s']+/g, '-')).substring(1) : this.formGroup.value.phone;
    }

    this.dataService.save(this.formGroup.value, 'form.requestor.contact');
    this.loadPaymentScripts();
    // determine what the next route is;
    const askDegreeQuestions = this.dataService.showDegreeQuestions();
    const askProgramQuestions = this.dataService.showProgramQuestions();
    const showAttendRoute = askDegreeQuestions || askProgramQuestions;
    const go = () => {
      const routeNext = showAttendRoute
        ? this.commonValues.routes.requestorAttend
        : this.commonValues.routes.recipientSelect;

      this.securityService.setDeactivateWarning(false);

      this.router.navigate([routeNext], this.commonValues.routes.extras);
    };

    // if the next route is the attend route, just go;
    // but if the next route is the recipient, then we need to post the collected student information;
    if (showAttendRoute) {
      go();
    } else {
      this.blockingIndicatorService.open();

      this.postService
        .student()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          window.setTimeout(() => {
            go();
          }, this.commonValues.loading.delay);
        });
    }
  }
}
