import { BehaviorSubject, Subject } from 'rxjs';
import { catchError ,  takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { sortBy as _sortBy } from 'lodash';

import { ApiResponseSchool } from '../../interfaces/api-response-school.interface';
import { AutocompleteOption } from '../../interfaces/autocomplete-option.interface';
import { SecurityService } from '../../services/security/security.service';

@Injectable()
export class SchoolsService implements OnDestroy {
  data$ = new BehaviorSubject(<AutocompleteOption[]>[]);
  unsubscribe$ = new Subject();

  constructor(private http: HttpClient, private securityService: SecurityService) {
    this.getData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this.http
      .get('api/tsorder/toscprofile')
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(error => this.securityService.catchResponse(error))
      )
      .subscribe((data: ApiResponseSchool[]) => {
        const sortedValues = _sortBy(data, 'name');

        this.data$.next(sortedValues);
      });
  }
}
