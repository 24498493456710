<div id="blockactivity" class="overlay loading" tabindex="0" role="alert" aria-live="assertive" aria-label="Loading...Please wait ">
  <div class="treecontainer">
    <img class="coinspin" src="../../assets/tree.svg" alt="Loading... Please wait"/>
  </div>
  <div class="letterrow" aria-hidden="true">
    <div class="letter letterfold" style="animation-delay: 0.0s;">L</div>
    <div class="letter letterfold" style="animation-delay: 0.1s;">o</div>
    <div class="letter letterfold" style="animation-delay: 0.2s;">a</div>
    <div class="letter letterfold" style="animation-delay: 0.3s;">d</div>
    <div class="letter letterfold" style="animation-delay: 0.4s;">i</div>
    <div class="letter letterfold" style="animation-delay: 0.5s;">n</div>
    <div class="letter letterfold" style="animation-delay: 0.6s;">g</div>
    <div class="letter letterfold" style="animation-delay: 0.7s;">.</div>
    <div class="letter letterfold" style="animation-delay: 0.8s;">.</div>
    <div class="letter letterfold" style="animation-delay: 0.9s;">.</div>
  </div>
</div>
