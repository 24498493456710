import { Component, Input, OnInit, ViewChild, ElementRef, QueryList, Output, EventEmitter } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter as _filter } from 'lodash';
import { fromEvent } from 'rxjs';

import { AutocompleteOption } from '../../interfaces/autocomplete-option.interface';
import { CommonValues } from '../../classes/common-values';
import { ValidationMessage } from '../../classes/validation-message';

@Component({
  selector: 'nsc-form-field-autocomplete',
  templateUrl: './form-field-autocomplete.component.html',
  styleUrls: ['./form-field-autocomplete.component.scss']
})
export class FormFieldAutocompleteComponent implements OnInit {
  @Input() controlName: string;
  @Input() group: FormGroup;
  @Input() hint: string;
  @Input() idx: string;
  @Input() notInList: boolean;
  @Input() options: AutocompleteOption[];
  @Input() placeholder: string;
  @Input() loading = false;
  @Input() searchType = this.commonValues.searchType.startsWithSearch;
  @Input() minCharactersToTrigger = 2;

  @Output() valueSelected = new EventEmitter<any>();

  @ViewChild('itemFilterInput',{ static:true}) itemFilterInput: ElementRef;
  @ViewChild('optionList', { read: ElementRef })
  optionList: ElementRef;

  focusTimeout: number;
  focusTimeoutDelay = 250;
  highlightedItem = 0;
  inputTimeout: number;
  inputTimeoutDelay: 300;
  // minCharactersToTrigger = 2;
  prevAriaText: string;
  show = {
    autocomplete: false
  };

  values = {
    autocomplete: [], // options to display to the user;
    hint: ''
  };

  constructor(private commonValues: CommonValues, private validationMessage: ValidationMessage) { }

  ngOnInit() {
    this.setHint(this.hint);
    this.configureAutocomplete();
  }

  setHint(hint: string, required?: boolean): void {
    // if a field isn't required and it's optional, we need to combine the `hint` values;
    this.values.hint = `${hint || ''}`;
  }

  configureAutocomplete(): void {
    fromEvent(this.itemFilterInput.nativeElement, 'keyup')
      .pipe(distinctUntilChanged())
      .subscribe((e: KeyboardEvent) => {
        this.keyUpHandler(e);
      });

  }

  showOptions(): void {
    // I removed 'debounce' from the Event Observable and I am replicating it's behavior
    // with this inputTimeout timer object
    window.clearTimeout(this.inputTimeout);
    const value = this.itemFilterInput.nativeElement.value;
    this.highlightedItem = 0;
    if (value && value.length >= this.minCharactersToTrigger) {
      this.values.autocomplete = _filter(this.options, (option: AutocompleteOption) => {
        // create a list where the entered value matchs something in the name;
        if (this.searchType === this.commonValues.searchType.wildCardSearch) {
          return option.schlName.toLowerCase().indexOf(value.toLowerCase()) > -1;
        } else if (this.searchType === this.commonValues.searchType.startsWithSearch) {
          return option.schlName.toLowerCase().indexOf(value.toLowerCase()) === 0;
        }
        return option.schlName.toLowerCase().indexOf(value.toLowerCase()) === 0;
      });

      // push the 'not in list' option;
      if (this.notInList) {
        this.values.autocomplete.push(this.commonValues.autocomplete.notInList);
      }

      let  injectSpacePoint = '.';
      if (this.prevAriaText){
        injectSpacePoint = this.prevAriaText.slice(-1) === '.' ? ' ' : injectSpacePoint;
      }
      const ariaText = `This is an auto complete element and there are currently ${this.values.autocomplete.length}  ${this.values.autocomplete.length === 1 ? `result` : `results`}  available. Keyboard users, use the up and down arrows to select an option${injectSpacePoint}`;
      this.itemFilterInput.nativeElement.setAttribute('aria-label', ariaText);
      this.prevAriaText = ariaText;
      if (!this.notInList && this.values.autocomplete.length === 0) {
        this.values.autocomplete = [this.commonValues.autocomplete.noMatch];
        this.itemFilterInput.nativeElement.setAttribute('aria-label', this.commonValues.autocomplete.noMatch.name );
      }

    this.show.autocomplete = true;
    }
    // if the form is less than X characters, show nothing - not even an error message;
    else {
      this.values.autocomplete = [];
    }
  }

  hideOptions(): void {
    this.show.autocomplete = false;
    this.highlightedItem = 0;
    this.itemFilterInput.nativeElement.removeAttribute('aria-label');
  }

  // whenever an option of the form field is focused, clear any existing timeout, and show the autocomplete options;
  // so if the form field already has content in it on focus, options are shown;
  onFocus(): void {
    window.clearTimeout(this.focusTimeout);
    this.showOptions();
    this.itemFilterInput.nativeElement.removeAttribute('aria-label');
  }

  // on leaving the input or option, start a timeout to hide the options;
  // we dont want to immediately hide the options because the user may be transitioning from the input to an option;
  // give the app time to manage that change in focus to options aren't hidden immediately on blur;
  onBlur(): void {
    this.focusTimeout = window.setTimeout(() => this.hideOptions(), this.focusTimeoutDelay);
  }

  // on select of an option, set the input value and hide the options (since we no longer need them);
  // use the blur event to hide so there is a slight delay (so it is't jarring to the user);
  selectOption(item): void {
    if (item) {
      // this.group.controls[this.controlName].setValue(item.schlName);
      this.onBlur();
      this.valueSelected.emit(item.schlName);
    }
  }

  // hovering on an option needs to overwrite whatever item was selected with the arrow keys
  optionHoverHandler(e, i): void {
    this.highlightedItem = i;
    this.itemFilterInput.nativeElement.setAttribute('aria-label', this.values.autocomplete[i].schlName + ' not selected. ' + (i + 1) + ' of ' + this.values.autocomplete.length);
  }

  // sets the highlighted state for options
  optionClass(i): string {
    if (i === this.highlightedItem) {
      return 'mat-selected';
    }
    return '';
  }

  // These two methods control scrolling. To make the scrolling work as expected, it gets a bit more
  // complicated than expected.
  scrollUpToOption(i): void {
    const itemHeight = this.optionList.nativeElement.firstElementChild.offsetHeight;
    const oldScroll = this.optionList.nativeElement.scrollTop;
    const newScroll = i * itemHeight;

    this.optionList.nativeElement.scrollTop = oldScroll > newScroll ? newScroll : oldScroll;
  }

  scrollDownToOption(i): void {
    const maxHeight = this.optionList.nativeElement.offsetHeight;
    const itemHeight = this.optionList.nativeElement.firstElementChild.offsetHeight;
    const oldScroll = this.optionList.nativeElement.scrollTop;
    const newScroll = (i - Math.floor(maxHeight / itemHeight) + 1) * itemHeight;

    this.optionList.nativeElement.scrollTop = oldScroll < newScroll ? newScroll : oldScroll;
  }

  // catch keyup events from the input field
  keyUpHandler(e): void {
    if (this.show.autocomplete && this.values.autocomplete.length >= 1) {
      if (e.code === 'ArrowDown' || e.key === 'Down') {
        if (this.highlightedItem < this.values.autocomplete.length - 1) {
          this.highlightedItem++;
          this.itemFilterInput.nativeElement.setAttribute('aria-label', this.values.autocomplete[this.highlightedItem].schlName + ' not selected. ' + (this.highlightedItem + 1) + ' of ' + this.values.autocomplete.length);
          if (this.highlightedItem > 4) {
            //window.setTimeout(() => this.scrollDownToOption(this.highlightedItem), 200);
          }
        }
      } else if (e.code === 'ArrowUp' || e.key === 'Up') {
        if (this.highlightedItem > 0) {
          this.highlightedItem--;
          this.itemFilterInput.nativeElement.setAttribute('aria-label', this.values.autocomplete[this.highlightedItem].schlName + ' not selected. ' + (this.highlightedItem + 1) + ' of ' + this.values.autocomplete.length);
          if (this.highlightedItem < this.values.autocomplete.length - 5) {
            //window.setTimeout(() => this.scrollUpToOption(this.highlightedItem), 200);
          }
        }
      } else if (e.code === 'Enter' || e.key === 'Enter') {
        this.selectOption(this.values.autocomplete[this.highlightedItem]);
        this.itemFilterInput.nativeElement.setAttribute('aria-label', this.values.autocomplete[this.highlightedItem].schlName + ' selected. ');
      } else {
        this.inputTimeout = window.setTimeout(() => this.showOptions(), this.inputTimeoutDelay);
      }
    } else {
      this.inputTimeout = window.setTimeout(() => this.showOptions(), this.inputTimeoutDelay);
    }
  }
}
