import { AbstractControl } from '@angular/forms';
import { isEmpty as _isEmpty } from 'lodash';


export function ValidateStudentIdPrimary(studenIdControlName,stIdMaxLength, stIdMinLength?) {
    const maxLength = stIdMinLength === stIdMaxLength ? 0: stIdMaxLength;
    return (control: AbstractControl) => {
        let errorsMin = null;
        // verify if there is errors to be validated
        if (control.errors) {
            // just execute if minlength or when the match is true
          
            if (control.errors.minlength) {
                if (control.value && control.value.length < control.errors.minlength.requiredLength) {
                    errorsMin = true;
                }
                else {
                    delete control.errors.studentIdPrimary;
                    delete control.errors.maxlength;
                }
            }
            else if (control.errors.match) {
                if (control.parent.value.studentIdPrimary.length === control.parent.value.studentIdConfirm.length) {
                    delete control.errors.studentIdPrimary;
                    delete control.errors.maxlength;
                }else{
                    if(control.parent.value.studentIdPrimary.length < stIdMinLength || control.parent.value.studentIdConfirm.length < stIdMinLength){
                      errorsMin = true;
                    }
                }
            }
            else if(control.value < stIdMinLength){
                      const studentId = control.parent.controls['studentIdPrimary'];
                const studentIdConfirm =control.parent.controls['studentIdConfirm'];
               if( (studentId.value && studentId.value.length < stIdMinLength ) || (studentIdConfirm.value && studentIdConfirm.value.length < stIdMinLength)){
              
                if(studentId.errors){
                    studentId.errors.studentIdPrimary = true;
                    studentId.errors.maxlength = maxLength;
                    studentId.errors.minlength ={
                        requiredLength: stIdMinLength,
                        actualLength: studentId.value ? studentId.value.length : 0
                    }
                }
                if(studentIdConfirm.errors){
                    studentIdConfirm.errors.studentIdPrimary = true;
                    studentIdConfirm.errors.maxlength = maxLength;
                    studentIdConfirm.errors.minlength ={
                        requiredLength: stIdMinLength,
                        actualLength: studentIdConfirm.value ? studentIdConfirm.value.length : 0
                    }
                }
                errorsMin = true;
            }
            else {
                delete control.errors.studentIdPrimary;
                delete control.errors.maxlength;
            }
        }
            if (errorsMin) {
                return { 
                    studentIdPrimary: true,
                    maxlength: maxLength,
                    minlength :{
                        requiredLength: stIdMinLength,
                        actualLength: control.value ? control.value.length : 0
                    }
                };
            }
        }
        else {
            // when the field has been validated the minglenght and then we press backspace key  errors are comming as null
            // so compare the new studentField value with the previous value saved in the parent form to make sure the validation for minlength is correct
            if (control.parent) {
                if (control.parent.value.studentIdPrimary && control.parent.value.studentIdConfirm) {
                    return ( control.value.length >= stIdMinLength) ? null : { studentIdPrimary: true ,maxlength: maxLength, minlength :{
                        requiredLength: stIdMinLength,
                        actualLength: control.value ? control.value.length : 0
                    }};
                }
            }
        }
        return null;
    };
}
