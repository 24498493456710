import { Injectable } from '@angular/core';
import { SecurityService } from '../security/security.service';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StudentAuthorizationService {

  constructor(private http: HttpClient,
    private securityService: SecurityService)
  {}

   postAuthorize(postdata: any){
    const url = '/api/tsorder/authorize';
    return this.http.post(url, postdata).pipe(catchError(error => this.securityService.catchResponse(error)));
    }

  }
