import { Component, Input, Output , EventEmitter } from '@angular/core';

import { ApiResponseFeesSummary } from '../../../interfaces/api-response-fees.interface';
// import { EventEmitter } from 'events';

@Component({
  selector: 'nsc-recipient-delivery-method-fees',
  templateUrl: './recipient-delivery-method-fees.component.html',
  styleUrls: ['./recipient-delivery-method-fees.component.scss']
})
export class RecipientDeliveryMethodFeesComponent {
  @Input() fees: ApiResponseFeesSummary;
  @Input() isTNSchool: boolean;
  @Input() isSPDESchool: boolean;
  @Input() isFASTSchool: boolean;
  @Output() showTNFeeDetails = new EventEmitter();
  @Output() showPartnerNetworkInfo = new EventEmitter();

  constructor() {
  }

  // trigget pop up parent event in order to see TNFee details message
  viewTotalFeeTNDetails(){
    if (this.isSPDESchool || this.isFASTSchool) {
      this.showPartnerNetworkInfo.emit();
    } else {
    this.showTNFeeDetails.emit();
  }
}
}
