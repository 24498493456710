import {ApiResponsePaymentConfig} from '../interfaces/api-response-payment-config.interface';
import { Injectable } from "@angular/core";


export interface PayeezyFormData {
   // ccType: string;
  // ccName: string;
  // ccNumber: string;
  // ccDate: string;
  // ccCvv: string;
  city: string;
  country: string;
  street: string;
  state: string;
  zip: string;
}

@Injectable()
export class Payeezy {

  private apiKey;
  private securityKey;
  private subdomain;
  private transarmorToken;

  getToken(production: boolean, apiResponsePaymentConfig$: ApiResponsePaymentConfig, formData: PayeezyFormData, callback: any): void {

      if (production)
      {
        this.apiKey = apiResponsePaymentConfig$.payeezyApiKeyProd;
        this.securityKey = apiResponsePaymentConfig$.payeezySecurityProd;
        this.subdomain = apiResponsePaymentConfig$.payeezySubdomainProd;
        this.transarmorToken = apiResponsePaymentConfig$.payeezyTaTokenProd;
      }
      else
      {
        this.apiKey = apiResponsePaymentConfig$.payeezyApiKeyTest;
        this.securityKey = apiResponsePaymentConfig$.payeezySecurityTest;
        this.subdomain = apiResponsePaymentConfig$.payeezySubdomainTest;
        this.transarmorToken = apiResponsePaymentConfig$.payeezyTaTokenTest;
      }


    // set the callback as a global property;
    // this is because the 3rd party Payeezy library will be calling the function, it can't be scoped;
    (<any>window).payeezyCallback = callback;

    // set the data to be set to Payeezy;
    // this will be converted into a query string to be appended to the SRC property of the Payeezy SCRIPT;
    const params = {
      apikey: this.apiKey,
      js_security_key: this.securityKey,
      callback: 'window.payeezyCallback',
      auth: true,
      ta_token: this.transarmorToken,
      type: 'FDToken',
     // 'credit_card.type': formData.ccType,
      // 'credit_card.cardholder_name': formData.ccName,
      // 'credit_card.card_number': formData.ccNumber,
      // 'credit_card.exp_date': formData.ccDate,
      // 'credit_card.cvv': formData.ccCvv,
      currency: 'undefined',
      'billing_address.city': formData.city,
      'billing_address.country': formData.country,
      'billing_address.email': 'undefined',
      'billing_address.phone.type': 'undefined',
      'billing_address.phone.number': 'undefined',
      'billing_address.street': formData.street,
      'billing_address.state_province': formData.state,
      'billing_address.zip_postal_code': formData.zip
    };

    // append the data to the SCRIPT tag, and then insert the SCRIPT into the DOM;
    const script = document.createElement('script');
    const subdomain = this.subdomain;

    script.src = `https://${subdomain}.payeezy.com/v1/securitytokens?${this.serialize(params)}`;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  // cf. https://stackoverflow.com/questions/1714786/query-string-encoding-of-a-javascript-object
  serialize(obj, prefix?): string {
    const str = [];
    let p;

    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + '[' + p + ']' : p;
        const v = obj[p];

        str.push(v !== null && typeof v === 'object' ? this.serialize(v, k) : k + '=' + v);
      }
    }

    return str.join('&');
  }
}
