<mat-form-field appearance="outline"
  [hideRequiredMarker]="true"
  [ngClass]="loading ? 'nsc-form-field-loading' : ''"
  [formGroup]="group"
  class="nsc-form-field-autocomplete">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    id="{{ idx }}"
    #itemFilterInput
    matInput
    aria-label="school"
    formControlName="{{ controlName }}"
    type="text"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [matAutocomplete]="auto"> 
    <div></div>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option
      *ngFor="let option of values.autocomplete; let i = index;"
      id="{{idx}}_{{i}}"
      [disabled]="option.disabled"
      [ngClass]="optionClass(i)"
      [value]="option.schlName"
      (click)="!option.disabled && selectOption(option)"
      (mouseenter)="optionHoverHandler($event, i)">
      {{ option.schlName }}
     </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="group.controls[controlName].invalid" [attr.aria-label]="'Error' + values.hint + validationMessage.get(group.controls[controlName], placeholder) ">Error: {{ validationMessage.get(group.controls[controlName], placeholder) }}</mat-error>
</mat-form-field>
