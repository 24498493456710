<div *ngIf="content.errors.length" role="alert">
  <nsc-card [error]="true">
    <nsc-card-title-error title="{{ content.errorTitle }}" icon="warning">
      <p>
        {{content.errorBody}}
        </p>
    </nsc-card-title-error>
    <!-- <nsc-card-content>
      <div >
        
      </div>
    </nsc-card-content> -->
  </nsc-card>

  <nsc-spacer size="sm"></nsc-spacer>
</div>
<form id="form" [formGroup]="formGroup">
  <nsc-card>
    <nsc-card-title note="All fields required, unless otherwise indicated">Payment Details</nsc-card-title>
    <nsc-card-content>
      <div class="row">
        <div class="col-12">
           
            <h3 class="mat-body-2 mat-hint payment-margin-bottom">Accepted Credit Cards:</h3>
           <br />
           <ul class="nsc-listitem">
             <li>
               <img class="cc-icon" src="/assets/credit-card-logo--visa.svg" alt="Visa">
             </li>
             
             <li>
               <img class="cc-icon" src="/assets/credit-card-logo--mastercard.svg" alt="Mastercard">
             </li>
            
             <li>
               <img class="cc-icon" src="/assets/credit-card-logo--amex.svg" alt="American Express">
             </li>
             
             <li>
               <img class="cc-icon" src="/assets/credit-card-logo--discover.svg" alt="Discover">
             </li>
           </ul>

          <nsc-spacer size="sm"></nsc-spacer>
        </div>
        <div class="col-12 col-md-4 payment-fields_wrapper">
          <label for="first-data-payment-field-name" class="control-label">Card Holder Name</label>
          <div class="form-controls payment-fields disabled" id="cc-name" data-cc-name></div>
          <div aria-live="assertive" aria-relevant="additions removals">
            <span id="data-cc-name__error"
              class="nsc-error nsc-error--hide"></span>
          </div>
        </div>

        <div class="col-12 col-md-4 payment-fields_wrapper">
        
          <label for="first-data-payment-field-card" class="control-label">Card Number </label>
          <div class="form-controls payment-fields disabled empty" id="cc-card" data-cc-card></div>
          <div aria-live="assertive" aria-relevant="additions removals">
            <span id="data-cc-card__error"
              class="nsc-error nsc-error--hide"></span>
          </div>
        </div>

        <div class="col-12 col-md-4"></div>

        <div class="col-12 col-md-4 payment-fields_wrapper">
          <label for="first-data-payment-field-exp" class="control-label">Expiration Date</label>
          <div class="form-controls payment-fields disabled empty" id="cc-exp" data-cc-exp #expDate></div>
          <div aria-live="assertive" aria-relevant="additions removals">
            <span id="data-cc-exp__error"
              class="mat-error nsc-error nsc-error--hide"></span>
          </div>
        </div>

        <div class="col-12 col-md-4 payment-fields_wrapper">
          <label for="first-data-payment-field-cvv" class="control-label">CVV</label>
          <div class="form-controls payment-fields disabled empty" id="cc-cvv" data-cc-cvv></div>
          <div aria-live="assertive" aria-relevant="additions removals">
            <span id="data-cc-cvv__error" class="nsc-error nsc-error--hide"></span>
          </div>
        </div>
        <div class="col-12 col-md-4"></div>

        <div class="col-12 col-md-8">
          <nsc-form-field-toggle idx="use_contactinfo" controlName="useContactInfo"
            label="Do you want to use your contact address as your billing address?" [group]="formGroup">
          </nsc-form-field-toggle>
        </div>

        <div class="col-12 col-md-8">
          <nsc-form-field-input autocomplete="shipping address-line1" controlName="addressLine1" idx="addressLine1"
            hint="Street number and name or PO Box" maxlength="30" placeholder="Address 1" [group]="formGroup">
          </nsc-form-field-input>
        </div>

        <div class="col-12 col-md-8">
          <nsc-form-field-input autocomplete="shipping address-line2" controlName="addressLine2" idx="addressLine2"
            hint="Building, campus box, floor, apt, suite" maxlength="30" placeholder="Address 2" [group]="formGroup"
            [required]="false"></nsc-form-field-input>
        </div>
        <div class="col-12 col-md-4">
        </div>

        <div class="col-12 col-md-4">
          <nsc-form-field-input autocomplete="shipping address-level2" controlName="city" maxlength="30" idx="city"
            placeholder="City" [group]="formGroup"></nsc-form-field-input>
        </div>
        <div class="col-12 col-md-4">
          <nsc-form-field-select-optgroup controlName="state" placeholder="{{ content.labelState }}" idx="state" [group]="formGroup"
            [selectObjects]="values.states$.value"></nsc-form-field-select-optgroup>
        </div>

        <div class="col-12 col-md-4">
        </div>
        <div class="col-6 col-md-4">
          <nsc-form-field-input autocomplete="shipping postal-code" idx="zipCode" controlName="zip" hint="{{ content.hintZip }}"
            maxlength="15" placeholder="Zip/Postal Code" [group]="formGroup"></nsc-form-field-input>
        </div>
        <div class="col-6 col-md-4">
          <nsc-form-field-select controlName="country" placeholder="Country" [group]="formGroup" idx="country"
            [selectObjects]="values.countries$.value"></nsc-form-field-select>
        </div>

        <div class="col-12">
          <div class="mat-caption">
            Selecting 'Submit Order' will transmit your payment information to
            <a href="https://www.firstdata.com/en_us/home.html" rel="noopener" target="_blank" id="FirstDataCorp"
              aria-label="First Data Corp">First Data Corp</a>,
            a third party payment processing provider. First Data will only share your name, address, or e-mail address
            with National Student Clearinghouse.
          </div>
        </div>
      </div>
    </nsc-card-content>
  </nsc-card>

  <nsc-spacer size="sm"></nsc-spacer>
  <div class="col-12" style="text-align: center;">
    <p class="nsc_total_fee">
      <strong> Total Fees for Order: {{ content.totalFees | currency }} </strong>
    </p>
  </div>

  <nsc-spacer size="sm"></nsc-spacer>



  <div class="nsc-form-actions">

    <button mat-stroked-button id="orderpayment_cancel" class="nsc-form-actions__button button-big" color="primary" type="button"
      (click)="cancel()">Cancel Order</button>
    <nsc-spacer horizontal="true" size="sm"></nsc-spacer>

    <button id="orderpayment_submitorder" color="primary" type="submit" mat-raised-button class="nsc-form-actions__button  nsc-btn__right button-big"
      data-submit-btn   >
      Submit Order <mat-icon aria-hidden="true" >chevron_right</mat-icon><span
        data-card-type></span>
    </button>
  </div>

</form>
