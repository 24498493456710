import { Component, OnInit } from '@angular/core';

import { SystemMessagesService } from '../../services/system-messages/system-messages.service';

@Component({
  selector: 'nsc-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.scss']
})
export class SystemMessageComponent implements OnInit {
  constructor(public systemMessages: SystemMessagesService) {}

  ngOnInit() {
    this.systemMessages.getData();
  }
}
