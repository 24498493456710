<div *ngIf="show.stepper">
  <mat-horizontal-stepper labelPosition="bottom" [linear]="isLinear" #stepper>
    <mat-step [completed]="content.step1StatusComplete">
      <ng-template matStepLabel>Enter Personal Information</ng-template>
    </mat-step>
    
    <mat-step [completed]="content.step2StatusComplete">
      <ng-template matStepLabel>Select Transcript and Delivery Details</ng-template>
    </mat-step> 
     
    <mat-step [completed]="content.step3StatusComplete">
      <ng-template matStepLabel>Confirm Order and Checkout</ng-template>
    </mat-step>
  </mat-horizontal-stepper>
</div>
