import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest ,HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY , throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';



import { DataService } from '../services/data/data.service';
import { CommonValues } from '../classes/common-values';
import { Router } from '@angular/router';

// cf. https://offering.solutions/blog/articles/2017/07/19/angular-2-new-http-interface-with-interceptors/
// cf. https://stackoverflow.com/questions/47609094/angular-5-httpinterceptor-this-interceptor-intercept-is-not-a-function
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private dataService: DataService,
    private commonValues: CommonValues , private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.includes('smartystreets.com')){
      return next.handle(req);
    }

    const jsonWebToken = this.dataService.get().jsonWebToken;

    const requestClone = req.clone({
      headers: req.headers.set('Authorization', jsonWebToken)
    });

    return next.handle(jsonWebToken ? requestClone : req).pipe(

        catchError((error: HttpErrorResponse) => {

          const responseUrl = error.url;
          const responseStatus = error.status;

          if (!(error.error instanceof ErrorEvent)) {
            if ((responseUrl.includes('api/tsorder/toscprofile') || responseUrl.includes('api/tsorder/toscprofiledetail') || responseUrl.includes('api/tsorder/system/property/config') ||
              responseUrl.includes('api/tsorder/authorize') || responseUrl.includes('api/tsorder/system-config/state')) &&
              this.commonValues.httpStatusCodesSystemOutage.indexOf(responseStatus) !== -1){             
              this.router.navigate([this.commonValues.routes.outageMessage]);
              return EMPTY;
            }
          }
          return throwError(error);
        })
      );
  }
}
