import * as moment from 'moment';
import { AbstractControl } from '@angular/forms';
import { CommonValues } from '../classes/common-values';

export function ValidateDateOfBirth(abstractControl: AbstractControl) {
  const commonValues = new CommonValues();

  if (abstractControl.value) {
    const dateFormat = commonValues.dateFormat.input;
    const yearModifier = 11;
    const momentDate = moment(abstractControl.value, dateFormat);
    const today = new Date();
    const dateRangePast = moment(today).subtract(120, 'years').format(dateFormat);
    const dateRangeFuture = moment(today).year(today.getFullYear() - yearModifier);
    let errors = false;

    // moment will allow a single slash and figure things out, but that's not allowed per the reqs;
    // reqs allow two different formats, so check for both;
    if (abstractControl.value.length === 10) {
      // is date valid;
      if (momentDate.isValid()) {
        // is date less than 1930;
        if (momentDate.isBefore(dateRangePast)) {
          errors = true;
        }
        // is date less greater than now - 11 years;
        else if (momentDate.isAfter(dateRangeFuture)) {
          errors = true;
        }
      }
      // otherwise invalid;
      else {
        errors = true;
      }
    }
    // otherwise invalid;
    else {
      errors = true;
    }

    if (errors) {
      return {
        dateOfBirth: true
      };
    }
  }
}
