import * as moment from 'moment';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiPostFees } from '../../interfaces/api-post-fees.interface';
import { ApiResponseFees } from '../../interfaces/api-response-fees.interface';
import { CommonValues } from '../../classes/common-values';
import { SecurityService } from '../../services/security/security.service';
import { DataService } from '../data/data.service';

@Injectable()
export class FeesService {
  constructor(
    private commonValues: CommonValues,
    private dataService: DataService,
    private http: HttpClient,
    private securityService: SecurityService
  ) {}

  get(postData: ApiPostFees): Observable<ApiResponseFees> {
    const orderId = this.dataService.get().response.orderHeader.toOrderId;
    return this.http
      .post<ApiResponseFees>(`/api/tsorder/recipient/preprocess/${orderId}`, postData)
      .pipe(catchError(error => this.securityService.catchResponse(error)));
  }
}
