import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AutocompleteOption } from '../../interfaces/autocomplete-option.interface';
import { BlockingIndicatorService } from '../../services/blocking-indicator/blocking-indicator.service';
import { CommonValues } from '../../classes/common-values';
import { DataService } from '../../services/data/data.service';
import { SchoolService } from '../../services/school/school.service';
import { SchoolsService } from '../../services/schools/schools.service';
import { SystemMessagesService } from '../../services/system-messages/system-messages.service';
import { filter as _filter } from 'lodash';
import { PropertyConfigService } from '../../services/property/property-config.service';
import { ApiResponsePropertyConfig } from '../../interfaces/api-response-property-config.interface';



@Component({
  selector: 'nsc-school-ficecode',
  templateUrl: './school-ficecode.component.html',
  styleUrls: ['./school-ficecode.component.scss']
})
export class SchoolFiceCodeComponent implements OnDestroy, OnInit {
  unsubscribe$ = new Subject();

  values = {
    schools$: new BehaviorSubject(<AutocompleteOption[]>[]) // complete list for autocomplete to filter down;
  };


  constructor(
    private activatedRoute: ActivatedRoute,
    private commonValues: CommonValues,
    private blockingIndicatorService: BlockingIndicatorService,
    private router: Router,
    private schoolService: SchoolService,
    private schoolsService: SchoolsService,
    private systemMessagesService: SystemMessagesService,
    private propertyConfigService: PropertyConfigService,
    private dataService: DataService,
    ) { }

  ngOnInit() {
    // enable progress bar, it will be disabled after route change;
    this.blockingIndicatorService.open();
    this.initServiceValues();
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      this.schoolService
      .get(params.ficeCode)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if(this.dataService.isSchoolNull()) {

          this.dataService.showSystemMessage = true;

          window.setTimeout(() => {
            this.router.navigate(
              [this.commonValues.routes.schoolSelect],
              this.commonValues.routes.extras
            );
          }, this.commonValues.loading.delay);
        } else {  
          const uiVersion = this.dataService.data.schoolProfile.uiVersion;

          if (uiVersion && uiVersion !== '2.0') {
            const ficeCode = this.dataService.data.schoolProfile.ficeCode;
            const legacySchoolWelcomeURL =  this.dataService.get().propertyConfig.legacySchoolWelcomeURL;
            const url = legacySchoolWelcomeURL + `?ficecode=${ficeCode}`;
            window.location.href = url;
          } else {
            // force a small delay;
              window.setTimeout(() => {
                this.router.navigate([this.commonValues.routes.schoolWelcome]);
            }, this.commonValues.loading.delay);
          }
        }
      });
    });
  }

  initServiceValues(): void {
    this.propertyConfigService.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: ApiResponsePropertyConfig) => {
        if (json)
        {
        this.dataService.save({
          propertyConfig: json
        });
      }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.blockingIndicatorService.close();
  }
}
