
<div >
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="row nsc-row--12">
          <div class="col-auto" *ngIf="editRecipient.observers.length">
            <button attr.aria-label="Edit Recipient: {{content.recipient}}" class="nsc-mat-button" mat-button (click)="edit()"><mat-icon aria-hidden="true">edit</mat-icon> Edit</button>
          </div>
          <div class="col-auto" *ngIf="deleteRecipient.observers.length">
            <button attr.aria-label="Remove Recipient: {{content.recipient}}" class="nsc-mat-button" mat-button (click)="delete()"> <mat-icon aria-hidden="true">delete</mat-icon> Remove</button>
          </div>
      </div>
    </div>
    <div class="col-12 col-lg-6" *ngIf="recipient.fees.totalFee !== 0 || ((isTNSchool || isSPDESchool || isFASTSchool) && recipient.fees.totalFee === 0)"> 
       <div class="row nsc-recipient-details__total-fee nsc-recipient-details__content">
       <div class="col-6">
            <!-- <strong>{{ recipient.fees.totalFee.description }}:</strong> -->
            <strong>Total Fee for this Recipient:</strong>
        </div>
        <div class="col-6">
          {{ recipient.fees.totalFee | currency }}
          <button (click)="viewTotalFeeDetails()" mat-icon-button aria-label="info" *ngIf="((isTNSchool && recipient.fees.totalFee === 0)|| isSPDESchool || isFASTSchool)">
            <mat-icon>info</mat-icon>
          </button>
        </div>
    </div>
    </div>
 </div>

  <nsc-spacer></nsc-spacer>

  <div class="row nsc-recipient-details__content">
    <div class="col-12 col-lg-6">
      <div>
        <strong>Recipient: </strong>{{ this.content.recipient }}
      </div>
      <div *ngIf="isOther()">
        <span *ngIf="recipient.address.attention" style="display: block;">
            <strong>Attention: </strong>{{ recipient.address.attention }}
        </span>
        <strong>Recipient Address:</strong>
        <div class="nsc-recipient-details__address">
          {{ recipient.address.addressLine1 }}<br>
          {{ recipient.address.addressLine2 }}<br *ngIf="recipient.address.addressLine2">
          {{ recipient.address.city }},<span *ngIf="recipient.address.state !='ZZ'"> {{ recipient.address.state }} </span> <span *ngIf="recipient.address.zip !='N/A'">{{ recipient.address.zip }} </span>
          <div *ngIf="recipient.address.country !== 'US'">{{ getFullCountryName(recipient.address.country) | uppercase}}</div>
        </div>
      </div>

      <div *ngIf="isElectronicPDF()">
        <strong>Email: </strong>{{ recipient.address.emailPrimary || recipient.who.emailPrimary }}
      </div>

      <div *ngIf="isETX() && !isCentralInbox() && !isAttentionNotVisible()">
        <strong>Attention: </strong>{{ content.etxDepartmentName || recipient.address.attention }}
      </div>

      <div *ngIf="isCentralInbox() && !isAttentionNotVisible()">
        <strong>Attention: </strong>{{ recipient.address.emailPrimary || recipient.who.emailPrimary }}
      </div>

    </div>
    <div class="col-12 col-lg-6" >
      <div class="d-lg-none">
        <nsc-spacer></nsc-spacer>
      </div>

      <div class="row" *ngIf="content.processingOption">
        <div class="col-6">
          <strong>Processing Option:</strong>
        </div>
        <div class="col-6">
          {{ content.processingOption.description }}
        </div>
      </div>

      <div class="row" *ngIf="recipient.fees.transcriptTypeFee !== 0">
          <div class="col-6">
            <strong>Transcript Type:</strong>
          </div>
          <div class="col-6">
            {{ content.transcriptType.display }}
          </div>
        </div>

      <div class="row nsc-recipient-details__delivery-method" *ngIf="content.deliveryMethod">
        <div class="col-6 nsc-recipient-details__delivery-method--name">
          <strong>Delivery Method:</strong>
        </div>
        <div class="col-6 ">
          <div class="nsc-recipient-details__delivery-method--content">
            <span>{{ content.deliveryMethodDisplay }}</span>
            <button (click)="viewDetails()" mat-icon-button aria-label="info">
              <mat-icon>info</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="content.quantity">
        <div class="col-6">
          <strong>Quantity:</strong>
        </div>
        <div class="col-6">
          {{ content.quantity.displayWithFee.split('=')[0].toString() }}
        </div>
      </div>

      <div class="row" *ngIf="recipient.fees.transcriptFee !== 0">
        <div class="col-6">
          
              <strong>Transcript Quantity Fee:</strong>
        </div>
        <div class="col-6">
          {{ recipient.fees.transcriptFee| currency }}
        </div>
      </div>

      <div class="row" *ngIf="recipient.fees.deliveryFee !== 0">
        <div class="col-6">
          <strong>{{ recipient.fees.deliveryFeeDescription}}:</strong>

        </div>
        <div class="col-6">
            {{ recipient.fees.deliveryFee | currency }}
        </div>
      </div>

      <div class="row" *ngIf="recipient.fees.transcriptTypeFee !== 0 ">
        <div class="col-6">
              <strong>Transcript Type Fee:</strong>
        </div>
        <div class="col-6">
          {{ recipient.fees.transcriptTypeFee | currency }}
        </div>
      </div>

      <div class="row" *ngIf="recipient.fees.shippingAndHandlingFee !== 0">
        <div class="col-6">
           <strong>Shipping and Handling Fee:</strong>
        </div>
        <div class="col-6">
          {{ recipient.fees.shippingAndHandlingFee | currency }}
        </div>
      </div>

      <div class="row" *ngIf="recipient.fees.onlineProcessingFee !== 0">
        <div class="col-6">
              <strong>Online Processing Fee:</strong>
        </div>
        <div class="col-6">
          {{ recipient.fees.onlineProcessingFee | currency }}
        </div>
      </div>

      <div class="row" *ngIf="isAttachmentAvailable()">
          <div class="col-6">
              <strong>Documents Uploaded:</strong>
          </div>
          <div class="col-6"></div>
       </div>
       <div *ngIf="isAttachmentAvailable()">
          <div class="row" *ngFor="let attachment of content.attachments; let i = index">
              <div class="col-12" *ngIf="attachment.originalFileName">
                  {{ attachment.originalFileName }}
              </div>
              <div class="col-12" *ngIf="attachment.attachmentResponse">
                  {{ attachment.attachmentResponse.originalFileName }}  
              </div>
          </div>
          
      </div>
    </div>
  </div>
</div>
