import { BehaviorSubject, Subject } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { sortBy as _sortBy, isEmpty as _isEmpty, filter as _filter, cloneDeep as _cloneDeep } from 'lodash';
import { takeUntil } from 'rxjs/operators';

import {
  ApiResponseRecipientProfile,
  ApiResponseRecipientDetails
} from '../../../interfaces/api-response-recipient-profile.interface';
import { BlockingIndicatorService } from '../../../services/blocking-indicator/blocking-indicator.service';
import { CommonValues } from '../../../classes/common-values';
import { DataService } from '../../../services/data/data.service';
import { DialogHelpComponent } from '../../../components/dialog-help/dialog-help.component';
import { ManageFields } from '../../../classes/manage-fields';
import { RecipientService } from '../../../services/recipient/recipient.service';
import { FormFieldSubNetworkTypeComponent } from '../../../components/form-field-sub-network-type/form-field-sub-network-type.component';

@Component({
  selector: 'nsc-recipient-select-department',
  templateUrl: './recipient-select-department.component.html',
  styleUrls: ['./recipient-select-department.component.scss']
})
export class RecipientSelectDepartmentComponent implements OnDestroy, OnInit {
  @Input() formGroup: FormGroup;
  @ViewChild(FormFieldSubNetworkTypeComponent)
  private subNetworkTypeFields: FormFieldSubNetworkTypeComponent;
  
  data = this.dataService.get();
  who = this.data.form.recipient.who;
  unsubscribe$ = new Subject();

  isElectronicPDFAvailable = true;
  isElectronicETXAvailable = true;
  isNotOnlyElectronicDeliveryMethod = false;

  content = {
    labelDepartment: null,

    requireDepartment: null, // department isn't always required, depends on the scenario;
    sendElectronicallyQuestion: 'Do you want to send electronically?',
  };
 

  values = {
    // SELECT values populated by service call;
    departments$: new BehaviorSubject(<any[]>[]),
    no: this.commonValues.api.no,
    yes: this.commonValues.api.yes,
    ohio: this.commonValues.api.oh,
  };

  constructor(
    public blockingIndicatorService: BlockingIndicatorService,
    private commonValues: CommonValues,
    private dataService: DataService,
    public dialog: MatDialog,
    private manageFields: ManageFields,
    private recipientService: RecipientService
  ) { }

  ngOnInit() {
    this.processRecipientJson(this.who.recipientResponse);

    this.initDepartmentListener();
    this.initSendElectronicallyQuestionListener();
    // Verify that the email matching and domain matching validator is enabled when the comes back to this page.
    if (this.formGroup.controls.sendElectronically.enabled && this.formGroup.controls.sendElectronically.value === this.commonValues.api.yes) {
      this.formGroup.controls.noDeliveryMethodAvailble.setValue(false);
      
      this.formGroup.setValidators([this.ValidateMatch('emailPrimary', 'emailConfirm')]);
    }
    this.isElectronicPDFAvailable = this.dataService.isElectronicPDFAvailable();
    this.isElectronicETXAvailable = this.dataService.isElectronicETXAvailable();
    this.isNotOnlyElectronicDeliveryMethod = this.dataService.isNotOnlyElectronicDeliveryMethod();
    this.initOborEligibilityQuestionListener();
  }

  initSendElectronicallyQuestionListener(): void {

    // subscribe to valueChanges observable of sendElectronically FormControl
    this.formGroup.controls.sendElectronically.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {

        const controls = this.formGroup.controls;
        if (value) {
          switch (value) {
            case this.values.yes:
              // disabled electronicDeliveryUnavailableFlag in case that error card is displayed
              controls.noDeliveryMethodAvailble.setValue(false);
              this.manageFields.disable([controls.electronicDeliveryUnavailableFlag]);
              
              // disable optional department
              controls.departmentNotInList.clearValidators();
              this.manageFields.disable([controls.departmentNotInList]);

              // display Email and Confirm Email Fields and make them required
              controls.emailConfirm.clearValidators();
              controls.emailConfirm.setValidators([Validators.required,
              Validators.pattern(this.commonValues.emailPattern)
              ]);
              controls.emailPrimary.clearValidators();
              controls.emailPrimary.setValidators([Validators.required,
              Validators.pattern(this.commonValues.emailPattern)
              ]);

              this.manageFields.enable([controls.emailConfirm, controls.emailPrimary]);

              // enable form-level validation for Domain Matching
              this.formGroup.setValidators([this.ValidateMatch('emailPrimary', 'emailConfirm')]);
              
              break;

            case this.values.no:

              // clear FormGroup Validators
              this.formGroup.clearValidators();

              // clear Validators for email field, and disable them
              controls.emailConfirm.clearValidators();
              controls.emailPrimary.clearValidators();
              this.manageFields.disable([controls.emailConfirm, controls.emailPrimary, controls.matchedUboxEntry]);

              // display 'Enter Department' and make it optional
              this.content.labelDepartment = 'Department Name';
              this.content.requireDepartment = false;
              controls.departmentNotInList.clearValidators();
              controls.departmentNotInList.setValidators([Validators.minLength(2)]);
              controls.departmentNotInList.setValue(null, { onlySelf: true, emitEvent: false });
              this.manageFields.enable([controls.departmentNotInList]);
              // verify if schools has been step up just only electronic delivery methods available
              // if so then the display the error message & user needs to select 'YES' if he wants to continue
              if ((this.isElectronicPDFAvailable || this.isElectronicETXAvailable) && !this.isNotOnlyElectronicDeliveryMethod){
                controls.electronicDeliveryUnavailableFlag.setValue(true);
              }
              break;
          }
        }

      });
  }

  initOborEligibilityQuestionListener(): void {
    const controls = this.formGroup.controls;
    // when the user edit the obor question and select option "NO" department fields needs to be populated
    if (controls.oborDeliveryEligibility.value === this.commonValues.api.no) {
      this.enableDepartmentNameField(controls.departmentNotInList.value);
    }
    // subscribe to valueChanges observable of OborEligibilityQuestion FormControl
    this.formGroup.controls.oborDeliveryEligibility.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        if (value) {
          switch (value) {
            case this.values.yes:

              // disable optional department
              controls.departmentNotInList.clearValidators();
              this.manageFields.disable([controls.departmentNotInList]);
              break;

            case this.values.no:

              this.enableDepartmentNameField(null);

              break;
          }
        }

      });
  }

  enableDepartmentNameField(value): void {
    const controls = this.formGroup.controls;
    // clear FormGroup Validators
    this.formGroup.clearValidators();

    // display 'Enter Department' and make it optional
    this.content.labelDepartment = 'Department Name';
    this.content.requireDepartment = false;
    controls.departmentNotInList.clearValidators();
    controls.departmentNotInList.setValidators([Validators.minLength(2)]);
    controls.departmentNotInList.setValue(value, { onlySelf: true, emitEvent: false });
    this.manageFields.enable([controls.departmentNotInList]);

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initDepartmentListener(): void {
    const controls = this.formGroup.controls;

    // wach the `department` field, and when it changes get the next questions ready;
    controls.department.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        if (value) {
          this.manageFields.disable([
            controls.amcasTranscriptIdNumber,
            controls.aamcAccountNumber,
            controls.casId,
            controls.departmentNotInList,
            controls.lsacAccountNumber,
            controls.emailPrimary,
            controls.emailConfirm,
            controls.matchedUboxEntry,
            controls.sendElectronically,
            controls.electronicDeliveryUnavailableFlag,
            controls.noDeliveryMethodAvailble
          ]);

          // enable the "not in list" field if we should;
          if (
            value.toString().toUpperCase() ===
            this.commonValues.autocomplete.notInList.schlName.toUpperCase()
          ) {
            this.setDepartmentNotInList(true);
            if (controls.recipientType.value == 'A') {
            controls.noDeliveryMethodAvailble.setValue(false);
            } if (controls.recipientType.value == 'S') {
              controls.noDeliveryMethodAvailble.setValue(false);

            } 
          }
        }
      });
  }

  matchDomainEmails(): boolean {
    
    const recipientResponse = this.formGroup.controls.recipientResponse;
    const controls = this.formGroup.controls;
    const primaryControl = controls.emailPrimary;
    const primaryEmail = primaryControl.value;
    const dPrimaryEmail = primaryEmail.substring(primaryEmail.indexOf('@')+1);
    
    if (recipientResponse && recipientResponse.value.recipientDepartments && this.checkForUboxEntries()) {
      // we have UboxEntries
      const departments = recipientResponse.value.recipientDepartments;
      for (let i = 0; i < departments.length; i++) {
        const boxEntriesForDepartment = departments[i].etxRgtryUboxEntries;
        if (boxEntriesForDepartment) {
          for (let j = 0; j < boxEntriesForDepartment.length; j++) {
            const domainEmail = boxEntriesForDepartment[j].domainEmail;
            const dDomainEmail = domainEmail.substring(domainEmail.indexOf('@')+1);
            
            if ((primaryEmail.toLowerCase().trim() === (domainEmail.toLowerCase().trim())) || domainEmail.startsWith("@") && (dPrimaryEmail.toLowerCase().trim() === dDomainEmail.toLowerCase().trim())) {
              // there is a match!
              if (controls.matchedUboxEntry.value === null || controls.matchedUboxEntry.value.domainEmail !== domainEmail) {
                controls.matchedUboxEntry.enable({ onlySelf: true, emitEvent: false });
                const matchedUboxEntry = _cloneDeep(boxEntriesForDepartment[j]);
                matchedUboxEntry.fileFormat = departments[i].fileFormat;
                matchedUboxEntry.etxDeptId = departments[i].ftpAccountName;
                matchedUboxEntry.recievingFileFormat = departments[i].recievingFileFormat;
                matchedUboxEntry.deptProcessingOption = departments[i].deptProcessingOption;
                controls.matchedUboxEntry.setValue(matchedUboxEntry);
              }
              if(this.formGroup.controls.noDeliveryMethodAvailble.value == true) {
                this.formGroup.controls.noDeliveryMethodAvailble.setValue(false);
              }
              
              if (controls.electronicDeliveryUnavailableFlag.value === true) {
                controls.electronicDeliveryUnavailableFlag.setValue(false);
              }
              return true;
            } //else {
             // if(this.formGroup.controls.noDeliveryMethodAvailble.value == false && i == departments.length-1) {
             // this.formGroup.controls.noDeliveryMethodAvailble.setValue(true);
              //}
           // }
          }
        }
      }
      // no match
      if (controls.matchedUboxEntry.enabled) { // checking if it's enable to prevent stack blow up
        // clear matchedUboxEntry, could have been set before
        controls.matchedUboxEntry.disable({ onlySelf: true, emitEvent: false });
        controls.matchedUboxEntry.setValue(null);
      }
      if (!this.isElectronicPDFAvailable) {
        // electronic PDF is not available and set sendEletronically value to 'N'.
        controls.isYesSendElectronically.setValue(true, { onlySelf: true, emitEvent: false });
        controls.electronicDeliveryUnavailableFlag.setValue(true, { onlySelf: true, emitEvent: false });
        controls.sendElectronically.setValue(this.commonValues.api.no);
      }
    }
    return false;
  }

  ValidateMatch(primaryControlName, secondaryControlName, secondaryRequired = true): ValidatorFn {
    return (abstractControl: AbstractControl): {
      [key: string]: any | null;
    } => {
     
      const primaryControl = abstractControl.get(primaryControlName);
      const secondaryControl = abstractControl.get(secondaryControlName);
      const errors = secondaryControl.errors || {};

      

      // only run if the secondary control is required;
      // or the user has entered an optional value;
      // so no value is allowed, or a matching value;
      if (secondaryRequired || secondaryControl.value) {
        // only run if both values have been entered, otherwise .toLowerCase() blows up;
        if (primaryControl.value && secondaryControl.value) {
          // if the values dont match, set a match error;
          // there may be other errors, dont overwrite them;
          // if they do match, make sure the match error is removed;
          if (primaryControl.value.toLowerCase() !== secondaryControl.value.toLowerCase()) {
            
            errors.match = true;
          } else {
            delete errors.match;
            this.matchDomainEmails();
            return null;
          }
        }
      }

      // if there are no errors present, return null;
      // angular will present an error even with an empty error object;
      secondaryControl.setErrors(_isEmpty(errors) ? null : errors);
      
      return _isEmpty(errors) ? null : { 'emailConfirm': 'Emails don\'t match' };
    };
  }

  getRecipient(ficeCode: string): void {
    const controls = this.formGroup.controls;

    this.blockingIndicatorService.open();

    this.recipientService
      .get(ficeCode)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((json: ApiResponseRecipientProfile) => {
        // disable the fields until we know we can show them;
        this.manageFields.disable([
          controls.amcasTranscriptIdNumber,
          controls.aamcAccountNumber,
          controls.casId,
          controls.department,
          controls.departmentNotInList,
          controls.lsacAccountNumber,
          controls.oborDeliveryEligibility
        ]);

        controls.recipientResponse.setValue(null);

        // force a small delay;
        window.setTimeout(() => {
          this.processRecipientJson(json);

          // now that we're done processing the recipient details, hide the progress bar;
          this.blockingIndicatorService.close();
        }, this.commonValues.loading.delay);
      },
        error => {
          console.log(error);
          this.setDepartmentNotInList(true);
          this.blockingIndicatorService.close();
        });
  }

  saveRecipientJson(json: ApiResponseRecipientProfile): void {
    // record the response JSON so on edit we don't have to make an Ajax call to determine which fields to show;
    this.formGroup.controls.recipientResponse.setValue(json);
  }

  processRecipientJson(json: ApiResponseRecipientProfile): void {
    const controls = this.formGroup.controls;

    // might not be a response;
    // dont do anything if there isn't a saved response;
    if (json) {
      // if there is a response, make sure it is an electronic exchange (ETX) organization;
      if (json.exchangeNetworkType === this.commonValues.api.etxNetwork) {
        this.saveRecipientJson(json);
        if(json.subNetwork){
          const isAmcas = json.subNetwork.subNetworkType === this.commonValues.api.amcas;
          const isLsac = json.subNetwork.subNetworkType === this.commonValues.api.lsac;
          const isLiaison = json.subNetwork.subNetworkType === this.commonValues.api.liason;
          if (isAmcas || isLsac || isLiaison) {
            if(this.subNetworkTypeFields){
              this.subNetworkTypeFields.processRecipientJson(json);
            }
          }
        }
        // show a SELECT form field of departments (if defined);
        else if (json.recipientDepartments && json.recipientDepartments.length) {
          this.setDepartmentSelect(json.recipientDepartments);
        } else {
          this.setDepartmentNotInList(false);
        }
      }
      else if (json.exchangeNetworkType === this.commonValues.api.obor || json.exchangeNetworkType === this.commonValues.api.speede || json.exchangeNetworkType === this.commonValues.api.fast) {
        this.saveRecipientJson(json);
        if (json.exchangeNetworkType === this.commonValues.api.obor) {
          this.manageFields.enable([controls.oborDeliveryEligibility]);
        }
      }
      // not an ETX network;
      else {
        this.setDepartmentNotInList(false);
      }
    }
  }

  // on change of organization or school, we need to determine how to display the department fields;
  // pass in the control of the recipient (organization or school), and the possible values;
  // this will allow us to determine which department fields to show;
  changeRecipient(control, recipients): void {
    const archiveStudent = this.data.response.orderHeader.archiveStudent;
    const etxArchiveStudentIsValid =
      archiveStudent === null ||
      archiveStudent === '' ||
      archiveStudent === this.commonValues.api.no;
    const validValues = [
      this.commonValues.api.both,
      this.commonValues.api.free,
      this.commonValues.api.pay
    ];
    const etxSendToSchoolIsValid =
      validValues.indexOf(this.data.schoolProfile.displaySendToSchoolOption) > -1;

    const value = control.value ? control.value.toUpperCase() : null;
    const isNotInList = value === this.commonValues.select.notInList.value;

    // the following ETX criteria are met, then make an API call for departments for the selected recipient;
    if (etxArchiveStudentIsValid && etxSendToSchoolIsValid && !isNotInList) {
      const recipientsArray = _filter(recipients, { value: value });

      if (recipientsArray.length) {
        const ficeCode = recipientsArray[0].ficeCode;

        this.formGroup.patchValue({
          recipientFiceCode: ficeCode
        });

        this.getRecipient(ficeCode);
      }
    }
    // if not an ETX school selection, show the optional department field;
    else {
      this.setDepartmentNotInList(false);
    }
  }

  setDepartmentSelect(departments: ApiResponseRecipientDetails[]): void {
    const controls = this.formGroup.controls;

    if (departments.length) {
      // conver the departments into an object to insert into the SELECT;
      const newValue = departments.map((option: ApiResponseRecipientDetails) => {
        return {
          name: option.deptName,
          value: option.deptId
        };
      });

      // sort the or departments, then add the not in list so it's at the bottom;
      const sortedValues = _sortBy(newValue, 'name');
      sortedValues.push(this.commonValues.select.notInList);

      // set the sorted value, then enable the form field;
      this.values.departments$.next(sortedValues);

      // only enable the control if it is disabled to not trigger a change event;
      if (controls.department.disabled) {
        this.manageFields.enable([controls.department]);
      }
    }
  }

  setDepartmentNotInList(required: boolean): void {
    const controls = this.formGroup.controls;
    const recipientType = controls.recipientType.value;
    let centralInbox = false;

    if (required) {
      if (controls.recipientResponse.value && controls.recipientResponse.value.recipientDepartments) {
        if (recipientType && recipientType === this.commonValues.api.college) {
          if (this.checkForUboxEntries()) {
            // Get Exchange API Response contains etxRgtryUboxEntries
            centralInbox = true;
          }
        }
      }
    }
    else if (required) {
      // Department is required and Exchange API is null.
      this.content.labelDepartment = 'Enter a Department';
      this.content.requireDepartment = true;
      controls.departmentNotInList.clearValidators();
      controls.departmentNotInList.setValidators([Validators.required, Validators.minLength(2)]);
      centralInbox = false;
    } else {
      // department is optional.
      this.content.labelDepartment = 'Department Name';
      this.content.requireDepartment = false;
      controls.departmentNotInList.clearValidators();
      controls.departmentNotInList.setValidators([Validators.minLength(2)]);
      // because we know an optional department is shown, we know that the departments SELECT shouldn't be visible;
      this.manageFields.disable([controls.department]);
      centralInbox = false;
    }

    if (centralInbox) {
      // hide Department Field
      this.manageFields.disable([controls.departmentNotInList]);
      // display SendElectronically question and make it required.
      controls.sendElectronically.clearValidators();
      controls.sendElectronically.setValidators([Validators.required]);
      this.manageFields.enable([controls.sendElectronically]);
    }
    else {
      // clear validators for email fields, and disable them, just in case they had been shown.
      controls.emailConfirm.clearValidators();
      controls.emailPrimary.clearValidators();
      this.manageFields.disable([controls.emailConfirm, controls.emailPrimary]);
      // clear validator for SendElectronically question and disable it.
      controls.sendElectronically.clearValidators();
      this.manageFields.disable([controls.sendElectronically]);
      // enable Deparment Field.
      this.content.labelDepartment = 'Department Name';
      this.content.requireDepartment = false;
      controls.departmentNotInList.clearValidators();
      controls.departmentNotInList.setValidators([Validators.minLength(2)]);
      this.manageFields.enable([controls.departmentNotInList]);
    }

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogHelpComponent, {
      width: '480px',
      ariaLabelledBy: "modalCasTitle",
      ariaDescribedBy: "modalCasContent",
      autoFocus:false,
      data: {
        id: "modalCas",
        title: 'CAS Transcript ID Help',
        body:
          '<img src="/assets/liaison-barcode.png" alt="" /><br />Enter a value for the CAS Transcript ID field. The CAS Transcript ID can be found on the Transcript Request form generated by the admissions service. The number is under the bar code on the form. Please copy and paste the ID number into the ‘CAS Transcript ID’ field.'
      }
    });
  }

  checkForUboxEntries(): boolean {
    const recipientResponse = this.formGroup.controls.recipientResponse;
    const departments = recipientResponse.value.recipientDepartments;
    // Iterate over department, and check for etxRgtryUboxEntries
    for (let i = 0; i < departments.length; i++) {
      // Get UboxEntries for i department.
      if (departments[i].etxRgtryUboxEntries) {
        return true;
      }
    }
    return false;
  }

}
