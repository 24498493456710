<div class="nsc-form-add-file">
 <div>
    <button
      mat-raised-button
      id="addfile_button"
      for="input-files"
      type="button"
      class="nsc-form-actions__button "
      color="primary"
      [disabled]="uploadedFiles.length >= maxFileCount"
      (click)="fileInputClick()"> Add File <mat-icon>add_circle_outline</mat-icon>
    </button>
  </div>
    <input
     ng2FileSelect
      id="input-files"
      type="file"
      (change)="onChange($event)"
      [disabled]="uploadedFiles.length >= maxFileCount" 
      [accept]="'.' + allowedFileTypes.join(', .')"
      [uploader]="uploader"
     />
    
    <div class="nsc-form-add-file__help">
      <button mat-icon-button aria-label="Information about file requirements"  (click)="openDialog()">
        <mat-icon aria-hidden="true">info</mat-icon>
      </button>
    </div>
    <span role="alert" aria-live="assertive"  [attr.aria-label]="removeStatus"></span>
  </div>
  <div *ngIf="uploadedFiles.length > 0" class="col-12 col-lg-10">
   
    <table mat-table #table   [dataSource]="dataSource">

      <ng-container matColumnDef="itemName">
        <th mat-header-cell *matHeaderCellDef><div class="column-title">File</div> </th>
        <td mat-cell *matCellDef="let uploadedFile">  {{ uploadedFile.originalFileItem.file.name }}</td>
      </ng-container>

      <ng-container matColumnDef="itemStatus">
        <th mat-header-cell *matHeaderCellDef><div class="column-title">Status</div> </th>
        <td mat-cell *matCellDef="let uploadedFile">
          <div  ></div>
          <span [attr.aria-label]=" uploadedFile.originalFileItem.file.name" *ngIf="!uploadedFile.originalFileItem.isSuccess && !uploadedFile.originalFileItem.isError && !uploadedFile.originalFileItem.isCancel && !uploadedFile.originalFileItem.isUploading">Pending  </span>
          <span [attr.aria-label]=" uploadedFile.originalFileItem.file.name" *ngIf="uploadedFile.originalFileItem.isSuccess && (uploadedFile.attachmentResponse && uploadedFile.attachmentResponse.status !== 'fail')">Uploaded </span>
          <span [attr.aria-label]=" uploadedFile.originalFileItem.file.name" class="mat-error" *ngIf="(uploadedFile.originalFileItem.isError || (uploadedFile.attachmentResponse && uploadedFile.attachmentResponse.status === 'fail')) && !uploadedFile.originalFileItem.isUploading">Error </span>
          <span [attr.aria-label]=" uploadedFile.originalFileItem.file.name" *ngIf="uploadedFile.originalFileItem.isCancel">Cancelled </span>
          <span [attr.aria-label]=" uploadedFile.originalFileItem.file.name" *ngIf="uploadedFile.originalFileItem.isUploading">Uploading...  </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="itemSize">
        <th mat-header-cell *matHeaderCellDef><div class="column-title">Size</div> </th>
        <td mat-cell *matCellDef="let uploadedFile">
          {{ roundFileSizeValue(uploadedFile.originalFileItem.file.size) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="itemButtons">
        <th mat-header-cell *matHeaderCellDef><div class="column-title">Remove</div></th>
        <td mat-cell *matCellDef="let uploadedFile">
          <button *ngIf="(uploadedFile.originalFileItem.isError || uploadedFile.originalFileItem.isCancel || (uploadedFile.attachmentResponse && uploadedFile.attachmentResponse.status === 'fail')) && !uploadedFile.originalFileItem.isUploading" mat-icon-button (click)="retryFileClick(uploadedFile)">
            <mat-icon>replay</mat-icon>
          </button>
          <button  mat-icon-button   (click)="removeFileClick(uploadedFile)"aria-label="Remove">
            <mat-icon>cancel</mat-icon>
          </button>
        
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"  ></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"  ></tr>

    </table>
   

    <nsc-spacer size="sm"></nsc-spacer>

    <div *ngIf="uploader.isUploading">
      <nsc-spacer size="md"></nsc-spacer>
      <div class="mat-body-2">Upload progress:</div>
      <div class="progress">
        <div class="progress-bar" role="progressbar"  aria-label="upload file progressbar"  [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
      </div>
    </div>
  </div>
