import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { fromEvent, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CommonValues } from '../../classes/common-values';
import { ValidationMessage } from '../../classes/validation-message';

import { AutocompleteOption } from '../../interfaces/autocomplete-option.interface';
import { AddressValidation } from '../../interfaces/address-validation';
import { AddressResponse } from '../../interfaces/address-response';
import { AddressInternationalResponse } from '../../interfaces/address-international-response';

import { VerifyUsService } from '../../services/address/verify-us.service';
import { AddressValidationDialogComponent } from '../address-validation-dialog/address-validation-dialog.component';


@Component({
  selector: 'nsc-address-auto-complete',
  templateUrl: './address-auto-complete.component.html',
  styleUrls: ['./address-auto-complete.component.scss']
})
export class AddressAutoCompleteComponent implements OnInit, OnDestroy {
  @Input() controlName: string;
  @Input() group: FormGroup;
  @Input() hint: string;
  @Input() required = true;
  @Input() idx: string;
  @Input() notInList: boolean;
  @Input() options: AutocompleteOption[];
  @Input() placeholder: string;
  @Input() loading = false;
  @Input() searchType = this.commonValues.searchType.startsWithSearch;
  @Input() minCharactersToTrigger = 5;
  @Input() continueOnlyWithValidAddress = false;
  @Input() maxlength: number;
  @Input() isExpressSecurePrint = false;
  @Input() validateInternationAddressKey = true;

  @ViewChild('itemFilterInput',{ static: true }) itemFilterInput: ElementRef;
  @ViewChild('optionList', { read: ElementRef })
  optionList: ElementRef;
  @Output() emitFocusOnValidAddress = new EventEmitter<boolean>();
  @Output() emitInternationalPremisePartialAddress = new EventEmitter<boolean>();

  focusTimeout: number;
  focusTimeoutDelay = 250;
  highlightedItem = -1;
  inputTimeout: number;
  inputTimeoutDelay: 300;
  dialogRef: MatDialogRef<AddressValidationDialogComponent>;

  content = {
    hint: ''
  };

  show = {
    autocomplete: false,
    addressValidationDialogue: false
  };

  values = {
    autocomplete: [], // options to display to the user;
    addressIsValid: false,
    userUsedHisAddress: false,
    addressVerificationTriggered: false,
    invalidAddressErrorMessage: '',
    country: null,
    verificationServiceUnavailable: false,
    isPremisePartialAddress: false
  };

  addressValidationRequest: AddressValidation = {
    request: {
      city: null,
      state: null,
      zipcode: null,
      street: null,
      street2: null,
      country: null
    }
  };


  usAddressValidationResponse: AddressResponse[];
  intAddressValidationResponse: AddressInternationalResponse[];
  missingSecondary = false;
  addressCorrected = false;
  enteredSecondaryNbr = null;
  unsubscribe$ = new Subject();
  isSuggestedAddressConstructedFromResponse = false;

  constructor(
    private commonValues: CommonValues,
    private verifyUsService: VerifyUsService,
    private dialog: MatDialog,
    private validationMessage: ValidationMessage
  ) {
  }

  ngOnInit() {
    this.configureAutocomplete();
    this.setHint(this.hint, this.required);
  }

  ngOnChanges(changes: SimpleChanges) {
    const newHint = changes.hint ? changes.hint.currentValue : this.hint;
    const newRequired = changes.required ? changes.required.currentValue : this.required;

    this.setHint(newHint, newRequired);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  configureAutocomplete(): void {
    fromEvent(this.itemFilterInput.nativeElement, 'keyup')
      .pipe(distinctUntilChanged())
      .subscribe((e: KeyboardEvent) => {
        this.keyUpHandler(e);
      });
  }

  showAddressOptions(): void {
    // I removed 'debounce' from the Event Observable and I am replicating it's behavior
    // with this inputTimeout timer object
    window.clearTimeout(this.inputTimeout);
    const value = this.itemFilterInput.nativeElement.value;
    this.highlightedItem = -1;
    if (value && value.length >= this.minCharactersToTrigger) {
      if (this.options) {
        this.values.autocomplete = this.options['suggestions'];
        this.show.autocomplete = true;
      }
    }
    // if the form is less than X characters, show nothing - not even an error message;
    else {
      this.values.autocomplete = [];
    }
  }

  hideOptions(): void {
    this.show.autocomplete = false;
    this.highlightedItem = -1;
  }

  // whenever an option of the form field is focused, clear any existing timeout, and show the autocomplete options;
  // so if the form field already has content in it on focus, options are shown;
  onFocus(): void {
    window.clearTimeout(this.focusTimeout);
    this.showAddressOptions();
  }

  // on leaving the input or option, start a timeout to hide the options;
  // we dont want to immediately hide the options because the user may be transitioning from the input to an option;
  // give the app time to manage that change in focus to options aren't hidden immediately on blur;
  onBlur(): void {
    this.focusTimeout = window.setTimeout(() => this.hideOptions(), this.focusTimeoutDelay);
  }

  // on select of an option, set the input value and hide the options (since we no longer need them);
  // use the blur event to hide so there is a slight delay (so it is't jarring to the user);
  selectOption(item): void {
    if (item) {
      this.group.controls['addressLine1'].setValue(item.street_line);
      this.group.controls['city'].setValue(item.city);
      this.group.controls['country'].setValue('US', { emitEvent: false });
      this.group.controls['state'].setValue(item.state);
      this.verifyEnteredAddress();
      this.onBlur();
    }
  }
  setHint(hint: string, required: boolean): void {
    // if a field isn't required and it's optional, we need to combine the `hint` values;
    this.content.hint = `${hint || ''} ${required ? '' : '(Optional)'}`;
  }
  // hovering on an option needs to overwrite whatever item was selected with the arrow keys
  optionHoverHandler(e, i): void {
    this.highlightedItem = i;
  }

  // sets the highlighted state for options
  optionClass(i): string {
    if (i === this.highlightedItem) {
      return 'mat-selected';
    }
    return '';
  }

  // These two methods control scrolling. To make the scrolling work as expected, it gets a bit more
  // complicated than expected.
  scrollUpToOption(i): void {
    const itemHeight = this.optionList.nativeElement.firstElementChild.offsetHeight;
    const oldScroll = this.optionList.nativeElement.scrollTop;
    const newScroll = i * itemHeight;

    this.optionList.nativeElement.scrollTop = oldScroll > newScroll ? newScroll : oldScroll;
  }

  scrollDownToOption(i): void {
    const maxHeight = this.optionList.nativeElement.offsetHeight;
    const itemHeight = this.optionList.nativeElement.firstElementChild.offsetHeight;
    const oldScroll = this.optionList.nativeElement.scrollTop;
    const newScroll = (i - Math.floor(maxHeight / itemHeight) + 1) * itemHeight;

    this.optionList.nativeElement.scrollTop = oldScroll < newScroll ? newScroll : oldScroll;
  }

  // catch keyup events from the input field
  keyUpHandler(e): void {
    if (this.show.autocomplete && this.values.autocomplete && this.values.autocomplete.length >= 1) {
      if (e.code === 'ArrowDown' || e.key === 'Down') {
        if (this.highlightedItem < this.values.autocomplete.length - 1) {
          this.highlightedItem++;
          if (this.highlightedItem > 4) {
            window.setTimeout(() => this.scrollDownToOption(this.highlightedItem), 200);
          }
        }
      } else if (e.code === 'ArrowUp' || e.key === 'Up') {
        if (this.highlightedItem > 0) {
          this.highlightedItem--;
          if (this.highlightedItem < this.values.autocomplete.length - 5) {
            window.setTimeout(() => this.scrollUpToOption(this.highlightedItem), 200);
          }
        }
      } else if (e.code === 'Enter' || e.key === 'Enter') {
        this.selectOption(this.values.autocomplete[this.highlightedItem]);
      } else {
        this.inputTimeout = window.setTimeout(() => this.showAddressOptions(), this.inputTimeoutDelay);
      }
    } else {
      this.inputTimeout = window.setTimeout(() => this.showAddressOptions(), this.inputTimeoutDelay);
    }
  }

  verifyEnteredAddress(): void {
    this.initAddressValidationRequest();
    this.show.addressValidationDialogue = true;
    this.values.userUsedHisAddress = false;
    this.values.addressIsValid = false;
    this.missingSecondary = false;
    this.addressCorrected = false;
    this.values.addressVerificationTriggered = true;
    if (this.addressValidationRequest.request.country === this.commonValues.api.us) {
      this.isThereEnoughInput();
      this.verifyUsService.verifyAddress(this.addressValidationRequest.request)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response: AddressResponse[]) => {
          this.usAddressValidationResponse = response;
          if (this.usAddressValidationResponse) {
            this.verifyIfAddressIsValid(this.usAddressValidationResponse);
          }
        },
          (error) => {
            this.values.verificationServiceUnavailable = true;
          }
        );
    } else {
      // CANADA address verification
      if (this.addressValidationRequest.request.country === this.commonValues.api.ca && (this.validateInternationAddressKey || this.validateInternationAddressKey === null)) {
        this.isThereEnoughInput();
        const addressToBeVerified: any = {};
        addressToBeVerified.country = this.addressValidationRequest.request.country;
        addressToBeVerified.address1 = this.addressValidationRequest.request.street;
        addressToBeVerified.address2 = this.addressValidationRequest.request.street2;
        addressToBeVerified.locality = this.addressValidationRequest.request.city;
        addressToBeVerified.administrative_area = this.addressValidationRequest.request.state;
        addressToBeVerified.postal_code = this.addressValidationRequest.request.zipcode;

        this.verifyUsService.verifyInternationalAddress(addressToBeVerified)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((response: AddressInternationalResponse[]) => {
            this.intAddressValidationResponse = response;
            if (this.intAddressValidationResponse) {
              this.verifyIfInternationalAddressIsValid(this.intAddressValidationResponse);
            }
          },
            (error) => {
              this.values.verificationServiceUnavailable = true;
            }
          );
      } else {
        if(!this.verifyUsService.verifyInternationalAddressVerificationToggle()){
            return;
        }
        // international address other than Canada address verification logic
          this.addressValidationRequest.request.state = 'ZZ';
          this.isThereEnoughInput();
          const addressToBeVerified: any = {};
          addressToBeVerified.country = this.addressValidationRequest.request.country;
          addressToBeVerified.address1 = this.addressValidationRequest.request.street;
          addressToBeVerified.address2 = this.addressValidationRequest.request.street2;
          addressToBeVerified.locality = this.addressValidationRequest.request.city;
          //addressToBeVerified.administrative_area = this.addressValidationRequest.request.state;
          addressToBeVerified.postal_code = this.addressValidationRequest.request.zipcode;
  
          this.verifyUsService.verifyInternationalAddress(addressToBeVerified)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((response: AddressInternationalResponse[]) => {
              this.intAddressValidationResponse = response;
              if (this.intAddressValidationResponse) {
                this.verifyIfInternationalAddressIsValidForNonCanada(this.intAddressValidationResponse);
              }
            },
              (error) => {
                this.values.verificationServiceUnavailable = true;
              }
            );
      }
    }
    return;
  }

  verifyIfInternationalAddressIsValid(intAddressValidationResponse: AddressInternationalResponse[]) {
    this.values.isPremisePartialAddress = false;
    this.emitInternationalPremisePartialAddress.next(false);
    if(intAddressValidationResponse){
    const analysisAddress = intAddressValidationResponse[0].analysis;
    if (intAddressValidationResponse.length > 1) {
      if (analysisAddress.address_precision === 'Premise'){
        this.values.isPremisePartialAddress = true;
      }
      this.openValidationDialog(this.commonValues.addressValidationMessages.addressAmbigousInternational);
    } else {
      // single match
      if ( analysisAddress.verification_status === 'Verified' && (analysisAddress.address_precision === 'DeliveryPoint' || analysisAddress.address_precision === 'Premise' ) && analysisAddress.max_address_precision === 'DeliveryPoint') {
        this.validateInternationalAddress2(this.addressValidationRequest, intAddressValidationResponse[0]);
        // address is valid
        // // check if address has been formatted or modified from entered address
        if (this.addressUpdatedFromOriginal(this.addressValidationRequest, intAddressValidationResponse[0])) {
          //   // address updated from original
          this.openValidationDialog(this.commonValues.addressValidationMessages.newInternationalAddressSuggested);
          return;
        }
        this.setAddressValuesFromResponse(this.intAddressValidationResponse[0], true);
        this.values.addressIsValid = true;
        this.changeFocus();
        return;
      }
      else {
        // if invalid address
        this.values.addressIsValid = false;
        const isAnyAddressPrecision = this.getAllAddressPrecision(analysisAddress.address_precision);
        const isPartialAddress = analysisAddress.verification_status === 'Partial';
        const isVerifiedAddress = analysisAddress.verification_status === 'Verified';
        const isAmbiguousAddress = analysisAddress.verification_status === 'Ambiguous';
        const isPremiseAddressPrecision = analysisAddress.address_precision === 'Premise';

        // check premise/partial address for SP and NONSP
        if (isPartialAddress && isPremiseAddressPrecision){
          this.values.isPremisePartialAddress = true;
          this.openValidationDialog(this.commonValues.addressValidationMessages.secondaryInternationalAddress);
          return;
        }
        // if it's express and security print order
        if (this.isExpressSecurePrint) {
          if ((isPartialAddress || isVerifiedAddress || isAmbiguousAddress)  && ((analysisAddress.address_precision === 'Thoroughfare') || (analysisAddress.address_precision === 'Locality') ||  (analysisAddress.address_precision === 'AdministrativeArea'))) {
            this.openValidationDialog(this.commonValues.addressValidationMessages.internationalCityNotFound);
            return;
          }
        }
        else{
          // checks if it's not express order
          if ((isPartialAddress || analysisAddress.verification_status === 'None') && ((analysisAddress.address_precision === 'Locality') || (analysisAddress.address_precision === 'AdministrativeArea') || (analysisAddress.address_precision === 'None'))){
            this.openValidationDialog(this.commonValues.addressValidationMessages.internationalCityNotFound);
            return;
          }
          else if ((isPartialAddress) && (analysisAddress.address_precision === 'Thoroughfare')){
            this.openValidationDialog(this.commonValues.addressValidationMessages.internationalMissingStreetNumber);
            return;
          }
          else if ((isVerifiedAddress) && (analysisAddress.address_precision === 'Thoroughfare')){
            this.openValidationDialog(this.commonValues.addressValidationMessages.internationalMissingStreetNumber);
            return;
          }

          else if ((isVerifiedAddress) && ((analysisAddress.address_precision === 'Locality') ||  (analysisAddress.address_precision === 'AdministrativeArea'))){
            this.openValidationDialog(this.commonValues.addressValidationMessages.internationalCityNotFound);
            return;
          }
        }
        // if all the conditions above failed then show generic invalid address message
        this.openValidationDialog(this.commonValues.addressValidationMessages.addressInvalid);
        return;
      }
    }
  }
  }

  getAllAddressPrecision(addressPrecision: string) {
    return addressPrecision === 'Premise' || addressPrecision === 'Locality' || addressPrecision === 'Thoroughfare' || addressPrecision === 'AdministrativeArea' || addressPrecision === 'None';
  }

  validateInternationalAddress2(addressRequest: AddressValidation, addressResponse: AddressInternationalResponse) {
    // if user doesn't enter the street2  and response is equals to 'cityVarSrc + stateVar + zipVarSrc' no needs to
    const cityStateZipcodeResponse = (addressResponse.components.locality + addressResponse.components.administrative_area + addressResponse.components.postal_code).toLowerCase().replace(/\s+/g, '');
    const address2 = (addressResponse.address2 ? addressResponse.address2 : '').toLowerCase().replace(/\s+/g, '');

    if (address2 === cityStateZipcodeResponse) {
      addressResponse.address2 = addressRequest.request.street2 === null ? addressRequest.request.street2 : '';
    } 
    else {
      let concatAddressess = '';
      Object.keys(addressResponse).forEach((item, i) => {
        if (item !== 'address1') {
          if (item.includes('address')) {
            const addressItem = (addressResponse[item]).toLowerCase().replace(/\s+/g, '');
            if (addressItem !== cityStateZipcodeResponse) {
              concatAddressess += addressResponse[item] + ' ';
            }
          }
        }
      });
      addressResponse.address2 = concatAddressess;
    }
  }

  verifyIfAddressIsValid(usAddressValidationResponse: AddressResponse[]) {
    // more than one match then address is ambigous
    if (usAddressValidationResponse.length > 1) {
      this.openValidationDialog(this.commonValues.addressValidationMessages.addressAmbigous);
      // open dialog to choose one of the option
    } else {
      // single match
      if (usAddressValidationResponse[0].analysis.dpv_match_code &&
        usAddressValidationResponse[0].analysis.dpv_match_code === 'Y' &&
        (usAddressValidationResponse[0].analysis.dpv_footnotes === 'AABB' ||
          usAddressValidationResponse[0].analysis.dpv_footnotes === 'AAF1')
      ) {
        // address is valid
        // check if address has been formatted or modified from entered address
        if (this.addressUpdatedFromOriginal(this.addressValidationRequest, usAddressValidationResponse[0])) {
          // address updated from original
          this.openValidationDialog(this.commonValues.addressValidationMessages.newAddressSuggested);
          return;
        }
        this.setAddressValuesFromResponse(this.usAddressValidationResponse[0], false);
        this.values.addressIsValid = true;
        this.changeFocus();
      } else if (usAddressValidationResponse[0].analysis.dpv_match_code &&
        usAddressValidationResponse[0].analysis.dpv_match_code === 'Y' &&
        usAddressValidationResponse[0].analysis.dpv_footnotes === 'AAU1') {
        // address is valid and matched unique zip code
        // check if address has been formatted or modified from entered address
        if (this.addressUpdatedFromOriginal(this.addressValidationRequest, usAddressValidationResponse[0])) {
          // address updated from original
          this.openValidationDialog(this.commonValues.addressValidationMessages.newAddressSuggested);
          return;
        }
        // setting unique address values
        this.setAddressValuesFromResponse(this.usAddressValidationResponse[0], false);
        this.values.addressIsValid = true;
        this.changeFocus();
      } else {
        // address is invalid
        this.values.addressIsValid = false;
        // check why is it invalid
        // missing primary
        if (usAddressValidationResponse[0].analysis.dpv_footnotes === 'AAM3') {
          this.openValidationDialog(this.commonValues.addressValidationMessages.missingPrimary);
          return;
        }
        // missing primary building number
        if (usAddressValidationResponse[0].analysis.dpv_footnotes === 'A1M1') {
          this.openValidationDialog(this.commonValues.addressValidationMessages.missingPrimaryBuildingNumber);
          return;
        }
        // city not found
        if (usAddressValidationResponse[0].analysis.dpv_footnotes === 'A1' &&
          !this.isNullorBlank(usAddressValidationResponse[0].analysis.footnotes) &&
          (usAddressValidationResponse[0].analysis.footnotes.includes('C#') || usAddressValidationResponse[0].analysis.footnotes.includes('F#'))) {
          this.openValidationDialog(this.commonValues.addressValidationMessages.cityNotFound);
          return;
        }
        // missing secondary
        if (usAddressValidationResponse[0].analysis.dpv_footnotes === 'AAN1' || usAddressValidationResponse[0].analysis.dpv_footnotes === 'AAC1' ||
          usAddressValidationResponse[0].analysis.dpv_footnotes === 'AACC' ||
          (!this.isNullorBlank(usAddressValidationResponse[0].analysis.footnotes) &&
            usAddressValidationResponse[0].analysis.footnotes.includes('H#'))) {
          // this.missingSecondary = true;
          this.openValidationDialog(this.commonValues.addressValidationMessages.secondaryAddress);
          return;
        }
        // missing secondary PO,RR or HC
        if (usAddressValidationResponse[0].analysis.dpv_footnotes === 'AAP1' ||
          usAddressValidationResponse[0].analysis.dpv_footnotes === 'AAP3') {
          // this.missingSecondary = true;
          this.openValidationDialog(this.commonValues.addressValidationMessages.ruralAddressMissingSecondaryNumber);
          return;
        }
        // private mailbox could not be confirmed
        if (usAddressValidationResponse[0].analysis.dpv_footnotes === 'AABBR1') {
          this.openValidationDialog(this.commonValues.addressValidationMessages.privateMailBox);
          return;
        }
        if (!this.isNullorBlank(usAddressValidationResponse[0].analysis.footnotes) && usAddressValidationResponse[0].analysis.footnotes.includes('I#')) {
          this.openValidationDialog(this.commonValues.addressValidationMessages.addressAmbigous);
          return;
        }
        this.openValidationDialog(this.commonValues.addressValidationMessages.addressInvalid);
        return;
      }
    }
  }

  // use the delivery lines provided by smart streets to set the address values.
  setAddressValuesFromResponse(response: AddressResponse | AddressInternationalResponse, isInternationalAddress?: boolean): any {
    const addressValues: any = {};
    if (!isInternationalAddress) {
      const newResponse = (response as AddressResponse);
      addressValues.addressLine1 = newResponse.delivery_line_1;
      addressValues.addressLine2 = newResponse.delivery_line_2 ? newResponse.delivery_line_2 : '';
      addressValues.city = newResponse.components.city_name;
      addressValues.state = newResponse.components.state_abbreviation;
      if (newResponse.components.zipcode) {
        addressValues.zipcode = newResponse.components.zipcode + ((newResponse.components.plus4_code ? '-' + newResponse.components.plus4_code : ''));
      }
    } else {
      const newResponse = (response as AddressInternationalResponse);
      addressValues.addressLine1 = newResponse.address1;
      addressValues.addressLine2 = newResponse.address2 ? newResponse.address2 : '';
      addressValues.city = newResponse.components.locality;
      addressValues.state = newResponse.components.administrative_area;
      if (newResponse.components.postal_code) {
        addressValues.zipcode = (newResponse.components.postal_code ? newResponse.components.postal_code : '');
      }
    }
    addressValues.addressLine1 = this.formatAddress(addressValues.addressLine1);
    addressValues.addressLine2 = this.formatAddress(addressValues.addressLine2);
    this.group.controls['addressLine1'].setValue(addressValues.addressLine1);
    this.group.controls['addressLine2'].setValue(addressValues.addressLine2);
    this.group.controls['city'].setValue(addressValues.city);
    // this.group.controls['country'].setValue(this.group.controls.country.value);
    this.group.controls['state'].setValue(addressValues.state);
    if (addressValues.zipcode) {
      this.group.controls['zip'].setValue(addressValues.zipcode);
    }
  }



  // initialize the address validation request
  initAddressValidationRequest() {
    this.addressValidationRequest.request.city = this.group.controls['city'].value;
    this.addressValidationRequest.request.state = this.group.controls['state'].value;
    if (this.group.controls['zip']) {
      this.addressValidationRequest.request.zipcode = this.group.controls['zip'].value;
    }
    this.addressValidationRequest.request.street = this.group.controls['addressLine1'].value;

    if (this.group.controls['addressLine2']) {
      this.addressValidationRequest.request.street2 = this.group.controls['addressLine2'].value;

    }
    this.addressValidationRequest.request.country = this.group.controls['country'].value;
  }



  formatAddress(addressLine: string): string {

    let formattedAddress = addressLine.trim();
    formattedAddress = formattedAddress.substring(0, 40);
    return formattedAddress;

  }

  setAddressValuesFromResponseUsingComponents(response: AddressResponse) {

    let addressLine1 = (response.components.primary_number ? response.components.primary_number : '') + ' ' +
      (response.components.street_name ? response.components.street_name : '') + ' ' +
      (response.components.street_suffix ? response.components.street_suffix : '') + ' ' +
      (response.components.street_postdirection ? response.components.street_postdirection : '');

    let addressLine2 = (response.components.secondary_designator ? response.components.secondary_designator : '') + ' ' +
      (response.components.secondary_number ? response.components.secondary_number : '');

    addressLine1 = this.formatAddress(addressLine1);
    this.group.controls['addressLine1'].setValue(addressLine1);
    addressLine2 = this.formatAddress(addressLine2);
    this.group.controls['addressLine2'].setValue(addressLine2);
    this.group.controls['city'].setValue(response.components.city_name);
    // this.group.controls['country'].setValue('US');
    this.group.controls['state'].setValue(response.components.state_abbreviation);
    if (response.components.zipcode) {
      this.group.controls['zip'].setValue(response.components.zipcode + (response.components.plus4_code ? ('-' + response.components.plus4_code) : ''));
    }
  }

  openValidationDialog(message: string) {
    this.dialogRef = this.dialog.open(AddressValidationDialogComponent, {
      autoFocus:false,
      width: '750px',
      ariaDescribedBy: "modalAddressContent",
      ariaLabelledBy: "modalAddressTitle"
    });
    this.dialogRef.componentInstance.addressValidationMessage = message;
    this.dialogRef.componentInstance.missingSecondary = this.missingSecondary;
    this.dialogRef.componentInstance.addressCorrected = this.addressCorrected;
    this.dialogRef.componentInstance.enteredAddress = this.addressValidationRequest;
    this.dialogRef.componentInstance.isAddressValid = this.values.addressIsValid;
    this.dialogRef.componentInstance.continueOnlyWithValidAddress = this.continueOnlyWithValidAddress;
    this.dialogRef.componentInstance.instructionalText = this.values.isPremisePartialAddress ? this.setInstructionalPartialPremiseText() : this.setInstructionalText();
    this.dialogRef.componentInstance.show.enteredAddress = this.setEnteredAddressDisplay();
    this.dialogRef.componentInstance.show.suggestedAddress = this.setSuggestedAddressDisplay();
    if (this.addressCorrected) {
      this.dialogRef.componentInstance.addressValidationMessage = this.dialogRef.componentInstance.instructionalText;
      this.dialogRef.componentInstance.instructionalText = null;
    }
    if (this.group.controls.country.value === this.commonValues.api.us && this.usAddressValidationResponse) {
      this.dialogRef.componentInstance.correctedAddress = this.usAddressValidationResponse[0];
    }
    else if (this.group.controls.country.value === this.commonValues.api.ca && this.intAddressValidationResponse) {
      this.dialogRef.componentInstance.correctedInternationalAddress = this.intAddressValidationResponse[0];
    }else if(this.intAddressValidationResponse){
      this.dialogRef.componentInstance.isSuggestedAddressConstructed = this.isSuggestedAddressConstructedFromResponse;
      if(this.dialogRef.componentInstance.isSuggestedAddressConstructed){
        this.contructAddressFromSmartyComponents(this.intAddressValidationResponse[0]);
        if(!this.intAddressValidationResponse[0].components.locality){
          this.intAddressValidationResponse[0].components.locality = this.group.controls.city.value;
        }
        this.dialogRef.componentInstance.correctedInternationalAddress = this.intAddressValidationResponse[0];
      }
    }

    this.dialogRef.afterClosed().subscribe(result => {

      if (typeof result !== 'boolean' && result) {
        if (result.components && result.analysis) {
          const isInternationalAddress = this.group.controls.country.value === this.commonValues.api.us ? false : true;
          // user chose valid suggested address
          this.setAddressValuesFromResponse(result, isInternationalAddress);
          this.values.addressIsValid = true;
          this.changeFocus();
        } else if (result.request) {
          // user used his entered address
          this.values.userUsedHisAddress = true;
          this.changeFocus();
        }
        if (this.missingSecondary && this.dialogRef.componentInstance.secondaryNbr) {
          this.group.controls['addressLine2'].setValue(this.dialogRef.componentInstance.secondaryNbr);
          this.verifyEnteredAddress();
          return;
        }
      } else if (result === true) {
        this.values.userUsedHisAddress = true;
        this.changeFocus();
      } else {
        this.values.userUsedHisAddress = false;
        this.changeFocus();
      }
      this.dialogRef = null;
    });

  }

  setSuggestedAddressDisplay(): boolean {
    return this.addressCorrected;
  }

  setEnteredAddressDisplay(): boolean {
    if (this.addressCorrected && this.continueOnlyWithValidAddress) {
      return false;
    }
    return true;
  }

  isUSCountry() {
    return this.addressValidationRequest.request.country === this.commonValues.api.us;
  }


  setInstructionalText(): string {
    if (this.addressCorrected) {
      if (!this.continueOnlyWithValidAddress) {
        const addressSuggestedMessage = this.isUSCountry() ? this.commonValues.addressValidationMessages.newAddressSuggested : this.commonValues.addressValidationMessages.newInternationalAddressSuggested;
        return addressSuggestedMessage;
      } else {
        const invalidAddressSuggestedMessageInfo = this.isUSCountry() ? this.commonValues.addressValidationMessages.addressSuggestedAndCannnotContinue : this.commonValues.addressValidationMessages.internationalAddressSuggestedAndCannnotContinue;
        return invalidAddressSuggestedMessageInfo;
      }
    } else if (!this.values.addressIsValid) {
      if (this.continueOnlyWithValidAddress) {
        const invalidAddressMessageInfo = this.isUSCountry() ? this.commonValues.addressValidationMessages.invalidAddressAndCannotContinue : this.commonValues.addressValidationMessages.invalidInternationalAddressAndCannotContinue;
        return invalidAddressMessageInfo;
      }
      else {
        return this.isUSCountry() ? this.commonValues.addressValidationMessages.invalidAddressMessage : this.commonValues.addressValidationMessages.invalidAddressMessageInternational;
      }
    }
    return null;
  }


  setInstructionalPartialPremiseText(): string{
    if (this.values.isPremisePartialAddress){
      this.emitInternationalPremisePartialAddress.next(true);
      return this.commonValues.addressValidationMessages.invalidAddressMessageInternational;
    }
    return;
  }

  changeFocus() {
   if (this.values.addressIsValid || this.values.userUsedHisAddress) {
      this.emitFocusOnValidAddress.next(true);
    }
  }

  // this method uses the footnotes to check if address has been updated from original
  addressUpdatedFromOriginalUsingFootnotes(enteredAddress: AddressValidation, validationResponse: AddressResponse): boolean {

    if (!this.isNullorBlank(validationResponse.analysis.footnotes) &&
      (validationResponse.analysis.footnotes.includes('A#') ||
        validationResponse.analysis.footnotes.includes('B#') ||
        validationResponse.analysis.footnotes.includes('G#') ||
        validationResponse.analysis.footnotes.includes('K#') ||
        validationResponse.analysis.footnotes.includes('L#') ||
        validationResponse.analysis.footnotes.includes('M#') ||
        validationResponse.analysis.footnotes.includes('N#') ||
        validationResponse.analysis.footnotes.includes('P#') ||
        validationResponse.analysis.footnotes.includes('U#') ||
        validationResponse.analysis.footnotes.includes('V#'))) {
      this.addressCorrected = true;
      return true;
    }
    return false;
  }

  // this method compares address returned by smarty streets with what user has entered to determine if address has been modified.
  addressUpdatedFromOriginal(enteredAddress: AddressValidation, validationResponse: AddressResponse | AddressInternationalResponse): boolean {
    const zipVarSrc = (enteredAddress.request.zipcode ? enteredAddress.request.zipcode : '').toLowerCase().replace(/\s+/g, '');
    const streetVarSrc = enteredAddress.request.street.toLowerCase().replace(/\s+/g, '');
    const secondaryVarSrc = (enteredAddress.request.street2 ? enteredAddress.request.street2 : '').toLowerCase().replace(/\s+/g, '');
    const cityVarSrc = enteredAddress.request.city.toLowerCase().replace(/\s+/g, '');
    const stateVar = enteredAddress.request.state.toLowerCase().replace(/\s+/g, '');

    const newValidationResponse: any = {};
    if (this.group.controls.country.value === this.commonValues.api.us) {
      const newAddressResponse = (validationResponse as AddressResponse);
      newValidationResponse.zipVarTgt = (newAddressResponse.components.zipcode + (newAddressResponse.components.plus4_code ? ('-' + newAddressResponse.components.plus4_code) : '')).toLowerCase().replace(/\s+/g, '');
      newValidationResponse.streetVarTgt = (newAddressResponse.delivery_line_1 ? newAddressResponse.delivery_line_1 : '').toLowerCase().replace(/\s+/g, '');
      newValidationResponse.secondaryVarTgt = (newAddressResponse.delivery_line_2 ? newAddressResponse.delivery_line_2 : '').toLowerCase().replace(/\s+/g, '');
      newValidationResponse.cityVarTgt = newAddressResponse.components.city_name.toLowerCase().replace(/\s+/g, '');
      newValidationResponse.stateTgt = newAddressResponse.components.state_abbreviation.toLowerCase().replace(/\s+/g, '');
    } else if (this.group.controls.country.value === this.commonValues.api.ca) {
      const newAddressResponse = (validationResponse as AddressInternationalResponse);
      newValidationResponse.zipVarTgt = (newAddressResponse.components.postal_code).toLowerCase().replace(/\s+/g, '');
      newValidationResponse.streetVarTgt = (newAddressResponse.address1 ? newAddressResponse.address1 : '').toLowerCase().replace(/\s+/g, '');
      newValidationResponse.secondaryVarTgt = (newAddressResponse.address2 ? newAddressResponse.address2 : '').toLowerCase().replace(/\s+/g, '');
      newValidationResponse.cityVarTgt = newAddressResponse.components.locality.toLowerCase().replace(/\s+/g, '');
      newValidationResponse.stateTgt = newAddressResponse.components.administrative_area.toLowerCase().replace(/\s+/g, '');
    } else {
      const newAddressResponse = (validationResponse as AddressInternationalResponse);
      newValidationResponse.zipVarTgt = (newAddressResponse.components.postal_code ? newAddressResponse.components.postal_code : '').toLowerCase().replace(/\s+/g, '');
      newValidationResponse.streetVarTgt = (newAddressResponse.address1 ? newAddressResponse.address1 : '').toLowerCase().replace(/\s+/g, '');
      newValidationResponse.secondaryVarTgt = (newAddressResponse.address2 ? newAddressResponse.address2 : '').toLowerCase().replace(/\s+/g, '');
      newValidationResponse.cityVarTgt = (newAddressResponse.components.locality ? newAddressResponse.components.locality : '').toLowerCase().replace(/\s+/g, '');
      newValidationResponse.stateTgt = (newAddressResponse.components.administrative_area ? newAddressResponse.components.administrative_area : '').toLowerCase().replace(/\s+/g, '');
    }

    const zipVarTgt = newValidationResponse.zipVarTgt;
    const streetVarTgt = newValidationResponse.streetVarTgt;
    const secondaryVarTgt = newValidationResponse.secondaryVarTgt;
    const cityVarTgt = newValidationResponse.cityVarTgt;
    const stateTgt = newValidationResponse.stateTgt;

    if (zipVarSrc !== zipVarTgt ||
      streetVarSrc !== streetVarTgt ||
      secondaryVarSrc !== secondaryVarTgt ||
      cityVarSrc !== cityVarTgt ||
      (stateVar !== stateTgt && (this.group.controls.country.value === this.commonValues.api.us 
      || this.group.controls.country.value === this.commonValues.api.ca))) {
        // added condition of country comparison, since for non-US/CA, state is null in request but not null in response
      this.addressCorrected = true;
      return true;
    }
    return false;
  }


  isThereEnoughInput() {
    if (!this.addressValidationRequest.request.street ||
      !this.addressValidationRequest.request.city ||
      (!this.addressValidationRequest.request.state && (this.group.controls.country.value === this.commonValues.api.us 
        || this.group.controls.country.value === this.commonValues.api.ca))) {
      this.openValidationDialog(this.commonValues.addressValidationMessages.notEnoughInput);
    }
  }


  isNullorBlank(text: string): boolean {
    if (typeof text !== 'undefined' && text !== ' ' && text) {
      return false;
    }
    return true;
  }

  verifyIfInternationalAddressIsValidForNonCanada(intAddressValidationResponse: AddressInternationalResponse[]) {
    this.continueOnlyWithValidAddress = false;
    if(intAddressValidationResponse.length === 0){
        this.values.isPremisePartialAddress = true;
        this.openValidationDialog(this.commonValues.addressValidationMessages.addressInvalid);
        return;
    }
    this.values.isPremisePartialAddress = false;
    this.emitInternationalPremisePartialAddress.next(true);
    const analysisAddress = intAddressValidationResponse[0].analysis;
    if (intAddressValidationResponse.length > 1) {
      if (analysisAddress.address_precision === 'Premise'){
        this.values.isPremisePartialAddress = true;
      }
      this.openValidationDialog(this.commonValues.addressValidationMessages.addressAmbigousInternational);
    } else {
      // single match and valid/suggested
      this.isSuggestedAddressConstructedFromResponse = this.verifyIfChangeInAnyAddressAttributes(analysisAddress);
      if ((analysisAddress.verification_status === this.commonValues.address.verified 
                || analysisAddress.verification_status === this.commonValues.address.partial)
         && this.isSuggestedAddressConstructedFromResponse ) {
        this.validateInternationalAddress2(this.addressValidationRequest, intAddressValidationResponse[0]);
        // address is valid
        // // check if address has been formatted or modified from entered address
        if (this.addressUpdatedFromOriginal(this.addressValidationRequest, intAddressValidationResponse[0])) {
          //   // address updated from original
          this.openValidationDialog(this.commonValues.addressValidationMessages.newInternationalAddressSuggested);
          return;
        }
        this.setAddressValuesFromResponse(this.intAddressValidationResponse[0], true);
        this.values.addressIsValid = true;
        this.changeFocus();
        
        return;
      } else if((analysisAddress.verification_status === this.commonValues.address.verified 
        || analysisAddress.verification_status === this.commonValues.address.partial) && !this.isSuggestedAddressConstructedFromResponse){
        this.validateInternationalAddress2(this.addressValidationRequest, intAddressValidationResponse[0]);
        // address is valid
        // // check if address has been formatted or modified from entered address
        this.setAddressValuesFromResponse(this.intAddressValidationResponse[0], true);
        this.values.addressIsValid = true;
        this.changeFocus();
      }
      else {
        this.values.isPremisePartialAddress = true;
        // single match and invalid
        // if invalid address
        this.values.addressIsValid = false;
        //const isAnyAddressPrecision = this.getAllAddressPrecision(analysisAddress.address_precision);
        const isPartialAddress = analysisAddress.verification_status === 'Partial';
        const isVerifiedAddress = analysisAddress.verification_status === 'Verified';
        const isAmbiguousAddress = analysisAddress.verification_status === 'Ambiguous';
        const isPremiseAddressPrecision = analysisAddress.address_precision === 'Premise';

        // check premise/partial address for SP and NONSP
        // isPremiseAddressPrecision = Address match is verified to a range of addresses on the specified street (i.e., building, block level or street segment).
        if (isPartialAddress && isPremiseAddressPrecision){
          this.values.isPremisePartialAddress = true;
          this.openValidationDialog(this.commonValues.addressValidationMessages.secondaryInternationalAddress);
          return;
        }
        // if it's express and security print order
        if (this.isExpressSecurePrint) {
          if ((isPartialAddress || isVerifiedAddress || isAmbiguousAddress)  && ((analysisAddress.address_precision === 'Thoroughfare') || (analysisAddress.address_precision === 'Locality') ||  (analysisAddress.address_precision === 'AdministrativeArea'))) {
            this.openValidationDialog(this.commonValues.addressValidationMessages.internationalCityNotFound);
            return;
          }
        }
        else{
          // checks if it's not express order
          if ((isPartialAddress || analysisAddress.verification_status === 'None') && ((analysisAddress.address_precision === 'Locality') || (analysisAddress.address_precision === 'AdministrativeArea') || (analysisAddress.address_precision === 'None'))){
            this.openValidationDialog(this.commonValues.addressValidationMessages.internationalCityNotFound);
            return;
          }
          else if ((isPartialAddress) && (analysisAddress.address_precision === 'Thoroughfare')){
            this.openValidationDialog(this.commonValues.addressValidationMessages.internationalMissingStreetNumber);
            return;
          }
          else if ((isVerifiedAddress) && (analysisAddress.address_precision === 'Thoroughfare')){
            this.openValidationDialog(this.commonValues.addressValidationMessages.internationalMissingStreetNumber);
            return;
          }
          else if ((isVerifiedAddress) && ((analysisAddress.address_precision === 'Locality') ||  (analysisAddress.address_precision === 'AdministrativeArea'))){
            this.openValidationDialog(this.commonValues.addressValidationMessages.internationalCityNotFound);
            return;
          }
        }
        // if all the conditions above failed then show generic invalid address message
        this.openValidationDialog(this.commonValues.addressValidationMessages.addressInvalid);
        return;
      }
    }
  }
  
  verifyIfChangeInAnyAddressAttributes(analysisAddress : any): boolean{
    let verifiedChangeObserved = false;
    Object.keys(analysisAddress.changes.components).forEach(function(key) {
      if(analysisAddress.changes.components[key] != 'Verified-NoChange' || analysisAddress.changes.components[key] != 'Identified-NoChange'){
        verifiedChangeObserved = true;
      }
    })
    return verifiedChangeObserved;
  }

  contructAddressFromSmartyComponents(intAddressValidationResponse: AddressInternationalResponse){
    var addressFormat = this.getAddressFormat(intAddressValidationResponse);
    let address1 = '';
    let address2 = '';
    let address1Constructed = false;
    addressFormat.forEach((component: String) => {
      if(component != ''){
        var attributeName = component.replace(/(\W+)/,'');
        var attributeDelimiter = component.replace(/(\w*)/,'');
        if(attributeDelimiter.includes('|')){
          attributeDelimiter = ', ';
        }
        if(!(attributeName === 'postal_code' || attributeName === 'locality' || attributeName === 'administrative_area')){
          var addressAttribute = attributeName === 'organization' ? intAddressValidationResponse.organization : intAddressValidationResponse.components[attributeName];
          var finalAddressAttributeValue = addressAttribute + attributeDelimiter;
          if(!address1Constructed && (address1.length + finalAddressAttributeValue.length < 40)){
            address1 = address1 + finalAddressAttributeValue;
          }else if(address2.length + addressAttribute.length < 40){
            address1Constructed = true;
            address2 = address2 + finalAddressAttributeValue;
          }
        }
    }
  });
    intAddressValidationResponse.address1 = address1;
    intAddressValidationResponse.address2 = address2;
     //if(!intAddressValidationResponse.components.locality){
     //  intAddressValidationResponse.components.locality = '';
     //}
    // if(intAddressValidationResponse.components.administrative_area != intAddressValidationResponse.components.locality){
    //   intAddressValidationResponse.components.locality = intAddressValidationResponse.components.locality + ", " + intAddressValidationResponse.components.administrative_area;
    // }
    intAddressValidationResponse.components.administrative_area = '';
  }

  getAddressFormat(intAddressValidationResponse: AddressInternationalResponse): any{
    if(intAddressValidationResponse.metadata.address_format){
      return intAddressValidationResponse.metadata.address_format.split(/(\w+[,\s\|-]+)/);
    }
  }
}
